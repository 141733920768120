import React, {useEffect, useRef, useState} from "react";
import {Button, Card, InputGroup, Overlay, Table, Tooltip} from "react-bootstrap";
import {BarLoader} from "react-spinners";
import apiCall from "../../../components/utils/apiCall";
import {useNavigate} from "react-router-dom";
import {JSONDateToString} from "../../../components/managers/DateManager";
import {displayToast} from "../../../components/managers/ToastManager";

export default function Dashboard() {

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const data = await apiCall("wo/exact/latest", "POST");
                const indexedData = data.map((item, index) => ({ ...item, index: index + 1 }));
                setProjects(indexedData);
            } catch (error) {
                displayToast("Er is een fout opgetreden bij het ophalen van de projecten.", "error")
            } finally {
                setLoading(false);
            }
        }

        fetchProjects();
    }, []);

    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const target = useRef(null);

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^P\d{0,4}$/.test(value)) {
            setInputValue(value);
            setIsValid(value.length === 5);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && isValid) {
            navigate(`../../wo/dashboard/details/${inputValue}`);
        }
    };

    return (
        <Card>
            <Card.Header>
                <h1>Dashboard</h1>
            </Card.Header>
            {loading ? ( <Card.Body className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}><BarLoader color='#007f6c' width={500} speedMultiplier={1} /></Card.Body> ) : (
            <Card.Body>
                <div className="d-flex justify-content-between">
                    <InputGroup>
                        <InputGroup.Text className="text-wrap">Specifiek Project Zoeken</InputGroup.Text>
                        <InputGroup.Text className="col-3 position-relative">
                            <input
                                type="text"
                                className={`form-control ${isTouched && !isValid ? 'is-invalid' : ''}`}
                                placeholder="PXXXX"
                                value={inputValue}
                                defaultValue="P"
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                onFocus={() => setIsTouched(true)}
                                onBlur={() => setIsTouched(false)}
                                ref={target}
                            />
                            <Overlay target={target.current} show={isTouched} placement="right">
                                {(props) => (
                                    <Tooltip id="input-tooltip" {...props}>
                                        Input moet beginnen met een 'P' gevolgd door 4 cijfers.
                                    </Tooltip>
                                )}
                            </Overlay>
                        </InputGroup.Text>
                    </InputGroup>

                    <Button variant="danger" className="mx-3" onClick={() => {
                        apiCall("wo/projects/sync_all_projects_in_exact", "POST", {}, {}, true);
                    }}>Sync with Exact</Button>

                    <Button variant="danger" className="mx-3" onClick={() => {
                        apiCall("wo/projects/sync_all_items_with_couchdb", "POST", {}, {}, true);
                    }}>Sync Exact Artikelen</Button>

                    {/* <Button variant="danger" onClick={() => {
                        apiCall("wo/projects/update_all_projects_in_database", "POST", {}, {}, true);
                    }}>Sync Werkorders</Button> */}

                </div>

                <hr />

                <h3>Latest Projects</h3>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th className="col-1">#</th>
                            <th className="col-1">P Nummer</th>
                            <th className="col-5">Beschrijving</th>
                            <th className="col-2">Start Datum</th>
                            <th className="col-2">Eind Datum</th>
                            <th className="col-1">Actie</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projects.map(item => (
                            <tr onClick={() => {navigate(`../../wo/dashboard/details/${item.Code}`)}}>
                                <td>{item.index}</td>
                                <td>{item.Code}</td>
                                <td className="text-wrap">{item.Description}</td>
                                <td>{JSONDateToString(item.StartDate)}</td>
                                <td>{JSONDateToString(item.EndDate)}</td>
                                <td>
                                    <Button variant="success">Bekijk Project</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
            )}
        </Card>
    );
}