import { Button, Card, Col, Form, Modal, Placeholder, ProgressBar, Row, Spinner, Table } from "react-bootstrap";
import React, { useState } from "react";
import toast from "react-hot-toast";

import $ from 'jquery';
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { GetAPIurl } from "../../components/hesselToDo/Data";

let totalItems = 0;
let itemsDone = 0;
let supline = [];

export default function details() {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState();
  const [Progress, setProgress] = useState(0);
  const [ModalData, setModalData] = useState({ show: false, notes: "Placeholder", list: [] });
  return (
    <>
      <Card>
        <Card.Header>
          <h4>Bulk voorraad check</h4>
        </Card.Header>
        <Card.Body>
          {BulkCheck(setLoaded, setData)}
          {Formopties(loaded, data, setProgress, Progress)}
          {LoadingBar(loaded, Progress)}
          {TableSet(loaded, data, setModalData)}
          {ModalDetails(ModalData, setModalData)}
        </Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" href="/materials">Terug</Button>
          &nbsp;
          {DownloadButton(loaded)}
        </Card.Footer>
      </Card>
      <div id="modallist"></div>
    </>
  );
}

function BulkCheck(setLoaded, setData) {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    const req = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      body: formData,
    };
    fetch(`${GetAPIurl()}/api/materials/bulkcheck/upload`, req)
      .then((response) => response.json())
      .then((data) => {
        toast.success("Bestand geladen.");
        setData(data);
        setLoaded(true);
      })
      .catch((error) => {
        toast.error("Ging iets fout bij het inladen van bestand");
        console.error(error);
      })
  }
  return (
    <Form onChange={handleSubmit} className="mb-3">
      <Form.Control type="file" />
    </Form>
  )
}

function LoadingBar(loaded, Progress) {
  if (!loaded) {
    return (
      <Col>
        <Placeholder xs={12} />
      </Col>
    )
  }
  return (
    <Col>
      <ProgressBar animated now={Progress} label={`${Progress}%`} />
    </Col>
  )
}

function Formopties(loaded, data, setProgress, Progress) {
  if (!loaded) {
    return (
      <Col>
        <Placeholder xs={2} style={{ marginRight: "20px" }} />
        <Placeholder xs={1} />
      </Col>
    )
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    const SearchOption = e.target['option'].value;
    switch (SearchOption) {
      case "HoogsteVoorraad":
        document.querySelectorAll('#LeverancierSelect').forEach(function (element) {
          if (!element.getAttribute("data-productid")) return;
          SupplyItemCheck(element.getAttribute("data-productid"), element.getAttribute("index"), setProgress);
        });
        break;
      case "Leverancier":
        ChangeSelectedSelectTrig("LeverancierSelect", supplier);
        break;
    }
  }
  return (
    <Col>
      <Form onSubmit={handleSubmit} className="mb-3">
        <Row>
          <Col sm={2}>
            <Form.Select id="option">
              <option value={"HoogsteVoorraad"}>Hoogste voorraad</option>
              <option value={"Leverancier"}>Leverancier</option>
            </Form.Select>
          </Col>
          <Col sm={2}>
            <Button type="submit" variant="warning">Controleren</Button>
          </Col>
        </Row>
      </Form>
    </Col>
  )
}

function ChangeSelectedSelectTrig(element, string) {
  $(element + " option").each(function () {

    $(this).filter(function () {
      return $(this).text() === string;
    }).prop('selected', true).trigger('change');
  })
}

function getHighestSupplier(data) {

  let Higgest = { quantity: 0, supplierName: "" }

  for (let i = 0; i < data.length; i++) {
    if (Higgest.quantity < data[i].quantity) {
      Higgest = data[i]
    }
  }

  return Higgest
}

function ChangeSelectedSelect(element, string) {
  $(element + " option").each(function () {

    $(this).filter(function () {
      return $(this).text() === string;
    }).prop('selected', true);
  })
}

function SupplyItemCheck(ProductID, index, setProgress) {
  $("#voorraad" + index).hide();
  $("#spinner" + index).show();
  totalItems++;
  fetch(`${GetAPIurl()}:5900/supply/highest/${ProductID}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
    }
  })
    .then((reponse) => reponse.json())
    .then((data) => {
      if (data != null) {
        let Higgest = getHighestSupplier(data)
        supline[index] = data;
        $("#voorraad" + index).val(Higgest.quantity);
        ChangeSelectedSelect("#LeverancierSelect" + index + " select", Higgest.supplierName)
        $("#voorraad" + index).show();
        $("#spinner" + index).hide();
        $("#voorraad" + index).val(Higgest.quantity);
      } else {
        $("#voorraad" + index).show();
        $("#spinner" + index).hide();
        $("#voorraad" + index).val("0");
      }
      itemsDone++;
      setProgress((itemsDone / totalItems) * 100)
    })
    .catch((error) => {
      toast.error("Ging iets fout met voorraad checken");
      console.error("error: " + error);
      $("#voorraad" + index).show();
      $("#spinner" + index).hide();
      $("#voorraad" + index).val("0");
    });
}

function TableSet(loaded, data, setModalData) {
  if (!loaded) return;
  if (!data) return;

  const OpenModalDetail = (e) => {
    e.preventDefault();
    const productid = data[e.target.value].ProductID;
    fetch(`${GetAPIurl()}:5900/supply/highest/${productid}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((reponse) => reponse.json())
      .then((voorraaddata) => {
        setModalData({
          show: true,
          notes: data[e.target.value].Notes,
          list: voorraaddata,
          index: e.target.value
        });
      })
      .catch((error) => {
        toast.error("Ging iets fout met voorraad checken");
        console.error("error: " + error);
      });

  }
  return (
    <Table id="TableCheck" striped responsive>
      <colgroup>
        <col />
      </colgroup>
      <thead>
        <tr>
          <th style={{ width: "180px" }}>ItemCode</th>
          <th style={{ width: "120px" }}>Inbouwplaats</th>
          <th style={{ width: "180px" }}>MaterialCode</th>
          <th style={{ width: "60px" }}>Quantity</th>
          <th style={{ width: "180px" }}>Aantekeningen</th>
          <th style={{ width: "60px" }}>Drawing</th>
          <th style={{ width: "80px" }}>Fabrikant</th>
          <th style={{ width: "80px" }}>Bestelnummer</th>
          <th style={{ width: "180px" }}>GTIN</th>
          <th style={{ width: "160px" }}>Product Hoofdgroep</th>
          <th style={{ width: "160px" }}>Bestellen/Levering</th>
          <th style={{ width: "160px" }}>Leverancier</th>
          <th style={{ width: "160px" }}>Voorraad</th>
          <th>({data.length})</th>
        </tr>
      </thead>
      <tbody>
        {data.map((element, index) => (
          <tr key={index}>
            <td>{element.ItemCode}</td>
            <td>{element.Inbouwplaats}</td>
            <td>{element.MaterialCode}</td>
            <td>{element.Quantity}</td>
            <td>{element.Notes}</td>
            <td>{element.Drawing}</td>
            <td>{element.Fabrikant}</td>
            <td>{element.Bestelnummer}</td>
            <td>{element.GTIN}</td>
            <td>{element.Product_Hoofdgroep}</td>
            <td>{element.Bestellen_Leveren}</td>
            <td id={"LeverancierSelect" + index}>
              <Form.Select index={index} data-productid={element.ProductID} id={"LeverancierSelect"}>
                {element.SelectListItems && (
                  element.SelectListItems.map((item) => (
                    <option key={item.Value} value={item.Value}>
                      {item.Text}
                    </option>
                  ))
                )}
              </Form.Select>
            </td>
            <td>
              <Col style={{ display: "-webkit-inline-box" }}>
                <Spinner id={"spinner" + index} variant="primary" style={{ display: "none" }} />
                <Form.Control id={"voorraad" + index} type="number" placeholder={element.Voorraad} readOnly />
              </Col>
            </td>
            <td><Button onClick={OpenModalDetail} value={index}>Details</Button></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function ModalDetails(data, setModalData) {
  if (!data.notes) {
    setModalData({ show: data.show, notes: "Geen beschrijving", list: data.list });
  }

  const handleClose = () => setModalData({ show: false, notes: "Placeholder", list: [] });

  const handleSelect = (element) => {
    ChangeSelectedSelect("#LeverancierSelect" + data.index + " select", element.supplierName);
    $("#voorraad" + data.index).val(element.quantity);
  }

  return (
    <Modal show={data.show}>
      <Modal.Header closeButton>
        <Modal.Title>{data.notes}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Leverancier</th>
              <th>Voorraad</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((element, index) => (
              <tr key={index}>
                <td>{element.supplierName}</td>
                <td>{element.quantity}</td>
                <td><Button onClick={() => handleSelect(element)}>Select</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Sluiten</Button>
      </Modal.Footer>
    </Modal>
  );
}

function DownloadButton(loaded) {
  if (!loaded) {
    return (
      <Placeholder xs={1} />
    )
  }

  const Download = (e) => {
    e.preventDefault();

    let datalist = [];
    $('#TableCheck tbody tr').each(function () {

      var itemCode = "";
      var inbouwPlaats = "";
      var materialCode = "";
      var quantity = "";
      var notes = "";
      var drawing = ""
      var fabrikant = "";
      var bestelnummer = "";
      var gtinnumber = 0;
      var bestellen_Leveren = "";
      var productHoofdgroep = "";
      var leverancier = "";
      var voorraad = "";

      if ($(this)[0].cells.length > 0) {
        $(this).find('td').each(function (index) {
          switch (index) {
            case 0: itemCode = $(this).text(); break;
            case 1: inbouwPlaats = $(this).text(); break;
            case 2: materialCode = $(this).text(); break;
            case 3: quantity = $(this).text(); break;
            case 4: notes = $(this).text(); break;
            case 5: drawing = $(this).text(); break;
            case 6: fabrikant = $(this).text(); break;
            case 7: bestelnummer = $(this).text(); break;
            case 8: gtinnumber = $(this).text(); break;
            case 9: productHoofdgroep = $(this).text(); break;
            case 10: bestellen_Leveren = $(this).text(); break;
            case 11: leverancier = parseInt($(this).find('select').val()) || 0; break;
            case 12: voorraad = parseInt($(this).find('input').val()) || 0; break;
          }
        });
        let row = {
          itemcode: itemCode,
          inbouwplaats: inbouwPlaats,
          materialcode: materialCode,
          quantity: Number(quantity),
          notes: notes,
          drawing: drawing,
          fabrikant: fabrikant,
          bestelnummer: Number(bestelnummer),
          gtin: gtinnumber,
          product_hoofdgroep: productHoofdgroep,
          bestellen_leveren: bestellen_Leveren,
          selectlistitemsid: Number(leverancier),
          voorraad: Number(voorraad)
        }
        datalist.push(row);
      }
    });
    const DataList = datalist;

    let request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify(DataList)
    }

    fetch(`${GetAPIurl()}/api/materials/bulkcheck/download`, request)
      .then((response) => response.json())
      .then((datatable) => {
        let request2 = {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(datatable)
        }
        fetch(`${GetAPIurl()}/api/eplan/Excel/CreateXLSX`, request2)
          .then((response) => response.json())
          .then((datafile) => {
            const zip = JSZip();
            zip.file(`${format(new Date(), "ddMMyyyy")}_bulkvoorraadcheck.xlsx`, datafile, { base64: true })
            zip.generateAsync({ type: "blob" })
              .then((content) => {
                saveAs(content, `${format(new Date(), "ddMMyyyy")}_bulkvoorraadcheck.zip`);
              })
              .catch((error) => {
                toast.error("Ging wat fout bij downloaden.");
                console.error(error);
              })
          })
      })
      .catch((error) => {
        toast.error("Ging iets fout met downloaden");
        console.error("error: " + error);
      });
  }

  return (
    <Button variant="primary" onClick={Download}>Download</Button>
  )
}