import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { usePagination } from "../managers/PaginationManager";
import apiCall from "../utils/apiCall";
import { Search } from "../utils/searchUtils";
import { displayToast } from "../managers/ToastManager";

export default function CompanyList() {
    const navigate = useNavigate();
    const [originalCompanies, setOriginalCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState("asc");

    useEffect(() => {
        (async () => {
            const companies = await apiCall("company/all", "POST");
            const sortedCompanies = sortCompanies(companies, sortOrder);
            setFilteredCompanies(sortedCompanies);
            setOriginalCompanies(sortedCompanies);
            setLoading(false);
            displayToast("Alle bedrijven zijn opgehaald", "success");
        })();
    }, [sortOrder]);

    const handleSearchChange = (e) => {
        const newQuery = e.target.value;
        let filtered = originalCompanies;
        if (newQuery.trim()) {
            filtered = Search(filtered, newQuery);
        }
        setFilteredCompanies(sortCompanies(filtered, sortOrder));
    };

    const sortCompanies = (companies, order) => {
        return companies.slice().sort((a, b) => {
            if (order === "asc") {
                return a.Name.localeCompare(b.Name);
            } else {
                return b.Name.localeCompare(a.Name);
            }
        });
    };

    const { currentItems, AboveTable, BelowTable, indexOfFirstItem } = usePagination(filteredCompanies);

    return (
        <>
            <Card>
                <Card.Header>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h1 id="tableLabel" style={{ marginRight: "10px" }}>Companies</h1>
                            <Button variant="success" href="/companies/create">
                                <i className="fas fa-plus"></i>
                            </Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col md={2}>
                            <Form.Control
                                type="text"
                                name="SearchString"
                                placeholder="Zoeken..."
                                onChange={handleSearchChange}
                            />
                        </Col>
                    </Row>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <h3 className="me-2">Loading companies...</h3>
                            <Spinner animation="border" role="status">
                                <span className="sr-only"></span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            {AboveTable}
                            <Table className="mt-2" striped hover>
                                <colgroup>
                                    <col className="text-align" style={{ width: "5%" }} />
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "30%" }} />
                                    <col style={{ width: "20%" }} />
                                    <col style={{ width: "25%" }} />
                                    <col style={{ width: "15%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th>Code</th>
                                        <th onClick={() => {
                                            const newOrder = sortOrder === "asc" ? "desc" : "asc";
                                            setSortOrder(newOrder);
                                            setFilteredCompanies(sortCompanies(filteredCompanies, newOrder));
                                            }}>
                                            Naam</th>
                                        <th>Stad</th>
                                        <th>Land</th>
                                        <th>Actie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((company, index) => (
                                        <tr key={company._id} onClick={() => navigate(`details/${company._id}`)}>
                                            <td className="text-center">{indexOfFirstItem + index + 1}</td>
                                            <td>{company.Code}</td>
                                            <td>{company.Name ? company.Name : <span style={{ color: 'red' }}>Niet bekend</span> }</td>
                                            <td>{company.City ? company.City : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>{company.Country ? company.Country : <span style={{ color: 'red' }}>Niet bekend</span> }</td>
                                            <td>
                                                <Button variant="info" onClick={() => { navigate(`details/${company._id}`) }}><i
                                                    className={"fa fa-info-circle"} /> Informatie</Button>
                                                {" "}
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {company.Employees.length > 0
                                                                ? `Werknemers: ${company.Employees.length}`
                                                                : 'Nog geen werknemers'}
                                                        </Tooltip>
                                                    }
                                                    >
                                                    <Badge bg="primary" text="dark" pill>{company.Employees.length > 0 ? company.Employees.length : '0'}</Badge>
                                                </OverlayTrigger>
                                                {" "}
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {company.Projects.length > 0
                                                                ? `Projecten: ${company.Projects.length}`
                                                                : 'Nog geen projecten'}
                                                        </Tooltip>
                                                    }
                                                    >
                                                    <Badge bg="warning" text="dark" pill>{company.Projects.length > 0 ? company.Projects.length : '0'}</Badge>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {BelowTable}
                        </>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}
