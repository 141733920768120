import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import apiCall, { apiCallRaw } from "../../components/utils/apiCall";
import { displayToast } from "../../components/managers/ToastManager";
import ExportButton from "../../components/utils/ExportButton";

export default function Export() {
  const [loading, setLoading] = useState(false);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [types, setTypes] = useState([]);
  const [targets, setTargets] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [selectedTarget, setSelectedTarget] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({});

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const data = await apiCall("export_generator_manager/available_projects", "POST");
        const document_misc = await apiCall("export_generator_manager/available_types", "POST");
        setTypes(document_misc.types);
        setTargets(document_misc.targets);
        setLanguages(document_misc.languages);
        setAvailableProjects(data);
      } catch (error) {
        displayToast("Er is een fout opgetreden bij het ophalen van de projectgegevens.", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleDownload = async (projectId) => {
    try {
      setLoading(true);
      const response = await apiCallRaw("export_generator_manager/generate", "POST", {
        project_id: projectId,
        doc_type: selectedType[projectId],
        doc_target: selectedTarget[projectId],
        doc_language: selectedLanguage[projectId],
      });

      if (!response.ok) {
        throw new Error('Download failed');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `EXPORT-GENERATOR-PROJECT-${projectId}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      displayToast("Er is een fout opgetreden bij het downloaden van het bestand.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (e, projectId, type) => {
    const value = e.target.value;
    if (type === "type") {
      setSelectedType((prev) => ({ ...prev, [projectId]: value }));
    } else if (type === "target") {
      setSelectedTarget((prev) => ({ ...prev, [projectId]: value }));
    } else if (type === "language") {
      setSelectedLanguage((prev) => ({ ...prev, [projectId]: value }));
    }
  };

  const isButtonDisabled = (projectId) => {
    return !(
      selectedType[projectId] &&
      selectedTarget[projectId] &&
      selectedLanguage[projectId]
    );
  };

  return (
    <Card>
      <Card.Header>
        <h1>Export</h1>
      </Card.Header>
      <Card.Body>
        {loading ? (
          <p>Gegevens worden geladen...</p>
        ) : (
          <p>Er zijn {availableProjects.length} projecten beschikbaar om te exporteren.</p>
        )}

        <Card.Text>
          <ul>
            {availableProjects.map((project, index) => (
              <li key={index} className="m-2">
                <h3>{project.ID}</h3>
                <ExportButton projectId={project.ID} disabled={loading} />
              </li>
            ))}
          </ul>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
