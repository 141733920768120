import React, { useEffect, useState } from 'react';
import { Button, Form, Offcanvas, Table } from 'react-bootstrap';
import AddColumn from '../propertyboxes/AddColumnToColumnList.jsx';
import { useNavigate } from 'react-router-dom';
import apiCall from '../utils/apiCall.jsx';
import { displayToast } from '../managers/ToastManager.jsx';
import { usePagination } from '../managers/PaginationManager.jsx';
import { PropertyBoxMeta } from '../propertyboxes/PropertyBoxes.jsx';
import { capitalize } from '@mui/material';
import { displayConfirmAlertCustom } from "../managers/AlertManager";
import ImportFile from "../managers/ImportManager";
import ExportDialogBox from "../managers/ExportManager";
import { useAuth } from "../../context/AuthContext";
import { usesort } from '../managers/SortingManager.jsx';

/**
 * README ColumnsTable
 *
 * ColumnsTable.jsx is een React-componentbestand dat de hoofdcomponent ColumnsTable bevat.
 * Deze component wordt gebruikt om eigenschappen van verschillende kolommen in de applicatie weer te geven en te manipuleren.
 *
 * ===========================================================================================================================
 *
 * ColumnsTable:
 * Deze component wordt gebruikt om de eigenschappen van kolommen weer te geven en te bewerken.
 * Het biedt een gebruikersinterface voor het bewerken van de eigenschappen van een geselecteerde rij in de kolommenlijst.
 * De eigenschappen omvatten de naam van de kolom, de groep, het element, het document-ID, het type, de antwoordopties en of het actief is.
 * De component maakt gebruik van Bootstrap voor stijl en lay-out.
 *
 * ============================================================================================================================
 *
 * Gebruik: Om deze component te gebruiken, importeer je deze vanuit het ColumnsTable.jsx-bestand en voeg je deze toe aan je React-component.
 * Je moet de benodigde props voor de component verstrekken.
 * De props omvatten de geselecteerde rijgegevens, de functie om het formulier in te dienen, de functie om een rij te verwijderen en andere gerelateerde gegevens en functies.
 *
 * Opmerking: Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je verstrekt overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een object met de naam selectedRow dat de gegevens van de geselecteerde rij bevat, en een functie met de naam handleSubmitMeta die de indiening van het formulier afhandelt
 *
 */

/**
 *
 * @param defaultColumns
 * @param columns
 * @param setColumns
 * @param type
 * @param taskgroups
 * @param newColumn
 * @param setNewColumn
 * @param dataTypes
 * @param dataTargets
 * @returns {Element}
 * @constructor
 */

// Component om de kolommen van de kolommenlijst weer te geven
const ColumnsTable = ({
    defaultColumns,
    columns,
    setColumns,
    group,
    type,
    taskgroups,
    newColumn,
    setNewColumn,
    dataTypes,
    dataTargets
}) => {
    const [showCreate, setShowCreate] = useState(false);
    const [offcanvas, setOffcanvas] = useState(false)
    const [displayColumns, setDisplayColumns] = useState(["Name", "Group", "Element", "DocID", "Type", "AnswerOptions", "isActive"]);
    const [fullwidth, setFullwidth] = useState("50vw");
    const [selectedRow, setSelectedRow] = useState(null);
    const [unchangedSelectedRow, setUnchangedSelectedRow] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [selectedElementData, setSelectedElementData] = useState({});
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedItems, setSortedItems] = useState();
    const { currentItems, AboveTable, BelowTable, indexOfFirstItem, reset } = usePagination(columns);
    const navigate = useNavigate();
    const { user } = useAuth();

    // Functie om een kolom te verwijderen
    const handleDeleteColumn = async (column) => {
        const index = defaultColumns.indexOf(column);
        setOffcanvas(false);
        displayConfirmAlertCustom(
            "Kolom verwijderen",
            <p className={"text-danger"}> Weet u zeker dat u de kolom wilt verwijderen?</p>,
            "confirm",
            [async () => {
                try {
                    await apiCall(`metadata/columns_delete`, 'POST', { index: index, Type: capitalize(type) });
                    navigate(0);
                } catch (error) {
                    displayToast('Er is iets misgegaan bij het verwijderen van de kolom', 'error');
                }
            },
                null],
            ["Verwijderen",
                "Annuleren"],
        );
    }
    // Functie om de metadata van een kolom te updaten of toe te voegen
    const handleSubmitMeta = async () => {
        // Add the new or updated row to the columns
        setOffcanvas(false);
        const selectedElementDataKeys = Object.keys(selectedElementData);
        // set the new metadata in the selectedRow
        selectedElementDataKeys.forEach(key => {

            key === "Name" ? selectedRow.Name = selectedElementData[key] : selectedRow.Metadata[key] = selectedElementData[key];
        });

        try {
            await apiCall('metadata/columns_update', "POST", {
                DocID: selectedRow.Metadata.DocID,
                Data: { "Name": selectedRow.Name, "Metadata": selectedRow.Metadata },
                Type: capitalize(type)
            });
        } catch (error) {
            console.error(error);
            displayToast('Er is iets misgegaan bij het updaten van de kolom', 'error');
        }
        try {
            setColumns(await apiCall(`metadata/columns`, 'POST'));
        } catch (error) {
            console.error(error);
        }
        finally {
            displayToast('Kolom is succesvol bijgewerkt', 'success');
        }
        setSelectedElementData("")
    };
    // Zet de hash naar metadata als er een kolom is geselecteerd
    useEffect(() => {
        window.location.hash = "metadata";
        setUnchangedSelectedRow(selectedRow);
        //set the selectRow.Metadata.Type in the selectedElementData
        setSelectedElementData({ "Type": selectedRow?.Metadata.Type });
    }, [selectedRow]);

    useEffect(() => {
        setSortedItems(currentItems);
    }, [AboveTable, selectedElementData]);

    return (
        <>
            {showCreate &&
                <AddColumn show={showCreate} type={type}
                    close={() => setShowCreate(false)}
                    valueOf={columns}
                    newColumn={newColumn}
                    setNewColumn={setNewColumn}
                    fullwidth={fullwidth}
                    setFullwidth={setFullwidth}
                    dataTargets={dataTargets}
                    dataTypes={dataTypes}
                    groups={[...new Set(defaultColumns.map(column => column.Metadata.Group))]}
                    elements={[...new Set(defaultColumns.map(column => column.Metadata.Element))]}
                    exportnames={[...new Set(defaultColumns.map(column => column.Metadata.ExportName))]}
                />
            }
            <>
                <Button className='m-1' disabled={!user.Roles.includes("Admin")} variant='success'
                    onClick={() => setShowCreate(true)}><i
                        className={"fa fa-plus"} /> Add New Column</Button>
                <Button disabled={!user.Roles.includes("Admin")} className='m-1' variant="warning"
                    onClick={() => ImportFile(type)}><i
                        className={"fa fa-download"} /> Import Column List</Button>
                <Button disabled={!user.Roles.includes("Admin")} className={'m-1'} variant={'info'}
                    onClick={() => ExportDialogBox(columns, `Kolommen-${type}-${group}`, "")}><i
                        className={"fa fa-upload"} /> Export Column List</Button>

            </>
            {AboveTable}
            <Table striped hover id='main'>
                <colgroup>
                    <col span={1} style={{ width: '2.5%' }} />
                    <col span={1} style={{ width: '12.5%' }} />
                    <col span={1} style={{ width: '10%' }} />
                    <col span={1} style={{ width: '15%' }} />
                    <col span={1} style={{ width: '15%' }} />
                    <col span={1} style={{ width: '10%' }} />
                    <col span={1} style={{ width: '30%' }} />
                    <col span={1} style={{ width: '5%' }} />

                </colgroup>
                <thead>
                    <tr>
                        <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, "#")}>#</th>
                        {
                            displayColumns.map((column, index) => (
                                <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, column)}>{column}</th>
                            ))
                        }

                    </tr>
                </thead>
                {sortedItems &&
                    <tbody>
                        {sortedItems.map((column, index) => (
                            <tr key={column.Name} onClick={() => {
                                setSelectedRowIndex(index);
                                setSelectedRow(column);
                                setOffcanvas(true);
                            }}>

                                <td className='text-center'>{index + 1}</td>
                                <td>
                                    {column.Name}
                                </td>
                                <td>
                                    {column.Metadata.Group}
                                </td>
                                <td>
                                    {column.Metadata.Element}
                                </td>
                                <td>
                                    {column.Metadata.DocID}
                                </td>
                                <td>
                                    {column.Metadata.Type}
                                </td>
                                <td>
                                    {column.Metadata.AnswerOptions.map((option, index) => {
                                        if (typeof option === "number") {
                                            return <span className={"text-wrap"}
                                                key={index}>{option}{index < column.Metadata.AnswerOptions.length - 1 ? ', ' : ''}</span>
                                        }
                                        else {
                                            return <span className={"text-wrap"}
                                                key={index}>{Object.values(option)}{index < column.Metadata.AnswerOptions.length - 1 ? ', ' : ''}</span>
                                        }
                                    })}
                                </td>
                                <td>
                                    <Form.Check
                                        type='switch'
                                        id={`switch-${index}`}
                                        label=''
                                        disabled={true}
                                        checked={column.Metadata.isActive}
                                    />
                                </td>
                            </tr>

                        ))}
                    </tbody>
                }
            </Table>
            {BelowTable}
            {selectedRow && (
                <Offcanvas show={offcanvas} onHide={() => {
                    handleSubmitMeta();
                    setSelectedRowIndex(null);
                    setSelectedRow(null);
                    setSelectedElementData("");
                }
                } style={{ width: fullwidth }} placement='end'>
                    <Offcanvas.Header closeButton>
                        <i className="fa fa-arrows-alt fa-2x fullscreen"
                            onClick={() => fullwidth === "50vw" ? setFullwidth("100vw") : setFullwidth("50vw")} />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <PropertyBoxMeta
                            user={user}
                            handleSubmitMeta={handleSubmitMeta}
                            handleDeleteColumn={handleDeleteColumn}
                            offcanvas={offcanvas}
                            setOffCanvas={setOffcanvas}
                            taskgroups={taskgroups}
                            setSelectedElementData={setSelectedElementData}
                            selectedElementData={selectedElementData}
                            selectedRow={selectedRow}
                            selectedRowIndex={selectedRowIndex}
                            unchangedSelectedRow={unchangedSelectedRow}
                            dataTypes={dataTypes}
                            dataTargets={dataTargets}
                        />
                    </Offcanvas.Body>
                </Offcanvas>

            )}
        </>
    );
}
export default ColumnsTable;