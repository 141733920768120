import {apiCallRaw} from "../utils/apiCall";

async function EmployeeData(id) {
    const response = await apiCallRaw(`employee/getbyid/${id}`, "POST");
    const data = await response.json();
    return data;
}

class EmployeeManager {
    constructor(employee) {
        this.employee = employee;
    }

    static async create(id) {
        const employee = await EmployeeData(id);
        return new EmployeeManager(employee);
    }
}

export default EmployeeManager;