


/**
 * 
 * @param {Array} items 
 * @param {String} sortField 
 * @param {String} sortDirection 
 * @param {Function} setItems
 * @param {Function} setSortDirection
 * 
 * @returns 
 */
export const usesort = (items, setItems, setSortDirection, sortDirection, sortField) => {
    const multiplier = sortDirection === 'asc' ? 1 : -1;
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');

    const sortedItems = [...items].sort((a, b) => {
        let aValue = a[sortField] !== undefined ? a[sortField] : (a.Metadata && a.Metadata[sortField]);
        let bValue = b[sortField] !== undefined ? b[sortField] : (b.Metadata && b.Metadata[sortField]);

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue) * multiplier;
        }
        return 0;
    });

    setItems(sortedItems);
};

