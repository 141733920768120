import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";

export const DarkModeToggle = () => {

  const [darkMode, setDarkMode] = useState(null);

  useEffect(() => {
    const isDark = localStorage.getItem("darkmode");
    if (isDark === "true") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  useEffect(() => {
    if (darkMode === null) return;
    if (darkMode) {
      localStorage.setItem("darkmode", "true");
      document.body.classList.toggle("dark", true);
      document.body.setAttribute("data-bs-theme", "dark");
    } else {
      localStorage.setItem("darkmode", "false");
      document.body.classList.remove("dark");
      document.body.setAttribute("data-bs-theme", "light");
    }
  }, [darkMode]);

  return (
    <Button variant={darkMode ? "light" : "dark"} onClick={() => setDarkMode(!darkMode)}>
      <i className={darkMode ? "fas fa-sun" : "fas fa-moon"}></i>
    </Button>
  );
};