import {Button, Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import {useClockifyQuery} from "../../../../components/hesselToDo/Clockify";
import { useParams} from 'react-router-dom';

export default function Detail() {
    
    const { id } = useParams();

    return (
        <>
            <Card>
                <Card.Header><h4>Details - {id}</h4></Card.Header>
                <Card.Body>{Details(id)}</Card.Body>
                <Card.Footer><Button variant="outline-primary" href="/clockify/projects">Terug</Button></Card.Footer>
            </Card>
        </>
    );
}

function Details(id) {
    const { data, error, loading } = useClockifyQuery(`projects/${id}?hydrated=true`);
    
    if (error) return <div className="text-danger">Laden mislukt</div>;
    if (loading) return <div className="text-primary">Bezig met laden...</div>;
    return (
        <Form>
            <Row>
                <Col sm="8">
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">ID</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="id" defaultValue={data.id} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Kleur</Form.Label>
                        <Col sm="9"><Form.Control type="color" id="kleur" defaultValue={data.color} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Naam</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="name" defaultValue={data.name} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Beschrijving</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="note" defaultValue={data.note} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Klant</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="note" defaultValue={data.client ? data.client.name : "Niet gekoppeld"} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Openbaar</Form.Label>
                        <Col sm="9"><Form.Check type="switch" id="public" defaultChecked={data.public} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Opslag</Form.Label>
                        <Col sm="9"><Form.Check type="switch" id="archived" defaultChecked={data.archived} disabled /></Col>
                    </Form.Group>
                </Col>
                <Col sm="4">
                    <h5>Taken</h5>
                    <ListGroup numbered id="taken">
                        {data.tasks.map((element) => (
                            <ListGroup.Item key={element.id} className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">{element.name}</div>
                                {/* <div style={{ backgroundColor: element.status == "ACTIVE" ? "green" : "red", color: "white", padding: "2px", borderRadius: "5px" }}>{element.status == "ACTIVE" ? "Actief" : "Non actief"}</div> */}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        </Form>
    );
}