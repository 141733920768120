// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wvb-text::after {
    content: "Werkvoorbereider:";
}

.wo-name::after,
.wo-amount::after,
.wo-status::after {
    display: block;
    content: "";
}

@media (max-width: 800px) {
    .wvb-text::after {
        content: "WVB:";
    }

    .wo-name,
    .wo-amount,
    .wo-status {
        display: none !important;
    }
}

.table-auto-width {
    table-layout: auto;
    width: 100%;
}

.wo-amount,
.wo-num {
    width: 1%;
    white-space: nowrap;
}

.input-group-text {
    border-radius: 0;
    border: 1px solid #5a5a5a;
    box-shadow: none !important;
}

.custom-badge-color {
    background-color: #3300be;
    color: #000;
    font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/styles/wo/wo-global.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;;;IAGI,cAAc;IACd,WAAW;AACf;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;;;QAGI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;;IAEI,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB","sourcesContent":[".wvb-text::after {\n    content: \"Werkvoorbereider:\";\n}\n\n.wo-name::after,\n.wo-amount::after,\n.wo-status::after {\n    display: block;\n    content: \"\";\n}\n\n@media (max-width: 800px) {\n    .wvb-text::after {\n        content: \"WVB:\";\n    }\n\n    .wo-name,\n    .wo-amount,\n    .wo-status {\n        display: none !important;\n    }\n}\n\n.table-auto-width {\n    table-layout: auto;\n    width: 100%;\n}\n\n.wo-amount,\n.wo-num {\n    width: 1%;\n    white-space: nowrap;\n}\n\n.input-group-text {\n    border-radius: 0;\n    border: 1px solid #5a5a5a;\n    box-shadow: none !important;\n}\n\n.custom-badge-color {\n    background-color: #3300be;\n    color: #000;\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
