import {Button, Col, Form, Placeholder, Row} from "react-bootstrap";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import DataTable from "datatables.net-dt";
import {OneFileCheck} from "../../../components/hesselToDo/Checkfiles";
import {MakeColorTable} from "../../../components/hesselToDo/Tables";

import {GetAPIurl} from "../../../components/hesselToDo/Data";

export default function Converter() {
  const [FileLoaded, setFileLoaded] = useState(false);
  const [TableType, setTableType] = useState("originalList");

  useEffect(() => (
    window.onscroll = function () { scrollFunction() }
  ))

  const GoToTop = (e) => {
    e.preventDefault();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const GoToBottom = (e) => {
    e.preventDefault();
    document.body.scrollTop = document.body.scrollHeight + 2000000 * 99;
    document.documentElement.scrollTop = document.body.scrollHeight + 2000000 * 99;
  }
  return (
    <>
      <Row className="mb-3">
        {Start(setFileLoaded)}
        {TelLijst(FileLoaded, setTableType)}
        {Download(FileLoaded)}
        {Export(FileLoaded, setTableType)}
      </Row>
      {GetTableList(FileLoaded, TableType)}
      <Row id="gotobuttons" className="gotobuttons">
        <Col>
          <Button className="btn-floating" onClick={GoToTop} title="Ga naar boven">
            <i className="fas fa-arrow-up"></i>
          </Button>
        </Col>
        <Col>
          <Button variant="warning" className="btn-floating" onClick={GoToBottom} title="Ga naar beneden">
            <i className="fas fa-arrow-down"></i>
          </Button>
        </Col>
      </Row>
    </>
  );
}

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementById("gotobuttons").style.display = "block"
  } else {
    document.getElementById("gotobuttons").style.display = "none";
  }
}

function Start(setFileLoaded) {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!OneFileCheck(e.target.files, ["xlsx", "xls", "csv"])) return;

    var formData = new FormData();
    formData.append('file', e.target.files[0]);

    var myheaders = {
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: formData,
    };

    fetch(`${GetAPIurl()}/api/eplan/material/converter/file`, req)
      .then((d) => {
        if (d.status === 200) {
          toast.success("Gelukt!");
          setFileLoaded(true);
          document.getElementById("mainfooter").style.display = "none";
        } else {
          toast.error("Gefaald");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  }
  return (
    <Col>
      <h3>Materiaallijst converter</h3>
      <span>
        Hier wordt de lijst vanuit hardware in gedaan, om dan een lijst(en) te
        krijgen voor werkvoorbereiding.
      </span><br /><br />
      <h6>Voer hier uw bestand in:</h6>
      <Form className="was-validated">
        <Form.Group className="input-group mb-3">
          <label htmlFor="file" className="btn btn-outline-primary"><i className="fas fa-cloud-arrow-up"></i></label>
          <Form.Control type="file" id="file" className="btn btn-outline-primary" accept=".xlsx, .xls, .csv" required onChange={handleSubmit} />
        </Form.Group>
      </Form>
    </Col>
  );
}

function TelLijst(FileLoaded, setTableType) {
  const handleChange = (e) => {
    setTableType(e.target.value)
  }
  return (
    <Col>
      {LijstOrigi(FileLoaded)}
      <br />
      {LijstFiltered(FileLoaded)}
      <br />
      {LijstGecomb(FileLoaded)}
      <br />
      {TextKasten(FileLoaded)}
      <Form id="optionsmenu">
        <Form.Group className="input-group mb-3">
          <label htmlFor="select" className="btn btn-outline-primary"><i className="fas fa-bars"></i></label>
          <Form.Select id="select" className="btn btn-outline-primary" onChange={handleChange}>
            <option value="originalList" defaultChecked>Main</option>
            <option value="filterdList">Filtered</option>
            <option value="combinedList">Combined</option>
            {GetReject(FileLoaded)}
            {GetOptions(FileLoaded)}
          </Form.Select>
        </Form.Group>
      </Form>
    </Col>
  );
}

function GetReject(FileLoaded) {
  const [data, setData] = useState();
  useEffect(() => {
    if (FileLoaded) {
      var req = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: "rejectList",
      };
      fetch(`${GetAPIurl()}/api/eplan/material/getlijst`, req)
        .then((response) => response.json())
        .then((res) => setData(res))
        .catch((error) => console.error(error));
    }
  }, [FileLoaded])
  if (!data) return;
  if (data["Item2"].length > 0) return (
    <option value={"rejectList"}>Geweigerd</option>
  );
}

function GetOptions(FileLoaded) {
  const [data, setData] = useState();
  useEffect(() => {
    if (FileLoaded) {
      fetch(`${GetAPIurl()}/api/eplan/material/getinbouwplaats`)
        .then((response) => response.json())
        .then((res) => setData(res))
        .catch((error) => console.error(error));
    }
  }, [FileLoaded])
  if (!data) return <option className="text-warning">Bezig met laden...</option>;
  return data
    .map((optie) => (
      <option key={optie} value={optie}>
        {optie}
      </option>
    ));
}

function LijstOrigi(FileLoaded) {
  const [data, setData] = useState();
  useEffect(() => {
    if (FileLoaded) {
      var req = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: "originalList",
      };
      fetch(`${GetAPIurl()}/api/eplan/material/getlijst`, req)
        .then((response) => response.json())
        .then((res) => setData(res))
        .catch((error) => console.error(error));
    }
  }, [FileLoaded])
  if (!data) return <Placeholder xs={12} />;
  var qcount = data.Item2.reduce((accumulator, item) => accumulator + item.Quantity, 0);
  return (
    <span>Originele bestand: {qcount} onderdelen</span>
  )
}

function LijstFiltered(FileLoaded) {
  const [data, setData] = useState();
  useEffect(() => {
    if (FileLoaded) {
      var req = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: "filteredList",
      };
      fetch(`${GetAPIurl()}/api/eplan/material/getlijst`, req)
        .then((response) => response.json())
        .then((res) => setData(res))
        .catch((error) => console.error(error));
    }
  }, [FileLoaded])
  if (!data) return <Placeholder xs={12} />;
  var qcount = data.Item2.reduce((accumulator, item) => accumulator + item.Quantity, 0);
  return (
    <span>Filtered bestand: {qcount} onderdelen</span>
  )
}

function LijstGecomb(FileLoaded) {
  const [data, setData] = useState();
  useEffect(() => {
    if (FileLoaded) {
      var req = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: "combinedList",
      };
      fetch(`${GetAPIurl()}/api/eplan/material/getlijst`, req)
        .then((response) => response.json())
        .then((res) => setData(res))
        .catch((error) => console.error(error));
    }
  }, [FileLoaded])
  if (!data) return <Placeholder xs={12} />;
  var qcount = data.Item2.reduce((accumulator, item) => accumulator + item.Quantity, 0);
  return (
    <span>Gecombineerd bestand: {qcount} onderdelen</span>
  )
}

function TextKasten(FileLoaded) {
  const [data, setData] = useState();
  useEffect(() => {
    if (FileLoaded) {
      var req = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: "InbouwPlaatsen",
      };
      fetch(`${GetAPIurl()}/api/eplan/material/getlijstvanlijst`, req)
        .then((response) => response.json())
        .then((res) => setData(res))
        .catch((error) => console.error(error));
    }
  }, [FileLoaded])
  if (!data) return <Placeholder xs={12} />;
  var Kastencount = 0;
  data.Item1.forEach(element => {
    element.forEach(item => {
      Kastencount += item.Quantity;
    })
  });
  return (
    <span>{data["Item1"].length} losse kasten bij elkaar: {Kastencount} onderdelen</span>
  )
}

function FileDownload(data, setDownloading) {
  const zip = new JSZip();
  zip.file(`${data['date']}_${data['project']}_Total.${data['extension']}`, data['filterd'], { base64: true })

  if (document.getElementById('expo-kast').checked) {
    for (let index = 0; index < data['plaatsen'].Item1.length; index++) {
      zip.file(`Kasten/${data['date']}_${data['plaatsen'].Item2[index]}.${data['extension']}`, data['plaatsen'].Item1[index], { base64: true })
    }
  }

  if (document.getElementById('expo-ritt').checked && !document.getElementById('expo-fabr').checked) {
    zip.file(`Fabrikanten/${data['date']}_${data['fabrikant'].Item2[data['rittal']]}.${data['extension']}`, data['fabrikant'].Item1[data['rittal']], { base64: true })
  }

  if (document.getElementById('expo-fabr').checked) {
    for (let index = 0; index < data['fabrikant'].Item1.length; index++) {
      zip.file(`Fabrikanten/${data['date']}_${data['fabrikant'].Item2[index]}.${data['extension']}`, data['fabrikant'].Item1[index], { base64: true })
    }
  }

  if (document.getElementById('expo-geco').checked) {
    zip.file(`${data['date']}_${data['project']}_Total_Combined.${data['extension']}`, data['comb'], { base64: true })
  }

  zip.generateAsync({ type: "blob" })
    .then((content) => {
      saveAs(content, `${data['date']}_${data['project']}.zip`);
      setDownloading(false);
    })
    .catch((error) => {
      toast.error("Ging wat fout bij downloaden.");
      console.error(error);
      setDownloading(false);
    })
}

function Download(FileLoaded) {
  const [downloading, setDownloading] = useState(false);
  const [data, setData] = useState();
  if (!FileLoaded) return (
    <Col>
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
    </Col>
  );

  const handleXLSX = (e) => {
    e.preventDefault();
    setDownloading(true);
    var req = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    };
    fetch(`${GetAPIurl()}/api/eplan/material/converter/zip?extension=xlsx`, req)
      .then((reponse) => reponse.json())
      .then(d => {
        FileDownload(d, setDownloading);
      })
      .catch((error) => console.error(error));
  }

  const handleXLS = (e) => {
    e.preventDefault();
    setDownloading(true);
    var req = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    };
    fetch(`${GetAPIurl()}/api/eplan/material/converter/zip?extension=xls`, req)
      .then((reponse) => reponse.json())
      .then(d => {
        FileDownload(d, setDownloading);
      })
      .catch((error) => console.error(error));
  }

  const handleCSV = (e) => {
    e.preventDefault();
    setDownloading(true);
    var req = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    };
    fetch(`${GetAPIurl()}/api/eplan/material/converter/zip?extension=csv`, req)
      .then((reponse) => reponse.json())
      .then(d => {
        FileDownload(d, setDownloading);
      })
      .catch((error) => console.error(error));
  }
  return (
    <Col>
      <Form className="list-group">
        <Button type="submit" className="list-group-item" title="Sla bestanden op als Excel XLSX" onClick={handleXLSX} disabled={downloading}>
          <i className="fas fa-floppy-disk"></i>
          Bestand(en) opslaan in Excel XLSX 2007+
        </Button>
        <Button type="submit" className="list-group-item" title="Sla bestanden op als Excel XLS" onClick={handleXLS} disabled={downloading}>
          <i className="fas fa-floppy-disk"></i>
          Bestand(en) opslaan in Excel XLS 2003
        </Button>
        <Button type="submit" className="list-group-item" title="Sla bestanden op als CSV" onClick={handleCSV} disabled={downloading}>
          <i className="fas fa-floppy-disk"></i>
          Bestand(en) opslaan in CSV
        </Button>
      </Form>
    </Col>
  );
}

function Export(FileLoaded, setTableType) {
  if (!FileLoaded) return (
    <Col>
      <Placeholder xs={8} />
      <Placeholder xs={8} />
      <Placeholder xs={8} />
      {GetRejectAlert()}
    </Col>
  );

  const handleChange = (e) => {
    if (document.getElementById('expo-fabr').checked) {
      document.getElementById('expo-ritt').checked = true;
      document.getElementById('expo-ritt').disabled = true;
    }
    else {
      document.getElementById('expo-ritt').disabled = false;
    }
  }

  return (
    <Col>
      <Form className="checkboxes" onChange={handleChange}>
        <Form.Check type="switch" id="expo-kast" label="Inbouwkasten exporteren" />
        <Form.Check type="switch" id="expo-ritt" label="Rittal exporteren" />
        <Form.Check type="switch" id="expo-fabr" label="Fabrikanten exporteren" />
        <Form.Check type="switch" id="expo-geco" label="Gecombineerd exporteren" />
      </Form>
      {GetRejectAlert(FileLoaded, setTableType)}
    </Col>
  );
}


function GetRejectAlert(FileLoaded, setTableType) {
  const [data, setData] = useState();
  useEffect(() => {
    if (FileLoaded) {
      var req = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: "rejectList",
      };
      fetch(`${GetAPIurl()}/api/eplan/material/getlijst`, req)
        .then((response) => response.json())
        .then((res) => setData(res))
        .catch((error) => console.error(error));
    }
  }, [FileLoaded])
  if (!data) return;
  const handleAlert = () => {
    document.getElementById("select").getElementsByTagName('option')[3].selected = "selected";
    setTableType("rejectList")
  }
  if (data["Item2"].length > 0) return (
    <span className="geweigerd" onClick={handleAlert}><div className="alert alert-danger"><strong> Opgelet!</strong> Aantal geweigerde items: {data["Item2"].length}</div></span>
  );
}

function GetTableList(FileLoaded, TableType) {
  const [data, setData] = useState();
  useEffect(() => {
    if (FileLoaded) {
      var req = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: TableType,
      };
      fetch(`${GetAPIurl()}/api/eplan/material/getlijst`, req)
        .then((response) => response.json())
        .then((res) => setData(res))
        .catch((error) => console.error(error));
    }
  }, [FileLoaded, TableType])
  if (!FileLoaded) return;
  if (!data) return <span className="text-primary" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Bezig met laden...</span>;

  setTimeout(() => {
    if (DataTable.isDataTable("#Listtable")) return;
    let table = new DataTable('#Listtable', {
      lengthMenu: [-1, 1000, 100, 50],
      language: {
        search: "Zoeken:",
        info: "Toont pagina _PAGE_ van _PAGES_ | Van _START_ tot _END_ van totaal _TOTAL_ aantal.",
        lengthMenu: "Toont _MENU_ aantal",
        zeroRecords: "Geen bijpassende records gevonden",
        paginate: {
          next: "Volgende",
          previous: "Vorige"
        }
      }
    });
  }, 1000);
  return (
    <div id="TableListId" className="custom-grid">
      <MakeColorTable id={"Listtable"} data={data.Item2} header={Object.keys(data.Item2[0])} />
    </div>
  )
}