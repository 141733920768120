/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { displayToast } from "../../../components/managers/ToastManager.jsx";
import { useNavigate, useParams } from "react-router-dom";
import apiCall from "../../../components/utils/apiCall.jsx";
import TableQuestions from "../../../components/tables/QuestionsTable.jsx";

/**
 * README ChecklistQuestions
 *
 * ChecklistQuestions.jsx is een React component bestand dat de hoofdcomponent ChecklistQuestions bevat.
 * Deze component wordt gebruikt om de vragen van een specifieke checklist weer te geven en te beheren in de applicatie.
 *
 * =====================================================================================================================
 *
 * ChecklistQuestions:
 * Deze component wordt gebruikt om de vragen van een specifieke checklist weer te geven en te beheren.
 * Het biedt een gebruikersinterface voor het navigeren door de vragenlijst en het zoeken naar specifieke vragen.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het ChecklistQuestions.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * Er zijn geen vereiste props voor deze component.
 *
 */

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChecklistQuestions() {
    //State variabelen
    const [groupActive, setGroupActive] = useState(-1);
    const [query, setQuery] = useState("");
    const [data, setData] = useState({})
    const [groups, setGroups] = useState([])
    const [questions, setQuestions] = useState([{}])
    const [groupQuestions, setGroupQuestions] = useState([])
    const [questionElements, setQuestionElements] = useState([])

    //Navigatie hook
    const navigate = useNavigate();

    //Haalt id uit de url
    const { id } = useParams();

    //Haalt de vragen op van de geselecteerde groep
    const handleClickGroup = (group, groupIndex) => {
        setGroupActive(groupIndex);
        setGroupQuestions(Object.values(questions[group.replace(/\s/g, '').toLowerCase()]));

    };
    //Haalt alle vragen op van de checklist
    function GetData() {
        (async () => {
            const response = await apiCall(`checklist/getbycode/${id}`, "POST");

            //Zet de gehele response in de data state
            setData(response);

            //Zet de groepen in de groepen state
            setGroups(response.Groups);

            //Zet de vragen in de vragen state
            setQuestions(response.Questions);
        })();
    }

    useEffect(() => {
        setQuestionElements(groupQuestions.map((question) => question.Element))
    }, [groupQuestions])

    //Haalt de vragen op van de geselecteerde groep

    useEffect(() => {
        GetData();
    }, [id]);


    return (
        <>
            <Card className="p-3">
                <Tab.Container id="question-tabs">
                    <Row>
                        <Col>
                            <h3> Checklist Vragen - {id} </h3>
                        </Col>
                        <Col className="text-end">
                            <Button variant="danger" style={{ margin: "4px" }} onClick={() => navigate(-1)}><i className="fa fa-arrow-left" /> Terug</Button>
                        </Col>
                    </Row>
                    <hr />
                    <Nav className="mb-3">
                        {groups.map((group, groupIndex) => (
                            <Nav.Item key={groupIndex}>
                                <Nav.Link
                                    eventKey={groupIndex.toString()}
                                    className={`cl-link ${groupActive === groupIndex ? "active" : ""}`}
                                    key={groupIndex}
                                    onClick={() => handleClickGroup(group, groupIndex)}
                                    disabled={groupActive === groupIndex}
                                    style={{
                                        borderRadius: "10px",
                                    }}
                                >
                                    {group}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
                <Tab.Content>
                    {groupActive > -1 &&
                        <div className="searchbar">
                            <InputGroup>
                                <InputGroup.Text>Zoeken</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="SearchString"
                                    onChange={(e) => {
                                        groupActive > -1 && setQuery(e.target.value);
                                    }}
                                />
                            </InputGroup>
                        </div>
                    }
                    {groupActive > -1 && getTable(GetData, groupQuestions, questionElements, query, groupActive, groups[groupActive])}
                </Tab.Content>
            </Card>
        </>
    );

}

// Functie om bepaalde karakters te escapen
// function escapeRegExp(string) {
//     return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// }

// Functie om te zoeken in de data
function Search(data, keyword) {
    if (!data) {
        return [];
    }

    // const safeKeyword = escapeRegExp(keyword);
    // const re = new RegExp(safeKeyword, "i");
    const dataArray = Object.values(data);
    return dataArray.filter(entry =>
        Object.values(entry).some(val => typeof val === "string" && val.match(keyword))
    );
}
// Functie om de data te filteren
function getTable(GetData, data, questionElements, query, groupIndex, group) {
    if (!data) {
        displayToast("Er is een fout opgetreden bij het ophalen van de data", "error");
    }
    const filtered = Search(data, query);
    return <TableQuestions getData={GetData} data={filtered} groupIndex={groupIndex} group={group} questionElements={questionElements} type="checklist" />;
}


