/**
 * README Checklist
 *
 * CheckListProject.jsx is een React component bestand dat de hoofdcomponent CheckListProject bevat.
 * Deze component wordt gebruikt om verschillende vragenlijsten in de applicatie te beheren.
 *
 * =====================================================================================================================
 * CheckListProject:
 * Deze component wordt gebruikt om een vragenlijst te beheren.
 * Het biedt een gebruikersinterface voor het navigeren door de vragenlijst, het beantwoorden van vragen, en het bijhouden van de voortgang.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het CheckListProject.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * De props omvatten de huidige vragen, de functie om de indiening van het formulier te verwerken, de functie om een vraag te verwijderen, en andere gerelateerde gegevens en functies.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een selectedRow object dat de gegevens van de geselecteerde rij bevat, en een handleSubmitMeta functie die de indiening van het formulier verwerkt.
 *
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Nav, OverlayTrigger, Popover, ProgressBar, Spinner, Tab, Row, Col } from "react-bootstrap";
import { displayToast } from "../../../components/managers/ToastManager";
import apiCall from "../../../components/utils/apiCall";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { usePagination } from "../../../components/managers/PaginationManager";
import { checkBoxQuestion, closedQuestion, openQuestion, Group, Element, BackButton } from "../../../components/utils/ChecklistUtils";

// Functie die de server-side props ophaalt voor de pagina

// Hoofdcomponent van de pagina
export default function CheckListProject() {
    // State hooks
    const [elements, setElements] = useState([]);
    const { user } = useAuth();
    const { id } = useParams();
    // Query hooks
    const [data, setData] = useState(null);
    const [error] = useState(null);
    const [groups, setGroups] = useState([]);
    const [isLoading] = useState(false);
    const [isError] = useState(null);

    // Element hooks
    const [elementActiveStep, setElementActiveStep] = useState(-1);
    const [elementActiveStepIndex, setElementActiveStepIndex] = useState(-1);

    // Answer hooks
    const [answers, setAnswers] = useState({});

    // Group hooks
    const [groupQuestions, setGroupQuestions] = useState([]);
    const [groupActiveStep, setGroupActiveStep] = useState(null);

    // Parent question & sub question hooks
    const [questions, setQuestions] = useState([]);
    const [parentQuestionValue, setParentQuestionValue] = useState({});
    const [projectCode, setProjectCode] = useState("");
    const tbodyRef = useRef(null);
    const [rowCount, setRowCount] = useState(0);
    const [totalAnswers, setTotalAnswers] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [totalProgress, setTotalProgress] = useState(0);
    const [checklistEdited, setChecklistEdited] = useState(false);

    const [answeredGroupQuestions, setAnsweredGroupQuestions] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState(0);
    const [progress, setProgress] = useState(0);
    const [groupQuestionIDs, setGroupQuestionIDs] = useState([]);

    // Table Reference
    const useTableRef = useRef();
    let initialAnswers = {};


    // WebSocket hooks
    const [isConnected, setIsConnected] = useState(false);
    const socketRef = useRef(null);


    // Zet de WebSocket connectie op
    const connectWebSocket = useCallback(() => {
        if (socketRef.current) {
            socketRef.current.close();
        }

        socketRef.current = new WebSocket(`${process.env.REACT_APP_API_URL_WEBSOCKET}${id}`);

        socketRef.current.onopen = () => {
            console.log("WebSocket connectie geopend");
            displayToast("Live updates zijn ingeschakeld", "info");
            setIsConnected(true);
        };

        socketRef.current.onmessage = async (event) => {
            try {
                const message = JSON.parse(event.data);
                console.log("Bericht ontvangen van server:", message);

                if (message.type === 'update') {
                    await getData();
                } else if (message.type === 'pong') {
                    console.log("Pong ontvangen van server");
                } else {
                    console.error("Onbekend bericht ontvangen van server:", message);
                }
            } catch (error) {
                console.error("Error bij het verwerken van bericht van server:", error);
            }
        };

        socketRef.current.onclose = () => {
            console.log("WebSocket connectie gesloten");
            displayToast("Live updates zijn uitgeschakeld", "info");
            setIsConnected(false);
        };

        socketRef.current.onerror = (error) => {
            console.error("WebSocket connectie error", error);
            displayToast("Er is een fout opgetreden bij het verbinden met de server", "error");
            setIsConnected(false);
        };

        const pingInterval = setInterval(() => {
            if (socketRef.current.readyState === WebSocket.OPEN) {
                console.log("Ping versturen naar server");
                socketRef.current.send(JSON.stringify({ type: 'ping' }));
            }
        }, 15000);

        return () => {
            clearInterval(pingInterval);
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [id]);

    // Haal de data op met de WebSocket connectie
    useEffect(() => {
        const cleanupWebSocket = connectWebSocket();
        getData().then(() => {
            console.log("Data is geladen");
        }).catch((error) => {
            console.error("Error bij het ophalen van data:", error);
        });
        return () => {
            cleanupWebSocket();
        };
    }, [id, connectWebSocket]);


    // Functie om de data op te halen van de server
    const getData = async () => {
        const project = await apiCall(`project/getbyid/${id}`, 'POST');
        const response = await apiCall(`checklist/getbycode/${project.Code}`, 'POST')

        // Check of de response niet undefined is
        if (response !== undefined) {
            setGroups(response.Groups);
            setProjectCode(response.Code);
            setData(response.Questions);
            setTotalProgress(response.Progress);

            // Check of de status van het project "Offerte" is en filter de vragen
            if (project.Status === "Offerte") {
                const filteredData = Object.keys(response.Questions).reduce((acc, group) => {
                    acc[group] = Object.values(response.Questions[group])
                        .filter(question =>
                            question.References.Quotation === true &&
                            (user.Roles.includes(question.Role) || user.Roles.includes("User"))
                        );

                    return acc;
                }, {});
                setData(filteredData);
            } else {
                // Filter de vragen op basis van de rol van de gebruiker
                const filteredData = Object.keys(response.Questions).reduce((acc, group) => {
                    acc[group] = Object.values(response.Questions[group])
                        .filter(question =>
                            user.Roles.includes(question.Role) || user.Roles.includes("User")
                        );

                    return acc;
                }, {});
                // Zet de gefilterde data in de state
                setData(filteredData);
            }
        }
    };

    // Functie om de totale progressie te berekenen
    useEffect(() => {
        if (data !== null) {
            setGroupQuestionIDs(groupQuestions.map((question) => question.QuestionID));
            setAnsweredGroupQuestions(Object.keys(answers).filter((answer) => groupQuestionIDs.includes(parseInt(answer))));
            setAnsweredQuestions(Object.keys(answers).length);
            setProgress(Math.ceil((answeredQuestions / rowCount) * 100 || 0));
            let totalQuestionCount = 0;
            setTotalAnswers(Object.values(data).flatMap((question) => question).filter((question) => question.Answer).length);
            Object.values(data).flatMap((question) => question).forEach((question) => {
                if ((question.isActive === true || question.isActive === "true") && question.PageTree === 1) {
                    totalQuestionCount++;

                    if (question.Answer === "Ja" && question.SubQuestions) {
                        totalQuestionCount += question.SubQuestions.length;
                    }
                }
            });

            setTotalQuestions(totalQuestionCount);

            setTotalProgress(Math.ceil((totalAnswers / totalQuestions * 100) || 0));
        }
    }, [rowCount, data]);

    // Functie om de antwoorden in te stellen
    useEffect(() => {
        for (let group in data)
            data[group].map((question) => {
                if (question.Answer) {
                    return initialAnswers[question.QuestionID] = question.Answer;
                }
                else if (question.DefaultAnswer) {
                    return initialAnswers[question.QuestionID] = question.DefaultAnswer;
                } else return null;
            });
        setAnswers(initialAnswers);
    }, [data]);

    const { currentItems, AboveTable, BelowTable } = usePagination(groupQuestions);

    /**
     *
     * @param groupIndex
     * @param group
     * @param dataGroup
     * @returns {Promise<void>}
     * Functie om te klikken op een groep
     */
    const handleClickGroup = async (groupIndex, group, dataGroup) => {
        const transformedGroup = group.toLowerCase().replace(/\s/g, '');
        const groupData = dataGroup.find(item => item === transformedGroup);
        setElementActiveStep("");
        setElementActiveStepIndex(-1);

        if (!groupData) {
            console.error(`Groep met de naam ${group} is niet gevonden`);
            return;
        }

        setGroupActiveStep(groupIndex);
        setGroupQuestions([]);

        const newElements = new Set(
            data[groupData].map((question) => question.Element)
        );

        setQuestions(data[groupData]);
        setElements([...newElements]);
    };

    /**
     *
     * @param element
     * @param elementIndex
     * Functie om te klikken op een element
     */
    const handleClickElement = (element, elementIndex) => {
        setElementActiveStep(element);
        setElementActiveStepIndex(elementIndex);

        const filteredQuestions = questions.filter(
            (question) => question.Element === element
        );

        const activefilteredQuestions = filteredQuestions.filter(
            (question) => question.isActive === true || question.isActive === "true"
        );

        let initialParentQuestions = {};
        activefilteredQuestions.forEach(question => {
            if (question.Answer === "Ja" && question.SubQuestions) {
                initialParentQuestions[question.QuestionID] = question.Answer;
            }
            else if (question.Answer === "Nee" && question.SubQuestions) {
                question.SubQuestions.forEach(subQuestionId => {
                    delete initialParentQuestions[subQuestionId];
                });
            }
        });
        setParentQuestionValue(initialParentQuestions);

        setGroupQuestions(activefilteredQuestions);
    };

    /**
     *
     * @param e
     * Functie om naar de volgende vraag te gaan
     */
    const handleNext = (e) => {
        e.preventDefault();
        if (elementActiveStepIndex < elements.length - 1) {
            let newElementActiveStepIndex = elementActiveStepIndex + 1;
            setElementActiveStepIndex(newElementActiveStepIndex);
            setElementActiveStep(elements[newElementActiveStepIndex]);
            handleClickElement(
                elements[newElementActiveStepIndex],
                newElementActiveStepIndex,
                groups[groupActiveStep]
            );
        }
    };

    /**
     *
      * @param e
     * Functie om naar de vorige vraag te gaan
     */
    const handlePrevious = (e) => {
        e.preventDefault();
        if (elementActiveStepIndex > 0) {
            let newElementActiveStepIndex = elementActiveStepIndex - 1;
            setElementActiveStepIndex(newElementActiveStepIndex);
            setElementActiveStep(elements[newElementActiveStepIndex]);
            handleClickElement(
                elements[newElementActiveStepIndex],
                newElementActiveStepIndex,
                groups[groupActiveStep]
            );
        }
    };

    /**
     *
     * @param e
     * @param question
     * @returns {Promise<void>}
     * Functie om de input van een vraag te verwerken
     */
    const handleInputChange = async (e, question) => {
        // Check of de checklist is bewerkt
        if (checklistEdited === false) setChecklistEdited(true);
        let value;
        // Check of de input een checkbox is
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else {
            value = e.target.value === "on"
                ? true
                : e.target.value === "off"
                    ? false
                    : e.target.value;
        }
        // Update de antwoorden in de state
        setAnswers((prevAnswers) => {
            if (value === "Selecteer een antwoord" || value === "") {
                const newAnswers = { ...prevAnswers };
                delete newAnswers[question.QuestionID];
                return newAnswers;
            } else if (question.SubQuestions && value !== "Ja") {
                const newAnswers = { ...prevAnswers, [question.QuestionID]: value };
                question.SubQuestions.forEach(subQuestionId => {
                    delete newAnswers[subQuestionId];
                });
                return newAnswers;
            } else {
                return {
                    ...prevAnswers,
                    [question.QuestionID]: value,
                };
            }
        });

        // Update de hoofdvraag in de state
        setParentQuestionValue((prevValues) => ({
            ...prevValues,
            [question.QuestionID]: value
        }));

        //TODO: Sla de totale progressie op in de database @Martijn
        // Update de antwoorden in de database
        await apiCall('checklist/update_answer', "POST", {
            websocketRef: id,
            id: projectCode,
            group: groups[groupActiveStep].toLowerCase().replace(/\s/g, ''),
            questionID: question.QuestionID,
            answer: value,
            required_role: question.Role,
            progress: totalProgress
        });
    };


    // Component voor de progress bar


    // Functie om de progressie te berekenen
    useEffect(() => {
        if (tbodyRef.current) {
            setRowCount(tbodyRef.current.getElementsByTagName("tr").length);
            setAnsweredQuestions(Object.keys(answers).filter((answer) => groupQuestionIDs.includes(parseInt(answer))).length);
            setProgress(Math.ceil((answeredQuestions / rowCount) * 100 || 0))
        }
    }, [tbodyRef.current, answers, elementActiveStep]);

    /**
     *
     * @param _stepsList
     * @param group
     * @param _questions
     * @param isLoading
     * @param error
     * @returns {false|React.JSX.Element}
     * Functie om de vragen te renderen
     */
    const RenderQuestions = ({ _stepsList, group, _questions, isLoading, error }) => {
        let subQuestion = null;
        return (
            groupActiveStep !== null && (
                <div className="columns-container">
                    <hr style={{ border: "transparent" }} />
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="cl-question-container">
                                <h1>{elementActiveStep ? elementActiveStep : group}</h1>
                                <ProgressBar className="progress-bar- mb-3" now={progress} label={`${progress}%`} />
                                <form
                                    id={`form-${groupActiveStep}-${elementActiveStep
                                        .toString()
                                        .replace(/\s/g, "")}`}
                                    key={elementActiveStep}
                                >
                                    {AboveTable}
                                    <table className="table" style={{ marginTop: "5px" }} ref={useTableRef}>
                                        <colgroup>
                                            <col style={{ width: "5%" }} />
                                            <col style={{ width: "5%" }} />
                                            <col style={{ width: "65%" }} />
                                            <col style={{ width: "25%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th className="text-center col-1">#</th>
                                                <th>Info</th>
                                                <th>Question</th>
                                                <th>Answer</th>
                                            </tr>
                                        </thead>
                                        <tbody ref={tbodyRef}>
                                            {elements.length > 0 ? (
                                                elementActiveStep ? (
                                                    isError ? (
                                                        <tr>
                                                            <td colSpan="2" className="danger">
                                                                {error}
                                                            </td>
                                                        </tr>
                                                    ) : groupQuestions && Object.values(groupQuestions).length > 0 ? (
                                                        _questions.flatMap((question, index) => (
                                                            question.PageTree === 1 ?
                                                                <React.Fragment key={question.QuestionID}>
                                                                    <tr className="question_row">
                                                                        <td className="text-center col-1 align-items-center">{index + 1}</td>
                                                                        <td>
                                                                            <OverlayTrigger placement="bottom"
                                                                                trigger={"hover"} overlay={
                                                                                    <Popover
                                                                                        style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                                                        <Popover.Body>{question.QuestionInfo}</Popover.Body>
                                                                                    </Popover>
                                                                                }>
                                                                                <i style={{ color: '#0d6efd' }}
                                                                                    className="fas fa-info-circle" />
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                        <td className={"text-wrap"}>
                                                                            <span
                                                                                className="col-12 checklist-question">
                                                                                {question.Name}
                                                                            </span>
                                                                        </td>
                                                                        <td className="col-3">
                                                                            {question.QuestionType === "open"
                                                                                ? openQuestion(question, answers, handleInputChange)
                                                                                : question.QuestionType === "multi"
                                                                                    ? closedQuestion(question, answers, handleInputChange)
                                                                                    : question.QuestionType === "check" || question.QuestionType === "checkbox"
                                                                                        ? checkBoxQuestion(question, answers, handleInputChange)
                                                                                        : null}
                                                                        </td>
                                                                    </tr>
                                                                    {(question.SubQuestions && parentQuestionValue[question.QuestionID] === "Ja" && question.SubQuestions.map((subQuestionId) => {
                                                                        let subQuestionIndex = _questions.findIndex(q => q.QuestionID === subQuestionId);
                                                                        subQuestion = _questions[subQuestionIndex];
                                                                        if (!subQuestion) return null;
                                                                        else
                                                                            return (
                                                                                <tr className="question_row" key={subQuestion.questionID}>
                                                                                    <td className="text-center col-1 align-items-center">{subQuestionIndex + 1}</td>
                                                                                    <td>
                                                                                        <OverlayTrigger placement="bottom"
                                                                                            trigger={"hover"}
                                                                                            overlay={
                                                                                                <Popover
                                                                                                    style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                                                                    <Popover.Body>{subQuestion.QuestionInfo}</Popover.Body>
                                                                                                </Popover>
                                                                                            }>
                                                                                            <i style={{ color: '#0d6efd' }}
                                                                                                className="fas fa-info-circle" />
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                    <td className={"text-wrap"}>
                                                                                        <span
                                                                                            className="col-12 checklist-question">
                                                                                            {subQuestion.Name}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="col-3">
                                                                                        {subQuestion.QuestionType === "open"
                                                                                            ? openQuestion(subQuestion, answers, handleInputChange)
                                                                                            : subQuestion.QuestionType === "multi"
                                                                                                ? closedQuestion(subQuestion, answers, handleInputChange)
                                                                                                : subQuestion.QuestionType === "check"
                                                                                                    ? checkBoxQuestion(subQuestion, answers, handleInputChange)
                                                                                                    : null}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                    }))}
                                                                </React.Fragment> : null
                                                        ))
                                                    ) : (
                                                        <p className={"text-danger"}>Geen vragen gevonden in deze groep.</p>
                                                    )
                                                ) : (
                                                    <p className={"text-danger"}>Geen onderdelen gevonden in deze groep.</p>
                                                )
                                            ) : (
                                                <p className={"text-danger"}>Geen groepen gevonden in deze checklist.</p>
                                            )}
                                        </tbody>
                                    </table>
                                    {BelowTable}
                                    <div className="btn-footer">
                                        <button
                                            className="btn btn-danger"
                                            onClick={handlePrevious}
                                            disabled={
                                                elementActiveStepIndex < 0 ||
                                                elementActiveStepIndex === 0
                                            }
                                        >
                                            <i className="fa fa-arrow-left" /> Vorige onderdeel
                                        </button>
                                        <button
                                            className="btn btn-success"
                                            onClick={handleNext}
                                            disabled={elementActiveStepIndex === elements.length - 1}
                                        >
                                            Volgende onderdeel <i className="fa fa-arrow-right" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    };

    return (
        <>
            <div className="cl-main-container">
                {data ? (
                    <>
                        <Row>
                            <Col sm={4} className="cl-title">
                                <h3>
                                    Checklist - Project: {projectCode}
                                </h3>
                            </Col>
                            <Col sm={8} className="text-end ">
                                <BackButton />
                            </Col>
                        </Row>
                        <ProgressBar className="progress-bar-custom" now={totalProgress}
                            label={`${totalProgress}%`} />

                        <Tab.Container id="step-navigation">
                            <Nav>
                                {groups.map((group, groupIndex) => (
                                    user.Roles.includes(group) || user.Roles.includes("User") ?
                                        <Group group={group}
                                            datagroup={Object.keys(data)}
                                            groupIndex={groupIndex}
                                            groupActiveStep={groupActiveStep}
                                            handleClickGroup={handleClickGroup}
                                            data={data}
                                        /> : null
                                ))}
                            </Nav>
                            <Tab.Content>
                                <div className="content-container">
                                    <div className="cl-column">
                                        <hr className="hr" />
                                        {elements.length > 0 && <h3>Onderdelen</h3>}
                                        {elements.map((element, elementIndex) => (
                                            <Element
                                                element={element}
                                                elementIndex={elementIndex}
                                                groupActiveStep={groupActiveStep}
                                                elementActiveStep={elementActiveStep}
                                                handleClickElement={handleClickElement}
                                                group={groups[groupActiveStep]}
                                            />
                                        ))}
                                    </div>
                                    <RenderQuestions
                                        _stepsList={elements}
                                        group={groups[groupActiveStep]}
                                        _questions={currentItems}
                                        isLoading={isLoading}
                                        error={error}
                                    />
                                </div>
                            </Tab.Content>
                        </Tab.Container>
                    </>
                ) : (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Laden...</span>
                        </Spinner>
                    </div>
                )
                }
            </div>
        </>
    );
}
