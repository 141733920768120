import React from 'react';
import * as XLSX from 'xlsx';
import apiCall from "../utils/apiCall";
import { displayConfirmAlertCustom } from "./AlertManager";
import { Form, InputGroup } from "react-bootstrap";

/**
 * README ExportManager
 *
 * ExportManager.jsx is een React component bestand dat de hoofdcomponent ExportManager bevat.
 * Deze component wordt gebruikt om verschillende elementen in de applicatie te exporteren.
 *
 * ============================================================================================
 *
 * ExportDialogBox:
 * Deze component wordt gebruikt om een dialoogvenster te tonen waarin de gebruiker het type bestand kan selecteren waarin de gegevens moeten worden geëxporteerd.
 * De opties zijn Excel, CSV, JSON en TXT.
 *
 * transformExportData:
 * Deze functie wordt gebruikt om de gegevens te transformeren die moeten worden geëxporteerd.
 * Het zet de gegevens om in een formaat dat kan worden geëxporteerd.
 *
 * ExportFile:
 * Deze functie wordt gebruikt om het bestand te exporteren.
 * Het neemt de getransformeerde gegevens, het type en de extensie en exporteert het bestand in het geselecteerde formaat.
 *
 * ExportAll:
 * Deze functie wordt gebruikt om alle gegevens te exporteren.
 * Het maakt een API-aanroep om alle gegevens op te halen en exporteert deze vervolgens naar een XLSX-bestand.
 *
 * exportToXLSX, exportToCSV, exportToJSON, exportToTXT:
 * Deze functies worden gebruikt om de gegevens te exporteren naar de respectievelijke bestandsformaten.
 *
 * ============================================================================================
 *
 * Gebruik
 * Om deze componenten te gebruiken, importeer ze uit het ExportManager.jsx bestand en voeg ze toe aan je React component.
 * Je moet de nodige props voor elke component leveren. De props omvatten de geselecteerde rijgegevens, de functie om de indiening van het formulier te verwerken, de functie om een rij te verwijderen, en andere gerelateerde gegevens en functies.
 *
 * Opmerking
 * Deze componenten zijn ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een selectedRow object dat de gegevens van de geselecteerde rij bevat, en een handleSubmitMeta functie die de indiening van het formulier verwerkt.
 *
 *
 */

/**
 *
 * @param data
 * @param type
 * @param columns
 * @constructor
 */
export const ExportDialogBox = (data, type, columns) => {
    displayConfirmAlertCustom(
        `Exporteer ${type === 'data_equipmentlist' ? "Equipment Lijst" : type === 'data_devicelist' ? "Device Lijst" : type} `,
        <InputGroup >
            <InputGroup.Text>File Type </InputGroup.Text>
            <Form.Select id={"export-select"}>
                <option value="xlsx">Excel</option>
                <option value="csv">CSV</option>
                <option value="json">JSON</option>
                <option value="txt">TXT</option>

            </Form.Select>
        </InputGroup>
        ,
        "confirm",
        [
            () => {
                ExportFile(data, type, document.getElementById("export-select").value, columns);
            }
            , null],
        [
            "Exporteren",
            "Annuleren"
        ])
}

/**
 *
 * @param data
 * @param type
 * @param columns
 * @returns {*[]}
 */
/**
 *
 * @param data
 * @param type
 * @param columns
 * @returns {*[]}
 */
const transformExportData = (data, type, columns) => {
    let rows = [];
    console.log(columns);

    if (type.includes('equipmentlist') || type.includes('devicelist') || type.includes('cabinetlist')) {
        const columnNames = columns.map(column => column.Name);

        rows = data.map(obj => columnNames.map(column => {
            if (column === 'QuestionAnswerOptions' && Array.isArray(obj[column])) {
                return obj[column].join(', ');
            } else if (column === 'SubQuestions' && Array.isArray(obj[column])) {
                return obj[column].map(subQuestion => subQuestion.QuestionID).join(', ');
            } else {
                return obj[column];
            }
        }));
        return [columnNames, ...rows];
    }
    else if (type.includes('Kolommen')) {
        rows = [["ColumnName", ...Object.keys(data[0].Metadata)]];
        const metadataRows = data.map(obj => [obj.Name, ...Object.keys(obj.Metadata).map(key => key === "AnswerOptions" ? obj.Metadata[key].join(", ") : obj.Metadata[key])]);
        return [...rows, ...metadataRows, [""]];
    }
    else {
        rows = data.map(obj => columns.map(column => {
            if (column === 'QuestionAnswerOptions' && Array.isArray(obj[column])) {
                return obj[column].join(', ');
            } else if (column === 'SubQuestions' && Array.isArray(obj[column])) {
                return obj[column].join(', ');
            } else {
                return obj[column];
            }
        }));
        return [columns, ...rows];
    }
};

/**
 *
 * @param data
 * @param type
 * @param extension
 * @param columns
 * @constructor
 */
const ExportFile = (data, type, extension, columns) => {
    switch (extension) {
        case 'xlsx':
            exportToXLSX(transformExportData(data, type, columns), type);
            break;
        case 'csv':
            exportToCSV(transformExportData(data, type, columns), type);
            break;
        case 'json':
            exportToJSON(data, type);
            break;
        case 'txt':
            exportToTXT(data, type);
            break;
        default:
            break;
    }
};

/**
 *
 * @returns {Promise<void>}
 * @constructor
 */
export const ExportAll = () => {
    const exportAllToXLSX = async () => {
        const response = await apiCall('export/all', 'POST');

        XLSX.writeFile(response.data, 'ExcelSource.xlsx');
    };

    return exportAllToXLSX()
};

/**
 *
 * @param data
 * @param type
 * @returns {WorkBook}
 */
const exportToXLSX = (data, type) => {
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    ws['!autofilter'] = { ref: XLSX.utils.encode_range({ s: { c: 0, r: 0 }, e: { c: data[0].length - 1, r: data.length - 1 } }) };
    ws['!cols'] = Array(data[0].length).fill({ wpx: 100 }); // Set the width of all columns to 100 pixels
    XLSX.utils.book_append_sheet(wb, ws, type);
    XLSX.writeFile(wb, `ExportedData_${type}.xlsx`);
    return wb;
};

/**
 *
 * @param data
 * @param type
 */
const exportToCSV = (data, type) => {
    const ws = XLSX.utils.aoa_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `ExcelSource_${type}.csv`;
    a.click();
    URL.revokeObjectURL(url);
};

/**
 *
 * @param data
 * @param type
 */
const exportToJSON = (data, type) => {
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `ExcelSource_${type}.json`;
    a.click();
    URL.revokeObjectURL(url);
};

/**
 *
 * @param data
 * @param type
 */
const exportToTXT = (data, type) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const txt = XLSX.utils.sheet_to_txt(ws);
    const blob = new Blob([txt], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `ExcelSource_${type}.txt`;
    a.click();
    URL.revokeObjectURL(url);
};

export default ExportDialogBox;