const escapeRegex = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const Search = (data, keyword) => {
    if (!keyword.trim()) return data;

    const safeKeyword = escapeRegex(keyword);
    const re = new RegExp(safeKeyword, "i");

    return data.filter((entry) =>
        Object.values(entry).some((val) => typeof val === "string" && val.match(re))
    );
};


