import {Table} from "react-bootstrap";
// Object.keys(data) voor de header data.
export const MakeTable = ({id, data, header}) => {
    return(
        <Table id={id} striped bordered responsive>
            <colgroup>
                <col />
            </colgroup>
            <thead>
                <tr key={6969}>
                {header.map((head) => (
                    <th key={Math.random()}>{head}</th>
                ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr key={Math.random()}>
                        {header.map((head) => (
                            <td key={Math.random()}>{item[head]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

function SetColor(text) {
    var color = '';
    if (text == "rood" || text == "geel"){
        if (text == "rood"){
        color = "table-danger";
        }
        else{
        color = "table-warning";
        }
        text = "Niet ingevuld!";
    }
    return(
        <td className={color}>{text}</td>
    )
}

export const MakeColorTable = ({id, data, header}) => {
    return(
        <Table id={id} striped bordered responsive>
            <colgroup>
                <col />
            </colgroup>
            <thead>
                <tr key={6969}>
                {header.map((head) => (
                    <th key={Math.random()}>{head}</th>
                ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr key={Math.random()}>
                        {header.map((head) => (
                            SetColor(item[head])
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}