import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Button, Badge } from 'react-bootstrap';
import confetti from 'canvas-confetti';
import apiCall from '../../components/utils/apiCall';
import styles from '../../styles/feedbacklist.module.css';

export default function FeedbackList() {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedTaskContent, setSelectedTaskContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [titleColor, setTitleColor] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const feedbackData = await apiCall('feedback/get_all_feedback', 'POST');
                const initialData = {
                    columns: {
                        open: {
                            id: 'open',
                            title: 'Open',
                            feedbackIds: [],
                        },
                        in_behandeling: {
                            id: 'in_behandeling',
                            title: 'In behandeling',
                            feedbackIds: [],
                        },
                        done: {
                            id: 'done',
                            title: 'Done',
                            feedbackIds: [],
                        },
                    },
                    feedbacks: {},
                };
                feedbackData.forEach(feedback => {
                    const columnId = feedback.behandeling_status === 'done' ? 'done' : feedback.behandeling_status === 'in_behandeling' ? 'in_behandeling' : 'open';
                    initialData.columns[columnId].feedbackIds.push(feedback._id);
                    initialData.feedbacks[feedback._id] = feedback;
                });
                setData(initialData);
                setLoading(false);
            } catch (error) {
                console.error('Error met ophalen van feedback:', error);
            }
        };

        fetchData();
    }, []);

    const updateFeedbackStatus = async (id, status) => {
        try {
            await apiCall('feedback/update', 'POST', { id, status });
        } catch (error) {
            console.error('Error met updaten van feedback:', error);
        }
    };

    const launchConfetti = () => {
        const duration = 30 * 10;
        const end = Date.now() + duration;

        (function frame() {
            confetti({
                particleCount: 10,
                angle: 60,
                spread: 75,
                origin: { x: 0 },
                colors: ['#f9ea29', '#007d6a', '#d43988'],
            });
            confetti({
                particleCount: 10,
                angle: 120,
                spread: 75,
                origin: { x: 1 },
                colors: ['#f9ea29', '#007d6a', '#d43988'],
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        })();
    };

    const onDragEnd = async result => {
        const { destination, source, draggableId } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        const startColumn = data.columns[source.droppableId];
        const finishColumn = data.columns[destination.droppableId];

        if (startColumn === finishColumn) {
            const newFeedbackIds = Array.from(startColumn.feedbackIds);
            newFeedbackIds.splice(source.index, 1);
            newFeedbackIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...startColumn,
                feedbackIds: newFeedbackIds,
            };

            const newState = {
                ...data,
                columns: {
                    ...data.columns,
                    [newColumn.id]: newColumn,
                },
            };

            setData(newState);
        } else {
            const startFeedbackIds = Array.from(startColumn.feedbackIds);
            startFeedbackIds.splice(source.index, 1);
            const newStartColumn = {
                ...startColumn,
                feedbackIds: startFeedbackIds,
            };

            const finishFeedbackIds = Array.from(finishColumn.feedbackIds);
            finishFeedbackIds.splice(destination.index, 0, draggableId);
            const newFinishColumn = {
                ...finishColumn,
                feedbackIds: finishFeedbackIds,
            };

            const newState = {
                ...data,
                columns: {
                    ...data.columns,
                    [newStartColumn.id]: newStartColumn,
                    [newFinishColumn.id]: newFinishColumn,
                },
            };

            setData(newState);

            if (finishColumn.id === 'done') {
                await updateFeedbackStatus(draggableId, 'done');
                launchConfetti();
            } else if (finishColumn.id === 'in_behandeling') {
                await updateFeedbackStatus(draggableId, 'in_behandeling');
            } else if (finishColumn.id === 'open') {
                await updateFeedbackStatus(draggableId, 'open');
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedFeedback(null);
        setEmployeeDetails(null);
    };

    useEffect(() => {
        const isModalOpen = localStorage.getItem('modalOpen');
        if (isModalOpen === 'true') {
            setShowModal(true);
            localStorage.removeItem('modalOpen');
        }
    }, []);

    const handleTaskClick = async feedback => {
        setSelectedTaskContent(feedback.subject);
        setSelectedFeedback(feedback);
        setShowModal(true);

        const color = feedback.report_type === 'open_bug_report' ? '#a54800' : '#216e4e';
        setTitleColor(color);

        try {
            const employee = await apiCall(`employee/getbyid/${feedback.id}`, 'POST');
            setEmployeeDetails(employee);
        } catch (error) {
            console.error('Error met ophalen van employee details:', error);
        }
    };

    return (
        <>
            <div className="feedback-container">
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', padding: '20px', height: '100%' }}>
                            {Object.values(data.columns).map(column => (
                                <div
                                    key={column.id}
                                    className={styles.column}
                                >
                                    <h3 className={styles.columnTitle}>{column.title}</h3>
                                    <Droppable droppableId={column.id}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className={styles.droppable}
                                            >
                                                {column.feedbackIds.map((feedbackId, index) => (
                                                    <Draggable key={feedbackId} draggableId={feedbackId} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={styles.draggable}
                                                                style={provided.draggableProps.style}
                                                                onClick={() => handleTaskClick(data.feedbacks[feedbackId])}
                                                            >
                                                                <div
                                                                    className={styles.sideBar}
                                                                    style={{
                                                                        backgroundColor: data.feedbacks[feedbackId].report_type === 'open_bug_report' ? '#a54800' : '#216e4e',
                                                                    }}
                                                                />
                                                                <h3>{data.feedbacks[feedbackId].subject}</h3>
                                                                <Badge pill bg="secondary" className="badge">
                                                                    {data.feedbacks[feedbackId].report_type === 'open_bug_report' ? 'Bug' : 'Request'}
                                                                </Badge>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            ))}
                        </div>
                    </DragDropContext>
                )}
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton style={{ backgroundColor: titleColor }}>
                    <Modal.Title>{selectedTaskContent}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedFeedback && (
                        <>
                            <h5>Onderwerp: {selectedFeedback.subject}</h5>
                            {selectedFeedback.report_type === "open_bug_report" ? <><br /><h5>Actie: {selectedFeedback.action}</h5><br /></> : null}
                            <h5>Beschrijving: {selectedFeedback.description}</h5>
                            {selectedFeedback.report_type === "open_bug_report" ?
                                (
                                    <>
                                        <h5>Resultaat: {selectedFeedback.result}</h5>
                                        <h5>Reproduceerbaar: {selectedFeedback.reproducibility ? "Ja" : "Nee"}</h5>
                                    </>
                                ) : null}
                            <hr />
                        </>
                    )}
                    {employeeDetails && (
                        <>
                            <h5>Gebruiker: {employeeDetails.Firstname} {employeeDetails.Lastname}</h5>
                            {employeeDetails.Email ? <h5>Email: {employeeDetails.Email}</h5> : null}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}