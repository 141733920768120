// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedbacklist_feedback-container__PvBkw {
  padding: 20px 0;
}

.feedbacklist_column__DYPaG {
  background-color: #141414;
  border-radius: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  height: calc(100svh - 197px);
  scrollbar-width: none;
  overflow-y: auto;
}

.feedbacklist_columnTitle__qfB-c {
  color: #b6c2cf;
  background-color: #141414;
  padding: 10px;
  text-align: center;
  position: sticky;
  top: 0;
  margin: 0;
  z-index: 1;
}

.feedbacklist_droppable__hW8D\\+ {
  flex-grow: 1;
  min-height: 100px;
  /* padding: 8px; */
  border-radius: 4px;
}

.feedbacklist_draggable__abIns {
  background-color: #222222;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 10px;
  margin-top: 0;
  position: relative;
  color: #a5b1bd;
}

.feedbacklist_sideBar__lJbrN {
  width: 10px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

/* .draggable h3 {
    margin-left: 10px;
}

.badge {
    margin-left: 10px;
} */
`, "",{"version":3,"sources":["webpack://./src/styles/feedbacklist.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,4BAA4B;EAC5B,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,MAAM;EACN,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,8BAA8B;EAC9B,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA;;;;;;GAMG","sourcesContent":[".feedback-container {\n  padding: 20px 0;\n}\n\n.column {\n  background-color: #141414;\n  border-radius: 10px;\n  width: 30%;\n  display: flex;\n  flex-direction: column;\n  height: calc(100svh - 197px);\n  scrollbar-width: none;\n  overflow-y: auto;\n}\n\n.columnTitle {\n  color: #b6c2cf;\n  background-color: #141414;\n  padding: 10px;\n  text-align: center;\n  position: sticky;\n  top: 0;\n  margin: 0;\n  z-index: 1;\n}\n\n.droppable {\n  flex-grow: 1;\n  min-height: 100px;\n  /* padding: 8px; */\n  border-radius: 4px;\n}\n\n.draggable {\n  background-color: #222222;\n  border-radius: 4px;\n  padding: 10px 20px;\n  margin: 10px;\n  margin-top: 0;\n  position: relative;\n  color: #a5b1bd;\n}\n\n.sideBar {\n  width: 10px;\n  height: 100%;\n  border-top-left-radius: 4px;\n  border-bottom-left-radius: 4px;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n/* .draggable h3 {\n    margin-left: 10px;\n}\n\n.badge {\n    margin-left: 10px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedback-container": `feedbacklist_feedback-container__PvBkw`,
	"column": `feedbacklist_column__DYPaG`,
	"columnTitle": `feedbacklist_columnTitle__qfB-c`,
	"droppable": `feedbacklist_droppable__hW8D+`,
	"draggable": `feedbacklist_draggable__abIns`,
	"sideBar": `feedbacklist_sideBar__lJbrN`
};
export default ___CSS_LOADER_EXPORT___;
