/**
 * README MakeJSON
 *
 * MakeJSON.jsx is een JavaScript bestand dat twee belangrijke functies bevat:
 *  > createDynamicJson
 *  > getAllKeys.
 *  Deze functies worden gebruikt om te werken met JSON-objecten in de applicatie.
 *
 * =====================================================================================
 *
 * Functies:
 *  createDynamicJson:
 *      Deze functie wordt gebruikt om een object dynamisch om te zetten naar een JSON-object.
 *      Het neemt een object als parameter en retourneert een nieuw JSON-object.
 *      Het controleert ook of de waarde een numerieke string is en converteert deze naar een integer.
 *
 *  getAllKeys:
 *      Deze functie wordt gebruikt om alle sleutels van een object op te halen.
 *      Het neemt een object als parameter en retourneert een array van sleutels.
 *      Het gaat recursief door alle geneste objecten om hun sleutels te krijgen.
 *      Het filtert ook bepaalde sleutels uit de uiteindelijke array.
 *
 * =====================================================================================
 *
 * Gebruik:
 * Om deze functies te gebruiken, importeer ze uit het MakeJSON.jsx bestand en gebruik ze in je componenten waar nodig.
 * Zorg ervoor dat je de juiste parameters levert voor elke functie.
 *
 */

/**
 *
 * @param obj
 * @returns {{}}
 */

//Converteer het object naar een JSON object
export const createDynamicJson = (obj) => {
    let json = {};
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null && key !== "QuestionAnswerOptions" && key !== "SubQuestions") {
            json[key] = createDynamicJson(obj[key]);
        } else {
            // Check if the value is a numeric string
            if (typeof obj[key] === 'string' && !isNaN(obj[key])) {
                // Convert numeric string to integer
                json[key] = parseInt(obj[key], 10);
            } else {
                json[key] = obj[key];
            }
        }
    }
    return json;
};

/**
 *
 * @param obj
 * @returns {*[]}
 */

//Haal alle keys op van het object
export function getAllKeys(obj) {
    let keys = [];
    for (let key in obj) {
        keys.push(key);
        if (typeof obj[key] === 'object') {
            keys = keys.concat(getAllKeys(obj[key]));
        }
    }
    keys = keys.filter((key) => {
        if (key !== 'Time' && key !== 'References') {
            if (isNaN(key)) {
                return true;
            }
        }
        return false;
    });
    return keys;
}