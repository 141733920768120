import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { getGroupSteps } from "../misc/HardcodedLists";
import apiCall from "../utils/apiCall";
import { displayToast } from "../managers/ToastManager";
import { useNavigate } from "react-router-dom";

/**
 * README ChangeCreateChecklistModal
 *
 * ChangeCreateChecklistModal.jsx is een React component bestand dat de hoofdcomponent ChangeCreateChecklistModal bevat.
 * Deze component wordt gebruikt om een checklist te veranderen of aan te maken in de applicatie.
 *
 * =====================================================================================================================
 *
 * ChangeCreateChecklistModal:
 * Deze component wordt gebruikt om een checklist te veranderen of aan te maken.
 * Het biedt een gebruikersinterface voor het invoeren van de checklistcategorieën en het aanmaken of updaten van de checklist.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het ChangeCreateChecklistModal.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * De props omvatten de huidige checklistcategorieën, groepen, de functie om de checklist aan te maken of te updaten, en andere gerelateerde gegevens en functies.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een checklistCategories array dat de gegevens van de checklistcategorieën bevat, en een handleCreate en handleUpdate functie die de aanmaak of update van de checklist verwerkt.
 *
 */


/**
 * @param show - The boolean that determines if the modal is shown.
 * @param setShow
 * @param handleClose - The function that closes the modal.
 * @param id - The id of the checklist.
 * @param hasChecklist - The boolean that determines if the checklist exists.
 * @returns {JSX.Element} - The JSX element for the modal.
 */

// Een Modal om de checklist te veranderen of aan te maken.
export const ChangeCreateChecklistModal = ({ show, setShow, handleClose, id, hasChecklist }) => {
    const [checklistCategories, setChecklistCategories] = useState([]);
    const [groups, setGroups] = useState([]);


    const navigate = useNavigate();

    // Functie om de input van de checkboxes te verwerken.
    const handleInputChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setChecklistCategories((prevCategories) => [...prevCategories, value]);
        } else {
            setChecklistCategories((prevCategories) =>
                prevCategories.filter((category) => category !== value)
            );
        }
    };

    // useEffect om de checklist te krijgen.
    useEffect(() => {
        if (hasChecklist) {
            (async () => {
                // apiCall
                const response = await apiCall(`checklist/getbycode/${id}`, "POST");
                if (response.data !== null) {
                    if (groups.length === 0) {
                        setChecklistCategories(response.Groups);
                    }
                }
            })();
        }
    }, [show]);

    // Functie om de checklist aan te maken.
    const handleCreate = async (event) => {
        event.preventDefault();
        // apiCall
        await apiCall(`checklist/create`, "POST", {
            Groups: checklistCategories,
            Code: id
        });
        setShow(false);
        displayToast("Checklist is aangemaakt", "success");
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    // Functie om de checklist te updaten.
    const handleUpdate = async (event) => {
        event.preventDefault();
        if (checklistCategories.length === 0) {
            try {
                // apiCall
                await apiCall(`checklist/update`, "POST", {
                    Groups: groups,
                    Code: id
                });
                setShow(false);
                displayToast("Checklist is geüpdatet", "success");
                setGroups([])
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                // apiCall
                await apiCall(`checklist/update`, "POST", {
                    Groups: checklistCategories,
                    Code: id
                });
                setShow(false);
                displayToast("Checklist is geüpdatet", "success");
                setChecklistCategories([])
            } catch (error) {
                console.error(error);
                displayToast("Checklist is niet geüpdatet", "error");

            }
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{hasChecklist ? "Verander Checklist" : "Maak Checklist"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row}>
                        <Col sm={8}>
                            <Form.Label>Selecteer Alles</Form.Label>
                        </Col>
                        <Col sm={4}>
                            <Form.Check
                                type="checkbox"
                                value="Selecteer Alles"
                                checked={checklistCategories.length === getGroupSteps().length - 2}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setChecklistCategories(getGroupSteps().filter(group => group !== "Project Information" && group !== "Project Management"));
                                    } else {
                                        setChecklistCategories([]);
                                    }
                                }}
                            />
                        </Col>
                    </Form.Group>
                    <hr />
                    {getGroupSteps().map((group, index) => (
                        <Form.Group as={Row}>
                            <Col sm={8}>
                                <Form.Label>{group}</Form.Label>
                            </Col>
                            <Col sm={4}>
                                <Form.Check
                                    type="checkbox"
                                    value={group}
                                    disabled={group === "Project Information" || group === "Project Management"}
                                    checked={checklistCategories.includes(group) || group === "Project Information" || group === "Project Management"}
                                    onChange={handleInputChange} W
                                />
                            </Col>
                        </Form.Group>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {hasChecklist ?
                    <>
                        <Button variant="primary" onClick={() => navigate(`/questions/checklist/${id}`)}>
                            <i className="fas fa-Pen" /> Verander Checklist Vragen
                        </Button>
                        <Button variant="success" onClick={handleUpdate}>
                            <i className="fas fa-save" /> Wijzigingen Opslaan
                        </Button>
                    </>
                    :
                    <Button variant="success" onClick={handleCreate}>
                        <i className="fas fa-save" /> Checklist Aanmaken
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}