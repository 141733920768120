import { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Col, Form, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { usePagination } from "../managers/PaginationManager";
import apiCall from "../utils/apiCall";
import { Search } from "../utils/searchUtils";
import { formatDate } from "../managers/DateManager";
import { StatusBadge } from "../managers/BadgeManager";
import { displayToast } from "../managers/ToastManager";
import { StatusList } from "./HardcodedLists";
import { useAuth } from "../../context/AuthContext";

export default function ProjectList() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [originalProjects, setOriginalProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortField, setSortField] = useState("Project");
    const [sortDirection, setSortDirection] = useState("asc");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [hideFinished, setHideFinished] = useState(true);

    // Dit is om de projecten op te halen
    useEffect(() => {
        (async () => {
            const projects = await apiCall("project/all", "POST");
            setOriginalProjects(projects);
            applyFilters(searchQuery, hideFinished, selectedStatus, projects);
            setLoading(false);
            displayToast("Alle projecten zijn geladen", "success");
        })();
    }, []);

    const { currentItems, AboveTable, BelowTable, indexOfFirstItem } = usePagination(filteredProjects);

    // Dit is om de filters toe te passen
    const handleSort = (field) => {
        setSortField(field);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };

    // Dit is om de filters toe te passen
    const sortedItems = [...currentItems].sort((a, b) => {
        if (a[sortField] < b[sortField]) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sortField] > b[sortField]) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Dit is voor de zoekfunctie
    const handleSearchChange = (e) => {
        const newQuery = e.target.value;
        setSearchQuery(newQuery);
        applyFilters(newQuery, hideFinished, selectedStatus, originalProjects);
    };

    // Dit is voor de checkbox
    const handleCheckboxChange = () => {
        const newHideFinished = !hideFinished;
        setHideFinished(newHideFinished);
        applyFilters(searchQuery, newHideFinished, selectedStatus, originalProjects);
    };

    // Dit is om de status te veranderen
    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        setSelectedStatus(newStatus);
        applyFilters(searchQuery, hideFinished, newStatus, originalProjects);
    };

    // Dit is om de filters toe te passen
    const applyFilters = (query, hideFinished, status, projects) => {
        let filtered = projects;
        if (query.trim()) {
            filtered = Search(projects, query);
        }
        if (hideFinished) {
            filtered = filtered.filter(project => project.Status !== "Finished");
        }
        if (status) {
            filtered = filtered.filter(project => project.Status === status);
        }
        setFilteredProjects(filtered);
    };

    // Dit is om de projecten aan te maken dit mag alleen wanneer je de juiste rechten hebt
    const ProjectRoleHandler = () => {
        if (user.Roles.includes("Exact")) {
            navigate('/projects/create');
        } else {
            displayToast("U heeft niet de juiste rechten om een project aan te maken", "error");
        }
    };

    return (
        <>
            <Card>
                <Card.Header>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h1 id="tableLabel" style={{ margin: "0", marginRight: "10px" }}>Projects</h1>
                            <Button variant="success" onClick={ProjectRoleHandler}>
                                <i className="fas fa-plus" />
                            </Button>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ border: '1px solid #495057', borderRadius: '5px', display: 'inline-block' }}>
                                <ButtonGroup>
                                    <Button style={{ width: '140px' }} variant="dark" onClick={() => navigate("/projects")} disabled={true}>Alle projecten</Button>
                                    <Button style={{ width: '140px' }} variant="dark" onClick={() => navigate("/profile/myProjects")}>Jouw projecten</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col md={2}>
                            <Form.Control
                                type="text"
                                name="SearchString"
                                placeholder="Zoeken..."
                                onChange={handleSearchChange}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Select
                                value={selectedStatus}
                                onChange={handleStatusChange}
                            >
                                <option value="">Selecteer een status</option>
                                {StatusList.map((stat, index) => (
                                    <option key={index} value={stat}>
                                        {stat}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Form.Check
                        type="checkbox"
                        label="Verberg voltooide projecten"
                        checked={hideFinished}
                        onChange={handleCheckboxChange}
                        className="mb-2"
                    />
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <h3 className="me-2">
                                Loading projects...
                            </h3>
                            <Spinner animation="border" role="status">
                                <span className="sr-only"></span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            {AboveTable}
                            <Table className="mt-2" striped hover >
                                    <colgroup>
                                        <col style={{ width: "1%" }} />
                                        <col style={{ width: "9%" }} />
                                        <col style={{ width: "2%" }} />
                                        <col style={{ width: "2%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "5%" }} />
                                        <col style={{ width: "5%" }} />
                                    </colgroup>
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th onClick={() => handleSort("Project")}>Project</th>
                                        <th onClick={() => handleSort("Start Datum")}>Start Datum</th>
                                        <th onClick={() => handleSort("Eind Datum")}>Eind Datum</th>
                                        <th>Projectleiding</th>
                                        <th onClick={() => handleSort("Status")}>Status</th>
                                        <th>Actie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedItems.map((project, index) => (
                                        <tr key={project._id} onClick={() => navigate(`details/${project._id}`)}>
                                            <td className="text-center">{indexOfFirstItem + index + 1}</td>
                                            <td>{project.Code} {project.Description}</td>
                                            <td>{project.StartDate ? formatDate(project.StartDate) : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>{project.EndDate ? formatDate(project.EndDate) : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>{project.Manager ? (project.Manager) : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>{project.Status ? <StatusBadge statusTitle={project.Status} /> : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>
                                                <Button variant="info" className="m-1" onClick={() => { navigate(`details/${project._id}`) }}><i className="fas fa-info-circle" /> Informatie</Button>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {project.Employees.length > 0
                                                                ? `Werknemers: ${project.Employees.length}`
                                                                : 'Nog geen werknemers'}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Badge bg="primary" text="dark" pill>{project.Employees.length > 0 ? project.Employees.length : '0'}</Badge>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {BelowTable}
                        </>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}