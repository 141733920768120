// components/ConfettiEffect.js

// Idee van Willem, wanneer er iemand jarig is een popup met confetti wanneer deze persoon inlogt

import React from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const ConfettiEffect = () => {
  const { width, height } = useWindowSize();
  return <Confetti width={width} height={height} numberOfPieces={250} opacity={0.8} />;
};

export default ConfettiEffect;
