import React, { useEffect, useState } from "react";
import apiCall from '../../../components/utils/apiCall';
import { displayToast } from "../../../components/managers/ToastManager";
import { Card, Table, Spinner, Row, Col } from "react-bootstrap";

const ClockifyDashboard = () => {
    const [employees, setEmployees] = useState([]);
    const [functionListAPI, setFunctionListAPI] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const employeesData = await apiCall("employee/all", "POST");
                const functionList = await apiCall("metadata/functionlist", "POST", {});
                setFunctionListAPI(functionList.functionlist || []);
                setEmployees(employeesData);
                displayToast("Alle medewerkers opgehaald", "success");
            } catch (error) {
                displayToast("Fout bij het ophalen van medewerkers", "error");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const calculateTotals = () => {
        const totals = {
            Monday: 0,
            Tuesday: 0,
            Wednesday: 0,
            Thursday: 0,
            Friday: 0,
            Saturday: 0,
            Sunday: 0,
            totalHours: 0,
        };

        employees.forEach(employee => {
            const functionInfo = functionListAPI.find(func => func.name === employee.Function);
            const hourlyRate = functionInfo ? functionInfo.hourlyRate : 0;

            const mondayHours = Math.floor(employee.Monday) || 0;
            const tuesdayHours = Math.floor(employee.Tuesday) || 0;
            const wednesdayHours = Math.floor(employee.Wednesday) || 0;
            const thursdayHours = Math.floor(employee.Thursday) || 0;
            const fridayHours = Math.floor(employee.Friday) || 0;
            const saturdayHours = Math.floor(employee.Saturday) || 0;
            const sundayHours = Math.floor(employee.Sunday) || 0;
            const totalHours = Math.floor(employee.totalHours) || 0;

            totals.Monday += mondayHours;
            totals.Tuesday += tuesdayHours;
            totals.Wednesday += wednesdayHours;
            totals.Thursday += thursdayHours;
            totals.Friday += fridayHours;
            totals.Saturday += saturdayHours;
            totals.Sunday += sundayHours;
            totals.totalHours += totalHours;
            totals.totalPrice += totalHours;
        });

        return totals;
    };

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <Spinner animation="border" />
            </div>
        );
    }

    const totals = calculateTotals();

    return (
        <Card>
            <Card.Header>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h1 id="tableLabel" style={{ margin: "0", marginRight: "10px" }}>Clockify Dashboard</h1>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                    <Card className='mt-3 mb-3'>
            <Card.Body>
                <Card.Title className="text-center">
                    <h5>Totaal aantal geplande uren deze week: {totals.totalHours}</h5>
                </Card.Title>
            </Card.Body>
        </Card>  
                    </Col>
                </Row>
                <Table className="mt-2" striped hover>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "5%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Naam</th>
                            <th>Functie</th>
                            <th>Maandag</th>
                            <th>Dinsdag</th>
                            <th>Woensdag</th>
                            <th>Donderdag</th>
                            <th>Vrijdag</th>
                            <th>Zaterdag</th>
                            <th>Zondag</th>
                            <th>Totaal Uren</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map((employee) => {
                            const functionInfo = functionListAPI.find(func => func.name === employee.Function);
                            const hourlyRate = functionInfo ? functionInfo.hourlyRate : 0;
                            const totalHours = Math.floor(employee.totalHours) || 0;

                            return (
                                <tr key={employee.ID}>
                                    <td>{employee.Firstname} {employee.Lastname}</td>
                                    <td>{employee.Function}</td>
                                    <td>{Math.floor(employee.Monday)}</td>
                                    <td>{Math.floor(employee.Tuesday)}</td>
                                    <td>{Math.floor(employee.Wednesday)}</td>
                                    <td>{Math.floor(employee.Thursday)}</td>
                                    <td>{Math.floor(employee.Friday)}</td>
                                    <td>{Math.floor(employee.Saturday)}</td>
                                    <td>{Math.floor(employee.Sunday)}</td>
                                    <td><strong>{totalHours}</strong></td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan="2"><strong>Totaal</strong></td>
                            <td><strong>{totals.Monday}</strong></td>
                            <td><strong>{totals.Tuesday}</strong></td>
                            <td><strong>{totals.Wednesday}</strong></td>
                            <td><strong>{totals.Thursday}</strong></td>
                            <td><strong>{totals.Friday}</strong></td>
                            <td><strong>{totals.Saturday}</strong></td>
                            <td><strong>{totals.Sunday}</strong></td>
                            <td><strong>{totals.totalHours}</strong></td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export default ClockifyDashboard;
