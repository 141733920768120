import {Button, Col, Container, Form, ListGroup, Placeholder, Row, Table} from "react-bootstrap";
import {useState} from "react";
import toast from "react-hot-toast";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import DataTable from "datatables.net-dt";
import {OneFileCheck} from "../../../components/hesselToDo/Checkfiles";

import {GetAPIurl} from "../../../components/hesselToDo/Data";

export default function Converter() {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState();
  return (
    <div>
      <>
        <Row className="container-fluid">
          {Start(setLoaded, setData)}
          {Download(loaded)}
        </Row>
        {Tables(loaded, data)}
      </>
    </div>
  );
}

function Tables(loaded, data) {
  if (!loaded) return <Row></Row>;

  setTimeout(() => {
    SetDatable("TableAanmaak");
    SetDatable("TableStuklijst");
    SetDatable("Tablebezonderlijst");
  }, 1000);

  return (
    <Container fluid>
      <Row>
        <Col id="table1">
          <h5>Aanmaak</h5>
          {Tabel("TableAanmaak", data['Item1'], ["Zoekcode", "Code", "Omschrijving"])}
        </Col>
        <Col id="table2">
          <h5>Stuklijst</h5>
          {Tabel("TableStuklijst", data['Item2'], ["ItemCode", "MaterialCode", "Quantity"])}
        </Col>
        <Col id="table3">
          <h5>Bezonderhedenlijst</h5>
          {Tabel("Tablebezonderlijst", data['Item3'], ["ItemCode", "MaterialCode", "Quantity", "Afkomstig"])}
        </Col>
      </Row>
    </Container>
  );
}

function Tabel(id, data, header) {
  return (
    <Table id={id} striped bordered responsive>
      <colgroup>
        <col />
      </colgroup>
      <thead>
        <tr key={6969}>
          {header.map((head) => (
            <th key={Math.random()}>{head}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={Math.random()}>
            {header.map((head) => (
              <td key={Math.random()}>{item[head]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function SetDatable(id) {
  if (DataTable.isDataTable(`#${id}`)) return;
  let table = new DataTable(`#${id}`, {
    lengthMenu: [-1, 1000, 100, 50],
    language: {
      search: "Zoeken:",
      info: "Toont pagina _PAGE_ van _PAGES_ | Van _START_ tot _END_ van totaal _TOTAL_ aantal.",
      lengthMenu: "Toont _MENU_ aantal",
      zeroRecords: "Geen bijpassende records gevonden",
      paginate: {
        next: "Volgende",
        previous: "Vorige"
      }
    }
  });
}

function Start(setLoaded, setData) {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!OneFileCheck(e.target.files, ["xlsx", "xls", "csv"])) return;

    var formData = new FormData();
    formData.append('file', e.target.files[0]);

    var myheaders = {
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: formData,
    };

    fetch(`${GetAPIurl()}/api/eplan/importartikel/file`, req)
      .then((response) => response.json())
      .then((d) => {
        toast.success("Gelukt!");
        setData(d);
        setLoaded(true);
        document.getElementById("mainfooter").style.display = "none";
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  };
  return (
    <Col>
      <h3>Exact Artikellijst</h3>
      <span>
        Hier wordt de stuklijst van de materiaalconverter omgegooit in een
        import artikelijst voor exact.
      </span>
      <p>
        - Artikelaanmaaklijst: <a className="text-primary">Verkoop - Artikelen</a>.
        <br />
        - Stuklijst: <a className="text-primary">Productie - Stuklijst(oude)</a>.
        <br />
        - Bezonderhedenlijst: <a className="text-danger">Nacontroleren</a>.
      </p>
      <h6>Voer hier uw bestand in:</h6>
      <Form className="was-validated" onChange={handleSubmit}>
        <Form.Group className="input-group mb-3">
          <label htmlFor="file" className="btn btn-outline-primary"><i className="fas fa-cloud-arrow-up"></i></label>
          <Form.Control id="file" type="file" className="btn btn-outline-primary" accept=".xlsx, .xls, .csv" required />
        </Form.Group>
      </Form>
    </Col>
  );
}

function FileDownload(data, setDownload) {
  const zip = new JSZip();
  zip.file(`${data['listjes'].Item4}_Artikelaanmaaklijst_${data['date']}.csv`, data['listjes'].Item1, { base64: true })
  zip.file(`${data['listjes'].Item4}_Stuklijst_${data['date']}.xls`, data['listjes'].Item2, { base64: true })
  zip.file(`${data['listjes'].Item4}_Bezonderhedenlijst_${data['date']}.xls`, data['listjes'].Item3, { base64: true })

  zip.generateAsync({ type: "blob" })
    .then((content) => {
      const blob = new Blob([content], { type: 'application/zip' });
      saveAs(blob, `${data['listjes'].Item4}_Pakket_${data['date']}.zip`);
      setDownload(false);
    })
    .catch((error) => {
      toast.error("Ging wat fout bij downloaden.");
      console.error(error);
      setDownload(false);
    })
}

function Download(loaded) {
  const [download, setDownload] = useState(false);
  if (!loaded) return (
    <Col>
      <Placeholder xs={8} />
    </Col>
  );
  const handleDownload = (e) => {
    e.preventDefault();
    setDownload(true);

    fetch(`${GetAPIurl()}/api/eplan/importartikel/zip`)
      .then((resp) => resp.json())
      .then(d => {
        FileDownload(d, setDownload);
      })
      .catch((error) => console.error(error));
  }

  const handleUpload = (e) => {
    e.preventDefault();

  }

  const WeergaveAanmaak = (e) => {
    e.preventDefault();
    document.getElementById('table1').classList.toggle('hidden');
  }

  const WeergaveStuklijst = (e) => {
    e.preventDefault();
    document.getElementById('table2').classList.toggle('hidden');
  }

  const WeergaveBezonderheden = (e) => {
    e.preventDefault();
    document.getElementById('table3').classList.toggle('hidden');
  }

  return (
    <Col>
      <ListGroup>
        <Button onClick={handleUpload} className="list-group-item" title="Bestanden laden naar exact." disabled={download}>
          <i className="fas fa-upload"></i>
          Exact uploaden <a className="text-warning">WIP</a>
        </Button>
        <Button onClick={handleDownload} className="list-group-item" title="Sla bestanden op als CSV" disabled={download}>
          <i className="fas fa-floppy-disk"></i>
          Bestanden opslaan
        </Button>
        <ListGroup horizontal>
          <Button style={{ width: "33%" }} className="list-group-item" onClick={WeergaveAanmaak} title="Laat zien / verberg aanmaaklijst.">
            <i className="fas fa-eye-slash"></i>
            AanmaakLijst
          </Button>
          <Button style={{ width: "33%" }} className="list-group-item" onClick={WeergaveStuklijst} title="Laat zien / verberg stuklijst.">
            <i className="fas fa-eye-slash"></i>
            StukLijst
          </Button>
          <Button style={{ width: "34%" }} className="list-group-item" onClick={WeergaveBezonderheden} title="Laat zien / verberg bezonderhedenlijst.">
            <i className="fas fa-eye-slash"></i>
            BezonderhedenLijst
          </Button>
        </ListGroup>
      </ListGroup>
    </Col>
  );
}
