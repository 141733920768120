import React from 'react'
import CabinetTable from '../../../components/tables/CabinetTable'
import {useParams} from 'react-router-dom'


export default function DeviceList() {

    const { id } = useParams();

    return (
        <CabinetTable id={id} type="data_devicelist" />
    )
}