import { Button, ButtonGroup, Card, Col, FloatingLabel, Form, Row, } from "react-bootstrap";
import toast from "react-hot-toast";
import { DateISO, TimeISO } from "../../../components/managers/DateManager";

import { GetAPIurl, useQueryID } from "../../../components/hesselToDo/Data";

export async function getServerSideProps(context) {
    const id = context.params.id;
    return { props: { id } };
}

export default function Project({ id }) {
    return (
        <Card>
            <Card.Header>
                <h4>Details - {id}</h4>
            </Card.Header>
            <Card.Body>{FormDetails(id)}</Card.Body>
            <Card.Footer>
                <Button variant="outline-primary" href="/materials">
                    Terug
                </Button>
            </Card.Footer>
        </Card>
    );
}

function FormDetails(id) {
    const { data, error, loading } = useQueryID("materials", id);
    if (error) return <div className="text-danger">Laden mislukt</div>;
    if (loading) return <div className="text-primary">Bezig met laden...</div>;
    const Delete = async (e) => {
        e.preventDefault();

        var req = {
            method: "DELETE",
            body: data.id,
        };

        fetch(`${GetAPIurl()}/api/materials/${data.id}`, req)
            .then((d) => {
                if (d.status === 204) {
                    toast.success("Success!");
                    window.location.replace("/materials");
                } else {
                    toast.error("Failed");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                toast.error("Failed");
            });
    };

    return (
        <>
            <Form>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="ID">
                            <Form.Control type="text" id="id" defaultValue={data.id} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Name">
                            <Form.Control type="text" id="name" defaultValue={data.name} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Brand">
                            <Form.Control type="text" id="brand" defaultValue={data.brand} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Model">
                            <Form.Control type="text" id="model" defaultValue={data.model} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Link">
                            <Form.Control type="text" id="deeplink" defaultValue={data.deeplink} readOnly />
                        </FloatingLabel>
                        <a href={data.deeplink} target="_blank">Link</a>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Version">
                            <Form.Control type="text" id="version" defaultValue={data.version} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Description">
                            <Form.Control type="text" id="description" defaultValue={data.description} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="EPLAN description">
                            <Form.Control type="text" id="eplanDescription" defaultValue={data.eplanDescription} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Check type="switch" id="discontinuedProduct" label="Discount" defaultChecked={data.discontinuedProduct} readOnly />
                        <Form.Check type="switch" id="unifeedControl" label="Control" defaultChecked={data.unifeedControl} readOnly />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Barcodetype">
                            <Form.Control type="text" id="barcodeType" defaultValue={data.barcodeType} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Barcode">
                            <Form.Control type="number" id="barcode" defaultValue={data.barcode} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Manufacturer name">
                            <Form.Control type="text" id="manufactureName" defaultValue={data.manufactureName} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Manufacturer GLN">
                            <Form.Control type="text" id="manufacturerGLN" defaultValue={data.manufacturerGLN} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Order number">
                            <Form.Control type="text" id="orderNumber" defaultValue={data.orderNumber} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Article number">
                            <Form.Control type="text" id="articleNumber" defaultValue={data.articleNumber} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Product Code">
                            <Form.Control type="text" id="productCode" defaultValue={data.productCode} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="SortID">
                            <Form.Control type="text" id="eplanSortID" defaultValue={data.eplanSortID} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="GTIN">
                            <Form.Control type="text" id="gtin" defaultValue={data.gtin} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Variant">
                            <Form.Control type="text" id="variant" defaultValue={data.variant} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Material Code">
                            <Form.Control type="text" id="materialCode" defaultValue={data.materialCode} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Identifier">
                            <Form.Control type="text" id="productIdentifier" defaultValue={data.productIdentifier} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Created time">
                            <Form.Control type="time" id="created_Attime" defaultValue={TimeISO(data.created_At)} readOnly />
                        </FloatingLabel>
                        <FloatingLabel label="Created date">
                            <Form.Control type="date" id="created_At" defaultValue={DateISO(data.created_At)} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Modified time">
                            <Form.Control type="time" id="modified_Attime" defaultValue={TimeISO(data.modified_At)} readOnly />
                        </FloatingLabel>
                        <FloatingLabel label="Modified date">
                            <Form.Control type="date" id="modified_At" defaultValue={DateISO(data.modified_At)} readOnly />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
            </Form>
            <Col sm="1">
                <ButtonGroup>
                    <Button variant="warning" href={`/materials/edit/${id}`}>
                        <i className="fas fa-pen"></i>
                    </Button>
                    <Button variant="danger" onClick={Delete}>
                        <i className="fas fa-trash-can"></i>
                    </Button>
                </ButtonGroup>
            </Col>
        </>
    );
}
