import React, { useState } from "react";
import {Form, Nav} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

/**
 * README ChecklistUtils
 *
 * ChecklistUtils.jsx is een JavaScript bestand dat een reeks hulpprogramma's bevat die worden
 * gebruikt in de checklistcomponenten van de applicatie.
 *
 * =============================================================================================
 * Functies:
 *  openQuestion:
 *      Deze functie wordt gebruikt om een open vraag te renderen.
 *      Het neemt een vraag, antwoorden en een handleInputChange functie als parameters.
 *
 *  closedQuestion:
 *      Deze functie wordt gebruikt om een gesloten vraag te renderen.
 *      Het neemt een vraag, antwoorden en een handleInputChange functie als parameters.
 *
 *  checkBoxQuestion:
 *      Deze functie wordt gebruikt om een checkbox vraag te renderen.
 *      Het neemt een vraag, antwoorden en een handleInputChange functie als parameters.
 *
 *  Group:
 *      Deze component wordt gebruikt om een groep te renderen.
 *      Het neemt een groep, datagroep, groepIndex, groupActiveStep, handleClickGroup en data als parameters.
 *
 *  Element:
 *      Deze component wordt gebruikt om een element te renderen.
 *      Het neemt een element, elementIndex, groupActiveStep, elementActiveStep, handleClickElement en groep als parameters.
 *
 *  BackButton:
 *      Deze component wordt gebruikt om de terug knop te renderen.
 *
 * =============================================================================================
 *
 * Gebruik:
 * Om deze functies te gebruiken, importeer ze uit het ChecklistUtils.jsx bestand en gebruik ze in je componenten waar nodig.
 * Zorg ervoor dat je de juiste parameters levert voor elke functie.
 *
 */

/**
 *
 * @param question
 * @param answers
 * @param handleInputChange
 * @returns {React.JSX.Element}
 */
// Functie om een open vraag te renderen
export const openQuestion = (question, answers, handleInputChange) => {
    let inputValue = answers[question.QuestionID] || question.DefaultAnswer || null;
    return (
        <Form.Control
            placeholder={question.Element === "Betalingstermijn" ? "Vul een percentage in" : "Vul een antwoord in"}
            defaultValue={inputValue === null ? question.Answer : inputValue}
            name={`answer-${question.QuestionID}`}
            onChange={(e) => {
                inputValue = e.target.value;
            }}
            onBlur={(e) => {
                handleInputChange(e, question);
            }}
        />
    );
};

/**
 *
 * @param question
 * @param answers
 * @param handleInputChange
 * @returns {React.JSX.Element}
 */
// Functie om een gesloten vraag te renderen
export const closedQuestion = (question, answers, handleInputChange) => {
    return (
        <Form.Select
            value={
                answers[
                    question.QuestionID
                    ] || question.DefaultAnswer || ""
            }
            name={`answer-${question.QuestionID}`}
            defaultValue={question.Answer}
            onChange={(e) => {
                handleInputChange(e, question);
            }}
        >
            <option value="">Selecteer een antwoord</option>
            {question.QuestionAnswerOptions.map((element) => (
                <option key={element} value={element}>
                    {element}
                </option>
            ))}
        </Form.Select>
    );
};

/**
 *
 * @param question
 * @param answers
 * @param handleInputChange
 * @returns {React.JSX.Element}
 */
// Functie om een checkbox vraag te renderen
export const checkBoxQuestion = (question, answers, handleInputChange) => {
    return (
        <input
            checked={
                answers[question.QuestionID
                    ] || question.DefaultAnswer || false
            }
            type="checkbox"
            defaultChecked={question.Answer}
            name={`answer-${question.QuestionID}`}
            onChange={(e) => {
                handleInputChange(e, question);
            }}
        />
    );
};


/**
 *
 * @param group
 * @param datagroup
 * @param groupIndex
 * @param groupActiveStep
 * @param handleClickGroup
 * @param data
 * @returns {Element}
 * @constructor
 */
    // Component om een groep te renderen
export const Group = ({ group, datagroup, groupIndex, groupActiveStep, handleClickGroup, data}) => {
        const filteredGroups = Object.keys(data).filter(key => data[key].length > 0);
        return (
            <div id={groupIndex} key={groupIndex}>
                <Nav.Link
                    eventkey={groupIndex}
                    className={`cl-link btn ${groupActiveStep === groupIndex ? "active" : ""
                    }`}
                    onClick={() => handleClickGroup(groupIndex, group, datagroup)}
                    disabled={!filteredGroups.includes(group.replace(/\s/g, '').toLowerCase())}
                >
                    {group}
                    <br />
                </Nav.Link>
            </div>
        );
    };

/**
 *
 * @param element
 * @param elementIndex
 * @param groupActiveStep
 * @param elementActiveStep
 * @param handleClickElement
 * @param group
 * @returns {Element}
 * @constructor
 */
    // Component om een element te renderen
export const Element = ({ element, elementIndex, groupActiveStep, elementActiveStep, handleClickElement, group }) => {
        return (
            <div id={elementIndex} key={elementIndex}>
                <Nav.Link
                    eventKey={`${groupActiveStep}-${element}`}
                    className={`cl-link-sub d-flex text-start ${elementActiveStep === element ? "active" : ""
                    }`}
                    onClick={() =>
                        handleClickElement(
                            element,
                            elementIndex,
                            group
                        )
                    }
                >
                    {element}{" "}
                </Nav.Link>
            </div>
        );
    };

/**
 *
 * @returns {Element}
 * @constructor
 */
    // Component om de terug knop te renderen
export const BackButton = () => {
        const navigate = useNavigate();
        return (
            <button
                className="btn btn-danger btn-back"
                onClick={() => navigate(-1)}
            >
                <i className="fa fa-arrow-left" /> Terug
            </button>
        );
    };