import { Button, ButtonGroup, Card, Col, FloatingLabel, Form, Row, } from "react-bootstrap";
import toast from "react-hot-toast";
import { DateISO, TimeISO } from "../../../components/managers/DateManager"

import { GetAPIurl, useQueryID } from "../../../components/hesselToDo/Data";

export async function getServerSideProps(context) {
    const id = context.params.id;
    return { props: { id } };
}

export default function Project({ id }) {
    return (
        <>
            <Card>
                <Card.Header>
                    <h4>Aanpassen - {id}</h4>
                </Card.Header>
                <Card.Body>{FormDetails(id)}</Card.Body>
                <Card.Footer>
                    <Button variant="outline-primary" href="/materials">
                        Terug
                    </Button>
                </Card.Footer>
            </Card>
        </>
    );
}

function FormDetails(id) {
    const { data, error, loading } = useQueryID("materials", id)
    if (error) return <div className="text-danger">Laden mislukt</div>;
    if (loading) return <div className="text-primary">Bezig met laden...</div>;
    const handleSubmit = (e) => {
        e.preventDefault();

        const Changed = new Date().toISOString();
        const raw = JSON.stringify({
            id: id,
            eplanSortID: document.getElementById("eplanSortID").value,
            materialCode: document.getElementById("materialCode").value,
            orderNumber: document.getElementById("orderNumber").value,
            articleNumber: document.getElementById("articleNumber").value,
            variant: document.getElementById("variant").value,
            eplanDescription: document.getElementById("eplanDescription").value,
            discontinuedProduct: document.getElementById("discontinuedProduct").checked,
            barcode: document.getElementById("barcode").value,
            barcodeType: document.getElementById("barcodeType").value,
            productCode: document.getElementById("productCode").value,
            gtin: document.getElementById("gtin").value,
            manufacturerGLN: document.getElementById("manufacturerGLN").value,
            manufactureName: document.getElementById("manufactureName").value,
            productIdentifier: document.getElementById("productIdentifier").value,
            description: document.getElementById("description").value,
            brand: document.getElementById("brand").value,
            model: document.getElementById("model").value,
            version: document.getElementById("version").value,
            deeplink: document.getElementById("deeplink").value,
            unifeedControl: document.getElementById("unifeedControl").checked,
            created_At: data.created_At,
            modified_At: Changed,
        });

        var myheaders = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };

        var req = {
            method: "PUT",
            headers: myheaders,
            body: raw,
        };

        fetch(`${GetAPIurl()}/api/materials/${id}`, req)
            .then((d) => {
                if (d.status === 204) {
                    toast.success("Success!");
                } else {
                    toast.error("Failed");
                }
                return d.json();
            })
            .catch((error) => {
                console.error("Error:", error);
                toast.error("Failed");
            });
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="ID">
                            <Form.Control type="text" id="id" defaultValue={data.id} disabled />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Name">
                            <Form.Control type="text" id="name" defaultValue={data.name} />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Brand">
                            <Form.Control type="text" id="brand" defaultValue={data.brand} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Model">
                            <Form.Control type="text" id="model" defaultValue={data.model} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Link">
                            <Form.Control type="text" id="deeplink" defaultValue={data.deeplink} />
                        </FloatingLabel>
                        <a href={data.deeplink} target="_blank">Link</a>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Version">
                            <Form.Control type="text" id="version" defaultValue={data.version} />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Description">
                            <Form.Control type="text" id="description" defaultValue={data.description} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="EPLAN description">
                            <Form.Control type="text" id="eplanDescription" defaultValue={data.eplanDescription} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Check type="switch" id="discontinuedProduct" label="Discount" defaultChecked={data.discontinuedProduct} />
                        <Form.Check type="switch" id="unifeedControl" label="Control" defaultChecked={data.unifeedControl} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Barcodetype">
                            <Form.Control type="text" id="barcodeType" defaultValue={data.barcodeType} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Barcode">
                            <Form.Control type="number" id="barcode" defaultValue={data.barcode} />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Manufacturer name">
                            <Form.Control type="text" id="manufactureName" defaultValue={data.manufactureName} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Manufacturer GLN">
                            <Form.Control type="text" id="manufacturerGLN" defaultValue={data.manufacturerGLN} />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Order number">
                            <Form.Control type="text" id="orderNumber" defaultValue={data.orderNumber} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Article number">
                            <Form.Control type="text" id="articleNumber" defaultValue={data.articleNumber} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Product Code">
                            <Form.Control type="text" id="productCode" defaultValue={data.productCode} />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="SortID">
                            <Form.Control type="text" id="eplanSortID" defaultValue={data.eplanSortID} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="GTIN">
                            <Form.Control type="text" id="gtin" defaultValue={data.gtin} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Variant">
                            <Form.Control type="text" id="variant" defaultValue={data.variant} />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Material Code">
                            <Form.Control type="text" id="materialCode" defaultValue={data.materialCode} />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Identifier">
                            <Form.Control type="text" id="productIdentifier" defaultValue={data.productIdentifier} />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <FloatingLabel label="Created time">
                            <Form.Control type="time" id="created_Attime" defaultValue={TimeISO(data.created_At)} disabled />
                        </FloatingLabel>
                        <FloatingLabel label="Created date">
                            <Form.Control type="date" id="created_At" defaultValue={DateISO(data.created_At)} disabled />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <FloatingLabel label="Modified time">
                            <Form.Control type="time" id="modified_Attime" defaultValue={TimeISO(data.modified_At)} disabled />
                        </FloatingLabel>
                        <FloatingLabel label="Modified date">
                            <Form.Control type="date" id="modified_At" defaultValue={DateISO(data.modified_At)} disabled />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Col sm="1">
                    <ButtonGroup>
                        <Button variant="success" type="submit">
                            <i className="fas fa-pen"></i>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Form>
        </div>
    );
}
