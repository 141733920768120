import React, { useState, useEffect } from 'react';
import apiCall from './apiCall';

export const OptionsByFunction = ({ function_name }) => {
    const [user_list, setUserList] = useState([]);

    useEffect(() => {
        const fetchUserList = async () => {
            try {
                const response = await apiCall("employee/getbyfunction", "POST", function_name);
                if (Array.isArray(response)) {
                    setUserList(response);
                } else {
                    console.error("Unexpected response format:", response);
                }
            } catch (error) {
                console.error("Error fetching user list:", error);
            }
        };
        fetchUserList();
    }, [function_name]);

    return (
        user_list.map((user) => (
            <option key={user.EmployeeID} value={user.EmployeeID}>
                {user.Fullname}
            </option>
        ))
    );
};

export const OptionsByUserList = ({ user_list }) => {

    return (
        user_list.map((user) => (
            <option key={user.EmployeeID} value={user.EmployeeID}>
                {user.Fullname}
            </option>
        ))
    );
};

export const OptionsByList = ({ list }) => {

    return (
        list.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ))
    );
};