import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Offcanvas, Row, Tab, Table, Tabs } from 'react-bootstrap';
import {
    getTimeFields
} from '../misc/HardcodedLists';
import apiCall from '../utils/apiCall';
import { createDynamicJson } from '../utils/MakeJSON';
import {
    nextTab,
    prevTab
} from '../modals/ModalFunctions';
import { displayToast } from '../managers/ToastManager';

/**
 * README CreateQuestionPropertyBox
 *
 * CreateQuestionPropertyBox.jsx is een React component bestand dat de hoofdcomponent CreateQuestionPropertyBox bevat.
 * Deze component wordt gebruikt om verschillende vragen in de applicatie aan te maken.
 *
 * =====================================================================================================================
 *
 * CreateQuestionPropertyBox:
 * Deze component wordt gebruikt om een nieuwe vraag aan te maken.
 * Het biedt een gebruikersinterface voor het invoeren van de details van de vraag, zoals het vraagtype, de vraagtekst, het antwoordtype, en andere gerelateerde eigenschappen.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het CreateQuestionPropertyBox.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren. De props omvatten de showCreate, setShowCreate, handleClose, questions, questionElements, groupIndex, type, group, dataTargets, dataTypes en GetData.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat de details van de vragen, de elementen van de vragen, de groepen van de vragen, de gegevenstargets en de gegevenstypen.
 */


/**
 * @param showCreate
 * @param setShowCreate
 * @param handleClose
 * @param questions
 * @param questionElements
 * @param groupIndex
 * @param type
 * @param group
 * @param dataTargets
 * @param dataTypes
 * @returns {Element}
 * @constructor
 */
const CreateQuestionPropertyBox = ({
    showCreate,
    setShowCreate,
    handleClose,
    questions,
    questionElements,
    taskGroups,
    type,
    group,
    code,
    dataTargets,
    dataTypes,
    GetData,
}) => {

    const getNewQuestionID = () => {
        const questionIDs = questions.map(question => question.QuestionID);
        const maxID = Math.max(...questionIDs);
        return maxID + 1;
    };

    const [key, setKey] = useState('general');
    const [answers, setAnswers] = useState([])
    const [newQuestion, setNewQuestion] = useState({
        QuestionID: getNewQuestionID(),
        isActive: false,
    });
    const [UserRoles, setUserRoles] = useState([]);

    useEffect(() => {
        (async () => {
            setUserRoles(await apiCall("metadata/questionroles", "POST", {}))
        })();
    }, []);

    // Functie om de vraag aan te maken
    const createQuestion = async () => {
        try {
            // converteer de antwoorden naar een JSON object
            const json = createDynamicJson(newQuestion, null, answers);

            // Kijkt of het een globale vraag is of een checklist vraag
            if (type === "global") {
                await apiCall("question/" + group.replace(" ", "").toLowerCase() + "/create", "POST", json);
            } else if (type === "checklist") {
                await apiCall("checklist/customquestioncreate/" + group.replace(" ", "").toLowerCase(), "POST", {
                    CustomQuestion: json,
                    ChecklistCode: code
                }, {}, true);
            }
            // Sluit de offcanvas en haal de data opnieuw op
            setShowCreate(false);
            setAnswers([]);
            GetData();

        } catch (error) {
            console.error("Er is een probleem met het aanmaken van de vraag", error);
        }
    };

    return (
        <Offcanvas show={showCreate} placement={"end"} className={"w-50"} onHide={() => handleClose("create")}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Vraag Aanmaken</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Tabs activeKey={key} onSelect={(k) => setKey(k)} defaultActiveKey="general" id="create-tabs">
                    <Tab eventKey="general" title="Generiek"><br />
                        <Row>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Vraag ID:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Control
                                            type="text"
                                            id="question_id"
                                            defaultValue={newQuestion.QuestionID}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Onderdeel:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Control
                                            list={"elements"}
                                            id="question_element"
                                            onBlur={(e) => setNewQuestion({ ...newQuestion, Element: e.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <datalist id="elements">
                                {questionElements.map((element) => (
                                    <option key={element}>{element}</option>
                                ))}
                            </datalist>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Invullen door:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Select
                                            as="select"
                                            id="question_role"
                                            onChange={(e) => setNewQuestion({ ...newQuestion, Role: e.target.value })}
                                        >
                                            <option id="question_role_answer" key="role">--- Select a Role ---
                                            </option>
                                            {UserRoles.map((role) => (
                                                <option key={role}>{role}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Vraag Type:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Select
                                            as="select"
                                            id="question_type"
                                            onChange={(e) => setNewQuestion({
                                                ...newQuestion,
                                                QuestionType: e.target.value
                                            })}
                                        >
                                            <option key="type">--- Select a Type ---</option>
                                            <option key={"open"}>open</option>
                                            <option key={"multi"}>multi</option>
                                            <option key={"checkbox"}>checkbox</option>

                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Vraag:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Control
                                            type="input"
                                            id="question_question"
                                            onChange={(e) => setNewQuestion({ ...newQuestion, Name: e.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            {newQuestion.QuestionType === "multi" ? (
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Meerkeuzen Antwoorden:
                                        </Form.Label>
                                        <Col md={8}>
                                            {newQuestion.QuestionAnswerOptions && newQuestion.QuestionAnswerOptions.map((answer, index) => (
                                                <div key={index} className={"d-flex mb-1"}>
                                                    <Form.Control
                                                        type="text"
                                                        className={"w-50"}
                                                        defaultValue={answer}
                                                        onChange={(e) => {
                                                            let questionAnswerOptions = [...newQuestion.QuestionAnswerOptions];
                                                            questionAnswerOptions[index] = e.target.value;
                                                            setNewQuestion({
                                                                ...newQuestion,
                                                                QuestionAnswerOptions: questionAnswerOptions
                                                            });
                                                        }}
                                                    />
                                                    <Button variant={"outline-danger"}
                                                        onClick={() => {
                                                            const newAnswers = [...newQuestion.QuestionAnswerOptions];
                                                            newAnswers.splice(index, 1);
                                                            setNewQuestion({
                                                                ...newQuestion,
                                                                QuestionAnswerOptions: newAnswers
                                                            });

                                                        }}>
                                                        <i className={"fa fa-trash"} />
                                                    </Button>
                                                </div>
                                            ))}
                                            <Button
                                                onClick={() => {
                                                    let questionAnswerOptions = Array.isArray(newQuestion.QuestionAnswerOptions) ? newQuestion.QuestionAnswerOptions : [];
                                                    questionAnswerOptions.push("");
                                                    setNewQuestion({
                                                        ...newQuestion,
                                                        QuestionAnswerOptions: questionAnswerOptions
                                                    });
                                                }}
                                            >
                                                <i className={"fa fa-plus"} /> Add Answer
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            ) : null}
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Standaard Antwoord:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Control
                                            type="text"
                                            id='DefaultAnswer'
                                            onChange={(e) => setNewQuestion({
                                                ...newQuestion,
                                                Answer: e.target.value
                                            })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Aangepaste Document:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Control
                                            type="text"
                                            id="DocumentCustom"
                                            onChange={(e) => setNewQuestion({
                                                ...newQuestion,
                                                DocCustom: e.target.value
                                            })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Document ID:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Control
                                            type="text"
                                            id="DocumentID"
                                            defaultValue={newQuestion.DocID}
                                            onChange={(e) => setNewQuestion({ ...newQuestion, DocID: e.target.value })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Taakgroep:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Select
                                            id="Taskgroup"
                                            onChange={(e) => setNewQuestion({
                                                ...newQuestion,
                                                Taskgroup: e.target.value
                                            })}
                                        >
                                            <option key="taskgroup">--- Select an Taskgroup ---</option>
                                            {taskGroups ? Object.entries(taskGroups).map(([id, func]) => (
                                                <option key={id}>{func.name}</option>
                                            )) : <option key="default">No Taskgroups</option>}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column>
                                        Actief:
                                    </Form.Label>
                                    <Col md={8}>
                                        <Form.Check
                                            type="switch"
                                            id="Active"
                                            defaultChecked={false}
                                            onChange={(e) => setNewQuestion({
                                                ...newQuestion,
                                                isActive: e.target.checked
                                            })}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>

                            {/*Word in de backend gedaan door Martijn met de page tree. Ook besloten om pas na het aanmaken van de vraag het neer te zetten als een subquestion of een parentquestion*/}
                            {/*<Col md={12}>*/}
                            {/*    <Form.Group as={Row} className="mb-3">*/}
                            {/*        <Form.Label column>*/}
                            {/*            PageTree:*/}
                            {/*        </Form.Label>*/}
                            {/*        <Col md={8}>*/}
                            {/*            <Form.Control*/}
                            {/*                type="number"*/}
                            {/*                id="PageTree"*/}
                            {/*                onChange={(e) => setNewQuestion({ ...newQuestion, PageTree: e.target.value })}*/}
                            {/*            />*/}
                            {/*        </Col>*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*{newQuestion.Element !== undefined && (*/}
                            {/*    <Col md={12}>*/}
                            {/*        <Form.Group as={Row} className="mb-3">*/}
                            {/*            <Form.Label column>*/}
                            {/*                Subvragen:*/}
                            {/*            </Form.Label>*/}
                            {/*            <Col md={8}>*/}
                            {/*                <div className="subquestionList">*/}
                            {/*                    {questions.filter(item => item.Element === newQuestion.Element && item.PageTree >= newQuestion.PageTree && item.ParentQuestionID === null).map(subquestion => (*/}
                            {/*                        <Form.Check*/}
                            {/*                            type="checkbox"*/}
                            {/*                            key={subquestion.QuestionID}*/}
                            {/*                            defaultChecked={false}*/}
                            {/*                            className="mt-2"*/}
                            {/*                            id={`question-${subquestion.QuestionID}`}*/}
                            {/*                            label={subquestion.Name}*/}
                            {/*                            onChange={e => handleSubQuestionCheckboxChange(e, subquestion.QuestionID, setNewQuestion)}*/}
                            {/*                        />*/}
                            {/*                    ))}*/}
                            {/*                </div>*/}
                            {/*            </Col>*/}
                            {/*        </Form.Group>*/}
                            {/*    </Col>*/}
                            {/*)}*/}
                            {/*{newQuestion.PageTree > 1 && (*/}
                            {/*    <Col md={12}>*/}
                            {/*        <Form.Group as={Row} className="mb-3">*/}
                            {/*            <Form.Label column>*/}
                            {/*                Hoofdvraag:*/}
                            {/*            </Form.Label>*/}
                            {/*            <Col md={8}>*/}
                            {/*                {questions.filter(item => item.Element === newQuestion.Element && item.PageTree <= newQuestion.PageTree).map(parentquestion => (*/}
                            {/*                    <Form.Check*/}
                            {/*                        type="checkbox"*/}
                            {/*                        key={parentquestion.QuestionID}*/}
                            {/*                        disabled={newQuestion.ParentQuestionID === undefined ? false : parentquestion.QuestionID !== newQuestion.ParentQuestionID}*/}
                            {/*                        className="mt-2"*/}
                            {/*                        id={`question-${parentquestion.QuestionID}`}*/}
                            {/*                        label={parentquestion.Name}*/}
                            {/*                        onChange={e => handleParentQuestionCheckboxChange(e, parentquestion.QuestionID, setNewQuestion)}*/}
                            {/*                    />*/}
                            {/*                ))}*/}
                            {/*            </Col>*/}
                            {/*        </Form.Group>*/}
                            {/*    </Col>*/}
                            {/*)}*/}
                        </Row>
                    </Tab>
                    <Tab eventKey="references" title="Referenties">
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        Data Types
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Reference</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataTypes.map((reference) => (
                                                    <tr key={reference}>
                                                        <td>{reference}</td>
                                                        <td>
                                                            <Form.Check
                                                                type="switch"
                                                                key={reference}
                                                                defaultChecked={false}
                                                                id={`${reference}`}
                                                                onChange={(e) =>
                                                                    setNewQuestion(
                                                                        {
                                                                            ...newQuestion,
                                                                            References: {
                                                                                ...newQuestion.References,
                                                                                [reference]: e.target.checked
                                                                            }
                                                                        }
                                                                    )

                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        Data Targets
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Reference</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataTargets.map((reference) => (
                                                    <tr key={reference}>
                                                        <td>{reference}</td>
                                                        <td>
                                                            <Form.Check
                                                                type="switch"
                                                                key={reference}
                                                                id={`${reference}`}
                                                                defaultChecked={false}
                                                                onChange={(e) =>
                                                                    setNewQuestion(
                                                                        {
                                                                            ...newQuestion,
                                                                            References: {
                                                                                ...newQuestion.References,
                                                                                [reference]: e.target.checked
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="time" title="Tijd"><br />
                        <Row>
                            {getTimeFields().map((time) => (
                                <Form.Group key={`fg_${time}`} as={Row} className="mb-3">
                                    <Form.Label key={`fl_${time}`} column>
                                        {time.split(/(?=[A-Z])/).join(" ")}:
                                    </Form.Label>
                                    <Col key={`col_${time}`} md={6}>
                                        <Form.Control
                                            key={`fc_${time}`}
                                            min={0}
                                            type={time.includes("Cost") ? "text" : "number"}
                                            id={`new${time}`}
                                            defaultValue={time.includes("Cost") ? '€' : 0}
                                            onChange={(e) => {
                                                const sanitizedValue = e.target.value >= 0 ? e.target.value.replace(/[^0-9]/g, '') : 0;
                                                const numericValue = sanitizedValue === '' ? 0 : parseInt(sanitizedValue, 10);
                                                if (e.target.value < 0) {
                                                    e.target.value = 0;
                                                    displayToast("error", "Negatieve waarden zijn niet toegestaan");
                                                }
                                                else {
                                                    setNewQuestion({
                                                        ...newQuestion,
                                                        Time: {
                                                            ...newQuestion.Time,
                                                            [time]: numericValue
                                                        }
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </Col>
                                </Form.Group>
                            ))}
                        </Row>
                    </Tab>
                </Tabs>
            </Offcanvas.Body>
            <div className={"text-end"}>
                <Button disabled={key === 'general'} className={"m-2"} variant="secondary"
                    onClick={() => prevTab(key, setKey)}>
                    <i className="fa fa-arrow-left" /> Vorige
                </Button>
                <Button className={"m-2"} variant="danger" onClick={() => handleClose("create")}>
                    <i className="fa fa-close" /> Sluiten
                </Button>
                <Button className={"m-2"} variant="primary" onClick={createQuestion}>
                    <i className="fa fa-save" /> Aanmaken
                </Button>
                <Button disabled={key === 'time'} className={"m-2"} variant="secondary"
                    onClick={() => nextTab(key, setKey)}>
                    Volgende <i className="fa fa-arrow-right" />
                </Button>
            </div>
        </Offcanvas>
    );
};

export default CreateQuestionPropertyBox;
