  import React, { useCallback, useEffect, useRef, useState } from "react";
  import { Badge, Button, Card, Col, InputGroup, Row, Table } from "react-bootstrap";
  import { useNavigate, useParams } from "react-router-dom";
  import apiCall from "../../../components/utils/apiCall";
  import "../../../styles/wo/dashboard.css";
  import { FunctionBadge } from "../../../components/managers/BadgeManager";
  import EmployeeManager from "../../../components/managers/EmployeeManager";
  import { displayToast } from "../../../components/managers/ToastManager";
  import { CopyText } from "../../../components/utils/CopyText";
  import { useAuth } from "../../../context/AuthContext";
  import GlobalChatbox from "../../../components/wo/GlobalChatbox";
  import { WOAppProjectHeader, WorkorderHead } from "../../../components/wo/wo_app_projectinfo";
import StatusFilter from "../../../components/wo/StatusFilter";

  export default function Workorder() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [project, setProject] = useState([]);
    const [werkvoorbereider, setWerkvoorbereider] = useState([]);
    const [workorder, setWorkorder] = useState({});
    const [statuses, setStatuses] = useState({});
    const [remarks, setRemarks] = useState({});
    const [isConnected, setIsConnected] = useState(true);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
    const [FunctionListAPI, setFunctionListAPI] = useState([]);
    const allStatuses = ["Goed", "Te weinig", "Te veel", "Anders", ""];
    const [statusFilter, setStatusFilter] = useState(allStatuses);
    const { user } = useAuth();
    const socketRef = useRef(null);
    const chatboxRefs = useRef({});

    const fetchWorkorderData = async () => {
      const projectData = await apiCall(`wo/workorder/get_workorder_by_id_extra/${id}`, "POST", {});
      setProject(projectData);
      setFunctionListAPI(await apiCall('metadata/functionlist', 'POST', {}));
      const hexPattern = /^[a-f0-9]{32}$/;
      if (hexPattern.test(projectData.ClaimedBy)) {
        const manager = await EmployeeManager.create(projectData.ClaimedBy);
        setWerkvoorbereider(`${manager.employee.Firstname} ${manager.employee.Lastname}`);
      } else {
        setWerkvoorbereider("Niet Toegewezen");
      }
      const workorder = Object.entries(projectData.workorders).find(([key]) => key === id);
      setWorkorder(workorder ? workorder[1] : null);

      if (workorder && workorder[1].partlist && workorder[1].partlist.items) {
        const initialStatuses = {};
        Object.values(workorder[1].partlist.items).forEach(item => {
          initialStatuses[item.LineNumber] = item.Status || "";
        });
        setStatuses(initialStatuses);
      } else {
        setStatuses({});
      }
    };

    const connectWebSocket = useCallback(() => {
      if (socketRef.current) {
        socketRef.current.close();
      }

      socketRef.current = new WebSocket(`${process.env.REACT_APP_API_URL_WEBSOCKET}${id}`);

      socketRef.current.onopen = () => {
        console.log("WebSocket connection established");
        displayToast("Live updates zijn ingeschakeld", "success");
        setIsConnected(true);
      };

      socketRef.current.onmessage = async (event) => {
        try {
          const message = JSON.parse(event.data);
          console.log("Received message from server:", message.type);

          if (message.type === 'update') {
            await fetchWorkorderData();
          } else if (message.type === 'pong') {
            console.log("Received pong response from server");
          } else {
            console.error("Unknown message type:", message.type);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      socketRef.current.onclose = () => {
        console.log("WebSocket connection closed");
        displayToast("Live updates zijn uitgeschakeld", "info");
        setIsConnected(false);
        setReconnectAttempts((prev) => prev + 1);
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket connection error", error);
        displayToast("Er is een fout opgetreden bij het verbinden met de server", "error");
        setIsConnected(false);
      };

      const pingInterval = setInterval(() => {
        if (socketRef.current.readyState === WebSocket.OPEN) {
          console.log("Sending ping request to server");
          socketRef.current.send(JSON.stringify({ type: 'ping' }));
        }
      }, 15000);

      return () => {
        clearInterval(pingInterval);
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }, [id]);

    useEffect(() => {
      fetchWorkorderData();

      const cleanupWebSocket = connectWebSocket();

      return () => {
        cleanupWebSocket();
      };
    }, [id, connectWebSocket]);

    useEffect(() => {
      Object.values(chatboxRefs.current).forEach(ref => {
        if (ref) {
          ref.scrollTop = ref.scrollHeight;
        }
      });
    }, [workorder]);

    const handleStatusChange = (lineNumber, status) => {
      setStatuses((prevStatuses) => ({
        ...prevStatuses,
        [lineNumber]: status,
      }));

      apiCall(`wo/workorder/update_status`, "POST", {
        id: workorder.ID,
        item: lineNumber,
        status: status,
      });
    };

    const handleRemarkSubmit = async (lineNumber, remark) => {
      if (remark.trim() === "") return;

      setRemarks((prevRemarks) => ({
        ...prevRemarks,
        [lineNumber]: remark,
      }));

      await apiCall(`wo/workorder/update_details`, "POST", {
        id: workorder.ID,
        item: lineNumber,
        remark: remark,
      });

      if (chatboxRefs.current[lineNumber]) {
        chatboxRefs.current[lineNumber].nextElementSibling.querySelector('textarea').value = '';
      }
    };

    const handleKeyPress = (e, lineNumber) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleRemarkSubmit(lineNumber, e.target.value);
      }
    };

    const handleDeleteMessage = async (lineNumber, messageIndex, message_owner_id) => {
      const updatedDetails = workorder.partlist.items[lineNumber].ExistingDetails.filter((_, index) => index !== messageIndex);

      setWorkorder((prevWorkorder) => {
        const updatedItems = { ...prevWorkorder.partlist.items };
        updatedItems[lineNumber].ExistingDetails = updatedDetails;

        return {
          ...prevWorkorder,
          partlist: {
            ...prevWorkorder.partlist,
            items: updatedItems,
          },
        };
      });

      await apiCall(`wo/workorder/delete_message`, "POST", {
        id: workorder.ID,
        item: lineNumber,
        detail: messageIndex,
        message_owner_id: message_owner_id,
      });
    };

    const onSort = (key) => {
      let direction = 'ascending';
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...(workorder.partlist && workorder.partlist.items ? Object.values(workorder.partlist.items) : [])];
    
      // Apply sorting based on sortConfig
      if (sortConfig.key) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
    
      // Apply status filtering if there are any selected statuses
      if (statusFilter.length > 0) {
        sortableItems = sortableItems.filter(item => statusFilter.includes(statuses[item.LineNumber]));
      }
    
      return sortableItems;
    }, [workorder.partlist, sortConfig, statuses, statusFilter]);    

    const getRowClass = (status) => {
      switch (status) {
        case 'Goed':
          return 'table-success';
        case 'Te veel':
          return 'table-warning';
        case 'Te weinig':
        case 'Anders':
          return 'table-danger';
        default:
          return '';
      }
    };

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between">
            <WorkorderHead Archived={project.Archived} ShopOrderNumber={workorder.ShopOrderNumber} ItemCode={workorder.ItemCode}/>
          <Button variant="danger" className="px-3" onClick={() => navigate(-1)}>Back</Button>
        </Card.Header>
        <Card.Body>
          <WOAppProjectHeader project={project} isArchived={project.Archived} werkvoorbereider={werkvoorbereider}/>
          <Table striped hover responsive>
            <colgroup>
              <col style={{ width: "2.5%" }} />
              <col style={{ width: "9.5%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "9%" }} />
              <col style={{ width: "9%" }} />
              <col style={{ width: "7.5%" }} />
              <col style={{ width: "2.5%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <thead>
              <tr>
                <th className="text-center" onClick={() => onSort('LineNumber')}>#</th>
                <th onClick={() => onSort('ItemCode')}>Artikel</th>
                <th onClick={() => onSort('ItemDescription')}>Omschrijving</th>
                <th onClick={() => onSort('Manufacturer')}>Manufacturer</th>
                <th onClick={() => onSort('Barcode')}>Barcode</th>
                <th className="text-center" onClick={() => onSort('PlannedDate')}>Verwacht</th>
                <th className="text-center" onClick={() => onSort('PlannedQuantity')}>Aantal</th>
                <th><StatusFilter statusFilter={statusFilter} setStatusFilter={setStatusFilter} /></th>
                <th>Chat / Opmerkingen</th>
              </tr>
            </thead>
            <tbody>
              {sortedItems.length > 0 ? (
                sortedItems.map((item) => (
                  <tr key={item.LineNumber} className={getRowClass(statuses[item.LineNumber])}>
                    <td className="text-center">{item.LineNumber}</td>
                    <td className="text-wrap">{CopyText(item.ItemCode, "Artikel")}</td>
                    <td className="text-wrap">{CopyText(item.ItemDescription, "Omschrijving")}{item.PickLocation ? <Badge bg="info">{item.PickLocation}</Badge> : null}</td>
                    <td className="text-wrap">{CopyText(item.Manufacturer, "Manufacturer")}</td>
                    <td>{CopyText(item.Barcode, "Barcode")}</td>
                    <td className="text-center">{item.PlannedDate}</td>
                    <td className="text-center">{item.PlannedQuantity}</td>
                    <td>
                      <select
                        className="form-select"
                        value={statuses[item.LineNumber] || ''}
                        onChange={(e) => handleStatusChange(item.LineNumber, e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value="Goed">Goed</option>
                        <option value="Te weinig">Te weinig</option>
                        <option value="Te veel">Te veel</option>
                        <option value="Anders">Anders</option>
                      </select>
                    </td>
                    <td>
                    <div
                      className="chatbox"
                      ref={el => chatboxRefs.current[item.LineNumber] = el}
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      {item.ExistingDetails && item.ExistingDetails.map((detail, index) => (
                        <div key={index} className="chat-message">
                          <strong className="d-flex align-items-center">
                            {detail.user}{detail.function && <FunctionBadge functionName={detail.function} functionList={FunctionListAPI} />}
                          </strong>
                          {detail.real_id === user.EmployeeID && <Button 
                            variant="danger" 
                            size="sm" 
                            className="delete-button" 
                            onClick={() => handleDeleteMessage(item.LineNumber, index, detail.real_id)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </Button>}
                          <span>{detail.date}</span>
                          <p className="text-wrap">{detail.details}</p>
                        </div>
                      ))}
                    </div>
                      <div>
                        <textarea
                          className="form-control"
                          rows="1"
                          style={{ minHeight: '38px' }}
                          placeholder="Plaats uw opmerking..."
                          onKeyDown={(e) => handleKeyPress(e, item.LineNumber)}
                        ></textarea>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">Geen items in deze werkorder</td>
                </tr>
              )}
            </tbody>
          </Table>

          <GlobalChatbox workorder={workorder} workorderId={id} chatboxRefs={chatboxRefs} FunctionListAPI={FunctionListAPI} />

          {!isConnected && (
            <Card style={{
              zIndex: 1000,
              position: 'fixed',
              width: '300px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              border: '3px solid red',
            }}>
              <Card.Header>WebSocket connectie verbroken</Card.Header>
              <Card.Body>
                <p>Als dit probleem aanhoudt, neem dan contact op met de software/development afdeling.</p>
                <Button variant="primary" onClick={() => {
                  setReconnectAttempts(prev => prev + 1);
                  connectWebSocket();
                }}>Reconnect</Button>
              </Card.Body>
            </Card>
          )}
        </Card.Body>
      </Card>
    );
  }
