import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {displayToast} from '../components/managers/ToastManager';
import {useNavigate} from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasRehydrated = useRef(false);

  const rehydrateUser = async () => {
    if (window.location.pathname === '/index.jsx') {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}rehydrate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data);
      } else {
        throw new Error(data.error || 'Failed to rehydrate user');
      }
    } catch (error) {
      setUser(null);
      displayToast(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRehydrated.current) {
      rehydrateUser();
      hasRehydrated.current = true;
    }
  }, []);

  const login = useCallback(async (username, password) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
      credentials: 'include',
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Login failed');
    }

    setUser(data);
  }, []);

  const logout = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}logout`, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Logout failed');
      }

      setUser(null);
      navigate('/');
    } catch (error) {
      displayToast("Something went wrong...", 'error');
    }
  }, [navigate]);

  const value = useMemo(() => ({
    user, loading, login, logout, rehydrateUser
  }), [user, loading, login, logout]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
