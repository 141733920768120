/**
 * README ModalFunctions
 *
 * ModalFunctions.jsx is een JavaScript bestand dat een reeks functies bevat die worden gebruikt in de modal componenten van de applicatie.
 *
 * =========================================================================================================================================
 *
 *  handleSubQuestionCheckboxChange:
 *      Deze functie wordt gebruikt om de checkbox van een subvraag te veranderen.
 *      Het neemt een event object, een vraag ID en een setEditQuestion functie als parameters.
 *      Afhankelijk van de status van de checkbox, voegt het de vraag ID toe aan de SubQuestions array of verwijdert het.
 *
 *  handleParentQuestionCheckboxChange:
 *      Deze functie wordt gebruikt om de checkbox van een parentvraag te veranderen.
 *      Het neemt een event object, een vraag ID en een setEditQuestion functie als parameters.
 *      Afhankelijk van de status van de checkbox, stelt het de ParentQuestion in op de vraag ID of stelt het deze in op null.
 *
 *  nextTab:
 *      Deze functie wordt gebruikt om naar de volgende tab te gaan. Het neemt een key en een setKey functie als parameters.
 *      Afhankelijk van de huidige key, stelt het de key in op de volgende tab.
 *
 *  prevTab:
 *      Deze functie wordt gebruikt om naar de vorige tab te gaan.
 *      Het neemt een key en een setKey functie als parameters.
 *      Afhankelijk van de huidige key, stelt het de key in op de vorige tab.
 *
 * =========================================================================================================================================
 *
 * Gebruik:
 * Om deze functies te gebruiken, importeer ze uit het ModalFunctions.jsx bestand en gebruik ze in je componenten waar nodig.
 * Zorg ervoor dat je de juiste parameters levert voor elke functie.
 *
 */


/**
 *
 * @param e
 * @param questionId
 * @param setEditQuestion
 */

// Functie om de checkbox van een subvraag te veranderen
export const handleSubQuestionCheckboxChange = (e, questionId, setEditQuestion) => {
    if (e.target.checked) {
        setEditQuestion(prevState => ({
            ...prevState,
            SubQuestions: [...prevState.SubQuestions, questionId]
        }));
    } else {
        setEditQuestion(prevState => ({
            ...prevState,
            SubQuestions: prevState.SubQuestions.filter(id => id !== questionId)
        }));
    }
};

/**
 *
 * @param e
 * @param questionId
 * @param setEditQuestion
 */

//Functie om de checkbox van een parentvraag te veranderen
export const handleParentQuestionCheckboxChange = (e, questionId, setEditQuestion) => {
    if (e.target.checked) {
        setEditQuestion(prevState => ({
            ...prevState,
            ParentQuestion: questionId
        }));
    } else {
        setEditQuestion(prevState => ({
            ...prevState,
            ParentQuestion: null
        }));
    }
}

/**
 * @param key
 * @param setKey
 */

// Functie om naar de volgende tab te gaan
export const nextTab = (key, setKey) => {
    const nextKey = key === 'general' ? 'references' : 'time';
    setKey(nextKey);
};

/**
 * @param key
 * @param setKey
 */

// Functie om naar de vorige tab te gaan
export const prevTab = (key, setKey) => {
    const prevKey = key === 'time' ? 'references' : 'general';
    setKey(prevKey);
};