import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useClockifyQuery} from "../../../../components/hesselToDo/Clockify";
import { useParams} from 'react-router-dom';

export default function Detail() {
    
    const { id } = useParams();
    return (
        <>
            <Card>
                <Card.Header><h4>Details - {id}</h4></Card.Header>
                <Card.Body>{Details(id)}</Card.Body>
                <Card.Footer><Button variant="outline-primary" href="/clockify/clients">Terug</Button></Card.Footer>
            </Card>
        </>
    );
}

function Details(id) {
    const { data, error, loading } = useClockifyQuery(`clients/${id}`);
    if (error) return <div className="text-danger">Laden mislukt</div>;
    if (loading) return <div className="text-primary">Bezig met laden...</div>;
    return (
        <Form>
            <Row>
                <Col sm="8">
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">ID</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="id" defaultValue={data.id} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Naam</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="name" defaultValue={data.name} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Beschrijving</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="note" defaultValue={data.note} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Email</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="email" defaultValue={data.email} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Address</Form.Label>
                        <Col sm="9"><Form.Control type="text" id="address" defaultChecked={data.address} disabled /></Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">Opslag</Form.Label>
                        <Col sm="9"><Form.Check type="switch" id="archived" defaultChecked={data.archived} disabled /></Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>

    );
}