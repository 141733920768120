import toast from 'react-hot-toast';

const typeStyles = {
    success: {
        border: '3px solid green',
        backgroundColor: '#D4EDDA',
        color: '#155724'
    },
    error: {
        border: '3px solid red',
        backgroundColor: '#F8D7DA',
        color: '#721C24'
    },
    forbidden: {
        border: '3px solid red',
        backgroundColor: '#F8D7DA',
        color: '#721C24'
    },
    info: {
        border: '3px solid blue',
        backgroundColor: '#D1ECF1',
        color: '#0C5460'
    },
    info2: {
        border: '3px solid red',
        backgroundColor: '#F8D7DA',
        color: 'red'
    },
    loading: {
        border: '3px solid #AAA',
        backgroundColor: '#E2E3E5',
        color: '#818182'
    }
};

let activeToasts = [];

export function displayToast(message, type, displayDuration = 2500, options = {}) {
    if (!typeStyles[type]) {
        toast.error("No type specified in the toast displayToast function!", "error");
        return;
    }

    if (activeToasts.length >= 4) {
        const oldestToastId = activeToasts.shift();
        toast.dismiss(oldestToastId);
    }

    const defaultOptions = {
        duration: displayDuration,
        style: {
            padding: '20px',
            fontSize: '18px',
            borderRadius: '8px',
            ...typeStyles[type],
            ...options.style
        }
    };

    const toastOptions = {
        ...defaultOptions,
        ...options,
        style: defaultOptions.style
    };

    let toastId;
    switch (type) {
        case "success":
            toastId = toast.success(message, { ...toastOptions, icon: '✅' });
            break;
        case "forbidden":
            toastId = toast.error(message, { ...toastOptions, icon: '⛔' });
            break;
        case "error":
            toastId = toast.error(message, { ...toastOptions, icon: '❌' });
            break;
        case "info":
            toastId = toast(message, { ...toastOptions, icon: 'ℹ️' });
            break;
        case "info2":
            toastId = toast(message, { ...toastOptions, icon: 'ℹ️' });
            break;
        case "loading":
            toastId = toast.loading(message, { ...toastOptions, icon: '⏳' });
            break;
        default:
            break;
    }

    activeToasts.push(toastId);
}
