import {Button, Card, Col, FloatingLabel, Form, ListGroup, Placeholder, Row} from "react-bootstrap";
import {useState} from "react";
import {saveAs} from "file-saver";
import toast from "react-hot-toast";
import {OneFileCheck} from "../../../components/hesselToDo/Checkfiles";
import JSZip from "jszip";

import {GetAPIurl} from "../../../components/hesselToDo/Data";

export default function Converter() {
  const [loaded, setLoaded] = useState(false);
  const [info, setInfo] = useState();
  return (
    <>
      <Row className="container-fluid mb-3">
        {Start(setLoaded)}
        {Lijstmenu(loaded, setInfo)}
        {Download(loaded, info)}
      </Row>
      {Showinfo(loaded, info)}
    </>
  );
}

function Start(setLoaded) {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!OneFileCheck(e.target.files, ["xlsx", "xls", "csv"])) return;

    var formData = new FormData();
    formData.append('file', e.target.files[0]);

    var myheaders = {
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: formData,
    };

    fetch(`${GetAPIurl()}/api/eplan/exact/bestel/file`, req)
      .then((d) => {
        if (d.status === 200) {
          toast.success("Gelukt!");
          setLoaded(true);
          document.getElementById("mainfooter").style.display = "none";
        } else {
          toast.error("Gefaald");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  }

  return (
    <Col>
      <h3>Exact bestel export</h3>
      <span>
        Hier wordt de bestellijst uit exact om gemaakt naar de fabrikant import
        lijst.
      </span>
      <h6>Voer hier uw bestand in:</h6>
      <Form className="was-validated" onChange={handleSubmit}>
        <Form.Group className="input-group mb-3">
          <label htmlFor="file" className="btn btn-outline-primary"><i className="fas fa-cloud-arrow-up"></i></label>
          <Form.Control id="file" type="file" className="btn btn-outline-primary" accept=".xlsx, .xls, .csv" required />
        </Form.Group>
      </Form>
    </Col>
  );
}

function Lijstmenu(loaded, setInfo) {
  const [data, setData] = useState();
  if (!loaded) return (
    <Col>
      <Placeholder xs={8} />
    </Col>
  );
  fetch(`${GetAPIurl()}/api/eplan/bestelexports`)
    .then((response) => response.json())
    .then((d) => {
      setData(d);
    })
    .catch((error) => {
      console.error("Error:", error);
      toast.error("Database niet gevonden.");
    });
  if (!data) return (
    <Col>
      <Placeholder xs={8} />
    </Col>
  );
  const FabrikOption = (e) => {
    e.preventDefault();
    setInfo(data[e.target.id - 1]);
  }
  return (
    <Col>
      <span>Fabrikant opties</span>
      <ListGroup>
        {data.map((item) => (
          <Button key={item.id} id={item.id} className="list-group-item" onClick={FabrikOption}>
            {item.fabrikant}
          </Button>
        ))}
      </ListGroup>
    </Col>
  );
}

function FileDownload(setDownloading, extension, fabrikant) {
  setDownloading(true);
  var req = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
    }
  };
  fetch(`${GetAPIurl()}/api/eplan/exact/bestel/zip?extension=${extension}&optie=${fabrikant}`, req)
    .then((reponse) => reponse.json())
    .then(data => {
      const zip = JSZip();
      zip.file(`${data.name}.${data.extension}`, data.bestel, { base64: true })
      zip.generateAsync({ type: "blob" })
        .then((content) => {
          saveAs(content, `${data.name}_bestel.zip`);
          setDownloading(false);
        })
        .catch((error) => {
          toast.error("Ging wat fout bij downloaden.");
          console.error(error);
          setDownloading(false);
        })
    })
    .catch((error) => console.error(error));
}

function Download(loaded, info) {
  const [downloading, setDownloading] = useState(false);
  if (!loaded || !info) return (
    <Col>
      <Placeholder xs={10} />
      <Placeholder xs={10} />
      <Placeholder xs={10} />
    </Col>
  );
  const handleXLSX = (e) => {
    e.preventDefault();
    FileDownload(setDownloading, "xlsx", info.fabrikant);
  }
  const handleXLS = (e) => {
    e.preventDefault();
    FileDownload(setDownloading, "xls", info.fabrikant);
  }
  const handleCSV = (e) => {
    e.preventDefault();
    FileDownload(setDownloading, "csv", info.fabrikant);
  }
  return (
    <Col>
      <ListGroup>
        {info.download == "xlsx" &&
          <Button className="list-group-item" title="Sla bestanden op als Excel XLSX" disabled={downloading} onClick={handleXLSX}>
            <i className="fas fa-floppy-disk"></i>
            Bestand(en) opslaan in Excel XLSX 2007+
          </Button>
        }
        {info.download == "xls" &&
          <Button className="list-group-item" title="Sla bestanden op als Excel XLS" disabled={downloading} onClick={handleXLS}>
            <i className="fas fa-floppy-disk"></i>
            Bestand(en) opslaan in Excel XLS 2003
          </Button>
        }
        {info.download == "csv" &&
          <Button className="list-group-item" title="Sla bestanden op als CSV" disabled={downloading} onClick={handleCSV}>
            <i className="fas fa-floppy-disk"></i>
            Bestand(en) opslaan in CSV
          </Button>
        }
      </ListGroup>
    </Col>
  );
}

function Showinfo(loaded, info) {
  if (!loaded || !info) return;
  return (
    <Card>
      <Card.Body>
        <h3>Details</h3>
        <Form>
          <Form.Group as={Col}>
            <FloatingLabel label="Fabrikant">
              <Form.Control type="text" value={info.fabrikant} disabled />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col}>
            {info.hasheader ? (
              <Form.Label>Heeft koptekst in bestand.</Form.Label>
            ) : (
              <Form.Label>Geen koptekst in bestand.</Form.Label>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <FloatingLabel label="Koptekst">
              <Form.Control type="text" value={info.header} disabled />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col}>
            <FloatingLabel label="Invoer">
              <Form.Control type="text" value={info.input} disabled />
            </FloatingLabel>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}