import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { usePagination } from '../managers/PaginationManager';
import apiCall from '../utils/apiCall';
import { FunctionBadge } from '../managers/BadgeManager';
import { Search } from '../utils/searchUtils';
import { displayToast } from "../managers/ToastManager";

export default function EmployeeList() {
    const navigate = useNavigate();
    const [originalEmployees, setOriginalEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFunction, setSelectedFunction] = useState("");
    const [FunctionListAPI, setFunctionListAPI] = useState([]);
    const [sortOrder, setSortOrder] = useState("asc");

    // Fetch employees and function list on component mount
    useEffect(() => {
        (async () => {
            const employees = await apiCall("employee/all", "POST");
            setFunctionListAPI(await apiCall("metadata/functionlist", "POST", {}));
            setOriginalEmployees(employees);
            setFilteredEmployees(employees);
            setLoading(false);
            displayToast("Alle medewerkers zijn opgehaald", "success");
        })();
    }, []);

    // Pagination configuration
    const { currentItems, AboveTable, BelowTable, indexOfFirstItem } = usePagination(filteredEmployees);

    // Handle search input change
    const handleSearchChange = (e) => {
        const newQuery = e.target.value;
        applyFilters(newQuery, selectedFunction);
    };

    // Handle function select change
    const handleFunctionChange = (e) => {
        const newFunction = e.target.value;
        setSelectedFunction(newFunction);
        applyFilters("", newFunction);
    };

    // Apply filters based on search query and function
    const applyFilters = (query, func) => {
        let filtered = originalEmployees;
        if (query.trim()) {
            filtered = Search(filtered, query);
        }
        if (func) {
            filtered = filtered.filter(employee => employee.Function === func);
        }
        setFilteredEmployees(filtered);
    };

const sortEmployees = (employees, order) => {
    return employees.slice().sort((a, b) => {
        const companyA = typeof a.Company === 'string' ? a.Company : '';
        const companyB = typeof b.Company === 'string' ? b.Company : '';

        if (order === "asc") {
            return companyA.localeCompare(companyB);
        } else {
            return companyB.localeCompare(companyA);
        }
    });
};

    const toggleSortOrder = () => {
        const newOrder = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(newOrder);
        setFilteredEmployees(sortEmployees(filteredEmployees, newOrder));
    };

    return (
        <>
            <Card>
                <Card.Header>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h1 id="tableLabel" style={{ margin: "0", marginRight: "10px" }}>Peoples</h1>
                            <Button variant="success" href="/people/create">
                                <i className="fas fa-plus"></i>
                            </Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col md={2}>
                            <Form.Control
                                type="text"
                                name="SearchString"
                                placeholder="Search..."
                                onChange={handleSearchChange}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Select
                                value={selectedFunction}
                                onChange={handleFunctionChange}
                                style={{
                                    minHeight: '15px',
                                }}
                            >
                                <option style={{ maxHeight: '50px', overflowY: 'auto' }} value="">
                                    Algemeen
                                </option>
                                {FunctionListAPI.map((func, index) => (
                                    <option key={index} value={func.name} >
                                        {func.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <h3 className="me-2">
                                Loading employees...
                            </h3>
                            <Spinner animation="border" role="status">
                                <span className="sr-only"></span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            {AboveTable}
                            <Table className="mt-2" striped hover>
                                <colgroup>
                                    <col style={{ width: "1%" }} />
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "5%" }} />
                                    <col style={{ width: "5%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th onClick={toggleSortOrder}>Bedrijfsnaam</th>
                                        <th>Volledige Naam</th>
                                        <th>Gebruikersnaam</th>
                                        <th>Functie</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((employee, index) => {
                                        if (!employee.Company) {
                                            employee.Company = [];
                                        }

                                        if (!employee.Projects) {
                                            employee.Projects = [];
                                        }
                                        
                                        const projectsCount = employee.Projects ? employee.Projects.length : 0;
                                        const employeeCompany = employee.Company.length > 0 ? employee.Company : <span style={{ color: 'red' }}>Niet bekend</span>;

                                        return (
                                            <tr key={employee._id} onClick={() => navigate(`details/${employee._id}`)}>
                                                <td className="text-center">{indexOfFirstItem + index + 1}</td>
                                                <td>{employeeCompany}</td>
                                                <td>
                                                    {employee.Firstname} {employee.Lastname}
                                                    {!employee.AccountStatus && (
                                                        <span style={{ color: '#7a0200', marginLeft: '10px', fontSize: '0.8rem' }}>
                                                            <em>(Inactive)</em>
                                                        </span>
                                                    )}
                                                </td>
                                                <td>{employee.Username}</td>
                                                <td>
                                                    <FunctionBadge
                                                        functionName={employee.Function}
                                                        functionList={FunctionListAPI}
                                                    />
                                                </td>
                                                <td>{employee.Email}</td>
                                                <td>
                                                    <Button variant="info" onClick={() => { navigate(`details/${employee._id}`) }}>
                                                        <i className={"fa fa-info-circle"} /> Information
                                                    </Button>
                                                    {" "}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                {projectsCount > 0
                                                                    ? `Projects: ${projectsCount}`
                                                                    : 'No projects yet'}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Badge bg="primary" text="dark" pill>{projectsCount}</Badge>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            {BelowTable}
                        </>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}
