// Hardcoded Elements of groups => Go to GetGroupsAndElements();

export function getGroupSteps() {
    const groupSteps = [
        "Project Information",
        "Project Management",
        "Software",
        "Mechanical",
        "Panelshop",
        "Human Resources",
        "Health Safety Environment",
        "Field Installation",
        "Administration",
        "Hardware",
        "Expedition",
        "Commissioning",
    ];
    return groupSteps;
}

export function getTimeFields() {
    const time = [
        "GenerationTime",
        "EngineeringTime",
        "MeetingTime",
        "TestingTime",
        "CommissioningTime",
        "DocumentationTime",
        "StartCost"
    ]
    return time;
}

export const StatusList = [
    "Offerte",
    "Order bevestiging",
    "Pre Engineering",
    "Engineering",
    "Testing",
    "Commissioning",
    "Service",
    "Finished",
];