import {Button, Card, Col, Form, FormLabel, Modal, Row} from "react-bootstrap";
import {useState} from "react";
import toast from "react-hot-toast";

import {GetAPIurl} from "../../components/hesselToDo/Data";

export default function details() {
  return (
    <>
      <Card>
        <Card.Header style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ marginRight: "10px" }}>Onderdeel - Aanmaken</h4>{BulkCreate()}
        </Card.Header>
        <Card.Body>{FormCreate()}</Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" href="/materials">
            Terug
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

function BulkCreate() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleBulkimport = (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append('file', e.target['file'].files[0]);

    var myheaders = {
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: formData,
    };

    fetch(`${GetAPIurl()}/api/materials/bulk`, req)
      .then((d) => {
        if (d.status === 200) {
          toast.success("Gelukt!");
        } else {
          toast.error("Gefaald");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  }
  return (
    <div>
      <Button variant="primary" title="Bulk" onClick={handleShow}>
        <i className="fab fa-deezer"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk onderdelen toevoegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleBulkimport} className="was-validated">
            <Form.Control id="file" type="file" required />
            <Button type="submit" variant="success">Toevoegen</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function FormCreate() {
  const [fromData, setFormData] = useState({
    eplanSortID: "",
    materialCode: "",
    orderNumber: "",
    articleNumber: "",
    variant: "",
    eplanDescription: "",
    discontinuedProduct: false,
    barcode: 0,
    barcodeType: "",
    productCode: "",
    gtin: "",
    manufacturerGLN: "",
    manufactureName: "",
    productIdentifier: 0,
    description: "",
    brand: "",
    model: "",
    version: "",
    deeplink: "",
    unifeedControl: false,
    created_At: "",
    modified_At: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const Created = new Date().toISOString();

    const raw = JSON.stringify({
      eplanSortID: fromData.eplanSortID,
      materialCode: fromData.materialCode,
      orderNumber: fromData.orderNumber,
      articleNumber: fromData.articleNumber,
      variant: fromData.variant,
      eplanDescription: fromData.eplanDescription,
      discontinuedProduct: fromData.discontinuedProduct,
      barcode: fromData.barcode,
      barcodeType: fromData.barcodeType,
      productCode: fromData.productCode,
      gtin: fromData.gtin,
      manufacturerGLN: fromData.manufacturerGLN,
      manufactureName: fromData.manufactureName,
      productIdentifier: fromData.productIdentifier,
      description: fromData.description,
      brand: fromData.brand,
      model: fromData.model,
      version: fromData.version,
      deeplink: fromData.deeplink,
      unifeedControl: fromData.unifeedControl,
      created_At: Created,
      modified_At: Created,
    });

    var myheaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: raw,
    };

    fetch(`${GetAPIurl()}/api/materials`, req)
      .then((d) => {
        if (d.status === 201) {
          toast.success("Gelukt!");
        } else {
          toast.error("Gefaald");
        }
        return d.json();
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  };
  return (
    <div>
      <Form onSubmit={handleSubmit} className="was-validated">
        <Row>
          <Col sm="3">
            <h4>Inhoud</h4>
            <FormLabel>Merk</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="brand" placeholder="Merk" required />
            <FormLabel>Modal</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="model" placeholder="Model" required />
            <FormLabel>Link</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="deeplink" placeholder="Link" required />
            <FormLabel>Versie</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="version" placeholder="Versie" required />
            <FormLabel>Beschrijving</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="description" placeholder="Beschrijving" required />
          </Col>
          <Col sm="3">
            <h4>Bedrijven</h4>
            <FormLabel>EPLAN beschrijving</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="eplanDescription" placeholder="EPLAN beschrijving" required />
            <FormLabel>Fabrikant naam</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="manufactureName" placeholder="Fabrikant naam" required />
            <FormLabel>Fabrikant GLN</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="manufacturerGLN" placeholder="Fabrikant GLN" required />
            <FormLabel>Barcode</FormLabel>
            <Form.Control type="number" className="mb-3" onChange={handleChange} id="barcode" placeholder="Barcode" required />
            <FormLabel>Barcodetype</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="barcodeType" placeholder="Barcodetype" required />
          </Col>
          <Col sm="3">
            <h4>Nummers</h4>
            <FormLabel>Bestelnummer</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="orderNumber" placeholder="Bestelnummer" required />
            <FormLabel>Artikelnummer</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="articleNumber" placeholder="Artikelnummer" required />
            <FormLabel>Product Code</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="productCode" placeholder="Product Code" required />
            <FormLabel>MaterialCode</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="materialCode" placeholder="MaterialCode" required />
            <FormLabel>GTIN</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="gtin" placeholder="GTIN" required />
          </Col>
          <Col sm="3">
            <h4>Overig</h4>
            <FormLabel>SortID</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="eplanSortID" placeholder="SortID" required />
            <FormLabel>Variant</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="variant" placeholder="Variant" required />
            <FormLabel>Identificatier</FormLabel>
            <Form.Control type="text" className="mb-3" onChange={handleChange} id="productIdentifier" placeholder="Identificatier" required />
            <Form.Check type="switch" className="mb-3" onChange={handleChange} id="discontinuedProduct" label="Korting" />
            <Form.Check type="switch" className="mb-3" onChange={handleChange} id="unifeedControl" label="Controleren" />
          </Col>
        </Row>
        <Button variant="success" type="submit">
          Aanmaken
        </Button>
      </Form>
    </div>
  );
}
