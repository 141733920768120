import { Button, Card, Col, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import apiCall from "../../../components/utils/apiCall.jsx";
import { useNavigate } from "react-router-dom";
import ColumnsTable from "../../../components/tables/ColumnsTable.jsx";

/**
 * README: Kolommenlijst
 *
 * ColumnsList.jsx is een React component bestand dat de hoofdcomponent ColumnsList bevat.
 * Deze component wordt gebruikt om een lijst van kolommen weer te geven in de applicatie.
 *
 * ==========================================================================================
 *
 * ColumnsList:
 * Deze component wordt gebruikt om een lijst van kolommen weer te geven in een tabel.
 * Het biedt een gebruikersinterface voor het navigeren door de kolommenlijst.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * ==========================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het ColumnsList.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * Er worden geen props verwacht voor deze component.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een lijst van kolommen en hun eigenschappen.
 *
 */

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function ColumnsList() {
    const [columns, setColumns] = useState([]);
    const [newColumn, setNewColumn] = useState({});
    const [selectedGroup, setSelectedGroup] = useState("");
    const [dataTypes, setDataTypes] = useState([]);
    const [dataTargets, setDataTargets] = useState([]);
    const [lists, setLists] = useState([]);
    const [taskgroups, setTaskgroups] = useState([]);

    const navigate = useNavigate();

    // Krijg de kolommen en taskgroups van de API
    useEffect(() => {
        (async () => {
            setColumns(await apiCall(`metadata/columns`, "POST"));
        })();
        (async () => {
            // Get the taskgroups for the dropdown in the offcanvas
            setTaskgroups(await apiCall("metadata/functionlist", "POST"));

        })();
        (async () => {
            setNewColumn(await apiCall("metadata/columns_layout", "POST"));
        })();
        (async () => {
            try {
                const response = await apiCall(`metadata/get_reference`, 'POST');
                const references = response;
                await setDataTypes(references.dataTypes);
                await setDataTargets(references.dataTargets);
            } catch (error) {
                console.error('Er was een probleem met het ophalen van de referenties', error);
            }
        })();
    }, []);

    // Zet de kolommen in een lijst
    useEffect(() => {
        if (columns)
            setLists(Object.keys(columns));
    }, [columns]);


    return (
        <>
            <Card>
                <Card.Header>
                    <Row className="d-flex justify-content-between">
                        <Col sm={6}>
                            <h3 className={"p-0"}>Kolommen Lijst</h3>
                        </Col>
                        <Col md={6} className="text-end">
                            <Button variant="danger" className={"m-1"}
                                onClick={() => navigate(-2)}><i
                                    className="fas fa-arrow-left" /> Terug</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Tabs id="uncontrolled-tab-example" className="mb-3">
                        {lists.length > 0 && columns ? lists.map((list, index) => {
                            const uniqueGroups = [...new Set(columns[list].map(column => column.Metadata.Group))];
                            return (
                                <Tab title={list + "lijst"} eventKey={list} key={index}>
                                    <Tabs defaultActiveKey={uniqueGroups[0]} id="uncontrolled-tab-example"
                                        className="mb-3" onSelect={(k) => setSelectedGroup(k)}>
                                        <Tab title="Alles" eventKey="all">
                                            <ColumnsTable defaultColumns={columns[list]} columns={columns[list]}
                                                type={list.toLowerCase()} />
                                        </Tab>
                                        {uniqueGroups.map((group, index) => {
                                            const groupColumns = columns[list].filter(col => col.Metadata.Group === group);
                                            const uniqueElements = [...new Set(groupColumns.map(column => column.Metadata.Element))];
                                            return (
                                                <Tab title={group} eventKey={group} key={index}>
                                                    {uniqueElements.length === 1 ? (
                                                        <ColumnsTable defaultColumns={columns[list]}
                                                            columns={groupColumns.filter(col => col.Metadata.Element === uniqueElements[0])}
                                                            setColumns={setColumns}
                                                            group={group.toLowerCase()}
                                                            type={list.toLowerCase()}
                                                            taskgroups={taskgroups}
                                                            newColumn={newColumn}
                                                            setNewColumn={setNewColumn}
                                                            dataTargets={dataTargets}
                                                            dataTypes={dataTypes}
                                                        />
                                                    ) : (
                                                        <Tabs id="uncontrolled-tab-example" className="mb-3">
                                                            <Tab title="Alles" eventKey="all">
                                                                <ColumnsTable defaultColumns={columns[list]}
                                                                    columns={groupColumns}
                                                                    setColumns={setColumns}
                                                                    group={group.toLowerCase()}
                                                                    type={list.toLowerCase()}
                                                                    taskgroups={taskgroups}
                                                                    newColumn={newColumn}
                                                                    setNewColumn={setNewColumn}
                                                                    dataTargets={dataTargets}
                                                                    dataTypes={dataTypes}
                                                                />
                                                            </Tab>
                                                            {uniqueElements.map((element, index) => (
                                                                <Tab title={element} eventKey={element} key={index}>
                                                                    <ColumnsTable defaultColumns={columns[list]}
                                                                        columns={groupColumns.filter(col => col.Metadata.Element === element)}
                                                                        setColumns={setColumns}
                                                                        group={group.toLowerCase()}
                                                                        type={list.toLowerCase()}
                                                                        taskgroups={taskgroups}
                                                                        newColumn={newColumn}
                                                                        setNewColumn={setNewColumn}
                                                                        dataTargets={dataTargets}
                                                                        dataTypes={dataTypes}
                                                                    />
                                                                </Tab>
                                                            ))}
                                                        </Tabs>
                                                    )}
                                                </Tab>
                                            );
                                        })}
                                    </Tabs>
                                </Tab>
                            );
                        }) : <Spinner animation="border" />}
                    </Tabs>
                </Card.Body>
            </Card>
        </>
    );

}
