import {confirmAlert} from "react-confirm-alert";

export default function displayConfirmAlert(title, message, type, options = {}) {
    const defaultOptions = {
        title: title,
        message: message,
        buttons: [
            {
                label: "Ok",
                onClick: () => { }
            }
        ]
    };

    const alertOptions = {
        ...defaultOptions,
        ...options
    };

    switch (type) {
        case "confirm":
            confirmAlert({
                ...alertOptions,
                title: title,
                buttons: [
                    {
                        label: "Ja",
                        onClick: options[0]
                    },
                    {
                        label: "Nee",
                        onClick: options[1]
                    }
                ]
            });
            break;
        default:
            break;
    }
}

export function displayConfirmAlertCustom(title, message, type, options = {}, labels = {}) {
    const defaultOptions = {
        title: title,
        message: message,
        buttons: [
            {
                label: "Ok",
                onClick: () => { }
            }
        ]
    };

    const alertOptions = {
        ...defaultOptions,
        ...options
    };

    switch (type) {
        case "confirm":
            confirmAlert({
                ...alertOptions,
                title: title,
                buttons: [
                    {
                        label: labels[0],
                        onClick: options[0]
                    },
                    {
                        label: labels[1],
                        onClick: options[1]
                    }
                ]
            });
            break;
        default:
            break;
    }
}