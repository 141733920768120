import React, {useEffect, useState} from "react";
import {Badge, Button, Card, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {BarLoader} from "react-spinners";
import apiCall from "../../../components/utils/apiCall";
import "../../../styles/wo/dashboard.css";
import EmployeeManager from "../../../components/managers/EmployeeManager";
import {countItemsWithoutStatus} from "../../../components/wo/workorderUtils";
import {useAuth} from "../../../context/AuthContext";
import { CopyText } from "../../../components/utils/CopyText";
import { ProjectHead, WOAppProjectHeader } from "../../../components/wo/wo_app_projectinfo";
import "../../../styles/wo/wo-global.css";

export default function Project() {
    const navigate = useNavigate();
    const { user, rehydrateUser } = useAuth();
    const { id } = useParams();
    const [project, setProject] = useState([]);
    const [werkvoorbereider, setWerkvoorbereider] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isArchived, setIsArchived] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const projectData = await apiCall(`wo/projects/get_project_by_code/${id}`, "POST");
                setProject(projectData);
                setIsArchived(projectData.Archived);
                await apiCall('employee/updatelastvisitedproject', 'POST', { ID: user.EmployeeID, ProjectCode: projectData.Code });
                await rehydrateUser();

                const hexPattern = /^[a-f0-9]{32}$/;
                if (hexPattern.test(projectData.ClaimedBy)) {
                    const manager = await EmployeeManager.create(projectData.ClaimedBy);
                    setWerkvoorbereider(`${manager.employee.Firstname} ${manager.employee.Lastname}`);
                } else {
                    setWerkvoorbereider("Niet Toegewezen");
                }
                setLoading(false);
            } catch (error) {
                console.error('Error loading project data', error);
            }
        })();
    }, [id]);

    return (
        <Card>
            {project && <Card.Header className="d-flex justify-content-between">
                <ProjectHead ProjectCode={project?.Code}/>
                <Button variant="danger" className="px-3" onClick={() => navigate(`../wo`)}>Back</Button>
            </Card.Header>}
            {loading ? (
                <Card.Body className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <BarLoader color='#007f6c' width={500} speedMultiplier={1} />
                </Card.Body>
            ) : (
                <Card.Body>
                    <WOAppProjectHeader project={project} isArchived={isArchived} werkvoorbereider={werkvoorbereider} />
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th className="wo-num">Werkorder</th>
                                <th className="wo-name">Naam</th>
                                <th className="wo-description">Beschrijving</th>
                                <th className="wo-amount">Hoeveelheid</th>
                                <th className="wo-status">Status</th>
                                <th className="wo-options">Opties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {project.workorders ? (
                                Object.values(project.workorders).map((workorder) => (
                                    <tr key={workorder.ID}>
                                        <td className="text-wrap wo-num">{CopyText(workorder.ShopOrderNumber, "Werkorder Nummer")}</td>
                                        <td className="text-wrap wo-name">{CopyText(workorder.ItemCode, "Artikel")}</td>
                                        <td className="text-wrap wo-description">{CopyText(workorder.Description, "Beschrijving")}</td>
                                        <td className="text-wrap wo-amount">{workorder.PlannedQuantity}x</td>
                                        <td className="text-wrap wo-status">{workorder.Status ? workorder.Status : "Geen Stuklijst"}</td>
                                        <td>
                                            <Button variant="success" onClick={() => navigate(`../wo/details/${workorder.ID}`)}>Afronden</Button>
                                            {" "}
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{`Openstaande onderdelen: ${countItemsWithoutStatus(workorder.partlist)}`}</Tooltip>} >
                                                <Badge bg="success">{countItemsWithoutStatus(workorder.partlist)}</Badge>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No work orders available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            )}
        </Card>
    );
}
