import React, { useEffect, useState } from 'react';
import { Badge, Collapse, Container, Image, InputGroup, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { DarkModeToggle } from "./Darkmode";
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { displayConfirmAlertCustom } from '../managers/AlertManager';
import apiCall from '../utils/apiCall';
import { displayToast } from '../managers/ToastManager';
import useClickPermissionHandler from './ClickPermissionHandler';

export default function NavMenu() {
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState('');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1400);

  const [isTyping, setIsTyping] = useState(false);
  const [projects, setProjects] = useState(undefined);


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1400);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleFocus = (event) => {
      if (['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
        setIsTyping(true);
      }
    };

    const handleBlur = (event) => {
      if (['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
        setIsTyping(false);
      }
    };

    document.addEventListener('focusin', handleFocus);
    document.addEventListener('focusout', handleBlur);

    return () => {
      document.removeEventListener('focusin', handleFocus);
      document.removeEventListener('focusout', handleBlur);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const response = await apiCall('project/all', 'POST');
      setProjects(Object.values(response).map(project => project.Code));
    })();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isTyping) {
        return;
      }

      if (event.key === user.Hotkeys?.PageBack) {
        navigate(-1);
      }
      if (event.key === user.Hotkeys?.PageForward) {
        navigate(+1);
      }

      if (event.key === user.Hotkeys?.PageDashboard) {
        navigate('/dashboard');
      }

      if (event.key === user.Hotkeys?.LastProject) {
        navigate(`/wo/${user.LastVisitedProject}`);
      }

      if (event.key === user.Hotkeys?.ExactDashboard) {
        if (user.access_token === false) {
          displayToast("U moet ingelogd zijn met uw Exact account om deze pagina te bekijken.", "error");
          return;
        }
        navigate('/wo/dashboard');
      }

      if (event.key === user.Hotkeys?.LastProjectExact) {
        if (user.access_token === false) {
          displayToast("U moet ingelogd zijn met uw Exact account om deze pagina te bekijken.", "error");
          return;
        }
        navigate(`/wo/dashboard/details/${user.LastVisitedProject}`);
      }

      if (event.key === user.Hotkeys?.WerkplaatsDashboard) {
        navigate('/wo');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate, isTyping, user.Hotkeys.PageBack, user.Hotkeys.PageForward, user.Hotkeys.PageDashboard, user.Hotkeys.LastProject, user.Hotkeys.ExactDashboard, user.LastVisitedProject, user.access_token]);

  const checkProjectNumber = (projectNumber, type) => {
    return (async () => {
      let response;
      if (type === 'checklist') {
        response = await apiCall(`checklist/getbycode/${projectNumber}`, 'POST');
      }
      if (type === 'equipmentlist') {
        response = await apiCall(`projectlist/get`, 'POST', { Code: projectNumber, DataType: "data_equipmentlist" });
      }
      if (type === 'devicelist') {
        response = await apiCall(`projectlist/get`, 'POST', { Code: projectNumber, DataType: "data_devicelist" });
      }
      if (type === 'cabinetlist') {
        response = await apiCall(`projectlist/get`, 'POST', { Code: projectNumber, DataType: "data_cabinetlist" });
      }
      return response !== null;

    })();
  };

  const { handleExactPermission, handleClickPermission } = useClickPermissionHandler();

  const handleDashboardClick = () => {
    handleExactPermission(
      "Voor deze pagina moet u ingelogd zijn met uw Exact account. Ga naar 'Profile' en klik op 'Exact Login'.",
      '/wo/dashboard'
    );
  };

  function handleClick(endpoint, roles) {
    handleClickPermission(
      "U heeft niet de juiste rechten om deze pagina te bekijken.",
      endpoint,
      roles
    );
  }

  const handleExactLogin = async () => {

    if (!user.Roles.includes("Exact")) {
      displayToast("U heeft niet de juiste rechten om in te loggen met Exact.", "error");
      return;
    }

    try {
      const response = await fetch("https://auth.piasolutions.nl/oauth/login", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ referrer: window.location.href })
      });
      const data = await response.json();
      window.location.href = data.auth_url;
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  return (
    <Navbar expand={isMobileView ? false : "lg"} className="border-bottom box-shadow" collapseOnSelect>
      <Container fluid>
        <Navbar.Brand onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
          <Image className="d-inline-block align-top nav-logo" src="/images/cropped-favicon-32x32.png" height={30} width={30} title="Logo" />
          PIA Solutions Environment
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">

          <Nav.Link onClick={() => navigate('/dashboard')}><i className="fa-solid fa-house pr-1"></i> Home</Nav.Link>
          <Nav className="me-auto my-2 my-lg-0">
            <datalist id="projects">
              {projects && projects.map((project, index) => (
                <option key={index} value={project} />
              ))}
            </datalist>


            <NavDropdown title={<><i className="fab fa-buffer"></i> Lijsten</>} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => handleClick("/projects", ["Admin", "Projectleider"])}>Projects</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/companies', ["Admin"])}>Companies</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/people', ["Admin", "Projectleider"])}>People</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/functions', ["Admin"])}>Functions</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Header style={{ display: 'flex', justifyContent: "space-between" }} onClick={() => setOpen(open === "questions" ? "" : "questions")}>Vragen <span style={{}} className={open === "questions" ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}></span></NavDropdown.Header>
              <Collapse in={open === "questions"}>
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => handleClick('/questions', ["Questions", "Admin"])}>Globale Vragen</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => displayConfirmAlertCustom('Vul een projectnummer in:', <InputGroup className='was-validated'>
                    <InputGroup.Text>Projectnummer</InputGroup.Text>
                    <input placeholder="P0000" className="form-control" required type="text" id="projectNumber" min={4} max={10} onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^P\d]/g, '');
                      if (!e.target.value.startsWith('P')) {
                        e.target.value = 'P';
                      }
                    }} />
                  </InputGroup>, 'confirm', [
                    () => {
                      const projectNumber = document.getElementById('projectNumber').value;
                      checkProjectNumber(projectNumber.toUpperCase(), "checklist")
                        ? navigate(`/questions/checklist/${projectNumber.toUpperCase()}`)
                        : displayToast('Checklist bestaat niet.', 'error');
                    },
                    null
                  ], [
                    "Ga naar checklist vragen",
                    "Annuleren"
                  ])}>Checklist Vragen</NavDropdown.Item>
                </div>
              </Collapse>
              <NavDropdown.Header style={{ display: 'flex', justifyContent: "space-between" }} onClick={() => setOpen(open === "cabinet" ? "" : "cabinet")}>Cabinet <span style={{}} className={open === "cabinet" ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}></span></NavDropdown.Header>
              <Collapse in={open === "cabinet"}>
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => handleClick('/cabinet/columns-metadata', ["Cabinet", "Admin"])}>Kolommen lijst</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => displayConfirmAlertCustom('Vul een projectnummer in:', <InputGroup className='was-validated'>
                    <InputGroup.Text>Projectnummer</InputGroup.Text>
                    <input placeholder="P0000" className="form-control" list='projects' required type="text" id="projectNumber" min={4} max={10} onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^P\d]/g, '');
                      if (!e.target.value.startsWith('P')) {
                        e.target.value = 'P';
                      }
                    }} />
                  </InputGroup>, 'confirm', [
                    () => {
                      const projectNumber = document.getElementById('projectNumber').value;
                      checkProjectNumber(projectNumber.toUpperCase(), "equipmentlist")
                        ? navigate(`/cabinet/equipmentlist/${projectNumber.toUpperCase()}`)
                        : displayToast('Equipmentlist bestaat niet.', 'error');
                    },
                    null
                  ], [
                    "Ga naar equipment lijst",
                    "Annuleren"
                  ])}>Equipment lijst</NavDropdown.Item>
                  <NavDropdown.Item onClick={() =>
                    displayConfirmAlertCustom('Vul een projectnummer in:', <InputGroup className='was-validated'>
                      <InputGroup.Text>Projectnummer</InputGroup.Text>
                      <input placeholder="P0000" className="form-control" list="projects" required type="text" id="projectNumber" min={4} max={10} onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^P\d]/g, '');
                        if (!e.target.value.startsWith('P')) {
                          e.target.value = 'P';
                        }
                      }} />
                    </InputGroup>, 'confirm', [
                      () => {
                        const projectNumber = document.getElementById('projectNumber').value;
                        checkProjectNumber(projectNumber.toUpperCase(), "devicelist")
                          ? navigate(`/cabinet/devicelist/${projectNumber.toUpperCase()}`)
                          : displayToast('Device lijst bestaat niet.', 'error');
                      },
                      null
                    ], [
                      "Ga naar devicelijst",
                      "Annuleren"
                    ])
                  }>Devicelijst</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => displayConfirmAlertCustom('Vul een projectnummer in:', <InputGroup className='was-validated'>
                    <InputGroup.Text>Projectnummer</InputGroup.Text>
                    <input placeholder="P0000" className="form-control" list='projects' required type="text" id="projectNumber" min={4} max={10} onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^P\d]/g, '');
                      if (!e.target.value.startsWith('P')) {
                        e.target.value = 'P';
                      }
                    }} />
                  </InputGroup>, 'confirm', [
                    () => {
                      const projectNumber = document.getElementById('projectNumber').value;
                      checkProjectNumber(projectNumber.toUpperCase(), "cabinetlist")
                        ? navigate(`/cabinet/cabinetlist/${projectNumber.toUpperCase()}`)
                        : displayToast('Cabinetlist bestaat niet.', 'error');
                    },
                    null
                  ], [
                    "Ga naar cabinetlist",
                    "Annuleren"
                  ])}>Cabinet lijst</NavDropdown.Item>
                </div>

              </Collapse>
              <NavDropdown.Header style={{ display: 'flex', justifyContent: "space-between" }} onClick={() => setOpen(open === "clockify" ? "" : "clockify")}>Clockify <span style={{}} className={open === "clockify" ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}></span></NavDropdown.Header>
              <Collapse in={open === "clockify"}>
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => handleClick('/clockify/dashboard', ["Clockify", "Admin"])}>Dashboard</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleClick('/clockify/projects', ["Clockify", "Admin"])}>Projecten</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleClick('/clockify/clients', ["Clockify", "Admin"])}>Klanten</NavDropdown.Item>
                </div>
              </Collapse>
            </NavDropdown>
            <NavDropdown title={<><i className="fas fa-wrench"></i> Materialen</>} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => handleClick('/materials', ["Materials", "Admin"])}>Materiaal Lijst</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/materials/bulk', ["Materials", "Admin"])}>Bulk stock check</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Header style={{ display: 'flex', justifyContent: "space-between" }} onClick={() => setOpen(open === "unifeed" ? "" : "unifeed")}>Unifeed <span style={{}} className={open === "unifeed" ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}></span></NavDropdown.Header>
              <Collapse in={open === "unifeed"}>
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => handleClick('/materials/unifeed', ["Materials", "Admin"])}>Unifeed Lijst</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleClick('/materials/unifeed/available', ["Materials", "Admin"])}>Beschikbaar</NavDropdown.Item>
                </div>
              </Collapse>
            </NavDropdown>
            <NavDropdown title={<><i className="fas fa-paperclip"></i> EPLAN</>} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => handleClick('/eplan/material/converter', ["E-Plan", "Admin"])}>Materiaal Converter</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/eplan/material/plusminlijst', ["E-Plan", "Admin"])}>Plus-minlijst</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Header style={{ display: 'flex', justifyContent: "space-between" }} onClick={() => setOpen(open === "kabel" ? "" : "kabel")}>Kabel <span style={{}} className={open === "kabel" ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}></span></NavDropdown.Header>
              <Collapse in={open === "kabel"}>
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => handleClick('/eplan/cable/converter', ["E-Plan", "Admin"])}>Kabel Converter</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleClick('/eplan/cable/plusminlijst', ["E-Plan", "Admin"])}>Plus-minlijst</NavDropdown.Item>
                </div>
              </Collapse>
              <NavDropdown.Divider />

              <NavDropdown.Header style={{ display: 'flex', justifyContent: "space-between" }} onClick={() => setOpen(open === "opties" ? "" : "opties")}>Opties <span style={{}} className={open === "opties" ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}></span></NavDropdown.Header>
              <Collapse in={open === "opties"}>
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => handleClick('/eplan/config', ["E-Plan", "Admin"])}>Configuratie</NavDropdown.Item>
                </div>
              </Collapse>
            </NavDropdown>
            <NavDropdown title={<><i className="fas fa-copy"></i> Exact</>} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => handleClick('/eplan/exact/artikellijst', ["E-Plan", "Admin"])}>Partslijst &rarr; Importlijst</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/eplan/exact/bestel', ["E-Plan", "Admin"])}>Exact &rarr; Fabrikant</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Header style={{ display: 'flex', justifyContent: "space-between" }} onClick={() => setOpen(open === "werkorders" ? "" : "werkorders")}>Werkorders <span style={{}} className={open === "werkorders" ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}></span></NavDropdown.Header>
              <Collapse in={open === "werkorders"}>
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => window.location.href = "https://piatools.nl"}>Dashboard</NavDropdown.Item>
                </div>
              </Collapse>
              <NavDropdown.Divider />
              <NavDropdown.Header style={{ display: 'flex', justifyContent: "space-between" }} onClick={() => setOpen(open === "API" ? "" : "API")}>API <span style={{}} className={open === "API" ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}></span></NavDropdown.Header>
              <Collapse in={open === "API"}>
                <div>
                  <NavDropdown.Divider />
                  <NavDropdown.Item className="text-warning" onClick={() => handleClick('/eplan/exact/plusminapi', ["E-Plan", "Admin"])}>Plus-minlijsten API</NavDropdown.Item>
                </div>
              </Collapse>
            </NavDropdown>
            <NavDropdown title={<><i className="fas fa-tools"></i> Werkorder App</>} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => handleClick('/wo', ["WorkorderApp", "Admin"])}>Werkplaats</NavDropdown.Item>
              <NavDropdown.Item onClick={handleDashboardClick}>Dashboard</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<><i className="fa-regular fa-comment"></i> Feedback</>} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => {
                if (user.Email === "") {
                  displayToast("Om feedback te geven moet er een emailadres bekend zijn in uw profiel.", "error", 10000);
                  return;
                }

                displayConfirmAlertCustom('Wat voor feedback wilt u geven?', 'Kies een van de onderstaande opties.', 'confirm', [
                  () => navigate('/feedback/bug'),
                  () => navigate('/feedback/feature')
                ], [
                  'Probleem melden',
                  'Feature aanvragen'
                ])
              }}> Feedback
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/feedback', ["Admin"])}> Feedback Overzicht</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <>
              {user.access_token ? (
                <span style={{ color: 'green', fontSize: '20px', marginTop: '4px', cursor: 'pointer' }} title="Je bent ingelogd met Exact">●</span>
              ) : (
                <span style={{ color: 'red', fontSize: '20px', marginTop: '4px', cursor: 'pointer' }} title="Je bent niet ingelogd met Exact">●</span>
              )}
            </>
            <NavDropdown align={"end"} title={<><Badge bg="success"><i className="fas fa-user"></i>{user.Fullname}</Badge></>} id="dropdown-menu-align-end">
              <NavDropdown.Item onClick={() => handleClick('/profile', ["User", "Admin"])} className="text">Profiel</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/profile/Timesheet', ["User", "Admin"])} className="text">Tijdschema</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick('/profile/myProjects', ["User", "Admin"])} className="text">Mijn Projecten</NavDropdown.Item>
              <NavDropdown.Item onClick={handleExactLogin}>Exact Login</NavDropdown.Item>
              <NavDropdown.Item> <Button variant={"outline-danger"} onClick={logout}> <i
                className={"fa fa-sign-out"} /> Uitloggen</Button></NavDropdown.Item>
            </NavDropdown>
            <DarkModeToggle />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
