import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import apiCall, { apiCallRaw } from "./apiCall";
import { displayToast } from "../managers/ToastManager";
import { OptionsByList } from "./OptionsByFunction";

const ExportButton = ({ projectId, loading }) => {
    const [show, setShow] = useState(false);
    const [selectedType, setSelectedType] = useState("");
    const [selectedTarget, setSelectedTarget] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [excludeNoAnswer, setExcludeNoAnswer] = useState(false);
    const [finalExport, setFinalExport] = useState(false);
    const [useVersioning, setUseVersioning] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [types, setTypes] = useState([]);
    const [targets, setTargets] = useState([]);
    const [languages, setLanguages] = useState([]);

    const handleShow = async () => {
        setShow(true);
        try {
            const document_misc = await apiCall("export_generator_manager/available_types", "POST");
            setTypes(document_misc.types);
            setTargets(document_misc.targets);
            setLanguages(document_misc.languages);
        } catch (error) {
            displayToast(
                "Er is een fout opgetreden bij het ophalen van de documentatiegegevens.",
                "error"
            );
        }
    };

    const handleClose = () => setShow(false);

    const handleExport = async () => {
        if (!selectedType || !selectedTarget || !selectedLanguage) {
            displayToast("Please select all fields", "error");
            return;
        }
    
        try {
            setIsExporting(true);
            const response = await apiCallRaw(
                "export_generator_manager/generate",
                "POST",
                {
                    project_id: projectId,
                    doc_type: selectedType,
                    doc_target: selectedTarget,
                    doc_language: selectedLanguage,
                    excludeNoAnswer: excludeNoAnswer,
                    finalExport: finalExport,
                    useVersioning: useVersioning,
                }
            );
    
            // Check the content type of the response
            const contentType = response.headers.get("content-type");
            let responseData;
    
            if (contentType && contentType.includes("application/json")) {
                // If the response is JSON, parse it
                responseData = await response.json();
            } else {
                // Otherwise, treat it as a blob
                responseData = await response.blob();
            }
    
            if (!response.ok) {
                if (responseData.error) {
                    throw new Error(responseData.error);
                } else {
                    throw new Error("An unknown error occurred");
                }
            }
    
            if (responseData instanceof Blob) {
                const url = window.URL.createObjectURL(responseData);
                const a = document.createElement("a");

                // Format the current date as dd-mm-yyyy
                const currentDate = new Date();
                const day = String(currentDate.getDate()).padStart(2, '0');
                const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = currentDate.getFullYear();
                const formattedDate = `${day}-${month}-${year}`;

                a.href = url;
                a.download = `EXPORT-GENERATOR-PROJECT-${projectId}-${formattedDate}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                handleClose();
            }
        } catch (error) {
            displayToast(
                error.message,
                "error"
            );
        } finally {
            setIsExporting(false);
        }
    };
    

    return (
        <>
            <Button variant="warning" onClick={handleShow} disabled={loading}>
                <i className={"fa fa-download"} /> Export
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Selecteer Export Opties</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedType}
                                onChange={(e) => setSelectedType(e.target.value)}
                            >
                                <option value="">Select type</option>
                                <OptionsByList list={types} />
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Target</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedTarget}
                                onChange={(e) => setSelectedTarget(e.target.value)}
                            >
                                <option value="">Select target</option>
                                <OptionsByList list={targets} />
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Language</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedLanguage}
                                onChange={(e) => setSelectedLanguage(e.target.value)}
                            >
                                <option value="">Select language</option>
                                <OptionsByList list={languages} />
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                type="checkbox"
                                label="Exclude no answer"
                                checked={excludeNoAnswer}
                                onChange={(e) => setExcludeNoAnswer(e.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                type="checkbox"
                                label="Final Export"
                                checked={finalExport}
                                onChange={(e) => setFinalExport(e.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                type="checkbox"
                                label="Use Versioning"
                                checked={useVersioning}
                                onChange={(e) => setUseVersioning(e.target.checked)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Anuleren
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleExport}
                        disabled={isExporting}
                    >
                        {isExporting ? "Exporting..." : "Exporteren"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ExportButton;
