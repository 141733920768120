/* global BigInt */

export const calculatePermissionsValue = (roles, selectedRoles) => {
    let totalValue = 0n; 

    selectedRoles.forEach(role => {
      const index = roles.indexOf(role);
      if (index !== -1) {
        totalValue += BigInt(2 ** index);
      }
    });

    return totalValue;
};