import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, InputGroup, ListGroup, Modal, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import apiCall from "../../../components/utils/apiCall";
import { displayToast } from "../../../components/managers/ToastManager";
import { FaExternalLinkAlt, FaInfoCircle, FaTrash, FaUser } from "react-icons/fa";
import { FunctionBadge, StatusBadge } from "../../../components/managers/BadgeManager";

const getCompanyById = async (id) => {
    try {
        const res = await apiCall(`company/getbyid/${id}`, "POST");

        return res;
    } catch (error) {
        displayToast(error.message, "error");
    }
};


const bulkByIDs = async (data) => {
    try {
        const res = await apiCall(`bulk/get/employee`, "POST", { IDs: data });
        return res;

    } catch (error) {
        throw new Error(error.message);
    }
};


const saveCompanyChanges = async (company) => {
    try {
        await apiCall(`company/update`, "POST", company);
        return true;
    } catch (error) {
        throw new Error(error.message);
    }
};

const Details = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tempCompany, setTempCompany] = useState(null);
    const [key, setKey] = useState("general");
    const [isEditing, setIsEditing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [sending] = useState(false);
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState(new Set());
    const [employeeData, setEmployeeData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [hideFinished, setHideFinished] = useState(true);
    const [FunctionListAPI, setFunctionListAPI] = useState([{}]);

    const bulkProjectsByIds = async (projectIds) => {
        try {
            const res = await apiCall(`bulk/get/project`, "POST", { IDs: projectIds });

            return res;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    useEffect(() => {
        const fetchCompany = async () => {
            try {
                const companyData = await getCompanyById(id);
                setCompany(companyData);
                setTempCompany(companyData);
                setLoading(false);
                setFunctionListAPI(await apiCall("metadata/functionlist", "POST", {}));

                if (companyData && companyData.Employees) {
                    const employeeIds = companyData.Employees.filter(emp => typeof emp === 'string');
                    if (employeeIds.length > 0) {
                        const response = await bulkByIDs(employeeIds);
                        setEmployeeData(response);
                        setSelectedEmployees([...selectedEmployees, ...response]);
                    }
                }

                if (companyData && companyData.Projects) {
                    const projectIds = companyData.Projects.filter(proj => typeof proj === 'string');
                    if (projectIds.length > 0) {
                        const projectResponse = await bulkProjectsByIds(projectIds);
                        if (projectResponse) {
                            setProjects(projectResponse);
                        }
                    }
                }
            } catch (error) {
                setLoading(false);
            }
        };
        if (id) {
            fetchCompany();
        }
    }, []);

    useEffect(() => {
        const getEmployees = async () => {
            try {
                const response = await apiCall(
                    "employee/all", "POST", {}
                );
                if (response) setEmployees(response);
            } catch (error) {
                console.error("Error met ophalen van Employees:", error);
                setError(error);
            }
        };

        getEmployees();
    }, []);

    const handleEdit = () => {
        setIsEditing(true);
        displayToast("U bent nu in bewerkingsmodus", "info");
    };

    const toggleHideFinished = () => {
        setHideFinished(!hideFinished);
    };

    const handleSave = async () => {
        try {
            const success = await saveCompanyChanges(tempCompany);
            if (success) {
                setCompany(tempCompany);
                setIsEditing(false);

                displayToast("Company succesvol bijgewerkt", "success");
            }
        } catch (error) {
            setError(error);
            displayToast("Companies kon niet worden bijgewerkt", "error");
        }
    };

    const handleCancel = () => {
        setTempCompany(company);
        setIsEditing(false);
        displayToast("U bent nu niet meer in bewerkingsmodus", "info");
    };

    const handleChange = (e) => {
        setTempCompany({ ...tempCompany, [e.target.id]: e.target.value });
    };

    const handleCloseModal = () => {
        localStorage.setItem('modalOpen', 'false');
        setShowModal(false);
        setSelectedEmployees([]);
        navigate(0);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleEmployeeAdd = async () => {
        try {
            const selectedEmployeeIds = selectedEmployees.map(emp => emp._id);

            await apiCall(
                'company/addemployee', 'POST',
                { selectedEmployees: selectedEmployeeIds, Code: company.Code }
            );

            const updatedEmployeeData = [...employeeData, ...selectedEmployees];
            setEmployeeData(updatedEmployeeData);
            setSelectedEmployees([]);

            displayToast("Employees succesvol toegevoegd", "success");
            setShowModal(false);
            window.location.reload();

        } catch (error) {
            console.error(error);
            displayToast("Employees konden niet worden toegevoegd", "error");
        }
    };

    const removeEmployeeFromCompany = async (employeesToRemove, Code) => {
        try {
            const employeeIds = employeesToRemove.map(emp => emp._id);

            await apiCall(
                "company/removeemployee", "POST",
                { selectedEmployees: employeeIds, Code }
            );

            const updatedEmployeeData = employeeData.filter(emp => !employeeIds.includes(emp._id));
            setEmployeeData(updatedEmployeeData);
            setSelectedEmployees(updatedEmployeeData);

        } catch (error) {
            throw new Error(error.message);
        }
    };

    const handleEmployeeDelete = async (employee) => {
        try {
            await removeEmployeeFromCompany([employee], company.Code);

            const updatedSelectedEmployees = selectedEmployees.filter((emp) => emp._id !== employee._id);
            setSelectedEmployees(updatedSelectedEmployees);

            const updatedSelectedEmployeeIds = new Set(selectedEmployeeIds);
            updatedSelectedEmployeeIds.delete(employee._id);
            setSelectedEmployeeIds(updatedSelectedEmployeeIds);

            displayToast("Employee succesvol verwijderd", "success");

        } catch (error) {
            console.error("Employee kon niet worden verwijderd:", error);
            displayToast("Employee kon niet worden verwijderd", "error");
        }
    };

    useEffect(() => {
        const isModalOpen = localStorage.getItem('modalOpen');
        if (isModalOpen === 'true') {
            setShowModal(true);
            localStorage.removeItem('modalOpen');
        }
    }, []);


    if (loading) {
        return (
            <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                <Spinner />Loading...
            </h1>
        );
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col sm={6}>
                            <h3>{isEditing ? `Company Bewerken - ${company.Name}` : `Company Details - ${company.Name}`}</h3>
                        </Col>
                        <Col sm={6} className={"text-end"}>
                            <Button variant="danger" onClick={() => navigate(-1)}><i className="fas fa-arrow-left" /> Terug</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Tabs onSelect={setKey} id="controlled-tab-example">
                    <Tab title="Generieke Informatie" eventKey="general">
                        <Form>
                            <Row className="p-3">
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Company ID</InputGroup.Text>
                                        <Form.Control type="text" id="id" value={tempCompany?._id} disabled />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Code</InputGroup.Text>
                                        <Form.Control type="text" id="Code" value={tempCompany.Code} disabled onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Naam</InputGroup.Text>
                                        <Form.Control type="text" id="Name" value={tempCompany.Name} disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Postcode</InputGroup.Text>
                                        <Form.Control type="text" id="PostalCode" value={tempCompany.PostalCode} disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Email</InputGroup.Text>
                                        <Form.Control type="text" id="Email" value={tempCompany.Email} disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Stad</InputGroup.Text>
                                        <Form.Control type="text" id="City" value={tempCompany.City} disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Land</InputGroup.Text>
                                        <Form.Control type="text" id="Country" value={tempCompany.Country} disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Adres</InputGroup.Text>
                                        <Form.Control type="text" id="Address" value={tempCompany.Address} disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Adres2</InputGroup.Text>
                                        <Form.Control type="text" id="Address2" value={tempCompany.Address2} disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Telefoon</InputGroup.Text>
                                        <Form.Control type="text" id="Phone" value={tempCompany.Phone} disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab title="Employees" eventKey="employees">
                        <Row className="p-3">
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <Col>
                                            <h5 style={{ display: 'flex', alignItems: 'center' }}>
                                                Company Leden
                                                <Button className="m-2" onClick={handleOpenModal} style={{ cursor: 'pointer', fontSize: '1em' }}>
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </Button>
                                            </h5>
                                        </Col>
                                    </Card.Header>
                                    <Col md="12">
                                        <div style={{ maxHeight: 'calc(100vh - 400px)' }}>
                                            {employeeData.length === 0 ? (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', color: '#a1a1a1' }}>
                                                    <FaInfoCircle style={{ fontSize: '1.5em', marginRight: '10px' }} />
                                                    <h6 style={{ marginTop: '8px' }}><em>Nog geen employees gekoppeld</em></h6>
                                                </div>
                                            ) : (
                                                <ListGroup id="employees">
                                                    <Row className="p-3">
                                                        {employeeData
                                                            .filter((employee, index, self) =>
                                                                index === self.findIndex((e) => e.Firstname === employee.Firstname && e.Lastname === employee.Lastname)
                                                            )
                                                            .sort((a, b) => a.Function > b.Function ? 1 : -1)
                                                            .map((employee, index) => (
                                                                <Col md={6} key={index}>
                                                                    <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div>
                                                                            {employee.Firstname} {employee.Lastname} <FunctionBadge functionName={employee.Function} functionList={FunctionListAPI} />
                                                                        </div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <FaUser
                                                                                onClick={() => navigate(`/people/details/${employee._id}`)}
                                                                                style={{ cursor: 'pointer', fontSize: '1em', color: 'gray', marginRight: '13px' }}
                                                                            />
                                                                            <FaTrash
                                                                                onClick={() => handleEmployeeDelete(employee)}
                                                                                style={{ cursor: 'pointer', fontSize: '1em', color: 'red' }}
                                                                            />
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Col>
                                                            ))}
                                                    </Row>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab title="Projects" eventKey="projects">
                        <Row className="p-3">
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <Col>
                                            <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '2px', marginTop: '18px', marginBottom: '25px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                                    <span>Projects Enlisted</span>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                                    {projects.length > 0 && (
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={hideFinished}
                                                            onChange={toggleHideFinished}
                                                            style={{ marginRight: '10px' }}
                                                        />
                                                    )}
                                                    {projects.length > 0 && (
                                                        <span style={{ fontSize: '0.7em' }}>Verberg afgemaakte projecten</span>
                                                    )}
                                                </div>
                                            </h5>
                                        </Col>
                                    </Card.Header>
                                    <Col md="12">
                                        <div>
                                            {projects.length === 0 ? (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', color: '#a1a1a1' }}>
                                                    <FaInfoCircle style={{ fontSize: '1.5em', marginRight: '10px' }} />
                                                    <h6 style={{ marginTop: '8px' }}><em>Nog geen projecten gekoppeld</em></h6>
                                                    <FaExternalLinkAlt style={{ cursor: 'pointer', fontSize: '0.9em', marginBottom: '20px', marginLeft: '8px' }} onClick={() => navigate(`/projects/create`)}></FaExternalLinkAlt>
                                                </div>
                                            ) : (
                                                <ListGroup id="projects">
                                                    <Row className="p-3">
                                                        {projects
                                                            .filter(project => !hideFinished || project.Status !== 'Finished')
                                                            .map((project, index) => (
                                                                <Col md={6} key={index}>
                                                                    <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div>
                                                                            {project.Code} {' '} {project.Description} {' '} {StatusBadge(project.Status)}
                                                                        </div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <FaInfoCircle
                                                                                onClick={() => navigate(`/projects/details/${project._id}`)}
                                                                                style={{ cursor: 'pointer', fontSize: '1em', color: 'gray', marginRight: '13px' }}
                                                                            />
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
                <Card.Footer>
                    <Row>
                        {key === "general" && (
                            isEditing ?
                                <Col sm={4}>
                                    <Button variant="primary" className="me-3" onClick={handleSave}><i className="fas fa-save" /> Opslaan</Button>
                                    <Button variant="danger" className="me-3" onClick={handleCancel}><i className="fas fa-cancel" /> Annuleren</Button>
                                </Col> :
                                <Col sm={4}>
                                    <Button variant="primary" onClick={handleEdit}><i className="fa fa-pen" /> Bewerken</Button>
                                </Col>
                        )}

                    </Row>
                </Card.Footer>
            </Card>
            <Card>
                <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Voeg Leden Toe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Lijst van Employees:</h5>
                        <input
                            className="form-control"
                            list="employeeList"
                            type="text"
                            value={searchInput}
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                                const selectedEmployee = employees.find(
                                    (employee) => `${employee.Firstname} ${employee.Lastname}` === e.target.value
                                );
                                if (selectedEmployee && !selectedEmployeeIds.has(selectedEmployee._id)) {
                                    setSelectedEmployees([...selectedEmployees, selectedEmployee]);
                                    setSelectedEmployeeIds(new Set(selectedEmployeeIds).add(selectedEmployee._id));
                                    setSearchInput("");
                                }
                            }}
                        />
                        <datalist id="employeeList">
                            {employees && employees.length > 0 && employees
                                .filter((employee) =>
                                    !selectedEmployeeIds.has(employee._id) &&
                                    !selectedEmployees.some(selected => selected._id === employee._id)
                                )
                                .map((employee) => {
                                    return (
                                        <option
                                            key={employee._id}
                                            value={`${employee.Firstname} ${employee.Lastname}`}
                                        />
                                    );
                                })}
                        </datalist>
                        <div style={{ marginTop: '40px' }}>
                            <h5 style={{ borderBottom: '1px solid gray', paddingBottom: '10px' }}>Members Enlisted:</h5>
                            {selectedEmployees.sort((a, b) => a.Function > b.Function ? 1 : -1).map((employee, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', fontSize: '1.2em', marginBottom: '8px' }}>
                                    <span>{employee.Firstname} {employee.Lastname} <FunctionBadge functionName={employee.Function} functionList={FunctionListAPI} /></span>
                                </div>
                            ))}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={handleEmployeeAdd}
                            disabled={sending || selectedEmployees.length === 0}
                            style={{
                                backgroundColor: sending || selectedEmployees.length === 0 ? '#ccc' : '#007bff',
                                color: '#fff',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: sending || selectedEmployees.length === 0 ? 'not-allowed' : 'pointer',
                                marginRight: '8px',
                                fontSize: '1em',
                            }}
                        >
                            <i className="fas fa-save" /> {sending ? "Versturen..." : "Verstuur"}
                        </Button>
                        <Button variant="danger" onClick={handleCloseModal}><i className="fas fa-close" />Sluiten</Button>
                    </Modal.Footer>
                </Modal>
            </Card>
        </>
    );
};

export default Details;
