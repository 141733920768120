import React from 'react';
import { Form, Modal, Table } from 'react-bootstrap';
import { displayConfirmAlertCustom } from "../managers/AlertManager";
import * as XLSX from 'xlsx';
import apiCall from '../utils/apiCall';
import { displayToast } from "./ToastManager";

/**
 * README ImportManager
 *
 * ImportManager.jsx is een React component bestand dat de hoofdcomponent ImportManager bevat.
 * Deze component wordt gebruikt om verschillende elementen in de applicatie te importeren.
 *
 * ==================================================================================================
 *
 * ImportList:
 * Deze functie wordt gebruikt om een lijst te importeren van een bestand.
 * Het leest het bestand en converteert het naar een JSON-object.
 *
 * transformImportList:
 * Deze functie wordt gebruikt om de geïmporteerde lijst te transformeren naar een array van objecten.
 * Het neemt de geïmporteerde lijst en converteert elke rij naar een object, waarbij de kolomnamen worden gebruikt als sleutels.
 *
 * ImportFile:
 * Deze functie wordt gebruikt om een bestand te importeren en de database bij te werken.
 * Het toont een dialoogvenster waarin de gebruiker een bestand kan selecteren om te importeren.
 * Na het selecteren van een bestand, wordt de inhoud van het bestand geïmporteerd en wordt de database bijgewerkt.
 *
 * ===================================================================================================
 *
 * Gebruik:
 * Om deze componenten te gebruiken, importeer ze uit het ImportManager.jsx bestand en voeg ze toe aan je React component.
 * Je moet de nodige props voor elke component leveren.
 * De props omvatten de geselecteerde rijgegevens, de functie om de indiening van het formulier te verwerken, de functie om een rij te verwijderen, en andere gerelateerde gegevens en functies.
 *
 * Opmerking:
 * Deze componenten zijn ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een selectedRow object dat de gegevens van de geselecteerde rij bevat, en een handleSubmitMeta functie die de indiening van het formulier verwerkt.
 *
 */

/**
 * 
 * @param {File} file
 * @returns 
 */
export const ImportList = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            resolve(jsonData);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

/**
 * Transformeer de importList naar een array van objecten
 * @param {Array} importList 
 * @returns {Array}
 */
export const transformImportList = (importList) => {
    const columns = importList[0];
    const data = importList.slice(1);

    console.log("Columns:" + columns);
    console.log("Data:" + data);

    return data.map(row => {
        let device = {};
        columns.forEach((col, index) => {
            device[col] = row[index] !== undefined && row[index] !== null ? row[index] : "";
        });
        return device;
    });
};

export function ImportToDatabase(data, type, id) {
    async function ImportData() {
        try {
            const response = await apiCall(`projectlist/updateall`, 'POST', { "Data": data, "Type": type, "Code": id });
            if (response.status === 200) {
                displayToast("Data succesvol geïmporteerd", "success");
            }
        } catch (error) {
            displayToast("Fout bij het importeren van de data", "error");
        }
    }
    ImportData();
}

/**
 *  Importeerd een excel bestand en update de database
 *  @param {String} type
 * @param id
 */
const ImportFile = (setImportedData) => {
    displayConfirmAlertCustom(
        "Importeer Lijst",
        <>
            <Form.Control id="file" type="file" className='w-100' />
        </>,
        "confirm",
        [
            async () => {
                const fileInput = document.getElementById('file');
                if (fileInput.files.length === 0) {
                    displayToast("Selecteer een bestand om te importeren", "error");
                    return;
                }
                const file = fileInput.files[0];
                try {
                    const importList = await ImportList(file);
                    console.log("RAW:" + importList);
                    const transformedList = transformImportList(importList);
                    console.log("Transformed:" + transformedList);
                    setImportedData(transformedList);
                } catch (error) {
                    displayToast("Fout bij het importeren van het bestand", "error");
                }
            },
            null
        ],
        [
            "Importeren",
            "Annuleren"
        ]
    );
};

export default ImportFile;