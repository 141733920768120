import React from 'react';
import { displayToast } from '../managers/ToastManager';


const CopyTextComponent = ({ children }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      displayToast("Text is gekopieerd naar het klembord", "success");
    }).catch(() => {
      displayToast("Text kon niet worden gekopieerd naar het klembord", "error");
    });
  };

  return (
    <div onClick={() => copyToClipboard(children)} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.target.title = 'Copy'; }} onMouseLeave={(e) => {
      e.target.title = '';
    }}>
      {children}
    </div>
  );
};

export function CopyText(value, title) {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      displayToast(`${title} is gekopieerd naar het klembord`, "success");
    }).catch(() => {
      displayToast(`${title} kon niet worden gekopieerd naar het klembord`, "error");
    });
  };

  return (
    <div onClick={() => copyToClipboard(value)} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.target.title = 'Copy'; }} onMouseLeave={(e) => {
      e.target.title = '';
    }}>
      {value}
    </div>
  );
};

export default CopyTextComponent;
