import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Form, InputGroup, ListGroup, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { FunctionBadge } from '../../components/managers/BadgeManager';
import { FaInfoCircle } from "react-icons/fa";
import apiCall from '../../components/utils/apiCall';
import { displayToast } from '../../components/managers/ToastManager';
import MyProjects from "../../components/misc/myProjects";
import { CopyText } from "../../components/utils/CopyText";
import ProfilePicture from "../../components/utils/ProfilePicture";

export default function Profile() {
    const navigate = useNavigate();
    const { user, rehydrateUser } = useAuth();
    const [key, setKey] = useState("general");
    const [FunctionListAPI, setFunctionListAPI] = useState([]);
    const [employee, setEmployee] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [hotkeys, setHotkeys] = useState({
        PageBack: '',
        PageForward: '',
        PageDashboard: '',
        LastProject: '',
        ExactDashboard: '',
        LastProjectExact: '',
        WerkplaatsDashboard: ''
    });

    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const employeeData = await apiCall(`employee/getbyid/${user.EmployeeID}`, "POST");
                setEmployee(employeeData);
                setFunctionListAPI(await apiCall("metadata/functionlist", "POST", {}));
                displayToast("Profielgegevens opgehaald", "success");

                if (employeeData && employeeData.Company && employeeData.Company.length > 0) {
                    const companyData = await apiCall("bulk/get/company", "POST", { IDs: employeeData.Company });
                    setCompanies(companyData);
                }

            } catch (error) {
                console.error("Error met ophalen van profielgegevens:", error);
            }
        };

        fetchEmployeeData();
    }, [user.EmployeeID]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setHotkeys((prevHotkeys) => ({
            ...prevHotkeys,
            [name]: value,
        }));
    };

    const handleSetHotkey = async (hotkeyType, hotkey) => {
        try {
            await apiCall('employee/updatehotkey', 'POST', { ID: user.EmployeeID, HotkeyType: hotkeyType, Hotkey: hotkey }, {}, true);
            await rehydrateUser();
            setHotkeys((prevHotkeys) => ({
                ...prevHotkeys,
                [hotkeyType]: ''
            }));
        } catch (error) {
            displayToast(`Error met instellen van hotkey: ${error.message}`, "error");
        }
    };

    if (!employee) {
        return <div>Loading...</div>;
    }

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col sm={6}>
                        <h3>
                            Your Profile
                        </h3>
                    </Col>
                    <Col sm={6} className="text-end">
                        <Button variant="danger" onClick={() => navigate(-1)}> <i className="fa fa-arrow-left" /> Terug</Button>
                    </Col>
                </Row>
            </Card.Header>
            <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="controlled-tab-example" className="mb-3">
                <Tab eventKey="general" title="Generieke Informatie">
                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <ProfilePicture id={employee._id} />
                            </div>
                            <div>
                                <h4 className="d-flex align-items-center">
                                    {employee.Firstname} {employee.Lastname}
                                    <span style={{ color: '#9b9b9b', fontSize: '0.8rem', border: 'none', background: 'none', padding: 0, marginLeft: '10px' }}>
                                        {CopyText(employee._id, "Werknemer ID")}
                                    </span>
                                </h4>
                                <h6 className="mb-2" style={{ color: '#9b9b9b' }}>
                                    {employee.Email}
                                </h6>
                                <h5>
                                    <FunctionBadge functionName={employee.Function} functionList={FunctionListAPI} />
                                </h5>
                            </div>
                        </div>
                        <hr />
                        <div className="border radius p-3">
                            <Row>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Gebruikersnaam</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="username"
                                            disabled
                                            aria-describedby="username"
                                            value={employee.Username}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Volledige naam</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="name"
                                            disabled
                                            aria-describedby="name"
                                            value={`${employee.Firstname} ${employee.Lastname}`}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Email</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="email"
                                            disabled
                                            aria-describedby="email"
                                            value={employee.Email}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Telefoon</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="phone"
                                            disabled
                                            aria-describedby="phone"
                                            value={employee.Phone}
                                        />
                                    </InputGroup>
                                </Col>

                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Adres</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="address"
                                            disabled
                                            aria-describedby="address"
                                            value={employee.Address}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Adres 2</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="address2"
                                            disabled
                                            aria-describedby="address2"
                                            value={employee.Address2}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Functie</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="function"
                                            disabled
                                            aria-describedby="function"
                                            value={employee.Function}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Postcode</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="postalcode"
                                            disabled
                                            aria-describedby="postalcode"
                                            value={employee.PostalCode}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Stad</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="city"
                                            disabled
                                            aria-describedby="city"
                                            value={employee.City}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6} className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text id="sub">Land</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            aria-label="country"
                                            disabled
                                            aria-describedby="country"
                                            value={employee.Country}
                                        />
                                    </InputGroup>
                                </Col>

                            </Row>
                        </div>
                    </Card.Body>
                </Tab>
                <Tab eventKey="projects" title="Mijn Projecten">
                    <Card.Body>
                        <MyProjects employee={employee} />
                    </Card.Body>
                </Tab>
                <Tab eventKey="organisations" title="Organisaties">
                    <Row className="p-3">
                        <Col md={12}>
                            <Card>
                                <Card.Header>
                                    <Col>
                                        <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '2px', marginTop: '18px', marginBottom: '25px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                                <span>Aangemelde Companies</span>
                                            </div>
                                        </h5>
                                    </Col>
                                </Card.Header>
                                <Col md="12">
                                    <div>
                                        {companies.length === 0 ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', color: '#a1a1a1' }}>
                                                <FaInfoCircle style={{ fontSize: '1.5em', marginRight: '10px' }} />
                                                <h6 style={{ marginTop: '8px' }}><em>Organisaties</em></h6>
                                            </div>
                                        ) : (
                                            <ListGroup id="companies">
                                                <Row className="p-3">
                                                    {companies.map((company, index) => (
                                                        <Col md={6} key={index}>
                                                            <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div>
                                                                    {company.Name}
                                                                </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                {company.Employees.length > 0
                                                                                    ? `Employees: ${company.Employees.length}`
                                                                                    : 'Nog geen employees'}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Badge bg="primary" text="dark" pill style={{ marginRight: '10px' }}>{company.Employees.length > 0 ? company.Employees.length : '0'}</Badge>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                {company.Projects.length > 0
                                                                                    ? `Projects: ${company.Projects.length}`
                                                                                    : 'Nog geen projects'}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Badge bg="warning" text="dark" pill>{company.Projects.length > 0 ? company.Projects.length : '0'}</Badge>
                                                                    </OverlayTrigger>
                                                                    <FaInfoCircle
                                                                        onClick={() => navigate(`/companies/details/${company._id}`)}
                                                                        style={{ cursor: 'pointer', fontSize: '1em', color: 'gray', marginRight: '13px', marginLeft: '10px' }}
                                                                    />
                                                                </div>
                                                            </ListGroup.Item>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </ListGroup>
                                        )}
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="settings" title="Instellingen">
                    <Row>
                        <Col xs={12}>
                            <Card.Body>
                                <div className="border radius p-3">
                                    <h4>Hotkeys:</h4>
                                    <Row className="mb-3">
                                        <Col xs={12} md={4}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="pageBackLabel">
                                                    Pagina terug
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="PageBack"
                                                    placeholder={user.Hotkeys?.PageBack}
                                                    value={hotkeys.PageBack}
                                                    maxLength={1}
                                                    onChange={handleInputChange}
                                                />
                                                <Button variant="primary" onClick={(e) => handleSetHotkey('PageBack', hotkeys.PageBack)}>
                                                    Zetten
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="pageForwardLabel">
                                                    Pagina vooruit
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="PageForward"
                                                    placeholder={user.Hotkeys?.PageForward}
                                                    value={hotkeys.PageForward}
                                                    maxLength={1}
                                                    onChange={handleInputChange}
                                                />
                                                <Button variant="primary" onClick={(e) => handleSetHotkey('PageForward', hotkeys.PageForward)}>
                                                    Zetten
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="pageDashboardLabel">
                                                    Dashboard
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="PageDashboard"
                                                    placeholder={user.Hotkeys?.PageDashboard}
                                                    value={hotkeys.PageDashboard}
                                                    maxLength={1}
                                                    onChange={handleInputChange}
                                                />
                                                <Button variant="primary" onClick={(e) => handleSetHotkey('PageDashboard', hotkeys.PageDashboard)}>
                                                    Zetten
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>
                                                    Werkplaats Dashboard
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="WerkplaatsDashboard"
                                                    placeholder={user.Hotkeys?.WerkplaatsDashboard}
                                                    value={hotkeys.WerkplaatsDashboard}
                                                    maxLength={1}
                                                    onChange={handleInputChange}
                                                />
                                                <Button variant="primary" onClick={(e) => handleSetHotkey('WerkplaatsDashboard', hotkeys.WerkplaatsDashboard)}>
                                                    Zetten
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>
                                                    Ga naar het laatste project
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="LastProject"
                                                    placeholder={user.Hotkeys?.LastProject}
                                                    value={hotkeys.LastProject}
                                                    maxLength={1}
                                                    onChange={handleInputChange}
                                                />
                                                <Button variant="primary" onClick={(e) => handleSetHotkey('LastProject', hotkeys.LastProject)}>
                                                    Zetten
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        {user.Roles.includes('Exact') &&
                                            <Col xs={12} md={4}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>
                                                        Exact Dashboard
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="text"
                                                        name="ExactDashboard"
                                                        placeholder={user.Hotkeys?.ExactDashboard}
                                                        value={hotkeys.ExactDashboard}
                                                        maxLength={1}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Button variant="primary" onClick={(e) => handleSetHotkey('ExactDashboard', hotkeys.ExactDashboard)}>
                                                        Zetten
                                                    </Button>
                                                </InputGroup>
                                            </Col>
                                        }
                                        {user.Roles.includes('Exact') &&
                                            <Col xs={12} md={4}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>
                                                        Last Project Exact
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="text"
                                                        name="LastProjectExact"
                                                        placeholder={user.Hotkeys?.LastProjectExact}
                                                        value={hotkeys.LastProjectExact}
                                                        maxLength={1}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Button variant="primary" onClick={(e) => handleSetHotkey('LastProjectExact', hotkeys.LastProjectExact)}>
                                                        Zetten
                                                    </Button>
                                                </InputGroup>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </Card>
    );
}
