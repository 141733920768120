import React, { useEffect, useState } from 'react';
import apiCall from '../utils/apiCall';

const statusColorMapping = {
    'Offerte': 'indigo',
    'Order bevestiging': 'green',
    'Pre Engineering': 'blue',
    'Engineering': 'green',
    'Testing': 'lime',
    'Commissioning': 'cyan',
    'Service': 'orange',
    'Finished': 'gray',
};

export const StatusBadge = ({ statusTitle }) => {
    return (
        <span className="badge" style={{ background: statusColorMapping[statusTitle] || 'black', color: 'white' }}>
            {statusTitle}
        </span>
    );
}

export const FunctionBadge = ({ functionName, functionList }) => {
    const [funcNotFound, setFuncNotFound] = useState(false);
    const [userFunction, setUserFunction] = useState(null);

    useEffect(() => {
        const fetchFunction = async () => {
            if (!functionList || functionList.length === 0) {
                return;
            }

            const func = functionList.find(f => f.name === functionName);
            if (!func) {
                await apiCall("employee/user_error", "POST", { title: "[ ERROR ] FunctionBadge", message: `Functie niet gevonden: ${functionName}` });
                setFuncNotFound(true);
            } else {
                setFuncNotFound(false);
                setUserFunction(func);
            }
        };

        fetchFunction();
    }, [functionName, functionList]);

    if (!functionList || functionList.length === 0) {
        return null;
    }

    if (funcNotFound) {
        return (
            <span style={{ backgroundColor: "#000", color: '#fff', padding: '2px 8px', borderRadius: '4px', marginLeft: '5px' }}>
                Functie niet gevonden
            </span>
        );
    }

    if (!userFunction) {
        return null;
    }

    return (
        <span style={{ backgroundColor: userFunction.color, color: '#fff', padding: '2px 8px', borderRadius: '4px', marginLeft: '5px' }}>
            {functionName}
        </span>
    );
};