import { displayToast } from "../managers/ToastManager";

/**
 * Function to make API calls to a specified endpoint. 
 * @param {string} endpoint - The endpoint of the API.
 * @param {string} method - The HTTP method (GET, POST, PUT, DELETE, etc.).
 * @param {Object} [body] - The payload for POST/PUT requests.
 * @param {Object} [headers] - Additional headers for the request.
 * @param {boolean} [toast] - Display a toast message on success.
 * @returns {Promise} - Returns a promise that resolves to the response of the API call.
 */
const apiCall = async (endpoint, method = "POST", body = null, headers = {}, toast = false) => {

  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'include',
    mode: 'cors',
  };

  if (body) {
    config.body = JSON.stringify(body);
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, config);
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(responseData.error || 'Unknown error');
    }
    if (toast) {
      displayToast(responseData.message, "success");
    }
    return responseData;
  } catch (error) {
    console.error("API call error:", error.message);
    if (error.status === 401) {
      displayToast("Token is verlopen, je wordt doorgestuurd naar de loginpagina", "error");
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
    displayToast(error.message, "error");
  }

};

export const apiCallRaw = async (endpoint, method, body = null, headers = {}) => {

  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'include',
    mode: 'cors',
  };

  if (body) {
    config.body = JSON.stringify(body);
  }
  return await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, config);

};

export default apiCall;