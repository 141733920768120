import {useEffect, useState} from "react";
import toast from "react-hot-toast";

const apikey = process.env.REACT_APP_CLOCKIFY_API_KEY;
const apiworkspace = process.env.REACT_APP_CLOCKIFY_API_WORKSPACE;

export function useClockifyQuery(location) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!apikey) { throw new Error('Api key was not ok'); }
        if (!apiworkspace) { throw new Error('Api workspace was not ok'); }
        const request = { headers: { "x-api-key": apikey } };
        const response = await fetch(`https://api.clockify.me/api/v1/workspaces/${apiworkspace}/${location}`, request);
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [location]);
  return { data, error, loading };
}

export function CreateClockifyProject(raw){
    var req = { method: "POST", headers: { "x-api-key": apikey, "Content-Type": "application/json" }, body: JSON.stringify(raw), };
    fetch(`https://api.clockify.me/api/v1/workspaces/${apiworkspace}/projects`, req)
        .then((d) => {
            if (d.status === 201) {
                toast.success("Project aangemaakt in clockify!", {duration: 5000});
            } else {
                toast.error("Project mislukt om aantemaken!", {duration: 5000});
            }
            return d.json();
        })
        .catch((error) => {
            console.error("Error:", error);
            toast.error("Project om klant aan te maken", {duration: 5000});
        });
}

export function DeleteClockifyProject(id){
    const confirm = confirm("Je gaat een clockify project verwijderen, Weet je het zeker?");
    if (!confirm) return;
    var req = { method: 'PUT', headers: { "x-api-key": apikey, "Content-Type": "application/json" }, body: JSON.stringify({ "archived": true }) };
    fetch(`https://api.clockify.me/api/v1/workspaces/${apiworkspace}/projects/${id}`, req)
        .then((d) => {
            if (d.status === 200) {
                var req = { method: 'DELETE', headers: { "x-api-key": apikey } };
                fetch(`https://api.clockify.me/api/v1/workspaces/${apiworkspace}/projects/${id}`, req)
                    .then((d) => {
                        if (d.status === 200) {
                            toast.success("Gelukt om clockify project te verwijderen!", {duration: 5000});
                            document.getElementById("listitem"+id).remove();
                        } else {
                            toast.error("Mislukt om clockify project te verwijderen!", {duration: 5000});
                        }
                        return d.json();
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        toast.error("Mislukt om clockify project te verwijderen!", {duration: 5000});
                    });
                
            } else {
                toast.error("Mislukt om clockify project te verwijderen!", {duration: 5000});
            }
            return d.json();
        })
        .catch((error) => {
            console.error("Error:", error);
            toast.error("Mislukt om clockify project te verwijderen!", {duration: 5000});
        });
}

export async function CreateClockifyKlantProject(raw){
    var req = { method: 'POST', headers: { "x-api-key": apikey, "Content-Type": "application/json" }, body: JSON.stringify(raw), };
    await fetch(`https://api.clockify.me/api/v1/workspaces/${apiworkspace}/clients`, req)
        .then((d) => {
            if (d.status === 201) {
                toast.success("Klant aangemaakt in clockify!", {duration: 5000});
            } else {
                toast.error("Klant mislukt om aantemaken!", {duration: 5000});
            }
            return d.json();
        })
        .then((data) => {
            const result = data;
            const select = document.getElementById("client");
            const option = document.createElement("option");
            option.setAttribute("key", result.id);
            option.setAttribute("id", result.id);
            option.setAttribute("value", result.id);
            option.textContent = result.name;
            select.appendChild(option);
        })
        .catch((error) => {
            console.error("Error:", error);
            toast.error("Gefaald om klant aan te maken", {duration: 5000});
        });
}

export async function CreateClockifyKlant(raw){
    var req = { method: 'POST', headers: { "x-api-key": apikey, "Content-Type": "application/json" }, body: JSON.stringify(raw), };
    await fetch(`https://api.clockify.me/api/v1/workspaces/${apiworkspace}/clients`, req)
        .then((d) => {
            if (d.status === 201) {
                toast.success("Klant aangemaakt in clockify!", {duration: 5000});
            } else {
                toast.error("Klant mislukt om aantemaken!", {duration: 5000});
            }
            return d.json();
        })
        .catch((error) => {
            console.error("Error:", error);
            toast.error("Gefaald om klant aan te maken", {duration: 5000});
        });
}

export function DeleteClockifyKlant(id){
    const confirm = confirm("Je gaat een clockify klant verwijderen, Weet je het zeker?");
    if (!confirm) return;
    var req = { method: "DELETE", headers: { "x-api-key": apikey } };
    fetch(`https://api.clockify.me/api/v1/workspaces/${apiworkspace}/clients/${id}`, req)
        .then((d) => {
            if (d.status === 200) {
                toast.success("Gelukt om clockify klant te verwijderen!", {duration: 5000});
                document.getElementById("listitem"+id).remove();
            } else {
                toast.error("Mislukt om clockify klant te verwijderen!", {duration: 5000});
            }
            return d.json();
        })
        .catch((error) => {
            console.error("Error:", error);
            toast.error("Mislukt om clockify klant te verwijderen!", {duration: 5000});
        });
}