import React, { useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { displayToast } from '../managers/ToastManager';
import apiCall from './apiCall';
import { Button } from 'react-bootstrap';


const ProfilePicture = ({ id }) => {
    const [imageSrc, setImageSrc] = useState('');
    const [hover, setHover] = useState(false);

    const fetchProfilePicture = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}employee/profile_picture`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ID: id }),
            });

            if (!response.ok) {
                throw new Error('Error retrieving profile picture');
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            setImageSrc(url);
        } catch (err) {
            console.error('Error while retrieving profile picture:', err);
        }
    };

    useEffect(() => {
        fetchProfilePicture();
    }, [id]);

    const handleMouseEnter = () => {
        setHover(true);
    };
    
    const handleMouseLeave = () => {
        setHover(false);
    };


    const handleClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('id', id);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}employee/upload_profile_picture`, {
                    method: 'POST',
                    credentials: 'include',
                    mode: 'cors',
                    body: formData,
                });

                const result = await response.json();

                if (!response.ok) {
                    throw new Error(result.error);
                }

                if (result.message) {
                    fetchProfilePicture();
                }
            } catch (err) {
                await apiCall("employee/user_error", "POST", { title: "[ SECURITY ]", message: err.message });
                displayToast(err.message, 'error');
            }
        }
    };

    //Dit is om de profielfoto te verwijderen
    const handleRemove = async (event) => {
        event.stopPropagation();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}employee/remove_profile_picture`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ID: id }),
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.error);
            }

            setImageSrc('');
        } catch (err) {
            console.error('Error trying to delete the profile picture:', err);
            displayToast(err.message, 'error');
        }
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            style={{
                position: 'relative',
                width: '10rem',
                height: '10rem',
                borderRadius: '50%',
                overflow: 'hidden',
                boxShadow: '0 0 5px rgba(0, 64, 0, 0.7)',
                cursor: 'pointer',
            }}
        >
            <input
                id="fileInput"
                type="file"
                style={{ display: 'none' }}
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
            />
            {imageSrc ? (
                <img
                    src={imageSrc}
                    alt="Profile"
                    style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover' }}
                />
            ) : (
                <FaUserCircle size="100%" color="#454545" />
            )}
            {hover && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontSize: '1rem',
                            textAlign: 'center',
                            padding: '0.5rem',
                        }}
                    >Profiel foto wijzigen</div>
                    {imageSrc && (
                        <Button
                            variant="outline-danger"
                            onClick={handleRemove}
                            style={{
                                position: 'absolute',
                                bottom: '10%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                border: 'none',
                                borderRadius: '5px',
                                padding: '0.5rem 1rem',
                                cursor: 'pointer',
                            }}
                        >Remove</Button>
                    )}
                </>
            )}
        </div>
    );
};

export default ProfilePicture;
