import React from 'react';
import { Row, Col, InputGroup, Badge } from 'react-bootstrap';
import { JSONDateToString } from '../managers/DateManager';
import { CopyText } from '../utils/CopyText';
import "../../styles/wo/wo-global.css";

export const WOAppProjectHeader = ({ project, isArchived, werkvoorbereider }) => {

    return (
        <Row className='mb-3'>
            <Col>
                <InputGroup>
                    <InputGroup.Text className="col-3">Project:</InputGroup.Text>
                    <InputGroup.Text className="col-9 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <span className="me-2">{CopyText(project.Code, "Project nummer")}</span>
                            {' - '}
                            {isArchived ? (
                                <Badge bg="danger" className="ms-2">Gearchiveerd</Badge>
                            ) : (
                                <Badge bg="success" className="ms-2">Actief</Badge>
                            )}
                        </div>
                        <div className="d-flex align-items-center">
                            <Badge className='custom-badge-color' pill={true}>{JSONDateToString(project.StartDate)} / {JSONDateToString(project.EndDate)}</Badge>
                        </div>
                    </InputGroup.Text>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text className="col-3">Beschrijving:</InputGroup.Text>
                    <InputGroup.Text className="col-9 text-wrap">{project.Description}</InputGroup.Text>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text className="col-3 text-wrap">Project aangemaakt door:</InputGroup.Text>
                    <InputGroup.Text className="col-9">{project.ManagerFullname}</InputGroup.Text>
                </InputGroup>
                {project.ShopOrder && project.ShopOrder.length > 0 && (
                <InputGroup>
                    <InputGroup.Text className="col-3">{project.ShopOrder.length > 1 ? ("Verkopers:") : ("Verkoper:")}</InputGroup.Text>
                    <InputGroup.Text className="col-9 d-flex flex-wrap">
                        {project.ShopOrder && project.ShopOrder.length > 0 ? (
                            project.ShopOrder.map((order, index) => (
                                <Badge 
                                    key={index}
                                    className='custom-badge-color'
                                    style={{
                                        margin: index === 0 ? '5px 0 5px 0' : '5px',
                                        display: 'inline-block',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {order.SalespersonFullName} | {order.OrderNumber} | {order.Description}
                                </Badge>
                            ))
                        ) : (
                            <span style={{
                                margin: '5px',
                                display: 'inline-block',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                                maxWidth: '100%',
                            }}>
                                Geen verkopers toegewezen <Badge text="white" bg="danger"><strong>Dit kan een fout zijn in exact!</strong></Badge>
                            </span>
                        )}
                    </InputGroup.Text>
                </InputGroup>
                )}
                <InputGroup>
                    <InputGroup.Text className="col-3 wvb-text"></InputGroup.Text>
                    <InputGroup.Text className="col-9"><Badge bg="warning" className='m-0' text="dark">{werkvoorbereider}</Badge></InputGroup.Text>
                </InputGroup>
            </Col>
        </Row>
    )
}

export const ProjectHead = ({ ProjectCode }) => {
    return (
        <h1>Project - <Badge bg="success" className="m-0">{ProjectCode}</Badge></h1>
    )
}

export const WorkorderHead = ({ Archived, ShopOrderNumber, ItemCode }) => {
    return (
        <h1>Werkorder - <Badge className='m-0' bg={!Archived ? ("success") : ("danger")}>{CopyText(ShopOrderNumber, "Werkorder Nummer")}</Badge> - <Badge className='m-0' bg={!Archived ? ("success") : ("danger")}>{CopyText(ItemCode, "Werkorder Code")}</Badge></h1>
    )
}