import React, { useState } from 'react';

export default function StatusFilter({ statusFilter, setStatusFilter }) {
    const [showFilter, setShowFilter] = useState(false); // Toggle for showing checkboxes

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setStatusFilter((prev) => [...prev, value]);
        } else {
            setStatusFilter((prev) => prev.filter((status) => status !== value));
        }
    };

    return (
        <>
            Status
            <button
                className="btn btn-sm"
                onClick={() => setShowFilter(!showFilter)}
                style={{ marginLeft: '10px' }}
            >
                <i className="fas fa-filter"></i>
            </button>

            {showFilter && (
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: 'black',
                        border: '1px solid #ccc',
                        padding: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        zIndex: 100,
                    }}
                >
                    <div>
                        <input
                            type="checkbox"
                            id="statusGoed"
                            value="Goed"
                            checked={statusFilter.includes("Goed")}
                            onChange={(e) => handleCheckboxChange(e)}
                        />
                        <label style={{ marginLeft: '10px' }} htmlFor="statusGoed">Goed</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="statusTeWeinig"
                            value="Te weinig"
                            checked={statusFilter.includes("Te weinig")}
                            onChange={(e) => handleCheckboxChange(e)}
                        />
                        <label style={{ marginLeft: '10px' }} htmlFor="statusTeWeinig">Te weinig</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="statusTeVeel"
                            value="Te veel"
                            checked={statusFilter.includes("Te veel")}
                            onChange={(e) => handleCheckboxChange(e)}
                        />
                        <label style={{ marginLeft: '10px' }} htmlFor="statusTeVeel">Te veel</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="statusAnders"
                            value="Anders"
                            checked={statusFilter.includes("Anders")}
                            onChange={(e) => handleCheckboxChange(e)}
                        />
                        <label style={{ marginLeft: '10px' }} htmlFor="statusAnders">Anders</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="statusSelect"
                            value=""
                            checked={statusFilter.includes("")}
                            onChange={(e) => handleCheckboxChange(e)}
                        />
                        <label style={{ marginLeft: '10px' }} htmlFor="statusSelect">Select Status</label>
                    </div>
                </div>
            )}
        </>
    );
}
