import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  FormControl,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import styles from "../styles/login.module.css";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  document.body.classList.remove("dark");

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");
    try {
      await login(username, password);
      const redirectTo = location.state?.from?.pathname || "/dashboard";
      navigate(redirectTo, { replace: true });
    } catch (error) {
      setError(error.message || "Failed to login. Please try again.");
    }
  };

  return (
    <div className={styles.loginscreen}>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <Card className={`p-4 text-dark ${styles.cardTransDark}`}>
          <CardBody>
            <CardTitle className="text-center">
              <img src="/images/cropped-favicon-32x32.png" alt="logo" />
              <span className="ms-2">PIA Solutions Environment</span>
            </CardTitle>
            <Form onSubmit={handleLogin} style={{ width: "100%" }}>
              <FormGroup className={`mb-3 ${styles.inputfields}`}>
                <FormLabel>
                  Username
                </FormLabel>
                <FormControl
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  aria-describedby="username-addon"
                  autoComplete="off"
                  required
                />
              </FormGroup>

              <FormGroup className={`mb-3 ${styles.inputfields}`}>
                <FormLabel >
                  Password
                </FormLabel>
                <FormControl
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  aria-describedby="password-addon"
                  autoComplete="off"
                  required
                />
              </FormGroup>
              {error && (
                <div
                  className="d-flex justify-content-center mb-3 border border-2 border-dark rounded p-2"
                  style={{
                    color: "red",
                    backgroundColor: "white",
                    width: "fit-content",
                    margin: "auto",
                  }}
                >
                  {error}
                </div>
              )}
              <div className="d-flex justify-content-center">
                <Button
                  type="submit"
                  style={{
                    minWidth: "100%",
                  }}
                >
                  <i className="fa fa-sign-in" /> Login
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Login;
