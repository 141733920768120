import React, { useState, useEffect, useRef } from "react";
import apiCall from "../../../components/utils/apiCall";
import { ListGroup, Form, Button, Card, Dropdown, ButtonGroup, Modal, OverlayTrigger, Tooltip, DropdownButton } from "react-bootstrap";
import { FaPlusCircle, FaTrash, FaCopy, FaPaperPlane } from "react-icons/fa";
import Select from 'react-select';
import styles from '../../../styles/profile.module.css';
import { displayToast } from "../../../components/managers/ToastManager";
import { useAuth } from "../../../context/AuthContext";

const Timesheet = () => {
  const { user } = useAuth();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectsList, setSelectedProjectsList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const [weekRange, setWeekRange] = useState({ start: null, end: null });
  const [showModal, setShowModal] = useState(false);
  const [filledHoursList, setFilledHoursList] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [canSendHours, setCanSendHours] = useState(false);
  const [borderColor, setBorderColor] = useState("#ced4da");
  const [remainingHours, setRemainingHours] = useState(user.TotalHours);

  const dropdownRef = useRef(null);

  const tasks = [
    { value: 'Additional work', label: 'Additional work' },
    { value: 'Commissioning', label: 'Commissioning' },
    { value: 'Customer support', label: 'Customer support' },
    { value: 'Documentation', label: 'Documentation' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Generation', label: 'Generation' },
    { value: 'Meetings', label: 'Meetings' },
    { value: 'School', label: 'School' },
    { value: 'Travel time', label: 'Travel time' }
  ];

  // Dit is om de dichtsbijzijnde maandag te krijgen
  const getNearestMonday = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };

  // Dit is om de projecten op te halen voor in de select
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await apiCall("project/all", "POST", {});
        setProjects(response);
        setLoading(false);
      } catch (error) {
        console.error("Error met ophalen van projecten:", error);
        setError(error.message || "Er is iets misgegaan met het ophalen van de projecten");
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Dit is om de weekdagen te krijgen
  const getDatesForWeek = (startDate) => {
    if (!startDate) return [];
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  const weekDates = getDatesForWeek(weekRange.start);

  // Dit is om de dropdown te sluiten als je er buiten klikt
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Dit is om de weekrange te initialiseren	
  useEffect(() => {
    const initializeWeekRange = () => {
      const today = new Date();
      const monday = getNearestMonday(today);
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6);
      setSelectedWeek(monday);
      setWeekRange({ start: monday, end: sunday });
    };

    initializeWeekRange();
  }, []);

  // Dit is om de projecten toe te voegen
  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
    setSelectedTask(null); // Reset task selection when project changes
    setBorderColor("#ced4da"); // Reset border color
  };

  const handleTaskChange = (selectedOption) => {
    setSelectedTask(selectedOption);
  };


  // Dit is om de projecten toe te voegen, als er geen project is geselecteerd krijg je een error
  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedProject && selectedTask) {
      const project = projects.find((proj) => proj.Code === selectedProject.value);
      const existingCombination = selectedProjectsList.some(
        (proj) => proj.Code === project.Code && proj.task === selectedTask.value
      );

      if (existingCombination) {
        displayToast("Deze combinatie van project en taak is al geselecteerd", "error");
        return;
      }

      if (project) {
        setSelectedProjectsList([...selectedProjectsList, { ...project, task: selectedTask.value, hours: { Monday: '', Tuesday: '', Wednesday: '', Thursday: '', Friday: '', Saturday: '', Sunday: '' } }]);
        setSelectedProject(null);
        setSelectedTask(null);
        setShowDropdown(false);
        setCanSendHours(true);
        displayToast("Project en taak zijn toegevoegd", "success");
      }
    } else {
      displayToast("Selecteer een project en een taak", "error");
    }
  };


  // Dit is om de uren te veranderen
  const handleHoursChange = (index, day, value) => {
    const updatedProjectsList = [...selectedProjectsList];
    updatedProjectsList[index].hours[day] = parseFloat(value).toFixed(1);
    setSelectedProjectsList(updatedProjectsList);
  };

  // Dit is om de dropdown te laten zien
  const handleShowDropdown = (e) => {
    e.preventDefault();
    setShowDropdown(!showDropdown);
  };

  const handleTaskClick = () => {
    if (!selectedProject) {
      displayToast("Selecteer eerst een project", "error");
      setBorderColor("red"); // Change border color to red
    }
  };

  // Dit is om de totale uren te berekenen
  const calculateTotalHours = () => {
    let totalHours = 0;

    selectedProjectsList.forEach(project => {
      Object.values(project.hours).forEach(hours => {
        if (hours !== '' && !isNaN(hours)) {
          totalHours += parseFloat(hours);
        }
      });
    });

    // Check if the total hours is a whole number
    if (Number.isInteger(totalHours)) {
      return totalHours.toString(); // Convert to string to ensure consistency
    } else {
      return totalHours.toFixed(1); // Keep one decimal place if not a whole number
    }
  };


  // Dit is om de totale uren van een project te berekenen
  const calculateProjectTotalHours = (project) => {
    let projectTotalHours = 0;

    Object.values(project.hours).forEach(hours => {
      if (hours !== '' && !isNaN(hours)) {
        projectTotalHours += parseFloat(hours);
      }
    });

    // Check if the total hours is a whole number
    if (Number.isInteger(projectTotalHours)) {
      return projectTotalHours.toString(); // Convert to string to ensure consistency
    } else {
      return projectTotalHours.toFixed(1); // Keep one decimal place if not a whole number
    }
  };


  // Dit is om de week te selecteren
  const handleThisWeek = () => {
    const today = new Date();
    const monday = getNearestMonday(today);
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    setSelectedWeek(monday);
    setWeekRange({ start: monday, end: sunday });
    displayToast("Deze week geselecteerd", "success");
  };

  // Dit is om de week te selecteren
  const handleDateChange = (e) => {
    const newDate = new Date(e.target.value);
    if (!isNaN(newDate.getTime())) {
      const selectedMonday = getNearestMonday(newDate);
      const selectedSunday = new Date(selectedMonday);
      selectedSunday.setDate(selectedMonday.getDate() + 6);
      setSelectedWeek(selectedMonday);
      setWeekRange({ start: selectedMonday, end: selectedSunday });
      displayToast("Week geselecteerd", "success");
    } else {
      displayToast(" Geen geldige datum", "error");
    }
  };

  // TODO Dit is om de projecten te kopieren 
  // const handleCopyActivities = () => {

  // };

  // const handleCopyActivitiesAndTime = () => {

  // };

  // Dit is om een project te verwijderen
  const handleDeleteProject = (index) => {
    const updatedProjectsList = [...selectedProjectsList];
    updatedProjectsList.splice(index, 1);
    setSelectedProjectsList(updatedProjectsList);
    displayToast("Project is verwijderd", "success");
  };

  // Dit is om de vorige week te selecteren
  const handlePreviousWeek = () => {
    const previousMonday = new Date(selectedWeek);
    previousMonday.setDate(previousMonday.getDate() - 7);
    const previousSunday = new Date(previousMonday);
    previousSunday.setDate(previousMonday.getDate() + 6);
    setSelectedWeek(previousMonday);
    setWeekRange({ start: previousMonday, end: previousSunday });
    displayToast("Vorige week geselecteerd", "success");
  };

  // Dit is om de modal te openen
  const handleOpenModal = () => {
    const filledHours = selectedProjectsList.map(project => ({
      projectCode: project.Code,
      projectName: project.Description,
      projectTask: project.task,
      hours: {
        Monday: project.hours.Monday,
        Tuesday: project.hours.Tuesday,
        Wednesday: project.hours.Wednesday,
        Thursday: project.hours.Thursday,
        Friday: project.hours.Friday,
        Saturday: project.hours.Saturday,
        Sunday: project.hours.Sunday
      }
    }));
    setFilledHoursList(filledHours);
    setShowModal(true);
  };

  // Dit is om de modal te sluiten
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const handleSendHours = async () => {
    const weekNumber = getWeekNumber(selectedWeek); // Calculate the week number
  
    const totalHours = filledHoursList.reduce((total, project) => {
      return total + Object.values(project.hours).reduce((sum, hours) => sum + parseFloat(hours || 0), 0);
    }, 0);
  
    const userTotalHours = user.TotalHours;
  
    // Check if filled hours exceed the user's total hours
    if (totalHours > userTotalHours) {
      const difference = totalHours - userTotalHours;
      const userConfirmed = window.confirm(
        `Je ingevulde uren (${calculateTotalHours()}) is hoger dan die van je werkweek (${userTotalHours}). Dit overschrijdt je beschikbare uren met ${difference} uur. Weet je zeker dat je je uren wilt verzenden?`
      );
  
      if (!userConfirmed) {
        displayToast("Uren verzending geannuleerd", "info");
        handleCloseModal();
        return; // Exit the function if the user does not confirm
      }
    }
  
    const data = {
      weekNumber,
      totalHours,
      ID: user.EmployeeID,
      TimesheetProjects: filledHoursList.map((project) => {
        const projectTotalHours = Object.values(project.hours).reduce((sum, hours) => sum + parseFloat(hours || 0), 0);
        return {
          projectCode: project.projectCode,
          task: project.projectTask,
          totalProjectHours: projectTotalHours,
          hours: project.hours
        };
      })
    };
  
    try {
      const response = await apiCall("metadata/test", "POST", data);
      if (response.success) {
        displayToast("Uren succesvol verzonden", "success");
        setFilledHoursList([]);
        setCanSendHours(false);
      } else {
        displayToast("Uren konden niet worden verzonden", "error", response.message || "Er is iets misgegaan");
      }
    } catch (error) {
      displayToast("Er is iets misgegaan bij het verzenden van de uren", "error", error.message);
    }
};

  




  // Dit is om de dichtsbijzijnde maandag te krijgen
  const today = new Date();
  const currentWeekMonday = getNearestMonday(today);
  const currentWeekSunday = new Date(currentWeekMonday);
  currentWeekSunday.setDate(currentWeekMonday.getDate() + 6);

  if (loading) {
    return <p>Loading projects...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // Dit is om de projecten te groeperen op klant
  const groupedOptions = projects.reduce((acc, project) => {
    const customerGroup = acc.find(group => group.label === project.CustomerID);
    const projectOption = {
      value: project.Code,
      label: `${project.Code} - ${project.Description}`
    };

    if (customerGroup) {
      customerGroup.options.push(projectOption);
    } else {
      acc.push({
        label: project.CustomerID,
        options: [projectOption]
      });
    }

    return acc;
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <h1 id="tableLabel" style={{ margin: "0" }}>Tijdschema</h1>
            <h6 className="drop-animation" style={{ margin: "0", marginTop: '4px', color: '#a1a1a1' }}>
                                <em>Jou werkweek bestaat uit {user.TotalHours} uren</em>
                            </h6>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="date"
                value={selectedWeek ? selectedWeek.toISOString().substring(0, 10) : ''}
                onChange={handleDateChange}
                style={{ height: '40px', padding: '5px', width: '150px', marginRight: '10px' }}
              />
              <ButtonGroup style={{ border: '1px solid #495057', borderRadius: '5px' }}>
                <Button
                  style={{ width: '140px' }}
                  variant="dark"
                  onClick={handleThisWeek}
                >
                  Deze Week
                </Button>
                <Button style={{ width: '140px' }}
                  variant="dark"
                  onClick={handlePreviousWeek}
                >
                  Vorige Week
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </Card.Header>
        <div>
          <ListGroup variant="flush" >
            <ListGroup.Item variant="secondary" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h5 style={{ margin: 0, marginRight: '20px' }}>Projects</h5>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginRight: '230px' }}>
                {weekDates.map((date, index) => (
                  <div key={index} style={{ width: '150px', textAlign: 'center' }}>
                    {date.toLocaleDateString("nl-NL", { weekday: 'short', month: 'short', day: 'numeric' })}
                  </div>
                ))}
              </div>
            </ListGroup.Item>
            {selectedProjectsList.map((project, index) => (
              <ListGroup.Item key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                  <div>
                    <strong>({project.Code}) {project.Description} </strong> - <dfn>{project.task}</dfn>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                    <Form.Group key={day} style={{ marginRight: '5px' }}>
                      <Form.Control
                        type="number"
                        value={project.hours[day]}
                        onChange={(e) => handleHoursChange(index, day, e.target.value)}
                        placeholder={day.substring(0, 3)}
                        style={{ height: '40px', padding: '5px', width: '150px' }}
                        min={0}
                        max={24}
                        step="0.1"
                      />
                    </Form.Group>
                  ))}
                  <Button
                    variant="danger"
                    size="sm"
                    style={{ marginLeft: '93px', marginRight: '10px' }}
                    onClick={() => handleDeleteProject(index)}
                  >
                    <FaTrash />
                  </Button>
                </div>
                <div>
                  <span>Totaal: {calculateProjectTotalHours(project)}</span>
                </div>
              </ListGroup.Item>
            ))}
            <ListGroup.Item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ position: "relative" }}>
                <h5
                  className={styles.selectProject}
                  onClick={handleShowDropdown}
                  style={{ color: "#03a9f4", cursor: "pointer", display: 'flex', alignItems: 'center' }}
                >
                  <FaPlusCircle
                    color="#03a9f4"
                    size={30}
                    style={{ marginRight: "8px", marginBottom: "5px" }}
                  />
                  Selecteer een project
                </h5>
                {showDropdown && (
                  <div ref={dropdownRef} style={{ position: "absolute", top: "100%", left: "0", width: "500px", padding: "10px" }}>
                    <Dropdown.Menu show={showDropdown} style={{ display: "block", width: "100%", minWidth: "450px" }}>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group style={{ display: "flex", justifyContent: "center" }}>
                          <Select
                            value={selectedProject}
                            onChange={handleProjectChange}
                            options={groupedOptions}
                            placeholder="Selecteer project"
                            isClearable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                borderColor: state.isFocused ? "black" : "#ced4da",
                                boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : "none",
                                "&:hover": {
                                  borderColor: state.isFocused ? "black" : "#ced4da",
                                },
                                width: "450px",
                                minHeight: "40px",
                                justifyContent: "center",
                              }),
                              menu: (base) => ({
                                ...base,
                                color: "black",
                              }),
                              groupHeading: (base) => ({
                                ...base,
                                color: "#3b3b3b",
                              }),
                            }}
                          />
                        </Form.Group>
                        <Form.Group controlId="formTask" style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                          <div onClick={handleTaskClick}>
                            <Select
                              value={selectedTask}
                              onChange={handleTaskChange}
                              options={tasks}
                              placeholder="Selecteer taak"
                              isClearable
                              isDisabled={!selectedProject}  // Disable task selection if no project is selected
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  borderColor: state.isFocused ? "black" : "#ced4da",
                                  boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused ? "black" : "#ced4da",
                                  },
                                  width: "450px",
                                  minHeight: "40px",
                                  justifyContent: "center",
                                }),
                                menu: (base) => ({
                                  ...base,
                                  color: "black",
                                }),
                                groupHeading: (base) => ({
                                  ...base,
                                  color: "#3b3b3b",
                                }),
                              }}
                            />
                          </div>
                        </Form.Group>
                        <div style={{ display: "flex", justifyContent: "right" }}>
                          <Button
                            variant="success"
                            type="submit"
                            style={{ marginTop: "10px", width: "150px", height: "40px", marginRight: "20px" }}
                          >
                            <i className="fa fa-plus" /> Voeg toe
                          </Button>
                        </div>
                      </Form>
                    </Dropdown.Menu>
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '210px' }}>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                  <OverlayTrigger
                    key={day}
                    placement="bottom"
                    overlay={<Tooltip>Selecteer eerst een project of taak</Tooltip>}
                  >
                    <Form.Group style={{ marginRight: '5px' }}>
                      <Form.Control
                        onChange={(e) => handleHoursChange(day, e.target.value)}
                        placeholder={day.substring(0, 3)}
                        style={{ height: '40px', padding: '5px', width: '150px', position: 'relative' }}
                        min={0}
                        max={24}
                        step="0.1"
                        disabled
                      />
                    </Form.Group>
                  </OverlayTrigger>
                ))}
              </div>
            </ListGroup.Item>
            <ListGroup.Item variant="secondary">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "10px" }}>Totaal:</span>
                <span style={{ marginBottom: "5px" }}>
                  {calculateTotalHours()}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "10px" }}>Geselecteerde week:</span>
                <span>
                  {weekRange.start
                    ? weekRange.start.toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {weekRange.end ? weekRange.end.toLocaleDateString() : ""}
                </span>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Card>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginLeft: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="success" onClick={handleOpenModal} style={{ marginRight: "10px" }}><FaPaperPlane style={{ marginRight: '5px' }} /> Verstuur uren</Button>
        </div>
        <div>
          <DropdownButton variant="dark" id="dropdown-basic-button" title={<><FaCopy /> Kopieer vorige week</>}>
            <Dropdown.Item onClick={{}}>Kopieer projecten</Dropdown.Item>
            <Dropdown.Item onClick={{}}>Kopieer projecten en tijd</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter"
        centered show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Controleer je uren</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filledHoursList.map((filledHours, index) => (
            <div key={index}>
              <hr />
              <details>
                <summary><strong>({filledHours.projectCode}) {filledHours.projectName} </strong> - <dfn>{filledHours.projectTask}</dfn></summary>
                <ol style={{ marginTop: "5px" }}>
                  {Object.entries(filledHours.hours).map(([day, hours]) => (
                    <ol style={{ marginTop: "5px" }} key={day}>{`${day}: ${hours !== '' ? hours : '0'}`}</ol>
                  ))}
                </ol>
              </details>
              <p><strong>Uren in dit project: {calculateProjectTotalHours(filledHours)}</strong></p>
            </div>
          ))}
          <hr />
          <p><strong>Totale uren deze week: {calculateTotalHours()}</strong></p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            <i className="fa fa-close" /> Sluiten
          </Button>
          <Button variant="success" onClick={handleSendHours}>
            <i className="fa fa-paper-plane" /> Versturen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Timesheet;