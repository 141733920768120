import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, InputGroup, Overlay, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { BarLoader } from "react-spinners";
import apiCall from "../../../components/utils/apiCall";
import { useNavigate } from "react-router-dom";
import { countOpenPartlists } from "../../../components/wo/workorderUtils";
import useClickPermissionHandler from "../../../components/misc/ClickPermissionHandler";

export default function Werkplaats() {

    const navigate = useNavigate();
    const [partlists, setPartlists] = useState([]);
    const [filteredPartlists, setFilteredPartlists] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const data = await apiCall("wo/projects/get_open", "POST", {});
            const indexedData = data.map((item, index) => ({ ...item, index: index + 1 }));
            setPartlists(indexedData);
            setFilteredPartlists(indexedData); // Initialize filteredPartlists
            setLoading(false);
        })();
    }, []);

    const { handleExactPermission } = useClickPermissionHandler();

    const handlePNumberClick = (id) => {
        handleExactPermission(
            "Voor deze link moet u ingelogd zijn met een Exact Online account. Ga naar Profiel -> Exact Login om in te loggen.",
            `/wo/dashboard/details/${id}`
        );
    };

    const [inputValue, setInputValue] = useState('');
    const target = useRef(null);

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        // Filter partlists locally
        const filtered = partlists.filter(item => {
            // Check if the Code matches the input
            const codeMatches = item.Code.toLowerCase().includes(value.toLowerCase());

            // Check if any ShopOrderNumber matches the input
            const shopOrderNumberMatches = Object.values(item.workorders).some(workorder =>
                workorder.ShopOrderNumber.toString().includes(value)
            );

            return codeMatches || shopOrderNumberMatches;
        });

        setFilteredPartlists(filtered);
    };

    return (
        <Card>
            <Card.Header>
                <h1>Openstaande Werkorders</h1>
            </Card.Header>
            {loading ? (
                <Card.Body className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <BarLoader color='#007f6c' width={500} speedMultiplier={1} />
                </Card.Body>
            ) : (
                <Card.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="text-wrap">Specifiek Project Zoeken</InputGroup.Text>
                        <InputGroup.Text className="col-3 position-relative">
                            <input
                                type="text"
                                className={`form-control`}
                                placeholder="PXXXX of ShopOrderNumber"
                                value={inputValue}
                                onChange={handleChange}
                                ref={target}
                            />
                        </InputGroup.Text>
                    </InputGroup>
                    <Table striped hover>
                        <colgroup>
                            <col style={{ width: "5%" }} />
                            <col style={{ width: "5%" }} />
                            <col style={{ width: "50%" }} />
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "15%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">P</th>
                                <th>Beschrijving</th>
                                <th>Werkvoorbereider</th>
                                <th>Start / Eind Datum</th>
                                <th>Actie</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPartlists.length > 0 ? (
                                    filteredPartlists.map(item => {
                                        const openWorkorders = countOpenPartlists(item.workorders);
                                        const tooltipText = openWorkorders === 0
                                            ? "Dit project heeft momenteel geen open werkorders"
                                            : `Dit project heeft momenteel ${openWorkorders} open werkorder${openWorkorders > 1 ? 's' : ''}`;
                                        return (
                                            <tr key={item._id}>
                                                <td className="text-center">{item.index}</td>
                                                <td className="text-center" onClick={() => {
                                                    handlePNumberClick(item.Code);
                                                }}>{item.Code}</td>
                                                <td>{item.Description}</td>
                                                <td>{item.ClaimedByUser ? (item.ClaimedByUser) : ("Opnieuw Toewijzen!")}</td>
                                                <td><Badge>{item.StartDate} / {item.EndDate}</Badge></td>
                                                <td>
                                                    <Button variant="success" onClick={() => { navigate(`../../wo/${item.Code}`) }}>Bekijk Project</Button>
                                                    {" "}
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltipText}</Tooltip>} >
                                                        <Badge bg="warning" text="dark" >{openWorkorders}</Badge>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">Geen projecten of werkorders gevonden</td>
                                    </tr>
                                )}
                        </tbody>
                    </Table>
                </Card.Body>
            )}
        </Card>
    );
}
