import {Button, Col, Form, ListGroup, Placeholder, Row, Table} from "react-bootstrap";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import DataTable from "datatables.net-dt";
import {TwoFileCheck} from "../../../components/hesselToDo/Checkfiles";
import JSZip from "jszip";
import {saveAs} from "file-saver";

import {GetAPIurl} from "../../../components/hesselToDo/Data";

export default function PlusminLijst() {
  const [FileLoaded, setFileLoaded] = useState(false);
  const [Data, setData] = useState();

  useEffect(() => (
    window.onscroll = function () { scrollFunction() }
  ))

  const GoToTop = (e) => {
    e.preventDefault();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const GoToBottom = (e) => {
    e.preventDefault();
    document.body.scrollTop = document.body.scrollHeight + 2000000 * 99;
    document.documentElement.scrollTop = document.body.scrollHeight + 2000000 * 99;
  }
  return (
    <>
      <Row className="container-fluid">
        {Start(setFileLoaded, setData)}
        {Download(FileLoaded)}
        {TelLijst(FileLoaded, Data)}
      </Row>
      {TableHolder(FileLoaded, Data)}
      <Row id="gotobuttons" className="gotobuttons">
        <Col>
          <Button className="btn-floating" onClick={GoToTop} title="Ga naar boven">
            <i className="fas fa-arrow-up"></i>
          </Button>
        </Col>
        <Col>
          <Button variant="warning" className="btn-floating" onClick={GoToBottom} title="Ga naar beneden">
            <i className="fas fa-arrow-down"></i>
          </Button>
        </Col>
      </Row>
    </>
  );
}

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementById("gotobuttons").style.display = "block"
  } else {
    document.getElementById("gotobuttons").style.display = "none";
  }
}

function Start(setFileLoaded, setData) {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!TwoFileCheck(e.target.fileold.files, e.target.filenew.files, ["xlsx", "xls", "csv"])) return;

    var formData = new FormData();
    formData.append('fileold', e.target.fileold.files[0]);
    formData.append('filenew', e.target.filenew.files[0]);

    var myheaders = {
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: formData,
    };

    fetch(`${GetAPIurl()}/api/eplan/material/plusminlijsten/file`, req)
      .then((response) => response.json())
      .then((d) => {
        setData(d);
        setFileLoaded(true);
        document.getElementById("mainfooter").style.display = "none";
        toast.success("Gelukt!");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  }
  return (
    <Col>
      <h3>Materiaal plus-minlijst</h3>
      <span>
        Hier word de oude lijst met de nieuwe lijst vergeleken en op drie
        lijsten gezet plus min en verplaast.
      </span>
      <Form className="was-validated" onSubmit={handleSubmit}>
        <h6>Voer hier de oude bestand in:</h6>
        <Form.Group className="input-group mb-3">
          <label className="input-group-text btn btn-outline-primary" htmlFor="fileold">
            <i className="fas fa-cloud-arrow-up"></i>
          </label>
          <Form.Control className="btn btn-outline-primary" type="file" id="fileold" title="Oude bestand" accept=".xlsx, .xls, .csv" required />
        </Form.Group>
        <h6>Voer hier de nieuwe bestand in:</h6>
        <Form.Group className="input-group mb-3">
          <label className="input-group-text btn btn-outline-primary" htmlFor="filenew">
            <i className="fas fa-cloud-arrow-up"></i>
          </label>
          <Form.Control className="btn btn-outline-primary" type="file" id="filenew" title="Nieuwe bestand" accept=".xlsx, .xls, .csv" required />
        </Form.Group>
        <Button type="submit">Laden</Button>
      </Form>
    </Col>
  );
}

function confetti() {
  const duration = 5 * 1000,
    animationEnd = Date.now() + duration,
    defaults = { startVelocity: 30, spread: 360, ticks: 20, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const interval = setInterval(function () {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 20 * (timeLeft / duration);

    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      })
    );
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      })
    );
  }, 250);
}

function FileDownload(data, setDownload) {
  const zip = new JSZip();

  if (data['minlijst'] != "AQ==") {
    zip.file(`plusminlijsten/${data['date']}_${data['project']}_minLijst.${data['extension']}`, data['minlijst'], { base64: true })
  }

  if (data['pluslijst'] != "AQ==") {
    zip.file(`plusminlijsten/${data['date']}_${data['project']}_plusLijst.${data['extension']}`, data['pluslijst'], { base64: true })
  }

  if (data['verplaastlijst'] != "AQ==") {
    zip.file(`plusminlijsten/${data['date']}_${data['project']}_verplaastLijst.${data['extension']}`, data['verplaastlijst'], { base64: true })
  }

  if (Object.keys(zip.files).length < 1) {
    toast.error("Er waren geen verschillen.");
    confetti();
    setDownload(false);
    return;
  }

  zip.generateAsync({ type: "blob" })
    .then((content) => {
      saveAs(content, `${data['date']}_${data['project']}.zip`);
      setDownload(false);
    })
    .catch((error) => {
      toast.error("Ging wat fout bij downloaden.");
      console.error(error);
      setDownload(false);
    })
}

function Download(loaded) {
  const [download, setDownload] = useState(false);
  if (!loaded) {
    return (
      <Col>
        <Placeholder xs={8} />
        <Placeholder xs={8} />
        <Placeholder xs={8} />
        <Placeholder xs={8} />
      </Col>
    );
  }

  const handleXLSX = (e) => {
    e.preventDefault();
    setDownload(true);
    var req = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    };
    fetch(`${GetAPIurl()}/api/eplan/material/plusminlijsten/zip?extension=xlsx`, req)
      .then((reponse) => reponse.json())
      .then(d => {
        FileDownload(d, setDownload);
      })
      .catch((error) => console.error(error));
  };

  const handleXLS = (e) => {
    e.preventDefault();
    setDownload(true);
    var req = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    };
    fetch(`${GetAPIurl()}/api/eplan/material/plusminlijsten/zip?extension=xls`, req)
      .then((reponse) => reponse.json())
      .then(d => {
        FileDownload(d, setDownload);
      })
      .catch((error) => console.error(error));
  };

  const handleCSV = (e) => {
    e.preventDefault();
    setDownload(true);
    var req = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      }
    };
    fetch(`${GetAPIurl()}/api/eplan/material/plusminlijsten/zip?extension=csv`, req)
      .then((reponse) => reponse.json())
      .then(d => {
        FileDownload(d, setDownload);
      })
      .catch((error) => console.error(error));
  };

  const SwitchWeergave = (e) => {
    e.preventDefault();

    document.getElementById('custom-grid').classList.toggle('rij');
    document.getElementById('custom-grid').classList.toggle('one-grid');
    document.getElementById('sectie').classList.toggle('sectie');

    SetDatable("TablePlustable");
    SetDatable("TableMintable");
    SetDatable("TableVertable");
  }

  return (
    <Col>
      <ListGroup>
        <Button className="list-group-item" title="Sla bestanden op als Excel XLSX" disabled={download} onClick={handleXLSX}>
          <i className="fas fa-floppy-disk"></i>
          Bestand(en) opslaan in Excel XLSX 2007+
        </Button>
        <Button className="list-group-item" title="Sla bestanden op als Excel XLS" disabled={download} onClick={handleXLS}>
          <i className="fas fa-floppy-disk"></i>
          Bestand(en) opslaan in Excel XLS 2003
        </Button>
        <Button className="list-group-item" title="Sla bestanden op als CSV" disabled={download} onClick={handleCSV}>
          <i className="fas fa-floppy-disk"></i>
          Bestand(en) opslaan in CSV
        </Button>
        <Button
          className="list-group-item"
          onClick={SwitchWeergave}
          title="Verander de weergave van horizontaal en verticaal."
        >
          <i className="fas fa-display"></i>
          Weergave veranderen
        </Button>
      </ListGroup>
    </Col>
  );
}

function TelLijst(loaded, data) {
  if (!loaded) {
    return (
      <Col>
        <Placeholder xs={8} />
        <Placeholder xs={8} />
        <Placeholder xs={8} />
        <Placeholder xs={8} />
        <Placeholder xs={8} />
      </Col>
    );
  }
  var origi_count = data.original.reduce((accumulator, item) => accumulator + item.Quantity, 0);
  var comp_count = data.compare.reduce((accumulator, item) => accumulator + item.Quantity, 0);
  var plus_count = data.plus.reduce((accumulator, item) => accumulator + item.Quantity, 0);
  var min_count = data.min.reduce((accumulator, item) => accumulator + item.Quantity, 0);
  var verand_count = data.veranderd.reduce((accumulator, item) => accumulator + item.Quantity, 0);
  return (
    <Col>
      <span>De oude lijst: {origi_count} onderdelen</span>
      <br />
      <span>De nieuwe lijst: {comp_count} onderdelen</span>
      <br />
      <span>De plus lijst: {plus_count} onderdelen</span>
      <br />
      <span>De min lijst: {min_count} onderdelen</span>
      <br />
      <span>De verplaast lijst: {verand_count} onderdelen</span>
    </Col>
  );
}

function SetDatable(id) {
  if (DataTable.isDataTable(`#${id}`)) return;
  let table = new DataTable(`#${id}`, {
    lengthMenu: [-1, 1000, 100, 50],
    language: {
      search: "Zoeken:",
      info: "Toont pagina _PAGE_ van _PAGES_ | Van _START_ tot _END_ van totaal _TOTAL_ aantal.",
      lengthMenu: "Toont _MENU_ aantal",
      zeroRecords: "Geen bijpassende records gevonden",
      paginate: {
        next: "Volgende",
        previous: "Vorige"
      }
    }
  });
}

function TableHolder(FileLoaded, data) {
  if (!FileLoaded || !data) {
    return;
  }

  setTimeout(() => {
    SetDatable("TablePlustable");
    SetDatable("TableMintable");
    SetDatable("TableVertable");
  }, 1000);
  return (
    <div id="custom-grid" className="rij custom-grid">
      <div id="sectie" className="sectie">
        <h3 className="text-center">Plus lijst</h3>
        <div id="TablePlus">{Tafel(data.plus, "TablePlustable")}</div>
      </div>
      <div id="sectie" className="sectie">
        <h3 className="text-center">Min lijst</h3>
        <div id="TableMin">{Tafel(data.min, "TableMintable")}</div>
      </div>
      <div id="sectie" className="sectie">
        <h3 className="text-center">Verplaatst lijst</h3>
        <div id="TableVer">{Tafel(data.veranderd, "TableVertable")}</div>
      </div>
    </div>
  );
}

function Tafel(data, idinfo) {
  if (!data || !data.length > 0) {
    return (
      <h5 className="text-center text-warning">Leeg</h5>
    )
  }
  return (
    <Table id={idinfo} striped bordered responsive>
      <colgroup>
        <col />
      </colgroup>
      <thead>
        <tr>
          <th>ID</th>
          <th>ItemCode</th>
          <th>Inbouwplaats</th>
          <th>Quantity</th>
          <th>Materialcode</th>
          <th>Notes</th>
          <th>Fabrikant</th>
          <th>Bestelnummer</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.ID}>
            <td>{item.ID}</td>
            <td>{item.ItemCode}</td>
            <td>{item.Inbouwplaats}</td>
            <td>{item.Quantity}</td>
            <td>{item.MaterialCode}</td>
            <td>{item.Notes}</td>
            <td>{item.Fabrikant}</td>
            <td>{item.Bestelnummer}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
