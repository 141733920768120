import { Button, Form, InputGroup, Offcanvas } from "react-bootstrap";
import React from "react";

/**
 * README AddItemToProjectMetadata
 *
 * AddProperty.jsx is een React component bestand dat de hoofdcomponent AddProperty bevat.
 * Deze component wordt gebruikt om nieuwe items toe te voegen aan een type lijst voor een specifiek project.
 *
 * =====================================================================================================================
 *
 * AddProperty:
 * Deze component wordt gebruikt om nieuwe items toe te voegen aan een type lijst.
 * Het biedt een gebruikersinterface voor het invoeren van de eigenschapswaarden en het toevoegen van de nieuwe item aan de type lijst.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het AddProperty.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * De props omvatten de huidige kolommen, apparaten, kasten, de functie om de toevoeging van de nieuwe item te verwerken, en andere gerelateerde gegevens en functies.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een newProperty object dat de gegevens van de nieuwe item bevat, en een handleAddRow functie die de toevoeging van de nieuwe item verwerkt.
 *
 */

/**
 *
 * @param columns
 * @param devices
 * @param cabinets
 * @param showAddProperty
 * @param setShowAddProperty
 * @param type
 * @param fullwidth
 * @param setFullwidth
 * @param newProperty
 * @param setNewProperty
 * @param handleAddRow
 * @returns {Element}
 * @constructor
 */
const AddProperty = ({
    columns,
    devices,
    cabinets,
    showAddProperty,
    setShowAddProperty,
    type,
    fullwidth,
    setFullwidth,
    newProperty,
    setNewProperty,
    handleAddRow,
}) => {
    return (
        <Offcanvas show={showAddProperty} onHide={() => setShowAddProperty(!showAddProperty)} placement='end' style={{ width: fullwidth }}>
            <Offcanvas.Header closeButton>
                <i className="fa fa-arrows-alt fa-2x fullscreen"
                    onClick={() => fullwidth === "50vw" ? setFullwidth("100vw") : setFullwidth("50vw")} />
            </Offcanvas.Header>
            <Offcanvas.Body>
                <h4>Voeg {type === "data_equipmentlist" ? "Equipment" :
                    type === "data_devicelist" ? "Device" :
                        type === "data_cabinetlist" ? "Cabinet" : null} toe aan {type === "data_equipmentlist" ? "Equipment" :
                            type === "data_devicelist" ? "Device" :
                                type === "data_cabinetlist" ? "Cabinet" : null} List</h4>
                <Button className="m-1" variant="primary" onClick={() => handleAddRow()}>Voeg Property toe</Button>
                <Button className="m-1" variant="danger" onClick={() => setShowAddProperty(false)}>Annuleren</Button>
                <hr />
                <Form>
                    {columns.map((column, index) => (
                        column.Metadata.Type === "open" ?
                            <InputGroup className="mb-3" key={index}>
                                <InputGroup.Text className="inputgroup-text">{column.Name}</InputGroup.Text>
                                <Form.Control type={column.Name === "Aantal" ? "number" : "text"} onChange={(e) => setNewProperty({ ...newProperty, [column.Name]: e.target.value })} />
                            </InputGroup>
                            : column.Metadata.Type === "multi" ?
                                <InputGroup className="mb-3" key={index}>
                                    <InputGroup.Text className="inputgroup-text">{column.Name}</InputGroup.Text>
                                    <Form.Select key={index} onChange={(e) => setNewProperty({ ...newProperty, [column.Name]: e.target.value })}>
                                        <option value=""> Selecteer een optie</option>
                                        {column.Metadata.AnswerOptions.map((option, index) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}

                                        {column.Name.includes("Cabinet") &&
                                            cabinets.map((cabinet, index) => (
                                                <option key={cabinet.Name} value={cabinet.Name}>{cabinet.Name}</option>
                                            ))
                                        }

                                        {column.Name.includes("CabinName") &&
                                            cabinets.map((cabinet, index) => (
                                                <option key={cabinet.Name} value={cabinet.Name}>{cabinet.Name}</option>
                                            ))
                                        }

                                        {column.Name.includes("Machine") &&
                                            devices.map((device, index) => (
                                                <option key={device.Name} value={device.Name}>{device.Name}</option>
                                            ))
                                        }

                                    </Form.Select>
                                </InputGroup>
                                : column.Metadata.Type === "checkbox" ?
                                    <InputGroup className="mb-3" key={index}>
                                        <InputGroup.Text className="inputgroup-text">{column.Name}</InputGroup.Text>
                                        <Form.Check className="mb-3" key={index} type="checkbox" label={column.Name} onChange={(e) => setNewProperty({ ...newProperty, [column.Name]: e.target.checked })} />
                                    </InputGroup>
                                    : null
                    ))}
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default AddProperty;