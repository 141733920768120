import {Button, Card, Col, Form, Table} from "react-bootstrap";
import {useState} from "react";
import {DeleteClockifyProject, useClockifyQuery} from "../../../components/hesselToDo/Clockify";
import {usePagination} from "../../../components/managers/PaginationManager";

export default function Main() {
  const [query, setQuery] = useState("");

  return (
    <>
      <Col>
        <Card>
          <Card.Header>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 id="tableLabel" style={{ marginRight: "10px" }}>
                  Projecten
                </h1>
                <Button variant="success" href="/clockify/projects/create">
                  <i className="fas fa-plus"></i>
                </Button>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  type="text"
                  name="SearchString"
                  placeholder="Zoeken..."
                  onChange={(e) => setQuery(e.target.value)}
                  style={{ marginRight: "10px" }}
                />
              </div>
            </div>
          </Card.Header>
          <Card.Body>{GetData(query)}</Card.Body>
        </Card>
      </Col>
    </>
  );
}

function Search(data, keyword) {
  const re = new RegExp(keyword, "i");
  return data.filter((entry) =>
    Object.values(entry).some((val) => typeof val === "string" && val.match(re))
  );
}

function GetData(query) {
  const { data, error, loading } = useClockifyQuery("projects?page-size=5000");
  if (error) return <div className="text-danger">Laden mislukt</div>;
  if (loading) return <div className="text-primary">Bezig met laden...</div>;
  const filterd = Search(data, query);
  return <Tafel data={filterd} />;
}

const Tafel = ({ data }) => {
  if (!data[0]) return <h4>Geen data</h4>;
  const { currentItems, AboveTable, BelowTable, indexOfFirstItem } = usePagination(data);
  return (
    <div>
      {AboveTable}
      <Table striped hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Naam</th>
            <th>Klant</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((project, index) => (
            <tr key={index} id={"listitem" + project.id} onClick={() => Details(project.id)}>
              <td>{indexOfFirstItem + index + 1}</td>
              <td>{project.name}</td>
              <td>{project.clientName}</td>
              <td><Button variant="danger" onClick={() => DeleteClockifyProject(project.id)}><i className="fas fa-trash"></i></Button></td>
            </tr>
          ))}
        </tbody>
      </Table>
      {BelowTable}
    </div>
  );
};

function Details(id) {
  window.location = `/clockify/projects/details/${id}`;
}