import React from 'react';
import {format, parseISO} from "date-fns";

export default function DateManager({ dateString }) {
  if (!dateString) return null; 
  const date = parseISO(dateString);
  return <time dateTime={dateString}>{format(date, "LLLL d, yyyy")}</time>;
}

export function JSONDateToString(dateString) {
  try {
    if (dateString.match(/^\d{2}-\d{2}-\d{4}$/)) {
      return dateString;
    }

    const timestampMatch = dateString.match(/\d+/);
    if (!timestampMatch) {
      return "N/A";
    }

    const timestamp = parseInt(timestampMatch[0], 10);
    const date = new Date(timestamp);

    return date.toLocaleDateString('nl-NL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  } catch (error) {
    return "N/A";
  }
}

export function TimeISO(ISOstring) {
  const formated = ISOstring.split("T")[1];
  return formated;
}
export function DateISO(ISOstring) {
  const formated = ISOstring.split("T")[0];
  return formated;
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); 
};