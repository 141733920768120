import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";

import {GetAPIurl} from "../../../components/hesselToDo/Data";

export default function Create() {
  const [query, setQuery] = useState("");
  return (
    <>
      <Card>
        <Card.Header>
          <h4>Onderdeel/Unifeed - Aanmaken</h4>
        </Card.Header>
        <Card.Body>
          <Form.Control type="text" className="mb-3" name="SearchString" placeholder="Zoeken..." onChange={(e) => setQuery(e.target.value)} />
          {Search(query)}
        </Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" href="/materials/unifeed">
            Terug
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

function Search(query) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetAllData(query);
  }, [query])

  const GetAllData = (SearchString) => {
    fetch(`${GetAPIurl()}/api/unifeed/products/search?search=${SearchString}`)
      .then((response) => response.json())
      .then(res => {
        setData(res)
        setLoading(false)
      })
      .catch((error) => console.error(error));
  };


  if (loading) return <div>Bezig met laden...</div>;
  if (!data) return <div>Geen data</div>;
  if (!data.totalFound > 0) return <div>Geen gevonden producten</div>;
  return (
    <Tafel context={data} />
  )
}

const Tafel = ({ context }) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const raw = JSON.stringify({
      id: Number(e.target[0].value),
      materialcode: e.target[1].value,
      ordernumber: e.target[2].value,
      description: e.target[3].value,
      productcode: e.target[4].value,
      manufacturergln: e.target[5].value,
      gtin: e.target[6].value,
      imageurl: e.target[7].value,
    })

    console.log(raw);

    var myheaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: raw,
    };

    fetch(`${GetAPIurl()}/api/unifeed/products`, req)
      .then((d) => {
        if (d.status === 201) {
          toast.success("Gelukt!");
        } else {
          toast.error("Gefaald");
        }
        return d.json();
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  }

  return (
    <div>
      <h5>Gevonden: {context.totalFound}</h5>
      <div>
        {context.products.map((keuze) => (
          <Row key={keuze.id}>
            <Col className="col-sm-2">
              <img src={`https://api.2ba.nl/1/json/Thumbnail/Product?gln=${keuze.manufacturerGLN}&productcode=${keuze.productcode}`} width="100px" height="100px" />
            </Col>
            <Col className="col-sm-8">
              <Row>
                <Col className="col-xl-12">
                  {keuze.manufacture} {keuze.model} {keuze.version}
                </Col>
                <dt className="col-sm-2">
                  GTIN:
                </dt>
                <dd className="col-sm-10">
                  {keuze.gtin}
                </dd>
                <dt className="col-sm-2">
                  GLN:
                </dt>
                <dd className="col-sm-10">
                  {keuze.manufacturerGLN}
                </dd>
                <dt className="col-sm-2">
                  ProductCode:
                </dt>
                <dd className="col-sm-10">
                  {keuze.productcode}
                </dd>
                <dt className="col-sm-2">
                  Beschrijving:
                </dt>
                <dd className="col-sm-10">
                  {keuze.description}
                </dd>
              </Row>
            </Col>
            <Col className="col-sm-2">
              <Form onSubmit={handleSubmit}>
                <Form.Control type="text" defaultValue={keuze.id} hidden />
                <Form.Control type="text" defaultValue={keuze.materialCode} hidden />
                <Form.Control type="text" defaultValue={keuze.orderNumber} hidden />
                <Form.Control type="text" defaultValue={keuze.description} hidden />
                <Form.Control type="text" defaultValue={keuze.productCode} hidden />
                <Form.Control type="text" defaultValue={keuze.manufacturerGLN} hidden />
                <Form.Control type="text" defaultValue={keuze.gtin} hidden />
                <Form.Control type="text" defaultValue={`https://api.2ba.nl/1/json/Thumbnail/Product?gln=${keuze.manufacturerGLN}&productcode=${keuze.productcode}`} hidden />
                <Button variant="success" type="submit">
                  Toevoegen
                </Button>
              </Form>
            </Col>
            <hr />
          </Row>
        ))}
      </div>
    </div>
  )
}