import {Accordion, Button, ButtonGroup, Col, Container, Form, Row, Table} from "react-bootstrap";
import toast from "react-hot-toast";

import {GetAPIurl, useQuery} from "../../components/hesselToDo/Data";
import {useState} from "react";

export default function List() {
  return (
    <>
      <Container fluid>
        <Row>
          <Accordion defaultActiveKey={["0"]} flush>
            {GTIN()}
            {ArtikelDelete()}
            {BestelExport()}
            {FabrikWissel()}
          </Accordion>
        </Row>
      </Container>
    </>
  );
}

function SubmitArtikel(body, path, code) {
  var req = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  };
  fetch(`${GetAPIurl()}/api/eplan/${path}`, req)
    .then((response) => {
      if (response.status == code) {
        toast.success("Gelukt!");
      }
      else {
        toast.error("Mislukt!");
      }
    })
    .catch((error) => {
      toast.error("Ging iets fout met het toevoegen.")
      console.error(error)
    })
}

function EditArtikel(body, path, id, code) {
  var req = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  };
  fetch(`${GetAPIurl()}/api/eplan/${path}/${id}`, req)
    .then((response) => {
      if (response.status == code) {
        toast.success("Gelukt!");
      }
      else {
        toast.error("Mislukt!");
      }
    })
    .catch((error) => {
      toast.error("Ging iets fout met bestelexports aanpassen.")
      console.error(error)
    })
}

function DeleteArtikel(path, id, objectid, code) {
  var req = {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(`${GetAPIurl()}/api/eplan/${path}/${id}`, req)
    .then((response) => {
      if (response.status == code) {
        toast.success("Gelukt!");
        document.getElementById(`${objectid}${id}`).remove();
      }
      else {
        toast.error("Mislukt!");
      }
    })
    .catch((error) => {
      toast.error("Ging iets fout met het verwijderen.")
      console.error(error)
    })
}

function GTIN() {
  const { data, error, loading } = useQuery("eplan/gtinmeldings");
  if (error) return <a className="text-danger">Laden mislukt</a>;
  if (loading) return <a className="text-primary">Bezig met laden...</a>;

  const handleGTIN = (e) => {
    e.preventDefault();
    const body = {
      id: 1,
      melding: e.target.checked
    };
    EditArtikel(body, "gtinmeldings", 1, 204);
  }
  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>GTIN bij error melding</Accordion.Header>
      <Accordion.Body>
        <span>
          Deze optie zet bij aan de GTIN bij de geweigerde items als hij leeg is
          zoals de MaterialCode.
        </span>
        <Col>
          <Form.Check type="switch" label="GTN leeg melding" defaultChecked={data[0].melding} onChange={handleGTIN} />
        </Col>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function ArtikelDelete() {
  const { data, error, loading } = useQuery("eplan/artikelverwijders");
  if (error) return <a className="text-danger">Laden mislukt</a>;
  if (loading) return <a className="text-primary">Bezig met laden...</a>;

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      artikel: e.target[1].value
    };
    SubmitArtikel(body, "artikelverwijders", 201);
  }

  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>Materiaal artikel verwijder</Accordion.Header>
      <Accordion.Body>
        <span>
          Deze lijst als één van de artikelen wordt gevonden bij de material
          converter wordt deze uit de lijst gehaald, ook bij importartikel exact. Er wordt gekeken naar MaterialCode.
        </span>
        <Form className="was-validated mb-3" onSubmit={handleSubmit}>
          <Row>
            <Col sm="1"><Button type="submit" variant="success">Toevoegen</Button></Col>
            <Col sm="3"><Form.Control type="text" placeholder="MaterialCode" required /></Col>
          </Row>
        </Form>
        <Table striped bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Artikel</th>
              <th style={{ width: "150px" }}>Acties</th>
            </tr>
          </thead>
          <tbody>
            {data.map((artikel) => (
              <tr key={artikel.id} id={`ArtikelVerwijder${artikel.id}`}>
                <td>{artikel.id}</td>
                <td>{artikel.artikel}</td>
                <td>
                  <Button type="submit" variant="danger" onClick={(e) => DeleteArtikel("artikelverwijders", artikel.id, "ArtikelVerwijder", 204)}>
                    <i className="fas fa-trash-can"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function BestelExport() {
  const [editing, setEditing] = useState();
  const { data, error, loading } = useQuery("eplan/bestelexports");
  if (error) return <a className="text-danger">Laden mislukt</a>;
  if (loading) return <a className="text-primary">Bezig met laden...</a>;

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      fabrikant: e.target[0].value,
      header: e.target[2].value,
      input: e.target[3].value,
      hasheader: e.target[1].checked,
      hastimeformat: e.target[4].value,
      hasseparator: e.target[5].value,
      download: e.target[6].value
    };
    SubmitArtikel(raw, "bestelexports", 201);
  }

  const handleBestelEdit = (id) => {
    if (!editing) {
      const item = document.getElementById(`editbestelexport${id}`).querySelectorAll('td');
      item[1].innerHTML = `<input type="text" value="${item[1].textContent}" />`;
      item[2].querySelector('#checkheader').disabled = false;
      item[3].innerHTML = `<input type="text" value="${item[3].textContent}" />`;
      item[4].innerHTML = `<input type="text" value="${item[4].textContent}" />`;
      item[5].innerHTML = `<input type="text" value="${item[5].textContent}" />`;
      item[6].innerHTML = `<input type="text" value="${item[6].textContent}" />`;
      item[7].innerHTML = `<input type="text" value="${item[7].textContent}" />`;
      setEditing(id);
      return
    }
    else if (editing != id) {
      toast.error("Je kan maar één tegelijk aanpassen.");
      return;
    }
    else if (editing == id) {
      const item = document.getElementById(`editbestelexport${id}`).querySelectorAll('td');
      item[1].innerHTML = `${item[1].querySelector('input').value}`;
      item[2].querySelector('#checkheader').disabled = true;
      item[3].innerHTML = `${item[3].querySelector('input').value}`;
      item[4].innerHTML = `${item[4].querySelector('input').value}`;
      item[5].innerHTML = `${item[5].querySelector('input').value}`;
      item[6].innerHTML = `${item[6].querySelector('input').value}`;
      item[7].innerHTML = `${item[7].querySelector('input').value}`;


      const raw = {
        id: id,
        fabrikant: item[1].textContent,
        hasheader: item[2].querySelector('#checkheader').checked,
        header: item[3].textContent,
        input: item[4].textContent,
        hastimeformat: item[5].textContent,
        hasseparator: item[6].textContent,
        download: item[7].textContent
      };
      EditArtikel(raw, "bestelexports", id, 204)
      setEditing();
      return;
    }
  }

  return (
    <Accordion.Item eventKey="2">
      <Accordion.Header>Fabrikant bestel exports</Accordion.Header>
      <Accordion.Body>
        <span>
          Deze optie geeft de mogelijkheid om bij de Exact de exports naar
          fabrikanten bestellingen in te stellen als zij een header hebben of
          niet.
        </span>
        <Form className="was-validated mb-3" onSubmit={handleSubmit}>
          <Col sm="3" className="mb-3">
            <h5>Fabrikant</h5>
            <Form.Control type="text" placeholder="Fabrikant" required />
          </Col>
          <Col sm="1" className="mb-3">
            <h5>Heeft header</h5>
            <Form.Check type="switch" defaultChecked />
          </Col>
          <Row>
            <Col sm="6" className="mb-3">
              <h5>Header</h5>
              <Form.Control type="text" placeholder="Productname|quantity|Project" required />
            </Col>
            <Col sm="6" className="mb-3">
              <h5>Input</h5>
              <Form.Control type="text" placeholder="Item|Quantity|Projectnummer" required />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="mb-3">
              <h5>Tijd Format</h5>
              <Form.Control type="text" placeholder="MM/dd/yyyy" required />
              <Form.Control.Feedback type="invalid">Gebruik: MM/dd/yyyy = MM voor maand, dd voor dag en yyyy voor jaar.</Form.Control.Feedback>
            </Col>
            <Col sm="4" className="mb-3">
              <h5>Scheider</h5>
              <Form.Control type="text" placeholder="Separator" required />
            </Col>
            <Col sm="4" className="mb-3">
              <h5>Bestandstype</h5>
              <Form.Select required>
                <option value="">Kies een download</option>
                <option value={"all"}>alle</option>
                <option value={"xlsx"}>xlsx</option>
                <option value={"xls"}>xls</option>
                <option value={"csv"}>csv</option>
              </Form.Select>
            </Col>
          </Row>
          <Button type="submit" variant="success">Toevoegen</Button>
        </Form>
        <Table striped bordered>
          <thead>
            <tr>
              <th style={{ width: "50px" }}>#</th>
              <th style={{ width: "200px" }}>Fabrikant</th>
              <th style={{ width: "50px" }}>Export</th>
              <th>Header</th>
              <th>Input</th>
              <th style={{ width: "150px" }}>Format</th>
              <th style={{ width: "50px" }}>Separator</th>
              <th style={{ width: "50px" }}>Type</th>
              <th style={{ width: "150px" }}>Acties</th>
            </tr>
          </thead>
          <tbody>
            {data.map((fabrik) => (
              <tr key={fabrik.id} id={`editbestelexport${fabrik.id}`}>
                <td>{fabrik.id}</td>
                <td>{fabrik.fabrikant}</td>
                <td><Form.Check id="checkheader" type="switch" disabled defaultChecked={fabrik.hasheader} /></td>
                <td>{fabrik.header}</td>
                <td>{fabrik.input}</td>
                <td>{fabrik.hastimeformat}</td>
                <td>{fabrik.hasseparator}</td>
                <td>{fabrik.download}</td>
                <td>
                  <ButtonGroup>
                    <Button type="submit" variant="warning" onClick={(e) => handleBestelEdit(fabrik.id)}><i className="fas fa-pen"></i></Button>
                    <Button type="submit" variant="danger" onClick={(e) => DeleteArtikel("bestelexports", fabrik.id, "editbestelexport", 204)}><i className="fas fa-trash-can"></i></Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function FabrikWissel() {
  const [editing, setEditing] = useState();
  const { data, error, loading } = useQuery("eplan/switchfabrikants");
  if (error) return <a className="text-danger">Laden mislukt</a>;
  if (loading) return <a className="text-primary">Bezig met laden...</a>;

  const itemarray = ["ItemCode", "Inbouwplaats", "MaterialCode", "Quantity", "Notes", "Drawing", "Calculator_Type", "Fabrikant", "Bestelnummer", "GTIN", "Product_hoofdgroep", "Bestelllen_Leveren"]
  const handleSubmit = (e) => {
    e.preventDefault();
    var selected = [];
    const buttonsContainer = document.getElementById('addfabrikwissbtns');
    const greenButtons = buttonsContainer.querySelectorAll('button.groen');
    if (greenButtons.length !== 2) {
      toast.error("Je moet en mag niet meer of minder dan 2 gegevens wisselen.");
      return;
    }
    const selectedButtonsText = Array.from(greenButtons).map((button) => button.textContent);
    selected.push(selectedButtonsText[0]);
    selected.push(selectedButtonsText[1]);

    const raw = {
      fabrikant: e.target[0].value,
      firstitem: selected[0],
      seconditem: selected[1]
    };
    SubmitArtikel(raw, "switchfabrikants", 201);
  }
  const handleWisselEdit = (id) => {
    if (!editing) {
      const item = document.getElementById(`editfabrikwissel${id}`);
      const btns = item.getElementsByClassName(`btn-group`)[0].querySelectorAll('button');
      btns.forEach(element => {
        element.disabled = false;
      });
      const fab = item.querySelector('#fab');
      fab.innerHTML = `<input type="text" id="editfab" value="${fab.textContent}" />`;
      setEditing(id);
      return
    }
    else if (editing != id) {
      toast.error("Je kan maar één tegelijk aanpassen.");
      return;
    }
    else if (editing == id) {
      const item = document.getElementById(`editfabrikwissel${id}`);
      const btngroep = item.getElementsByClassName(`btn-group`)[0];
      const btns = btngroep.querySelectorAll('button');
      btns.forEach(element => {
        element.disabled = true;
      });
      const fab = item.querySelector('#fab');
      const fabedit = item.querySelector('#editfab');
      const savefabrikant = fabedit.value;
      fab.innerHTML = `${savefabrikant}`;
      const selectedButtonsText = Array.from(btngroep.querySelectorAll('button.groen')).map((button) => button.textContent);

      const raw = {
        id: id,
        fabrikant: savefabrikant,
        firstitem: selectedButtonsText[0],
        seconditem: selectedButtonsText[1]
      };
      EditArtikel(raw, "switchfabrikants", id, 204);
      setEditing();
      return;
    }
  }
  const handleSetSwitch = (e) => {
    e.preventDefault();
    e.target.classList.toggle('groen');
  }
  return (
    <Accordion.Item eventKey="3">
      <Accordion.Header>Fabrikant gegevens wisselen</Accordion.Header>
      <Accordion.Body>
        <span>
          Deze optie verwisseld de aangegevens plekken met elkaar in de
          converter.
        </span>
        <Form className="was-validated mb-3" onSubmit={handleSubmit}>
          <Col sm="3" className="mb-3">
            <h5>Fabrikant</h5>
            <Form.Control type="text" placeholder="Fabrikant" required />
          </Col>
          <Col className="mb-3">
            <ButtonGroup id="addfabrikwissbtns">
              {itemarray.map((head) => (
                <Button key={Math.random()} variant="secondary" onClick={handleSetSwitch}>{head}</Button>
              ))}
            </ButtonGroup>
          </Col>
          <Col className="mb-3">
            <Button type="submit" variant="success">Toevoegen</Button>
          </Col>
        </Form>
        <Table striped bordered>
          <thead>
            <tr>
              <th style={{ width: "50px" }}>#</th>
              <th style={{ width: "100px" }}>Fabrikant</th>
              <th>Wisseld</th>
              <th style={{ width: "120px" }}>Acties</th>
            </tr>
          </thead>
          <tbody>
            {data.map((gegeven) => (
              <tr key={gegeven.id} id={`editfabrikwissel${gegeven.id}`}>
                <td>{gegeven.id}</td>
                <td id="fab">{gegeven.fabrikant}</td>
                <td>
                  <ButtonGroup>
                    {itemarray.map((head, index) => (
                      <Button key={index} variant="secondary" className={`${gegeven.firstitem == head ? "groen" : ""}${gegeven.seconditem == head ? "groen" : ""}`} onClick={handleSetSwitch} disabled>{head}</Button>
                    ))}
                  </ButtonGroup>
                </td>
                <td>
                  <ButtonGroup>
                    <Button type="submit" variant="warning" onClick={(e) => handleWisselEdit(gegeven.id)}><i className="fas fa-pen"></i></Button>
                    <Button type="submit" variant="danger" onClick={(e) => DeleteArtikel("switchfabrikants", gegeven.id, "editfabrikwissel", 204)}><i className="fas fa-trash-can"></i></Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
}