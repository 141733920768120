import React from 'react';
import { Button } from 'react-bootstrap';
import { apiCallRaw } from './apiCall';

const StandaloneProjectDatasheet = ({project}) => {
  const downloadPDF = async () => {
    try {
        const response = await apiCallRaw(
            "project/generate_standalone_project_datasheet",
            "POST",
            {
              project_id: project.ID
            }
        );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `project_bon_${project.Code}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        className="mx-2 py-2 px-3"
        onClick={() => {
            downloadPDF(project.ID);
        }}
      >
        Bon
      </Button>
    </>
  );
};

export default StandaloneProjectDatasheet;
