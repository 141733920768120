import {Button, ButtonGroup, Card, Col, FloatingLabel, Form, Row,} from "react-bootstrap";
import toast from "react-hot-toast";

import {GetAPIurl, useQueryID} from "../../../../components/hesselToDo/Data";

export async function getServerSideProps(context) {
  const id = context.params.id;
  return { props: { id } };
}

export default function Project({ id }) {
  return (
    <>
      <Card>
        <Card.Header>
          <h4>Details - {id}</h4>
        </Card.Header>
        <Card.Body>{FormDetails(id)}</Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" href="/materials">
            Terug
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

function FormDetails(id) {
  const { data, error, loading } = useQueryID("unifeed/products", id);
  if (error) return <div className="text-danger">Laden mislukt</div>;
  if (loading) return <div className="text-primary">Bezig met laden...</div>;
  const Delete = async (e) => {
    e.preventDefault();

    var req = {
      method: "DELETE",
      body: data.id,
    };

    fetch(`${GetAPIurl()}/api/unifeed/products/${data.id}`, req)
      .then((d) => {
        if (d.status === 204) {
          toast.success("Gelukt!");
          window.location.replace("/materials/unifeed");
        } else {
          toast.error("Gefaald");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  };

  return (
    <div>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <img src={data.imageURL} height={100} width={100} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="ID">
              <Form.Control type="text" id="id" defaultValue={data.id} readOnly />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="MateriaalCode">
              <Form.Control type="text" id="materialCode" defaultValue={data.materialCode} readOnly />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="ProductCode">
              <Form.Control type="text" id="productCode" defaultValue={data.productCode} readOnly />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="Bestelnummer">
              <Form.Control type="text" id="orderNumber" defaultValue={data.orderNumber} readOnly />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="description">
              <Form.Control type="text" id="description" defaultValue={data.description} readOnly />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="manufacturerGLN">
              <Form.Control type="text" id="manufacturerGLN" defaultValue={data.manufacturerGLN} readOnly />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="gtin">
              <Form.Control type="text" id="gtin" defaultValue={data.gtin} readOnly />
            </FloatingLabel>
          </Form.Group>
        </Row>
      </Form>
      <Col sm="1">
        <ButtonGroup>
          <Button variant="warning" href={`/materials/unifeed/edit/${id}`}>
            <i className="fas fa-pen"></i>
          </Button>
          <Button variant="danger" onClick={Delete}>
            <i className="fas fa-trash-can"></i>
          </Button>
        </ButtonGroup>
      </Col>
    </div>
  );
}
