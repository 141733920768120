// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/background-pattern.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/loginscreen.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_loginscreen__HgEYF {
  position: absolute;
  /* background: url("../../public/loginscreen.svg") center no-repeat; */
  /* background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("../../public/background-pattern.jpg") center no-repeat; */
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  width: 100%;
  height: 100%;
}

.login_cardTransDark__PdbnS {
  /* background-color: rgba(0, 0, 0, 0.512); */
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center no-repeat;
  background-size: cover;
  /* background-position: center; */
  box-shadow: 0 0 7px 0 #5a5a5a !important;
}

/* Input */
.login_inputfields__ix\\+7e input {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.login_inputfields__ix\\+7e input:focus {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.login_inputfields__ix\\+7e input::placeholder {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/login.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sEAAsE;EACtE,wIAAwI;EACxI,oEAAuE;EACvE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,4CAA4C;EAC5C,oEAAgE;EAChE,sBAAsB;EACtB,iCAAiC;EACjC,wCAAwC;AAC1C;;AAEA,UAAU;AACV;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".loginscreen {\n  position: absolute;\n  /* background: url(\"../../public/loginscreen.svg\") center no-repeat; */\n  /* background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(\"../../public/background-pattern.jpg\") center no-repeat; */\n  background: url(\"../../public/background-pattern.jpg\") center no-repeat;\n  width: 100%;\n  height: 100%;\n}\n\n.cardTransDark {\n  /* background-color: rgba(0, 0, 0, 0.512); */\n  background: url(\"../../public/loginscreen.svg\") center no-repeat;\n  background-size: cover;\n  /* background-position: center; */\n  box-shadow: 0 0 7px 0 #5a5a5a !important;\n}\n\n/* Input */\n.inputfields input {\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n}\n\n.inputfields input:focus {\n  background-color: rgba(0, 0, 0, 0.7);\n  color: white;\n}\n\n.inputfields input::placeholder {\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginscreen": `login_loginscreen__HgEYF`,
	"cardTransDark": `login_cardTransDark__PdbnS`,
	"inputfields": `login_inputfields__ix+7e`
};
export default ___CSS_LOADER_EXPORT___;
