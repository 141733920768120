import React, { useEffect, useRef, useState } from "react";
import { Button, Card, FormControl, InputGroup } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import apiCall from "../utils/apiCall";
import { v4 as uuidv4 } from 'uuid'; // Import UUID library
import { FunctionBadge } from "../managers/BadgeManager";

const GlobalChatbox = ({ workorder, workorderId, chatboxRefs, FunctionListAPI }) => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const { user } = useAuth();
    const chatboxRef = useRef(chatboxRefs);

    useEffect(() => {
        if (workorder.partlist?.GlobalDetails) {
            setMessages(workorder.partlist.GlobalDetails);
        }
    }, [workorder]);

    useEffect(() => {
        if (chatboxRef.current) {
            chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
    }, [messages]);

    const sendMessage = async () => {
        if (message.trim()) {
            const messageData = {
                id: workorderId,
                message_id: uuidv4(),
                message: message,
            };
            setMessage("");
            await apiCall("wo/workorder/update_global_details", "POST", messageData);
        }
    };

    const deleteMessage = async (id, message_id) => {
        setMessages((prevMessages) => prevMessages.filter(msg => msg.message_id !== message_id));
        await apiCall("wo/workorder/delete_global_details", "POST", { workorder_id: id, message_id: message_id });
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <Card className="border">
            <Card.Header>Algemene Chat</Card.Header>
            <Card.Body>
                <div ref={chatboxRef} style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {messages.map((msg) => (
                        <div key={msg.id} className="chat-message">
                            <strong>{msg.user}{msg.function && <FunctionBadge functionName={msg.function} functionList={FunctionListAPI} />}</strong> <span>{msg.date}</span>
                            <p>{msg.message}</p>
                            {msg.user === user.Fullname && (
                                <Button variant="danger" size="sm" className="delete-button" onClick={() => deleteMessage(workorder.ID, msg.message_id)}>
                                    <i className="fas fa-trash-alt"></i>
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
                <InputGroup className="mt-3">
                    <FormControl
                        as="textarea"
                        rows={1}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                        placeholder="Type a message..."
                    />
                    <Button onClick={sendMessage}>Send</Button>
                </InputGroup>
            </Card.Body>
        </Card>
    );
};

export default GlobalChatbox;
