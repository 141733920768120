import {useEffect, useState} from "react";

const apiurl = process.env.REACT_APP_API_URL;

export function GetAPIurl(){
  if (!apiurl) { throw new Error('Api url was not ok'); }
  return apiurl;
}

export function useQuery(location) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!apiurl) { throw new Error('Api url was not ok'); }
        const response = await fetch(`${apiurl}/api/${location}`);
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [location]);
  return { data, error, loading };
}

export function useQueryID(location, id) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!apiurl) { throw new Error('Api url was not ok'); }
        if (!id) { throw new Error('ID was not ok'); }
        const response = await fetch(`${apiurl}/api/${location}/${id}`);
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [location]);
  return { data, error, loading };
}
