"use client";

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { displayToast } from '../../components/managers/ToastManager';
import apiCall, { apiCallRaw } from '../../components/utils/apiCall';
import { calculatePermissionsValue } from '../../components/managers/PermissionManager';

export default function AddEmployee() {
  const [Firstname, setFirstname] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Username, setUsername] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Phone, setPhone] = useState("");
  const [Address, setAddress] = useState("");
  const [Address2, setAddress2] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [City, setCity] = useState("");
  const [Country, setCountry] = useState("");
  const [Function, setFunction] = useState("");
  const [Role, setRole] = useState([]);
  const [UserRoles, setUserRoles] = useState([]);
  const [FunctionList, setFunctionList] = useState([]);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    digit: false,
    special: false,
  });
  const [isTouched, setIsTouched] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setUserRoles(await apiCall("metadata/userroles", "POST", {}))
      setFunctionList(await apiCall("metadata/functionlist", "POST", {}))
    })();
  }, []);

  const handleRoleChange = (e) => {
    const roleName = e.target.value;
    if (Role.includes(roleName)) {
      setRole(Role.filter(selectedRole => selectedRole !== roleName));
    } else {
      setRole([...Role, roleName]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!Firstname || !Lastname || !Username || !Email || !Password) {
      displayToast("Please fill in all required fields.", "error");
      return;
    }

    const permissionsValue = calculatePermissionsValue(UserRoles, Role);

    const response = await apiCallRaw("employee/create", "POST", {
      Firstname,
      Lastname,
      Username,
      Email,
      Password,
      Phone,
      Address,
      Address2,
      PostalCode,
      City,
      Country,
      Function,
      PermissionsValue: permissionsValue.toString()
    }, undefined, true);

    const data = await response.json();
    if (response.status === 200) {
      displayToast(data.message, "success");
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } else {
      displayToast(data.error, "error");
    }
  };

  const validatePassword = (value) => {
    setPasswordRequirements({
      length: value.length >= 12,
      maxlength: value.length <= 256,
      lowercase: /[a-z]/.test(value),
      uppercase: /[A-Z]/.test(value),
      digit: /\d/.test(value),
      special: /[!@#$%&*]/.test(value),
      allowedChars: /^[A-Za-z\d!@#$%&*]*$/.test(value),
    });
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col md={6}>
              <h3>People - Aanmaken</h3>
            </Col>
            <Col md={6} className='text-end'>
              <Button variant="danger" onClick={() => navigate(-1)}>
                <i className="fas fa-arrow-left" />Terug
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Card>
              <Card.Header>Persoonljke Informatie <Badge bg={"danger"}>Verplicht</Badge> </Card.Header>
              <Card.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Voornaam
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" value={Firstname} minLength={3} onChange={e => setFirstname(e.target.value)} placeholder="John" autoComplete='off' required />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Achternaam
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" value={Lastname} minLength={3} onChange={e => setLastname(e.target.value)} placeholder="Doe" autoComplete='off' required />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Email
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="email" value={Email} onChange={e => setEmail(e.target.value)} placeholder="example@example.com" autoComplete='off' required />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Gebruikersnaam
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" value={Username} minLength={3} onChange={e => setUsername(e.target.value)} placeholder="johndoe" autoComplete='off' required />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Wachtwoord
                  </Form.Label>
                  <Col sm="10">
                    <OverlayTrigger
                      placement="left"
                      show={isTouched}
                      overlay={
                        <Tooltip>
                          <div id="password-requirements" className="password-requirements">
                            <p className={passwordRequirements.length ? 'text-success' : 'text-danger'}>Minimaal 12 tekens maximaal 256 tekens</p>
                            <p className={passwordRequirements.lowercase ? 'text-success' : 'text-danger'}>1 kleine letter</p>
                            <p className={passwordRequirements.uppercase ? 'text-success' : 'text-danger'}>1 hoofdletter</p>
                            <p className={passwordRequirements.digit ? 'text-success' : 'text-danger'}>1 cijfer</p>
                            <p className={passwordRequirements.special ? 'text-success' : 'text-danger'}>1 speciaal teken</p>
                            <p className={passwordRequirements.allowedChars ? 'text-success' : 'text-danger'}>Only letters, numbers, and !@#$%&* are allowed</p>
                          </div>
                        </Tooltip>
                      }
                    >
                      <Form.Control
                        type="password"
                        value={Password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          validatePassword(e.target.value);
                        }}
                        onFocus={() => setIsTouched(true)}
                        onBlur={() => setIsTouched(false)}
                        autoComplete="new-password"
                        required
                      />
                    </OverlayTrigger>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>Contact Informatie <Badge bg={"primary"}>Optioneel</Badge></Card.Header>
              <Card.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Telefoon
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="tel" value={Phone} onChange={e => setPhone(e.target.value)} placeholder={"+31 6 12345678"} autoComplete='off' />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Adres
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" value={Address} onChange={e => setAddress(e.target.value)} placeholder={"Street 123"} autoComplete='off' />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Adres 2
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" value={Address2} onChange={e => setAddress2(e.target.value)} placeholder={"Apartment, studio, or floor"} autoComplete='off' />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Postcode
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" value={PostalCode} onChange={e => setPostalCode(e.target.value)} placeholder={"1234 AB"} autoComplete='off' />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Stad
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" value={City} onChange={e => setCity(e.target.value)} placeholder={"Amsterdam"} autoComplete='off' />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Land
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" value={Country} onChange={e => setCountry(e.target.value)} placeholder={"Netherlands"} autoComplete='off' />
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>Rol Informatie <Badge bg={"danger"}>Verplicht</Badge></Card.Header>
              <Card.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Functie
                  </Form.Label>
                  <Col sm="10">
                    <Form.Select value={Function} onChange={e => setFunction(e.target.value)} required>
                      <option selected value="">Selecteer een functie</option>
                      {FunctionList.map((func, index) => (
                        <option key={index} value={func.name} style={{ color: func.color || "#fff" }}>
                          {func.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Rol
                  </Form.Label>
                  <Col sm="10">
                    <Row>
                      {UserRoles?.map((role, index) => (
                        <Col md={4}>
                          <Form.Check
                            key={index}
                            type="checkbox"
                            id={`role-${index}`}
                            label={role}
                            value={role}
                            checked={Role.includes(role)}
                            onChange={handleRoleChange}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button type="submit" variant="success">
                  <i className='fa fa-plus' /> Voeg Employee toe
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
