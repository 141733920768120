import {Button, Card, Col, Form, FormCheck, ListGroup, Row} from "react-bootstrap";
import toast from "react-hot-toast";

import {
    CreateClockifyKlantProject,
    CreateClockifyProject,
    useClockifyQuery
} from "../../../components/hesselToDo/Clockify";

export default function details() {
  return (
    <>
      <Card>
        <Card.Header>
          <h4>Projecten - Aanmaken</h4>
        </Card.Header>
        <Card.Body>
          <h5>Helenaam: <span id="showname" className="text-primary"></span></h5>
          {FormCreate()}
        </Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" href="/clockify/projects">
            Terug
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

function GetKlanten() {
  const { data, error, loading } = useClockifyQuery("clients?page-size=5000");
  if (error) return <option disabled>Laden mislukt</option>;
  if (loading) return <option disabled>Bezig met laden...</option>;
  return data.map((element) => (<option key={element.id} id={element.id} value={element.id}>{element.name}</option>));
}

function FormCreate() {
  const HardcodedTasks = [
    "Additional work",
    "Commissioning",
    "Customer support",
    "Documentation – FDS",
    "Documentation – HDS",
    "Documentation – Manual",
    "Documentation – SDS",
    "Engineering",
    "Generation",
    "Meetings",
    "Travel time",
    "School"
  ]

  const handleSubmit = async (e) => {
    e.preventDefault();
    const takeninput = Array.from(document.getElementsByClassName("fw-bold")).reduce((accumulator, element, index) => {
      const checkbox = document.getElementById("Checkinput" + index);
      if (checkbox && checkbox.checked) {
        accumulator.push(element.textContent);
      }
      return accumulator;
    }, []);

    var takenouput = [];
    takeninput.forEach((element) => {
      takenouput.push({ name: element })
    })
    const raw = {
      "name": `${e.target['pnummer'].value} - ${e.target['name'].value} - ${e.target['beschrijving'].value}`,
      "clientId": e.target['client'].value,
      "tasks": takenouput
    };
    CreateClockifyProject(raw);
  };

  const handleChange = (e) => {
    e.preventDefault();
    document.getElementById("showname").textContent = `${document.getElementById('pnummer').value} - ${document.getElementById('name').value} - ${document.getElementById('beschrijving').value}`;
  }

  const MakeClient = async (e) => {
    e.preventDefault();
    const name = document.getElementById("clientmakename").value;
    if (!name || name.length < 1) {
      toast.error("Om een klant te maken moet je wel een naam invullen!");
      return;
    }

    const raw = {
      "name": name
    };

    CreateClockifyKlantProject(raw);
  }

  const handleMakeTasks = (e) => {
    e.preventDefault();
    const taskname = document.getElementById("takenaanmaken").value;
    const count = document.getElementsByClassName("d-flex").length;

    const newListItem = document.createElement("li");
    newListItem.id = "Taaklist" + count;
    newListItem.className = "d-flex justify-content-between align-items-start list-group-item list-group-item-action";
    const innerDiv = document.createElement("div");
    innerDiv.className = "ms-2 me-auto";
    const boldDiv = document.createElement("div");
    boldDiv.className = "fw-bold";
    boldDiv.textContent = taskname;
    const inputcheck = document.createElement("input");
    inputcheck.type = "checkbox";
    inputcheck.className = "form-check-input";
    inputcheck.checked = true;
    innerDiv.appendChild(boldDiv);
    newListItem.appendChild(innerDiv);
    newListItem.appendChild(inputcheck);
    document.getElementById("taken").appendChild(newListItem);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit} className="was-validated">
        <Row>
          <Col sm="4">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">Projectnummer</Form.Label>
              <Col><Form.Control type="text" id="pnummer" placeholder="Projectnummer" onChange={handleChange} required /></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">Klantnaam</Form.Label>
              <Col><Form.Control type="text" id="name" placeholder="Klantnaam" onChange={handleChange} required /></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">Beschrijving</Form.Label>
              <Col><Form.Control type="text" id="beschrijving" placeholder="Beschrijving" onChange={handleChange} required /></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">Klant</Form.Label>
              <Col><Form.Select id="client" required><option value="">-- Selecteer klant --</option>{GetKlanten()}</Form.Select></Col>
            </Form.Group>
          </Col>
          <Col sm="4">
            <h5>Taken</h5>
            <ListGroup numbered id="taken">
              {HardcodedTasks.map((element, index) => (
                <ListGroup.Item key={index} id={"Taaklist" + index} as={"li"} action className="d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto"><div className="fw-bold">{element}</div></div>
                  <FormCheck.Input id={"Checkinput" + index} defaultChecked />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col sm="4" style={{ border: "dashed 2px #0654" }}>
            <div className="mb-3">Extra</div>
            <Form.Group as={Row} className="mb-3">
              <Col sm="4"><Button type="button" onClick={MakeClient}>Klant aanmaken</Button></Col>
              <Col><Form.Control type="text" id="clientmakename" placeholder="Naam klant" /></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm="4"><Button type="button" variant="success" onClick={handleMakeTasks}>Taak aanmaken</Button></Col>
              <Col><Form.Control type="text" id="takenaanmaken" placeholder="Taak naam" /></Col>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="success" type="submit">Aanmaken</Button>
      </Form>
    </div>
  );
}