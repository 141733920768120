import {Button, ButtonGroup, Card, Col, FloatingLabel, Form, Row,} from "react-bootstrap";
import toast from "react-hot-toast";

import {GetAPIurl, useQueryID} from "../../../../components/hesselToDo/Data";

export async function getServerSideProps(context) {
  const id = context.params.id;
  return { props: { id } };
}

export default function Project({ id }) {
  return (
    <>
      <Card>
        <Card.Header>
          <h4>Aanpassen - {id}</h4>
        </Card.Header>
        <Card.Body>{FormDetails(id)}</Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" href="/materials">
            Terug
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

function FormDetails(id) {
  const { data, error, loading } = useQueryID("unifeed/products", id);
  if (error) return <div className="text-danger">Laden mislukt</div>;
  if (loading) return <div className="text-primary">Bezig met laden...</div>;
  const handleSubmit = async (e) => {
    e.preventDefault();

    const raw = JSON.stringify({
      id: id,
      materialCode: document.getElementById("materialCode").value,
      orderNumber: document.getElementById("orderNumber").value,
      description: document.getElementById("description").value,
      productCode: document.getElementById("productCode").value,
      manufacturerGLN: document.getElementById("manufacturerGLN").value,
      gtin: document.getElementById("gtin").value,
      imageURL: document.getElementById("imgurl").src,
    })

    var myheaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "PUT",
      headers: myheaders,
      body: raw,
    };

    fetch(`${GetAPIurl()}/api/unifeed/products/${id}`, req)
      .then((d) => {
        if (d.status === 204) {
          toast.success("Gelukt!");
        } else {
          toast.error("Gefaald");
        }
        return d.json();
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <img id="imgurl" src={data.imageURL} height={100} width={100} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="ID">
              <Form.Control type="text" id="id" defaultValue={data.id} disabled />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="MateriaalCode">
              <Form.Control type="text" id="materialCode" defaultValue={data.materialCode} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="ProductCode">
              <Form.Control type="text" id="productCode" defaultValue={data.productCode} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="Bestelnummer">
              <Form.Control type="text" id="orderNumber" defaultValue={data.orderNumber} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="description">
              <Form.Control type="text" id="description" defaultValue={data.description} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="manufacturerGLN">
              <Form.Control type="text" id="manufacturerGLN" defaultValue={data.manufacturerGLN} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <FloatingLabel label="gtin">
              <Form.Control type="text" id="gtin" defaultValue={data.gtin} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Col sm="1">
          <ButtonGroup>
            <Button variant="success" type="submit">
              <i className="fas fa-pen"></i>
            </Button>
          </ButtonGroup>
        </Col>
      </Form>
    </div>
  );
}
