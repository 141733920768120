import {Button, Card, Col, Form, Table} from "react-bootstrap";
import {useState} from "react";
import {useQuery} from "../../../components/hesselToDo/Data";
import {usePagination} from "../../../components/managers/PaginationManager";

export default function Main() {
  const [query, setQuery] = useState("");

  return (
    <>
      <Col>
        <Card>
          <Card.Header>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 id="tableLabel" style={{ marginRight: "10px" }}>
                  Unifeed/Onderdelen
                </h1>
                <Button variant="success" href="/materials/unifeed/create">
                  <i className="fas fa-plus"></i>
                </Button>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  type="text"
                  name="SearchString"
                  placeholder="Zoeken..."
                  onChange={(e) => setQuery(e.target.value)}
                  style={{ marginRight: "10px" }}
                />
              </div>
            </div>
          </Card.Header>
          <Card.Body>{GetData(query)}</Card.Body>
        </Card>
      </Col>
    </>
  );
}

function Search(data, keyword) {
  const re = new RegExp(keyword, "i");
  return data.filter((entry) =>
    Object.values(entry).some((val) => typeof val === "string" && val.match(re))
  );
}

function GetData(query) {
  const { data, error, loading } = useQuery("unifeed/products");
  if (error) return <div className="text-danger">Laden mislukt</div>;
  if (loading) return <div className="text-primary">Bezig met laden...</div>;
  const filterd = Search(data, query);
  return <Tafel data={filterd} />;
}

function DetailsColumn(id) {
  window.location = `/materials/unifeed/details/${id}`;
}

const Tafel = ({ data }) => {
  if (!data[0]) return <h4>Geen data</h4>;
  const { currentItems, AboveTable, BelowTable, indexOfFirstItem } = usePagination(data);
  return (
    <div>
      {AboveTable}
      <Table striped hover>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Materiaalcode</th>
            <th>GTIN</th>
            <th>Beschrijving</th>
            <th>Controlleer</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((material) => (
            <tr onClick={() => DetailsColumn(material.id)} key={material.id}>
              <td>
                <img src={material.imageURL} width={50} height={50} />
              </td>
              <td>{material.id}</td>
              <td>{material.materialCode}</td>
              <td>{material.gtin}</td>
              <td>{material.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {BelowTable}
    </div>
  );
};
