"use client"

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { displayToast } from '../../components/managers/ToastManager';
import { Badge, Button, Card, CardBody, Col, Form, Row } from 'react-bootstrap';

import apiCall from '../../components/utils/apiCall'

export default function AddCompany() {
    const navigate = useNavigate();
    const [Code, setCode] = useState("");
    const [PostalCode, setPostalCode] = useState("");
    const [Email, setEmail] = useState("");
    const [Name, setName] = useState("");
    const [City, setCity] = useState("");
    const [Country, setCountry] = useState("");
    const [Address, setAddress] = useState("");
    const [Address2, setAddress2] = useState("");
    const [Phone, setPhone] = useState("");
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!Code || !PostalCode || !Email || !Name || !City || !Country || !Address || !Phone) {
            displayToast("Vul alle verplichte velden in", "error");
            return;
        }

        try {
            await apiCall("company/create", "POST", { Code, PostalCode, Email, Name, City, Country, Address, Address2, Phone }, {}, true);
            navigate("/companies");

        } catch (error) {
            console.error(error);
            displayToast("Company toevoegen mislukt", "error");
        }
    };

    return (
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col md={1}>
                            <Button variant="danger" onClick={() => navigate(-1)}>
                                <i className='fa fa-arrow-left' /> Terug
                            </Button>
                        </Col>
                        <Col className='text-center'>
                            <h4>Company - Aanmaken</h4>
                        </Col>
                    </Row>
                </Card.Header>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <Card>
                            <Card.Header>Company Informatie <Badge bg={"danger"}>Verplicht</Badge> </Card.Header>
                            <Card.Body>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Code
                                    </Form.Label>
                                    <Col sm="10">
                                    <Form.Control 
                                        type="text" 
                                        value={Code} 
                                        onChange={e => setCode(e.target.value)} 
                                        placeholder="00000" 
                                        pattern="\d{4,6}" 
                                        title="Code moet minimaal 4 en maximaal 6 nummers zijn"
                                        required 
                                    />

                                        
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Postcode
                                    </Form.Label>
                                    <Col sm="10">
                                    <Form.Control 
                                        type="text" 
                                        value={PostalCode} 
                                        onChange={e => setPostalCode(e.target.value)} 
                                        placeholder="8302 AE" 
                                        pattern="\d{4}\s*[A-Z]{2}" 
                                        title="Postcode moet in het volgende format '1234 AB'"
                                        required 
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Email
                                    </Form.Label>
                                    <Col sm="10">
                                    <Form.Control 
                                        type="email" 
                                        value={Email} 
                                        onChange={e => setEmail(e.target.value)} 
                                        placeholder="example@test.nl" 
                                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                        title="Please enter a valid email address"
                                        required 
                                    />

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Naam
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                        type="text" 
                                        value={Name} 
                                        onChange={e => setName(e.target.value)} 
                                        placeholder="Company Name"
                                        required />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Stad
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                        type="text" 
                                        value={City} 
                                        onChange={e => setCity(e.target.value)}
                                        placeholder="City" 
                                        required />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Land
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                        type="text" 
                                        value={Country} 
                                        onChange={e => setCountry(e.target.value)} 
                                        placeholder="Country" 
                                        required />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Adres
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                        type="text" 
                                        value={Address} 
                                        onChange={e => setAddress(e.target.value)} 
                                        placeholder="Address" 
                                        required />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Adres2
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                        type="text" 
                                        value={Address2} 
                                        onChange={e => setAddress2(e.target.value)} 
                                        placeholder="Address2" 
                                        required />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Telefoon
                                    </Form.Label>
                                    <Col sm="10">
                                    <Form.Control 
                                        type="tel" 
                                        value={Phone} 
                                        onChange={e => setPhone(e.target.value)} 
                                        placeholder="+31 6 12345678" 
                                        required 
                                    />

                                    </Col>
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                                <Button type="submit" variant="success">
                                    <i className='fa fa-plus' /> Voeg Company toe
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Form>
                </CardBody>
            </Card>
        </>
    );
}