import toast from "react-hot-toast";

export function OneFileCheck(bestanden, filetype){
    if (bestanden.length < 1) {
        toast.error("Je moet wel een bestand mee sturen!")
        return false;
    }

    const fileName = bestanden[0].name;
    const fileExtension = fileName.split('.').pop();
    if (!filetype.includes(fileExtension)) {
        toast.error(`Bestand is geen ${filetype}!`)
        return false;
    }

    if (bestanden.length > 1) {
        toast.error("Dat is meer dan één bestand!")
        return false;
    }

    return true;
}

export function TwoFileCheck(bestandold, bestandnew, filetype){
    if (bestandold.length < 1 || bestandnew.length < 1) {
        toast.error("Je moet wel een bestand mee sturen!")
        return false;
    }

    const fileNameold = bestandold[0].name;
    const fileExtensionold = fileNameold.split('.').pop();
    const fileNamenew = bestandnew[0].name;
    const fileExtensionnew = fileNamenew.split('.').pop();
    if (!filetype.includes(fileExtensionold) || !filetype.includes(fileExtensionnew)) {
        toast.error(`Bestand is geen ${filetype}!`)
        return false;
    }

    return true;
}