import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Col, Form, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { usePagination } from "../../../components/managers/PaginationManager";
import apiCall from "../../../components/utils/apiCall";
import { Search } from "../../../components/utils/searchUtils";
import { formatDate } from "../../../components/managers/DateManager";
import { StatusBadge } from "../../../components/managers/BadgeManager";
import { displayToast } from "../../../components/managers/ToastManager";
import { FaInfoCircle } from "react-icons/fa";
import { StatusList } from "../../../components/misc/HardcodedLists";

// Functie om de medewerker op te halen zodat je de projecten van deze specifieke medewerker kan ophalen
const getEmployeeById = async (id) => {
    try {
        const res = await apiCall(`employee/getbyid/${id}`, "POST");
        return res;
    } catch (error) {
        throw new Error(error.message);
    }
};

// Projecten worden via deze manier opgehaald zodat je de projecten van die medewerker kan ophalen
const bulkProjectsByIds = async (Projects) => {
    try {
        const res = await apiCall(`bulk/get/project`, "POST", { IDs: Projects });
        return res;
    } catch (error) {
        throw new Error(error.message);
    }
};

export default function MyProfile() {
    const navigate = useNavigate();
    const [originalProjects, setOriginalProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const { user } = useAuth();
    const [employee, setEmployee] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sortField, setSortField] = useState("Project");
    const [sortDirection, setSortDirection] = useState("asc");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [hideFinished, setHideFinished] = useState(true);

    // user.id gebruiken om jou specifieke projecten op te halen
    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const employeeData = await getEmployeeById(user.EmployeeID);
                setEmployee(employeeData);

                if (employeeData && employeeData.Projects && employeeData.Projects.length > 0) {
                    const projectsData = await bulkProjectsByIds(employeeData.Projects);
                    setOriginalProjects(projectsData);
                    applyFilters(searchQuery, hideFinished, selectedStatus, projectsData);
                    displayToast("Uw projecten zijn succesvol geladen", "success");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployeeData();
    }, [user.EmployeeID]);

    const { currentItems, AboveTable, BelowTable, indexOfFirstItem } = usePagination(filteredProjects);

    //Functie om de projecten te sorteren
    const handleSort = (field) => {
        setSortField(field);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };

    const sortedItems = [...currentItems].sort((a, b) => {
        if (a[sortField] < b[sortField]) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sortField] > b[sortField]) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Functie om de zoekfunctie te gebruiken 
    const handleSearchChange = (e) => {
        const newQuery = e.target.value;
        setSearchQuery(newQuery);
        applyFilters(newQuery, hideFinished, selectedStatus, originalProjects);
    };

    // Functie om de checkbox te gebruiken om de voltooide projecten te verbergen
    const handleCheckboxChange = () => {
        const newHideFinished = !hideFinished;
        setHideFinished(newHideFinished);
        applyFilters(searchQuery, newHideFinished, selectedStatus, originalProjects);
    };

    // Functie om de status te veranderen
    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        setSelectedStatus(newStatus);
        applyFilters(searchQuery, hideFinished, newStatus, originalProjects);
    };

    // Functie om de filters toe te passen
    const applyFilters = (query, hideFinished, status, projects) => {
        let filtered = projects;
        if (query.trim()) {
            filtered = Search(projects, query);
        }
        if (hideFinished) {
            filtered = filtered.filter(project => project.Status !== "Finished");
        }
        if (status) {
            filtered = filtered.filter(project => project.Status === status);
        }
        setFilteredProjects(filtered);
    };

    return (
        <>
            <Card>
                <Card.Header>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h1 id="tableLabel" style={{ margin: "0", marginRight: "10px" }}>My Projects</h1>
                            <Button variant="success" href="/projects/create">
                                <i className="fas fa-plus"></i>
                            </Button>
                            <h6 className="drop-animation" style={{ margin: "0", marginLeft: '50px', marginTop: '4px', color: '#a1a1a1' }}>
                                <em>Dit zijn de projecten waaraan je momenteel bent gekoppeld</em>
                            </h6>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ border: '1px solid #495057', borderRadius: '5px', display: 'inline-block' }}>
                                <ButtonGroup>
                                    <Button style={{ width: '140px' }} variant="dark" onClick={() => navigate("/projects")}>Alle projecten</Button>
                                    <Button style={{ width: '140px' }} variant="dark" onClick={() => navigate("/profile/myProjects")} disabled={true}>Jouw projecten</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col md={2}>
                            <Form.Control
                                type="text"
                                name="SearchString"
                                placeholder="Zoeken..."
                                onChange={handleSearchChange}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Select
                                value={selectedStatus}
                                onChange={handleStatusChange}
                            >
                                <option value="">Selecteer een status</option>
                                {StatusList.map((stat, index) => (
                                    <option key={index} value={stat}>
                                        {stat}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Form.Check
                        type="checkbox"
                        label="Verberg voltooide projecten"
                        checked={hideFinished}
                        onChange={handleCheckboxChange}
                        className="mb-2" />
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <h3 className="me-2">Loading projects...</h3>
                            <Spinner animation="border" role="status">
                                <span className="sr-only"></span>
                            </Spinner>
                        </div>
                    ) : employee && employee.Projects && employee.Projects.length > 0 ? (
                        <>
                            {AboveTable}
                            <Table className="mt-2" striped hover>
                            <colgroup>
                                        <col style={{ width: "1%" }} />
                                        <col style={{ width: "9%" }} />
                                        <col style={{ width: "2%" }} />
                                        <col style={{ width: "2%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "5%" }} />
                                        <col style={{ width: "5%" }} />
                                    </colgroup>
                                <thead>
                                    <tr>
                                    <th className="text-center">#</th>
                                        <th onClick={() => handleSort("Project")}>Project</th>
                                        <th onClick={() => handleSort("Start Date")}>Start Datum</th>
                                        <th onClick={() => handleSort("End Date")}>Eind Datum</th>
                                        <th>Projectleiding</th>
                                        <th onClick={() => handleSort("Status")}>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedItems.map((project, index) => (
                                        <tr key={project._id} onClick={() => navigate(`/projects/details/${project._id}`)}>
                                            <td className="text-center">{indexOfFirstItem + index + 1}</td>
                                            <td>{project.Code} {project.Description}</td>
                                            <td>{project.StartDate ? formatDate(project.StartDate) : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>{project.EndDate ? formatDate(project.EndDate) : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>{project.Manager ? (project.Manager) : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>{project.Status ? <StatusBadge statusTitle={project.Status} /> : <span style={{ color: 'red' }}>Niet bekend</span>}</td>
                                            <td>
                                                <Button variant="info" className="m-1" onClick={() => { navigate(`details/${project._id}`) }}><i className="fas fa-info-circle" /> Informatie</Button>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {project.Employees.length > 0
                                                                ? `Werknemers: ${project.Employees.length}`
                                                                : 'Nog geen werknemers'}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Badge bg="primary" text="dark" pill>{project.Employees.length > 0 ? project.Employees.length : '0'}</Badge>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {BelowTable}
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', color: '#a1a1a1' }}>
                            <FaInfoCircle style={{ fontSize: '1.5em', marginRight: '10px' }} />
                            <h6 style={{ marginTop: '8px' }}>
                                <em>You are currently not assigned to any projects</em>
                            </h6>
                        </div>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}
