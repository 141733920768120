// import {Button, Card, Col, Form, Table} from "react-bootstrap";
// import {useState} from "react";
// import {useQuery} from "../../../components/hesselToDo/Data";
// import {usePagination} from "../../../components/managers/PaginationManager"

// export default function Main() {
//   const [query, setQuery] = useState("");

//   return (
//     <>
//       <Col>
//         <Card>
//           <Card.Header>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//               }}
//             >
//               <div style={{ display: "flex", alignItems: "center" }}>
//                 <h1 id="tableLabel" style={{ marginRight: "10px" }}>
//                   Unifeed/Onderdelen op voorraad
//                 </h1>
//               </div>
//               <div style={{ display: "flex", alignItems: "center" }}>
//                 <Form.Control
//                   type="text"
//                   name="SearchString"
//                   placeholder="Zoeken..."
//                   onChange={(e) => setQuery(e.target.value)}
//                   style={{ marginRight: "10px" }}
//                 />
//               </div>
//             </div>
//           </Card.Header>
//           <Card.Body>{GetData(query)}</Card.Body>
//         </Card>
//       </Col>
//     </>
//   );
// }

// function Search(data, keyword) {
//   const re = new RegExp(keyword, "i");
//   return data.filter((entry) =>
//     Object.values(entry).some((val) => typeof val === "string" && val.match(re))
//   );
// }

// function GetData(query) {
//   const { data, error, loading } = useQuery("unifeed/products/available");
//   if (error) return <div className="text-danger">Laden mislukt</div>;
//   if (loading) return <div className="text-primary">Bezig met laden...</div>;
//   const newdata = data.filter(material => material.isAvailable)
//   if (!newdata) return <h4>Geen producten</h4>;
//   const filterd = Search(newdata, query);
//   return <Tafel data={filterd} />;
// }

// const Tafel = ({ data }) => {
//   if (!data[0]) return <h4>Geen data</h4>;
//   const { currentItems, AboveTable, BelowTable, indexOfFirstItem } = usePagination(data);
//   return (
//     <div>
//       {AboveTable}
//       <Table striped hover>
//         <thead>
//           <tr>
//             <th>Foto</th>
//             <th>Beschrijving</th>
//             <th>Aantal leveranciers</th>
//             <th>Aantal onderdelen</th>
//             <th>Acties</th>
//           </tr>
//         </thead>
//         <tbody id="listitems">
//           {currentItems.map((material, index) => (
//             <tr key={material.id} style={{ cursor: "default" }}>
//               <td>
//                 <img src={`https://api.2ba.nl/1/json/Thumbnail/Product?gln=${material.manufacturerGLN}&productcode=${material.productCode}`}
//                   width={75}
//                   height={75}
//                   onError={(e) => e.target.src = '/placeholder.png'}
//                 />
//               </td>
//               <td>
//                 <a href={"/materials/details/" + material.id}>
//                   {material.description}
//                 </a>
//               </td>
//               <td>{getTotalAvailableSuppliers(material.productSupplier)}</td>
//               <td>{getTotalAvailible(material.productSupplier)}</td>
//               <td>
//                 <Button variant="success" onClick={() => ShowDetails(material, index)}>Laat zien</Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//       {BelowTable}
//     </div>
//   );
// };

// function ShowDetails(material, index) {
//   if (document.getElementById("detail" + index)) {
//     document.getElementById("detail" + index).remove();
//     return
//   }

//   const tr = document.createElement("tr");
//   tr.id = "detail" + index;
//   const td = document.createElement("td");
//   td.colSpan = 10;

//   material.productSupplier.forEach(element => {
//     if (element.quantity <= 0) return;
//     const row = document.createElement("div");
//     row.className = "row";
//     row.style = "border-bottom: 1px solid #d3d3d3; padding: 5px 0px;"
//     const dt1 = document.createElement("dt");
//     dt1.className = "col-md-2";
//     const dt2 = document.createElement("dd");
//     dt2.className = "col-md-2";
//     const dt3 = document.createElement("dt");
//     dt3.className = "col-md-2";
//     const dt4 = document.createElement("dd");
//     dt4.className = "col-md-2";

//     dt1.textContent = "Leverancier";
//     dt2.textContent = element.supplierName;
//     dt3.textContent = "Aantal beschikbaar";
//     dt4.textContent = element.quantity;

//     row.append(dt1);
//     row.append(dt2);
//     row.append(dt3);
//     row.append(dt4);
//     td.append(row);
//   });

//   tr.append(td);
//   const list = document.getElementById("listitems");
//   list.insertBefore(tr, list.childNodes[index + 1]);
// }


// function getTotalAvailableSuppliers(suppliers) {
//   var aantal = 0;
//   suppliers.forEach((supplier) => {
//     if (supplier.quantity > 0) {
//       aantal++;
//     }
//   })
//   return aantal;
// }

// function getTotalAvailible(suppliers) {
//   var aantal = 0;
//   suppliers.forEach((supplier) => {
//     if (supplier.quantity > 0) {
//       aantal += supplier.quantity;
//     }
//   })
//   return aantal;
// }