import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import apiCall from '../../components/utils/apiCall';
import { FaInfoCircle } from 'react-icons/fa'

/**
 * README FeedbackPage
 *
 * [feedback].jsx is een React component dat wordt gebruikt om feedback te geven over een bug of een feature.
 * Het bevat een customalert component waar wordt gevraagd of de feedback een bug of een feature is.
 *
 * =====================================================================================================================
 *
 * ChangeCreateChecklistModal:
 * Dit component bevat een formulier om feedback te geven over een bug of een feature.
 * Het formulier bevat verschillende inputvelden die de gebruiker moet invullen.
 *
 *  - Bug:
 *      - Onderwerp
 *      - Reproduceerbaarheid
 *      - Actie
 *      - Resultaat
 *      - Beschrijving
 *  - Feature:
 *      - Onderwerp
 *      - Feature Type
 *      - Feature Impact
 *      - Feature Change
 *          - Alleen zichtbaar als Feature Type = 'existing'
*       - Beschrijving
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer dan de FeedbackPage component in een ander React bestand.
 *
 * Opmerking
 * De FeedbackPage component vereist geen props.
 *
 */

/**
 *
 * @returns {Element}
 * @constructor
 */
const FeedbackPage = () => {
    const { user } = useAuth();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [formDataBug, setFormDataBug] = useState({ id: user.EmployeeID, subject: '', reproducibility: '', action: '', result: '', description: '' });
    const [formDataFeature, setFormDataFeature] = useState({ id: user.EmployeeID, subject: '', feature_type: '', feature_impact: '', feature_change: '', description: '' });
    const { feedback } = useParams();
    const navigate = useNavigate();

    const isFormValid = () => {
        const checkTextField = (field, minLength) => field && field.length >= minLength;

        if (feedback === 'bug') {
            for (let key in formDataBug) {
                if (formDataBug[key] === '' || formDataBug[key] === null) {
                    return false;
                }
            }
            if (!checkTextField(formDataBug.action, 32) ||
                !checkTextField(formDataBug.result, 32) ||
                !checkTextField(formDataBug.description, 32)) {
                return false;
            }
        }
        else if (feedback === 'feature') {
            for (let key in formDataFeature) {
                if (key === 'feature_change' && formDataFeature['feature_type'] !== 'existing') {
                    continue;
                }
                if (formDataFeature[key] === '' || formDataFeature[key] === null) {
                    return false;
                }
            }
            if (!checkTextField(formDataFeature.feature_impact, 32) ||
                !checkTextField(formDataFeature.description, 32)) {
                return false;
            }
        }

        return true;
    };

    const handleSubmit = async () => {
        try {
            await apiCall(`feedback/${feedback}`, 'POST', feedback === "bug" ? formDataBug : formDataFeature, undefined, true);
            navigate("/dashboard");
        }
        catch (error) {
            console.log(error);
        }
    };

    function autoExpand(textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }

    useEffect(() => {
        setIsButtonDisabled(!isFormValid());
    }, [formDataBug, formDataFeature]);

    return (
        <div>
            {feedback === 'bug' && (<>
                <Card>
                    <Card.Header>
                        <Card.Title> Vermeld een Bug</Card.Title>
                        <Card.Text>
                            <FaInfoCircle /> Vul alstublieft zoveel mogelijk details in. Dit helpt ons om uw bugmelding sneller te verwerken.
                        </Card.Text>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <h3>Gebruiker</h3>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text className='inputgroup-text'>Naam</InputGroup.Text>
                                    <Form.Control disabled type="text" id="name" name="name" value={user.Fullname} />
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text className='inputgroup-text'>Functie</InputGroup.Text>
                                    <Form.Control disabled type="text" id="name" name="name" value={user.Function} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Form className='was-validated'>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Onderwerp</Form.Label>
                                        <Form.Control type="text" id="subject" name="subject" onChange={(e) => setFormDataBug({ ...formDataBug, subject: e.target.value })} required />
                                    </Form.Group>
                                </Col>
                                <hr />
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label > Is het probleem reproduceerbaar? </Form.Label>
                                        <Form.Select id="reproducibility" name="reproducibility" onChange={(e) => setFormDataBug({ ...formDataBug, reproducibility: e.target.value })} required>
                                            <option value="">--- Selecteer een optie ---</option>
                                            <option value={true}>Ja</option>
                                            <option value={false}>Nee</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Wat heeft u gedaan voordat het probleem optrad?</Form.Label>
                                        <textarea className='form-control' placeholder='Voer minimaal 32 tekens in' id="action" name="action" minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)} onChange={(e) => setFormDataBug({ ...formDataBug, action: e.target.value })} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Wat was het resultaat van deze actie?</Form.Label>
                                        <textarea className='form-control' placeholder='Voer minimaal 32 tekens in' id="result" name="result" minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)} onChange={(e) => setFormDataBug({ ...formDataBug, result: e.target.value })} required />
                                    </Form.Group>
                                </Col>
                                <hr />
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label> Kunt u het probleem in detail beschrijven? </Form.Label>
                                        <textarea className='form-control' placeholder='Voer minimaal 32 tekens in' id="description" name="description" minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)} onChange={(e) => setFormDataBug({ ...formDataBug, description: e.target.value })} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button disabled={isButtonDisabled} onClick={handleSubmit}><i className='fa fa-send' /> Send</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </>
            )}

            {feedback === 'feature' && (<>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={6}>
                                <h3> Feedback </h3>
                            </Col>
                            <Col md={6} className="text-end">
                                <Button variant="danger" style={{ margin: "4px" }} onClick={() => navigate(-1)}><i className="fa fa-arrow-left" /> Terug</Button>
                            </Col>
                        </Row>
                        <Card.Text>
                            <FaInfoCircle /> Vul alstublieft zoveel mogelijk details in. Dit helpt ons om uw feature aanvraag sneller te verwerken.
                        </Card.Text>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <h4>Gebruiker</h4>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text className='inputgroup-text'>Naam</InputGroup.Text>
                                    <Form.Control disabled type="text" id="name" name="name" value={user.Fullname} />
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text className='inputgroup-text'>Functie</InputGroup.Text>
                                    <Form.Control disabled type="text" id="name" name="name" value={user.Function} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Form className='was-validated'>
                            <Row>
                                <h4>Feature Aanvraag</h4>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Onderwerp</Form.Label>
                                        <Form.Control type="text" id="subject" name="subject" onChange={(e) => setFormDataFeature({ ...formDataFeature, subject: e.target.value })} required />
                                    </Form.Group>
                                </Col>
                                <hr />
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label > Wat voor soort feature wilt u aanvragen? </Form.Label>
                                        <Form.Select id="feature_type" name="feature_type" onChange={(e) => setFormDataFeature({ ...formDataFeature, feature_type: e.target.value })} required>
                                            <option value="">--- Selecteer een optie ---</option>
                                            <option value="new">Nieuwe feature</option>
                                            <option value="existing">Verander een bestaande feature</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label> Wat is de impact van deze feature? </Form.Label>
                                        <textarea className='form-control' id="feature_impact"
                                            placeholder='Voer minimaal 32 tekens in' name="feature_impact"
                                            minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)}
                                            onChange={(e) => setFormDataFeature({
                                                ...formDataFeature,
                                                feature_impact: e.target.value
                                            })} required />
                                    </Form.Group>
                                </Col>
                                {formDataFeature.feature_type === "existing" ? (
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label > Wat wilt u veranderen aan de feature? </Form.Label>
                                            <Form.Control type="text" id="feature_change" name="feature_change" onChange={(e) => setFormDataFeature({ ...formDataFeature, feature_change: e.target.value })} required />
                                        </Form.Group>
                                    </Col>
                                ) : null}
                                <hr />
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label > Kunt u de feature in detail beschrijven? </Form.Label>
                                        <textarea className='form-control' id="description" placeholder='Voer minimaal 32 tekens in' name="description" minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)} onChange={(e) => setFormDataFeature({ ...formDataFeature, description: e.target.value })} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Button disabled={isButtonDisabled} onClick={handleSubmit}><i className='fa fa-paper-plane' /> Versturen</Button>
                    </Card.Body>
                </Card>
            </>
            )}
        </div>
    );
};

export default FeedbackPage;
