import React, {useEffect, useRef} from 'react';

export function countOpenPartlists(workorders) {
    return Object.values(workorders).filter(order => order.partlist.items !== null).length;
}

export const AutoExpandingTextarea = ({ value, disabled = false }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            autoExpand(textareaRef.current);
        }
    }, [value]);

    const autoExpand = (element) => {
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
    };

    return (
        <textarea
            className="form-control"
            rows="1"
            style={{ minHeight: '38px' }}
            value={value}
            disabled={disabled}
            ref={textareaRef}
        ></textarea>
    );
};

export const countItemsWithoutStatus = (partlist) => {
    let count = 0;
    if (partlist && partlist.items) {
        Object.values(partlist.items).forEach((item) => {
            if (!item.Status) {
                count++;
            }
        });
    }
    return count;
};

export const countTotalItems = (partlist) => {
    return partlist && partlist.items ? Object.values(partlist.items).length : 0;
};

export const countItemStatus = (partlist, item_status) => {
    let count = 0;
    if (partlist && partlist.items) {
        Object.values(partlist.items).forEach((item) => {
            if (item.Status === item_status) {
                count++;
            }
        });
    }
    return count;
};