import {Button, Col, Container, Form, Placeholder, Row, Table} from "react-bootstrap";
import {useState} from "react";
import {OneFileCheck} from "../../../components/hesselToDo/Checkfiles";
import toast from "react-hot-toast";
import JSZip from "jszip";
import {saveAs} from "file-saver";

import {GetAPIurl} from "../../../components/hesselToDo/Data";

export default function Converter() {
  const [loaded, setLoaded] = useState(false);
  const [info, setInfo] = useState();
  return (
    <>
      <Container fluid>
        <Row>
          {Start(setLoaded, setInfo)}
          {Download(loaded)}
        </Row>
        {Tablepreview(info)}
      </Container>
    </>
  );
}
function Start(setLoaded, setInfo) {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!OneFileCheck(e.target.files, "csv")) return;

    var formData = new FormData();
    formData.append('file', e.target.files[0]);

    var myheaders = {
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: formData,
    };

    fetch(`${GetAPIurl()}/api/eplan/cableconverter/file`, req)
      .then((response) => response.json())
      .then((d) => {
        toast.success("Gelukt!");
        setLoaded(true);
        setInfo(d);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  }
  return (
    <Col>
      <h3>Kabellijst converter</h3>
      <span>Kabels in de lijst worden van AWG naar MM² gezet.</span>
      <h6>Voer hier uw bestand in:</h6>
      <Form className="was-validated" onChange={handleSubmit}>
        <Form.Group className="input-group mb-3">
          <label htmlFor="file" className="input-group-text btn btn-outline-primary"><i className="fas fa-cloud-arrow-up"></i></label>
          <Form.Control type="file" id="file" className="btn btn-outline-primary" accept=".csv" required />
        </Form.Group>
      </Form>
    </Col>
  );
}

function Download(loaded) {
  const [downloading, setDownloading] = useState(false);
  if (!loaded) {
    return (
      <Col>
        <Placeholder xs={6} />
      </Col>
    );
  }

  const handleDownload = (e) => {
    e.preventDefault();
    setDownloading(true);
    fetch(`${GetAPIurl()}/api/eplan/cableconverter/zip`)
      .then((reponse) => reponse.json())
      .then(data => {
        const zip = JSZip();
        zip.file(`${data.name}`, data.bytes, { base64: true })
        zip.generateAsync({ type: "blob" })
          .then((content) => {
            saveAs(content, `${data.name.substring(0, data.name.length - 4)}_converter.zip`);
            setDownloading(false);
          })
          .catch((error) => {
            toast.error("Ging wat fout bij downloaden.");
            console.error(error);
            setDownloading(false);
          })
      })
      .catch((error) => console.error(error));
  }

  return (
    <Col>
      <Button title="Bestand opslaan" onClick={handleDownload} disabled={downloading}>
        <i className="fas fa-floppy-disk"></i>
        Bestand opslaan
      </Button>
    </Col>
  );
}

function Tablepreview(info) {
  if (!info) {
    return (
      <Row>
        <Col>
        </Col>
        <Col>
          {Tableinfo()}
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col>
        <h4>Bestand</h4>
        <Table striped bordered>
          <colgroup>
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>Id</th>
              <th>Oud</th>
              <th>Nieuw</th>
            </tr>
          </thead>
          <tbody>
            {info.oldpreview.map((line, index) => (
              <tr key={Math.random()}>
                <td>{index}</td>
                <td>{line}</td>
                <td>{info.newpreview[index]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      <Col>
        {Tableinfo()}
      </Col>
    </Row>
  )
}

function Tableinfo() {
  return (
    <Col>
      <h4>System lijst</h4>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Input</th>
            <th>Output</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>28</td>
            <td>0.08</td>
          </tr>
          <tr>
            <td>26</td>
            <td>0.14</td>
          </tr>
          <tr>
            <td>24</td>
            <td>0.25</td>
          </tr>
          <tr>
            <td>22</td>
            <td>0.34</td>
          </tr>
          <tr>
            <td>20</td>
            <td>0.75</td>
          </tr>
          <tr>
            <td>18</td>
            <td>1.00</td>
          </tr>
          <tr>
            <td>16</td>
            <td>1.50</td>
          </tr>
          <tr>
            <td>14</td>
            <td>2.50</td>
          </tr>
          <tr>
            <td>12</td>
            <td>4.00</td>
          </tr>
          <tr>
            <td>10</td>
            <td>6.00</td>
          </tr>
          <tr>
            <td>8</td>
            <td>10.0</td>
          </tr>
          <tr>
            <td>6</td>
            <td>16.0</td>
          </tr>
          <tr>
            <td>4</td>
            <td>25.0</td>
          </tr>
          <tr>
            <td>3</td>
            <td>35.0</td>
          </tr>
          <tr>
            <td>2</td>
            <td>35.0</td>
          </tr>
          <tr>
            <td>1</td>
            <td>50.0</td>
          </tr>
        </tbody>
      </Table>
    </Col>
  )
}