import React, { useEffect, useState } from 'react';
import DateManager from "../components/managers/DateManager";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import apiCall from '../components/utils/apiCall';
import { useNavigate } from 'react-router-dom';
import { StatusBadge } from '../components/managers/BadgeManager';
import { useAuth } from '../context/AuthContext';

const Dashboard = () => {

  const navigate = useNavigate();
  const { user } = useAuth();
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    (async () => {
      setProjects(await apiCall("project/all", "POST"));
      setCompanies(await apiCall("company/all", "POST"));
      setEmployees(await apiCall("employee/all", "POST"));
    })();
  }, []);

  function GridItem(icon, title, data, link) {
    return (
      <Col>
        <Card onClick={() => { navigate(link); }} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} className='mb-3'>
          <Card.Body>
            <Card.Title className='text-center'>
              <i className={icon}></i> {title}
            </Card.Title>
            <Card.Text className='text-center'>{data}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  return (
    <Container fluid>
      <Card className='mb-3'>
        <Card.Body>
          <Card.Title className="text-center">
            <i className="fas fa-briefcase"></i> Welkom <b className='text-info'>{user.Fullname}</b> op de PIA Solutions Environment
          </Card.Title>
        </Card.Body>
      </Card>
      <Row>
        {GridItem("fas fa-briefcase", "Projecten", projects.length, "/projects")}
        {GridItem("fa fa-group", "Klanten", companies.length, "/companies")}
        {GridItem("fas fa-people-carry-box", "Medewerkers", employees.length, "/people")}
        {/*{GridItem("fas fa-box", "Materialen", GetLength("materials"), "/materials")}*/}
        {/*{GridItem("fas fa-dolly", "Unifeed Materialen", GetLength("unifeed/products"), "/unifeed/materials")}*/}
        {/*{GridItem("fas fa-clipboard-check", "Beschikbare Unifeed Materialen", GetAvailableUnifeedMaterials(), "/available-unifeed/materials")}*/}
      </Row>
      <Card className='mb-3'>
        <Card.Body>
          <Card.Title className="text-center">
            <i className="fas fa-briefcase"></i> Projects
          </Card.Title>
          <Table striped hover className='mt-3'>
            <thead>
              <tr>
                <th>Naam</th>
                <th>Start Datum</th>
                <th>Eind Datum</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project, index) => (
                <tr key={index} onClick={() => { navigate(`/projects/details/${project._id}`) }}>
                  <td>{`${project.Code} ${project.Description}`}</td>
                  <td>
                    {project.StartDate ? (
                      <DateManager dateString={project.StartDate} />
                    ) : (
                      "n.v.t"
                    )}
                  </td>
                  <td>
                    {project.EndDate ? (
                      <DateManager dateString={project.EndDate} />
                    ) : (
                      "n.v.t"
                    )}
                  </td>
                  <td>
                    <StatusBadge statusTitle={project.Status} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Dashboard;
