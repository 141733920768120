import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { usePagination } from '../managers/PaginationManager';
import DetailsQuestionPropertyBox from '../propertyboxes/DetailsQuestionPropertyBox';
import CreateQuestionPropertyBox from '../propertyboxes/CreateQuestionPropertyBox';
import { useAuth } from "../../context/AuthContext";
import ExportDialogBox from "../managers/ExportManager";
import { getAllKeys } from "../utils/MakeJSON";
import apiCall from "../utils/apiCall";
import { usesort } from '../managers/SortingManager';

/**
 * README QuestionsTable
 *
 * QuestionsTable.jsx is een React component bestand dat de hoofdcomponent QuestionsTable bevat.
 * Deze component wordt gebruikt om de vragen van een specifieke checklist of globale vragen in de applicatie te beheren, te bewerken en bij te houden.
 *
 * ====================================================================================================================================================
 *
 * QuestionsTable:
 * Deze component wordt gebruikt om globale vragen of de vragen van een specifieke checklist te beheren.
 * Het biedt een gebruikersinterface voor het navigeren door de vragenlijst, het bewerken van vragen, en het bijhouden van de voortgang.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * ====================================================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het QuestionsTable.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * De props omvatten de huidige data, de groepsindex, de groep, de vraagelementen en het type.

 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een array van objecten met de volgende sleutels:
 *  - QuestionID: een unieke ID voor de vraag
*      - Element: het onderdeel van de vraag
*      - Role: de rol die de vraag moet invullen
*      - QuestionType: het type vraag
*      - Name: de vraag
*      - QuestionAnswerOptions: de antwoordopties voor de vraag
*      - DocID: het document ID van de vraag
*      - isActive: of de vraag actief is
 *
 */

/**
 *
 * @param GetData
 * @param data
 * @param groupIndex
 * @param group
 * @param questionElements
 * @param type
 * @returns {Element}
 * @constructor
 */
// Functie om de vragen te tonen in een tabel
const TableQuestions = ({ GetData, data, groupIndex, group, questionElements, type }) => {
    const [questions, setQuestions] = useState(data);
    const [editing, setEditing] = useState(false);
    const [newQuestion, setNewQuestion] = useState({ References: {}, Time: {} });
    const [showCreate, setShowCreate] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedID, setSelectedID] = useState(null);
    const { currentItems, AboveTable, BelowTable, indexOfFirstItem, reset } = usePagination(data);
    const [key, setKey] = useState('general');
    const [editQuestion, setEditQuestion] = useState({});
    const [subQuestionsArray, setSubQuestionsArray] = useState([]);
    const [sortedItems, setSortedItems] = useState([]);
    const [sortDirection, setSortDirection] = useState(null);
    const [taskGroups, setTaskGroups] = useState([]);
    const [dataTypes, setDataTypes] = useState([]);
    const [dataTargets, setDataTargets] = useState([]);
    const columns = getAllKeys(data[0]);

    //Haalt de referenties op voor om een vraag te maken of updaten
    async function getReferences(setDataTypes, setDataTargets) {
        try {
            const response = await apiCall(`metadata/get_reference`, 'POST');
            const references = response;
            await setDataTypes(references.dataTypes);
            await setDataTargets(references.dataTargets);
        } catch (error) {
            console.error('There has been a problem with your fetch operation: ' + error);
        }
    }

    const { id } = useParams();
    const { user } = useAuth();

    //Sluit de modals
    const handleClose = (option) => {
        setEditing(false);

        if (option === "create") {
            setShowCreate(false);
        } else if (option === "details") {
            setShowDetails(false);
        }

        setKey('general');

        if (!showDetails) {
            setSelectedID(null);
            setEditQuestion(null);
        }

        if (!showCreate) {
            setNewQuestion({});
        }
    };

    //Laat de modals zien
    const handleShow = (option, id) => {
        if (option === "create") {
            setShowCreate(true);
        } else if (option === "details") {
            setShowDetails(true);
        }

        setSelectedID(id);
    };

    //Reset de state variabelen
    useEffect(() => {
        reset();
    }, [groupIndex, reset]);

    //Update de vragen
    useEffect(() => {
        if (dataTargets.length === 0) {
            getReferences(setDataTypes, setDataTargets);
        }
        if (taskGroups.length === 0) {
            (async () => {
                const functionList = await apiCall("metadata/functionlist", "POST", {});
                setTaskGroups(functionList);
            })();
        }
    }, [dataTargets.length, taskGroups.length]);

    //Update de vragen
    useEffect(() => {
        setSortedItems(currentItems);
    }, [currentItems, AboveTable]);

    //Update de subvragen
    useEffect(() => {
        setNewQuestion(prevQuestion => ({ ...prevQuestion, SubQuestions: subQuestionsArray }));
        setEditQuestion(prevEditQuestion => ({ ...prevEditQuestion, SubQuestions: subQuestionsArray }));
    }, [subQuestionsArray]);

    return (
        <>
            <div>
                <Button className='me-2'
                    variant="success"
                    key={`new_question_button_${group}`}
                    disabled={type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin" || "Projectleider") : true}

                    onClick={() => handleShow("create")}
                >
                    <i className="fa fa-plus" /> Nieuwe vraag
                </Button>
                <Button className='m-2'
                    variant="info"
                    disabled={type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin" || "Projectleider") : true}
                    onClick={() => ExportDialogBox(questions, group, columns)}>
                    <i className={"fa fa-download"}></i> Exporteer {group}
                </Button>
                {AboveTable}

                <Table striped className="mt-2">
                    <colgroup>
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '7.5%' }} />
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '2.5%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }} onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, 'QuestionID')}>#</th>
                            <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, 'Element')}>Onderdeel</th>
                            <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, 'Role')}>Invullen door</th>
                            <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, 'Type')}>Type</th>
                            <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, 'Name')}>Vraag</th>
                            <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, 'QuestionAnswerOptions')}>Meerkeuzen Antwoorden</th>
                            <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, 'DocID')}>Document ID</th>
                            <th onClick={() => usesort(sortedItems, setSortedItems, setSortDirection, sortDirection, 'isActive')}>Actief</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedItems && sortedItems.flatMap((question, index) => {
                            return (
                                <tr key={question.QuestionID} onClick={() => {
                                    handleShow("details", question.QuestionID);
                                    setEditing(true)
                                }}>
                                    <td key={`ID_${question.QuestionID}`} style={{ textAlign: "center" }}
                                        id="qindex">{question.QuestionID}</td>
                                    <td key={`Element_${question.QuestionID}`}>{question.Element}</td>
                                    <td key={`Role_${question.QuestionID}`}
                                        style={{ whiteSpace: "pre-wrap" }}>{question.Role}</td>
                                    <td key={`Type_${question.QuestionID}`}>{question.QuestionType}</td>
                                    <td key={`Question_${question.QuestionID}`} style={{ overflow: "auto" }}>
                                        <div key={`QuestionName_${question.QuestionID}`}
                                            style={{ whiteSpace: "pre-wrap" }}>{question.Name}</div>
                                    </td>
                                    <td key={`AnswerOptions_${question.QuestionID}`}>
                                        {question.QuestionType === "multi" &&
                                            question.QuestionAnswerOptions.map((answer, answerIndex) => {
                                                return (
                                                    <div key={`Answer_${question.QuestionID}_${answerIndex}`}>
                                                        {answer}
                                                    </div>
                                                );
                                            })}
                                    </td>
                                    <td key={`DocID_${question.QuestionID}`}>{question.DocID}</td>
                                    <td key={`Active_${question.QuestionID}`}>
                                        <Form.Check
                                            type="switch"
                                            key={`ActiveSwitch_${question.QuestionID}`}
                                            id={`question_active_table_${index}`}
                                            disabled
                                            checked={question.isActive}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <div>
                    {/* Details Question Propertybox */}
                    {selectedID ? <DetailsQuestionPropertyBox
                        key={`details_propertybox_${selectedID}`}
                        showDetails={showDetails}
                        setShowDetails={setShowDetails}
                        handleClose={handleClose}
                        questions={questions}
                        setQuestions={setQuestions}
                        currentItems={currentItems}
                        selectedID={selectedID}
                        editing={editing}
                        setEditing={setEditing}
                        taskGroups={taskGroups}
                        questionElements={questionElements}
                        type={type}
                        group={group}
                        code={id}
                        dataTypes={dataTypes}
                        dataTargets={dataTargets}
                        GetData={GetData}
                    /> : null}

                    {/* Create Question Propertybox */}
                    {showCreate ? <CreateQuestionPropertyBox
                        key={`create_propertybox_${group}`}
                        showCreate={showCreate}
                        setShowCreate={setShowCreate}
                        handleClose={handleClose}
                        questions={questions}
                        questionElements={questionElements}
                        taskGroups={taskGroups}
                        type={type}
                        group={group}
                        code={id}
                        dataTypes={dataTypes}
                        dataTargets={dataTargets}
                        GetData={GetData}
                    /> : null}
                </div>
                <Row>
                    <Col sm={11}>
                        {BelowTable}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default TableQuestions;