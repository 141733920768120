import React, { useState, useEffect, useRef } from 'react';
import apiCall from '../../components/utils/apiCall';
import { Badge, Button, Card, Table } from "react-bootstrap";
import { displayToast } from '../../components/managers/ToastManager';

function Functionlist() {
  const [functionList, setFunctionList] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [newFunction, setNewFunction] = useState({
    name: '',
    color: '',
    hourlyRate: ''
  });

  const bottomRef = useRef(null);

  // Dit is om de functies op te halen
  useEffect(() => {
    const getFunctions = async () => {
      const functions = await apiCall('metadata/functionlist', 'POST', {});
      setFunctionList(functions);
    };
    getFunctions();
  }, []);

  // Dit is om de functies te bewerken  
  const handleEditClick = (id) => {
    setEditMode(id);
    displayToast("You are now in edit mode", "info")
  };

  // Dit is om de input te veranderen
  const handleInputChange = (id, field, value) => {
    setEditedValues({
      ...editedValues,
      [id]: {
        ...editedValues[id],
        [field]: value
      }
    });
  };

  // Dit is om de functie op te slaan
  const handleSaveClick = async (id) => {
    const updatedFunction = {
      key: id,
      ...functionList[id],
      ...editedValues[id]
    };

    try {
      await apiCall('metadata/edit_functionlist', 'POST', updatedFunction);
      setFunctionList({
        ...functionList,
        [id]: updatedFunction
      });

      setEditMode(false);
      setEditedValues({});
    } catch (error) {
      console.error('Error updating function:', error);
    }
  };

  // Dit is om de bewerking te annuleren
  const handleCancelClick = () => {
    setEditMode(false);
    setEditedValues({});
    displayToast("You are not in edit mode anymore", "info")
  };

  // Dit is om de functie toe te voegen
  const handleAddClick = async () => {
    try {
      const response = await apiCall('metadata/edit_functionlist', 'POST', newFunction);
      const newFunctionWithKey = { ...newFunction };
      setFunctionList({
        ...functionList,
        [response.id]: newFunctionWithKey
      });
      setNewFunction({
        name: '',
        color: '',
        hourlyRate: ''
      });
    } catch (error) {
      console.error('Error adding function:', error);
    }
  };

  // Dit is om naar beneden te scrollen om een nieuwe functie toe te voegen
  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Card>
        <Card.Header>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1 id="tableLabel" style={{ margin: "0", marginRight: "10px" }}>Functions</h1>
              <Button variant="success" onClick={scrollToBottom}>
                <i className="fas fa-plus" />
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table className="mt-2" striped hover>
            <thead>
              <tr>
                <th>Functienaam</th>
                <th>Kleur</th>
                <th>Uurloon in €</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(functionList).map(([id, func]) => (
                <tr key={id}>
                  <td>{func.name}</td>
                  <td>
                    {editMode === id ? (
                      <input
                        type="color"
                        value={editedValues[id]?.color || func.color || ''}
                        onChange={(e) => handleInputChange(id, 'color', e.target.value)}
                        style={{ width: '100%', padding: '5px', boxSizing: 'border-box' }}
                      />
                    ) : (
                      <div style={{ width: '30px', height: '30px', backgroundColor: func.color, border: '1px solid #ccc' }}></div>
                    )}
                  </td>
                  <td>
                    {editMode === id ? (
                      <input
                        type="number"
                        value={editedValues[id]?.hourlyRate || func.hourlyRate || ''}
                        onChange={(e) => handleInputChange(id, 'hourlyRate', e.target.value)}
                      />
                    ) : func.hourlyRate === 0 ? <Badge variant="danger">Geen uurloon</Badge> : <Badge variant="success">€ {func.hourlyRate}</Badge>}
                  </td>
                  <td>
                    {editMode === id ? (
                      <>
                        <Button
                          onClick={() => handleSaveClick(id)}
                          variant="primary"
                          style={{ marginRight: '5px' }}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => handleCancelClick()}
                          variant="secondary"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => handleEditClick(id)}
                          variant="primary"
                          style={{ marginRight: '5px' }}
                        >
                          <i className="fas fa-pen" /> Edit
                        </Button>
                        <Button onClick={async () => {
                          await apiCall('metadata/delete_function', 'POST', { key: func.key })
                          displayToast("Function deleted", "success")
                          window.location.reload()
                        }} 
                        variant="outline-danger">
                          <i className='fa fa-trash' /> Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
              <tr ref={bottomRef}>
                <td>
                  <input
                    type="text"
                    value={newFunction.name}
                    onChange={(e) => setNewFunction({ ...newFunction, name: e.target.value })}
                  />
                </td>
                <td>
                  <input
                    type="color"
                    value={newFunction.color}
                    onChange={(e) => setNewFunction({ ...newFunction, color: e.target.value })}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={newFunction.hourlyRate}
                    onChange={(e) => setNewFunction({ ...newFunction, hourlyRate: e.target.value })}
                  />
                </td>
                <td>
                  <Button onClick={handleAddClick} variant="success"><i className='fa fa-plus' /> Add</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}

export default Functionlist;
