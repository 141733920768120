import {Button, Col, Container, Form, Placeholder, Row, Table} from "react-bootstrap";
import {useState} from "react";
import {TwoFileCheck} from "../../../components/hesselToDo/Checkfiles";
import toast from "react-hot-toast";
import JSZip from "jszip";
import {saveAs} from "file-saver";

import {GetAPIurl} from "../../../components/hesselToDo/Data";

export default function Converter() {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState();
  return (
    <>
        <Container fluid>
          <Row>
            {Start(setLoaded, setData)}
            {Download(loaded)}
          </Row>
          {GetTables(loaded, data)}
        </Container>
    </>
  );
}
function Start(setLoaded, setData) {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!TwoFileCheck(e.target.fileold.files, e.target.filenew.files, "csv")) return;

    var formData = new FormData();
    formData.append('fileold', e.target.fileold.files[0]);
    formData.append('filenew', e.target.filenew.files[0]);

    var myheaders = {
      "Access-Control-Allow-Origin": "*",
    };

    var req = {
      method: "POST",
      headers: myheaders,
      body: formData,
    };

    fetch(`${GetAPIurl()}/api/eplan/cableplusmin/file`, req)
      .then((response) => response.json())
      .then((d) => {
        setData(d);
        setLoaded(true);
        toast.success("Gelukt!");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Gefaald");
      });
  }
  return (
    <Col>
      <h3>Kabel plus-minlijst</h3>
      <span>
        Hier wordt de oude lijst met de nieuwe lijst vergelekgen en op twee
        lijsten gezet plus en min.
      </span>
      <Form className="was-validated" onSubmit={handleSubmit}>
        <h6>Voer hier de oude bestand in:</h6>
        <Form.Group className="input-group mb-3">
          <label htmlFor="fileold" className="input-group-text btn btn-outline-primary"><i className="fas fa-cloud-arrow-up"></i></label>
          <Form.Control type="file" id="fileold" className="btn btn-outline-primary" accept=".csv" required />
        </Form.Group>
        <h6>Voer hier de nieuwe bestand in:</h6>
        <Form.Group className="input-group mb-3">
          <label htmlFor="filenew" className="input-group-text btn btn-outline-primary"><i className="fas fa-cloud-arrow-up"></i></label>
          <Form.Control type="file" id="filenew" className="btn btn-outline-primary" accept=".csv" required />
        </Form.Group>
        <Button type="submit">Laden</Button>
      </Form>
    </Col>
  );
}

function confetti() {
  const duration = 5 * 1000,
    animationEnd = Date.now() + duration,
    defaults = { startVelocity: 30, spread: 360, ticks: 20, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const interval = setInterval(function () {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 20 * (timeLeft / duration);

    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      })
    );
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      })
    );
  }, 250);
}

function Download(loaded) {
  const [downloading, setDownloading] = useState(false);
  if (!loaded) {
    return (
      <Col>
        <Placeholder xs={6} />
      </Col>
    );
  }

  const handleDownload = (e) => {
    e.preventDefault();
    setDownloading(true);
    fetch(`${GetAPIurl()}/api/eplan/cableplusmin/zip`)
      .then((reponse) => reponse.json())
      .then(data => {
        const zip = JSZip();
        if (data.pluslist != "") {
          zip.file(`${data.date}_${data.name}_plus.csv`, data.pluslist, { base64: true })
        }
        if (data.minlist != "") {
          zip.file(`${data.date}_${data.name}_min.csv`, data.minlist, { base64: true })
        }

        if (Object.keys(zip.files).length < 1) {
          toast.error("Er waren geen verschillen.");
          confetti();
          setDownloading(false);
          return;
        }

        zip.generateAsync({ type: "blob" })
          .then((content) => {
            saveAs(content, `${data.date}_plusmin.zip`);
            setDownloading(false);
          })
          .catch((error) => {
            toast.error("Ging wat fout bij downloaden.");
            console.error(error);
            setDownloading(false);
          })
      })
      .catch((error) => console.error(error));
  }

  return (
    <Col>
      <Button title="Bestand opslaan" onClick={handleDownload} disabled={downloading}>
        <i className="fas fa-floppy-disk"></i>
        Bestand opslaan
      </Button>
    </Col>
  );
}

function GetTables(loaded, data) {
  if (!data || !loaded) {
    return (
      <Row>
        <Col>
          <h4>Pluslijst</h4>
          <span>Wachten...</span>
        </Col>
        <Col>
          <h4>Minlijst</h4>
          <span>Wachten...</span>
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col>
        <h4>Pluslijst</h4>
        {TableData(data.pluslist)}
      </Col>
      <Col>
        <h4>Minlijst</h4>
        {TableData(data.minlist)}
      </Col>
    </Row>
  );
}

function TableData(data) {
  if (!data || data.length < 1) {
    return (<h4>Geen data</h4>);
  }
  return (
    <Table striped bordered>
      <colgroup>
        <col />
      </colgroup>
      <thead>
        <tr>
          <th style={{ width: "60px" }}>Id</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((line) => (
          <tr key={line[0]}>
            <td>{line[0]}</td>
            <td>{line}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}