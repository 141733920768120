import {useNavigate} from 'react-router-dom';
import {displayToast} from '../managers/ToastManager';
import {useAuth} from '../../context/AuthContext';

const useClickPermissionHandler = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClickPermission = (msg, route, required_roles = []) => {
    if (user && required_roles.length > 0 && !required_roles.some(role => user.Roles.includes(role))) {
      displayToast(msg, "error", 10000);
    } else {
      navigate(route);
    }
  };

  const handleExactPermission = (msg, route) => {
    if (user && user.Roles.includes("Exact")) {
      if (user && user.access_token === false) {
        displayToast(msg, "error", 10000);
      } else {
        navigate(route);
      }
    } else {
      displayToast("U heeft niet de juiste rechten om deze actie uit te voeren.", "error", 10000);
    }
  };

  return {
    handleClickPermission,
    handleExactPermission,
  };
};

export default useClickPermissionHandler;
