import {Button, Card, Col, Form, Row} from "react-bootstrap";

import {CreateClockifyKlant} from "../../../components/hesselToDo/Clockify";

export default function details() {
  return (
    <>
      <Card>
        <Card.Header>
          <h4>Klanten - Aanmaken</h4>
        </Card.Header>
        <Card.Body>{FormCreate()}</Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" href="/clockify/clients">
            Terug
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

function FormCreate() {

  const handleSubmit = async (e) => {
    e.preventDefault();

    const raw = {
      "name": e.target['name'].value
    };

    CreateClockifyKlant(raw);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className="was-validated">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="1">
            Naam
          </Form.Label>
          <Col sm="5">
            <Form.Control
              type="text"
              id="name"
              maxLength={100}
              required
            />
          </Col>
        </Form.Group>
        <Button variant="success" type="submit">
          Aanmaken
        </Button>
      </Form>
    </div>
  );
}
