import React, {useState} from 'react';

function Exacttest() {
  const [responseData, setResponseData] = useState(null);

  const fetchData = () => {
    // JE MOET HIER JOU API CALL DOEN
    fetch(`${process.env.REACT_APP_API_URL}employees/`)
      .then(response => response.json())
      .then(data => setResponseData(data))
      .catch(error => console.error('Error fetching data:', error));
  };

  return (
    <div>
      <h1>Martijn's test pagina</h1>
      <button onClick={fetchData}>API CALL KNOP</button>
      {responseData && (
        <div>
          <h2>Response Data:</h2>
          <pre>{JSON.stringify(responseData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default Exacttest;
