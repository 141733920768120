import React from 'react'
import CabinetTable from '../../../components/tables/CabinetTable'
import {useParams} from 'react-router-dom'


export default function Equipmentlist() {

    const { id } = useParams();

    return (
        <CabinetTable id={id} type="data_equipmentlist" />
    )
}