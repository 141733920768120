// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_selectProject__nKGxB {
  color: #03a9f4;
  cursor: pointer;
  margin-top: 10px;
}

.profile_selectProject__nKGxB:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/styles/profile.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".selectProject {\n  color: #03a9f4;\n  cursor: pointer;\n  margin-top: 10px;\n}\n\n.selectProject:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectProject": `profile_selectProject__nKGxB`
};
export default ___CSS_LOADER_EXPORT___;
