import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row, Table } from "react-bootstrap";
import {BarLoader, ClipLoader} from "react-spinners";
import {useNavigate, useParams} from "react-router-dom";
import apiCall, {apiCallRaw} from "../../../../components/utils/apiCall";
import "../../../../styles/wo/dashboard.css";
import {displayToast} from "../../../../components/managers/ToastManager";
import EmployeeManager from "../../../../components/managers/EmployeeManager";
import {OptionsByUserList} from "../../../../components/utils/OptionsByFunction";
import "../../../../styles/wo/wo-global.css";
import { ProjectHead, WOAppProjectHeader } from "../../../../components/wo/wo_app_projectinfo";
import DownloadProjectDatasheet from "../../../../components/utils/ProjectDatasheet";
import StandaloneProjectDatasheet from "../../../../components/utils/StandaloneProjectDatasheet";

export default function Project() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [project, setProject] = useState({});
    const [workorders, setWorkorders] = useState({});
    const [werkvoorbereiders, setWerkvoorbereiders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState({});
    const [werkvoorbereider, setWerkvoorbereider] = useState("Niet Toegewezen");
    const [assignee, setAssignee] = useState("");
    const [isArchived, setIsArchived] = useState(null);

    useEffect(() => {
        displayToast("Loading project data...", "info");
        const fetchProjectAndWorkorders = async () => {
            try {
                const projectData = await apiCall(`wo/exact/get_project/${id}`, "POST");
                setProject(projectData);
                if (projectData?.ID) {
                    const projectDataOld = await apiCall(`wo/projects/get_project_by_id/${projectData.ID}`, "POST");
                    setIsArchived(projectDataOld.Archived);
                    const werkvoorbereidersData = await apiCall("employee/getbyfunction", "POST", "Werkvoorbereider");
                    setWerkvoorbereiders(werkvoorbereidersData);
                    const hexPattern = /^[a-f0-9]{32}$/;
                    if (hexPattern.test(projectDataOld.ClaimedBy)) {
                        const manager = await EmployeeManager.create(projectDataOld.ClaimedBy);
                        setWerkvoorbereider(`${manager.employee.Firstname} ${manager.employee.Lastname}`);
                        setAssignee(projectDataOld.ClaimedBy);
                    } else {
                        setWerkvoorbereider("Niet Toegewezen");
                    }
                    setWorkorders(projectDataOld.workorders);
                } else {
                    setWorkorders({});
                }
            } catch (error) {
                displayToast("Er is een fout opgetreden bij het ophalen van de projectgegevens.", "error");
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchProjectAndWorkorders();
        }
    }, [id]);

    const handleWorkorderButton = async (workorderID, action, endpoint) => {
        if (action === "delete") {
            if (!window.confirm("Weet u zeker dat u deze werkorder wilt verwijderen? Dit kan NIET ongedaan worden gemaakt. (Alle gerelateerde data die met de items van deze werkorder te maken hebben zullen ook worden verwijderd)")) {
                return;
            }
        }

        setButtonLoading((prev) => ({ ...prev, [`${workorderID}_${action}`]: true }));
        try {
            const response = await apiCallRaw(endpoint, 'POST', { id: workorderID });
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.error || 'Unknown error');
            }
            displayToast(responseData.message, "success");

            const workordersData = await apiCall(`wo/workorder/get_workorders_by_project/${project.ID}`, "POST");
            setWorkorders(workordersData);

        } catch (error) {
            displayToast(error.message, "error");
        } finally {
            setButtonLoading((prev) => ({ ...prev, [`${workorderID}_${action}`]: false }));
        }
    };

    const handleAssignButton = async (event) => {
        const selectedEmployee = event.target.value;
        const response = await apiCallRaw(`wo/projects/assign_project`, "POST", { id: project.ID, EmployeeID: selectedEmployee });
        const responseData = await response.json();
        if (!response.ok) {
            displayToast(responseData.error || 'Unknown error', "error");
        }
        displayToast(responseData.message, responseData.responseType);
        setAssignee(selectedEmployee);
        setWerkvoorbereider(responseData.content)
    };

    if (Object.keys(project).length === 0 && !loading) {

        displayToast("Project niet gevonden.", "error");

        return (
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <h1>Project Overzicht</h1>
                    <Button variant="danger" className="px-3" onClick={() => navigate(-1)}>Back</Button>
                </Card.Header>
                <Card.Body>
                    <div className="my-3">
                        <Row>
                            <Col>
                                <p>Project niet gevonden... Ga terug naar het projecten overzicht. Als dit probleem zich blijft voordoen, neem dan contact op met de systeembeheerder.</p>
                                <Button variant="danger" onClick={() => navigate(-1)}>Terug</Button>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        );
    }

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <ProjectHead ProjectCode={project?.Code}/>
                <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                    {!loading && <StandaloneProjectDatasheet project={project} />}
                    {!loading &&
                        <Button
                            variant={isArchived ? "success" : "danger"}
                            className="px-3"
                            onClick={async () => {
                                const response = await apiCallRaw(`wo/projects/archive_project`, "POST", { id: project.ID });
                                const responseData = await response.json();
                                if (!response.ok) {
                                    displayToast(responseData.error || 'Unknown error', "error");
                                } else {
                                    displayToast(responseData.message, responseData.responseType);
                                    setIsArchived(responseData.content);
                                }
                            }}
                        >{isArchived ? "Heropenen" : "Archiveren"}</Button>
                    }
                    {!loading &&
                        <select 
                            className="mx-2 py-2 px-3 form-select"
                            value={assignee}
                            onChange={handleAssignButton}
                        >
                            <option value="Niet Toegewezen">Vrijgeven</option>
                            <OptionsByUserList user_list={werkvoorbereiders} />
                        </select>
                    }
                    <Button variant="danger" className="px-3" onClick={() => navigate(-1)}>Back</Button>
                </div>
            </Card.Header>
            {loading ? (
                <Card.Body className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <BarLoader color='#007f6c' width={500} speedMultiplier={1} />
                </Card.Body>
            ) : (
                <Card.Body>
                    <WOAppProjectHeader project={project} isArchived={isArchived} werkvoorbereider={werkvoorbereider} />
                    <Table striped hover>
                        <colgroup>
                            <col style={{ width: '7.5%' }} />
                            <col style={{ width: '7.5%' }} />
                            <col style={{ width: '35%' }} />
                            <col style={{ width: '7.5%' }} />
                            <col style={{ width: '7.5%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '10%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>WO Nummer</th>
                                <th>Naam</th>
                                <th>Beschrijving</th>
                                <th>Hoeveelheid</th>
                                <th>Status</th>
                                <th>Opties</th>
                                <th>Eind Datum</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(workorders).length > 0 ? (
                                Object.values(workorders).map((workorder) => (
                                    <tr key={workorder.ID}>
                                        <td className="text-wrap">{workorder.ShopOrderNumber}</td>
                                        <td onClick={() => {
                                            navigate(`/wo/details/${workorder.ID}`);
                                        }} className="text-wrap">{workorder.ItemCode}</td>
                                        <td className="text-wrap">{workorder.Description}</td>
                                        <td>{workorder.PlannedQuantity}</td>
                                        <td>{workorder.Status}</td>
                                        <td className="text-wrap">
                                            {!isArchived && (
                                                <>
                                                    <Button
                                                        variant="primary"
                                                        style={{ margin: '2.5px' }}
                                                        disabled={buttonLoading[`${workorder.ID}_update`]}
                                                        onClick={() => {
                                                            handleWorkorderButton(workorder.ID, 'update', 'wo/exact/update_workorder_button');
                                                        }}
                                                    >
                                                        {buttonLoading[`${workorder.ID}_update`] ? <ClipLoader size={20} color="#fff" /> : 'Update'}
                                                    </Button>
                                                    {" "}
                                                    <Button
                                                        variant="danger"
                                                        style={{ margin: '2.5px' }}
                                                        disabled={buttonLoading[`${workorder.ID}_delete`]}
                                                        onClick={() => {
                                                            handleWorkorderButton(workorder.ID, 'delete', 'wo/exact/delete_workorder_button');
                                                        }}
                                                    >
                                                        {buttonLoading[`${workorder.ID}_delete`] ? <ClipLoader size={20} color="#fff" /> : 'Delete'}
                                                    </Button>
                                                    {" "}
                                                    {workorder.ItemCode !== "Uren bewerking" && workorder.ShopOrderParentNumber === "Geen" && (<><DownloadProjectDatasheet project={project} workorder={workorder} /></>)}
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            {workorder.PlannedDate}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8">Geen werkorders gekoppeld aan dit project.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            )}
        </Card>
    );
}
